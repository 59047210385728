import React, { useEffect } from 'react';
import { get, isObjectLike } from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { reduxForm, Form as ReduxForm, Field, FieldArray, SubmissionError } from 'redux-form';

import TextArea from 'components/Common/TextArea';
import SelectTreeAdd from 'components/Common/SelectTree/SelectTreeAdd';
import ComboBox from 'components/Common/ComboBox';
import DinamicParamsForm from '../DinamicParamsForm/DinamicParamsForm';
import Header from './Header';
import InputField from '../../Common/InputField';
import FormBlock from './FormBlock';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { saveFeedback, } from 'actions/appeal';
import validates, { validate } from './validate';
import { isOrganization, findPropertyInArray, getValueOrNull, searchTree } from 'helpers';
import { ReduxFormDecorator as AppealTypeSelect } from './components/AppealTypeSelect';

function Form (props) {
	const {
		formParams, checkRestrictions, shouldRenderField, t, creationDate, repeatRequestId, operatorName,
		stateName, appeal, unlockAppealForm, destinations, customer, selectedAppealAction, handleSubmit,
		setAppealRoute,
		intiValues, initialized, initialize
	} = props;
	
	useEffect(() => {
		if (!initialized) {
			initialize(intiValues);
		}
	}, [initialized]);
	
	function submit (values, dispatch, props) {
		const validation = validates(values, props, props.appeal.restriction);
		const appealId = props.appeal.id;
		props.setSaveAndCreateNew(appealId, false);
		
		if (validation) {
			props.showValidationNotification();
			throw new SubmissionError({ ...validation, _error: 'error' });
		} else {
			const destination = [...values.destination].filter(value => value);
			const dynamicFields = { ...values.blocks };
			
			Object.keys(dynamicFields).forEach((key) => {
				if (Array.isArray(dynamicFields[key])) {
					if (dynamicFields[key].length) {
						dynamicFields[key] = dynamicFields[key].map(value => (value.id.toString()));
					} else {
						dynamicFields[key] = [null];
					}
				} else if (isObjectLike(dynamicFields[key]) && !moment.isMoment(dynamicFields[key])) {
					dynamicFields[key] = [getValueOrNull(dynamicFields[key].key)];
				} else if (dynamicFields[key] && moment(dynamicFields[key], 'YYYY-MM-DDThh:mm:ssZ', true).isValid()) {
					dynamicFields[key] = ['' + (moment(dynamicFields[key]).unix() * 1000)];
				} else {
					dynamicFields[key] = [getValueOrNull(dynamicFields[key])];
				}
			});


			const appealRoutes = [];
			destination.forEach((i) => {
				const isFounded = searchTree(props.destinations, 'result', (item) => {
					if(parseInt(item.object.id) === parseInt(i)){
						appealRoutes.push(item.object.route)
					}
				});
			});

			
			const saveAppealData = {
				appealId,
				destination,
				dynamicFields,
				subject: values.subject,
				description: getValueOrNull(values.description),
				type: String(get(values, 'type.id')),
				resolveDate: Number(moment(values.resolveDate).format('x')),
				priority: values.priority.value,
				solution: getValueOrNull(values.solution),
				objectTypeId: values.interactionId,
				interactionId: values.interactionId,
				objectTypeName: null,
				objectType: 'appeal',
				objectId: null,
				status: props.firstLevelAppealAction ? props.firstLevelAppealAction.toStateName : get(values, 'status.code'),
				resolution: get(values, 'resolution.id', null),
				customerId: null,
				contactPersonId: null,
				route: appealRoutes
				
			};

			if (values.timer && values.nextState) {
				saveAppealData.timer = values.timer;
				saveAppealData.nextState = values.nextState;
			}

			if (values.statusComment) {
				saveAppealData.statusComment = values.statusComment;
			}

			if (props.customer) {
				const contactsType = [];
				const contactsValue = [];

				saveAppealData.customerId = props.customer.id;

				if (props.contactPerson && isOrganization(props.customer.party.partyType)) {
					saveAppealData.contactPersonId = props.contactPerson.id;
				}

				if (values.destinationFeedback && values.destinationFeedback.length) {
					values.destinationFeedback.forEach((field, key) => {
						if (field === 0 && props.callPhoneNumber) {
							contactsType.push('homePhone');
							contactsValue.push(props.callPhoneNumber);
							values.destinationFeedback[key] = null;
						} else {
							contactsType.push(null);
							contactsValue.push(null);
						}
					});
					const saveFeedbackData = {
						data: {
							appealId,
							customerId: props.customer.id,
							contacts: values.destinationFeedback,
							contactsType: contactsType,
							contactsValue: contactsValue,
							personId: props.contactPerson && isOrganization(props.customer.party.partyType) ? props.contactPerson.id : null
						},
						jsonType: true,
					};

					saveFeedback(saveFeedbackData);
				}
			}

			_.forEach(saveAppealData, (value, key) => {
				if (value && moment(value, 'YYYY-MM-DDThh:mm:ssZ', true).isValid()) {
					saveAppealData[key] = '' + (moment(value).unix() * 1000);
				}
			});
			
			if (props.modalStatusList.length > 0) {
				props.setStatusModalVisibility(true, appealId);
			} else {
				props.saveAppeal(saveAppealData).then(() => {
					props.postSave({ saveAndCreateNew: props.saveAndCreateNew });
				});
			}
		}
	}
	
	function renderDynamicParams (orderFilter) {
		
		function filterCondition (block) {
			return (
				block.action !== 'del' &&
				block.widgets && block.widgets.length &&
				orderFilter(block.order) && block.widgets.some(widget => shouldRenderField(widget.key))
			);
		}
		
		return formParams && formParams.blocks.filter(filterCondition).map(renderFormBlock);
	}
	
	function renderFormBlock (block) {
		let widgets = block.widgets;
		
		if (selectedAppealAction.length) {
			widgets = block.widgets.map(i => {
				const newRestriction = selectedAppealAction.find(item => i.key === item.key);
				
				if (newRestriction) {
					return { ...i, isReq: newRestriction.isReq, disabled: newRestriction.isDisable };
				}
				
				return i;
			});
		}
		
		return (
			<FormBlock key={block.key}>
				{
					block.title && block.title !== '*' &&
					<div className={styles.formBlockTitle}>
						{block.title}
					</div>
				}
				<div className={cx(styles.view, styles[`view${block.view}`])}>
					<DinamicParamsForm
						blockKey={block.key}
						widgets={widgets}
						selectedAppealAction={selectedAppealAction}
						checkRestrictions={checkRestrictions}
						shouldRenderField={shouldRenderField}
						unlockAppealForm={unlockAppealForm}
					/>
				</div>
			</FormBlock>
		);
	}
	
	function feedbackOptions () {
		const { appealFeedback, incomePhoneNumber, customer } = props;
		
		if (!customer || !appealFeedback) return [];
		const options = [];
		
		const foundCustomer = findPropertyInArray(appealFeedback, 'type', 'customer') || {};
		if (foundCustomer.contacts) {
			foundCustomer.contacts.forEach(field => options.push({
				label: field.value,
				value: field.id,
				contact: field.value
			}));
		}
		
		if (isOrganization(customer.party.partyType)) {
			const gerContactPersons = appealFeedback.filter(i => (i.type === 'contactPerson' && foundCustomer.id !== i.id));
			if (gerContactPersons.length > 0) {
				gerContactPersons.forEach(contact => {
					contact.contacts.forEach(
						field => options.push(
							{
								label: <span>{field.value} <span className='opacity-half'>({contact.name})</span></span>,
								value: field.id,
								contact: field.value
							}
						)
					);
				});
			}
		}
		
		// TODO don't delete maybe it will be needed later
		// if (incomePhoneNumber) {
		// 	const existPreDefault = options.filter(i => i.contact === incomePhoneNumber);
		// 	if (existPreDefault.length === 0) {
		// 		options.push(
		// 			{ label: <span>{incomePhoneNumber}</span>, value: 0, contact: incomePhoneNumber }
		// 		);
		// 	}
		// }
		
		return options;
	}
	
	let shouldRenderFeedback;
	if (formParams !== null) {
		shouldRenderFeedback = findPropertyInArray(formParams.blocks, 'key', 'contactPerson');
	} else {
		shouldRenderFeedback = false;
	}
	
	const descriptionActions = selectedAppealAction.find(i => i.key === 'description');
	const typeActions = selectedAppealAction.find(i => i.key === 'type');
	const destinationFeedbackActions = selectedAppealAction.find(i => i.key === 'destinationFeedback');
	const destinationActions = selectedAppealAction.find(i => i.key === 'destination');
	const solutionActions = selectedAppealAction.find(i => i.key === 'solution');
	const subjectActions = selectedAppealAction.find(i => i.key === 'subject');
	
	return (
		<ReduxForm onSubmit={handleSubmit(submit)} className={cx(styles.formWrapper, styles[props.iconClass])}>
			<div className='scrollbox m-t-0'>
				<Header
					unlockAppealForm={unlockAppealForm}
					checkRestrictions={checkRestrictions}
					shouldRenderField={shouldRenderField}
					status={stateName}
					repeatAppealId={repeatRequestId}
					repeatCustomerId={customer && customer.id}
					creationDate={creationDate}
					author={operatorName}
					regnum={appeal.regnum}
					priorityList={props.priorityList}
					t={t}
				/>
				<div className='scrollbox-content'>
					
					{renderDynamicParams(order => order < 10)}
					
					<FormBlock>
						{
							shouldRenderField('subject') && ENVIRONMENT !== 'ukc' &&
							<div className={styles.inputWrapper}>
								<Field
									name='subject'
									id='subject'
									required={subjectActions ? subjectActions.isReq : false}
									disabled={subjectActions ? subjectActions.isDisable : checkRestrictions('subject')}
									label={t('appeal.subject')}
									component={InputField}
									onChange={unlockAppealForm}
								/>
							</div>
						}
						
						{
							shouldRenderField('description') &&
							<div className={cx(styles.inputWrapper, styles.textArea)}>
								<Field
									name='description'
									id='description'
									component={TextArea}
									label={t('appeal.description')}
									placeholder={t('appeal.descriptionPlaceholder')}
									required={descriptionActions ? descriptionActions.isReq : false}
									disabled={descriptionActions ? descriptionActions.isDisable : checkRestrictions('description')}
									autosize
									rows={1}
									onChange={unlockAppealForm}
									order={10}
								/>
							</div>
						}
						
						{
							shouldRenderField('type') &&
							<div className={styles.inputWrapper}>
								<Field
									name='type'
									id='type'
									label={t('appeal.type')}
									placeholder={t('appeal.selectPlaceholder')}
									component={AppealTypeSelect}
									nodeArray={props.appealTypes.children}
									required={typeActions ? typeActions.isReq : true}
									disabled={typeActions ? typeActions.isDisable : checkRestrictions('type')}
									onChange={unlockAppealForm}
									resetAppealType={props.resetAppealType}
								/>
							</div>
						}
					</FormBlock>
					
					{renderDynamicParams(order => order > 10 && order < 50)}
					
					<FormBlock>
						{
							shouldRenderFeedback && shouldRenderField('destinationFeedback') && feedbackOptions().length > 0 &&
							<div className={styles.inputWrapper}>
								<Field
									name='destinationFeedback'
									id='destinationFeedback'
									label={t('appeal.feedback')}
									component={ComboBox}
									options={feedbackOptions()}
									multi
									required={destinationFeedbackActions ? destinationFeedbackActions.isReq : false}
									disabled={destinationFeedbackActions ? destinationFeedbackActions.isDisable : checkRestrictions('destinationFeedback')}
									onChange={unlockAppealForm}
									placeholder={'-'}
								/>
							</div>
						}
						
						{
							shouldRenderField('routeDestination') &&
							<div id='executor' className={styles.inputWrapper}>
								<FieldArray
									name='destination'
									id='destination'
									component={SelectTreeAdd}
									label={t('appeal.destination')}
									placeholder={t('appeal.selectPlaceholder')}
									options={
										{
											initialOptions: destinations,
											searchable: true,
											treeParam: 'result',
											textField: 'text',
											valueField: (v) => { return v.id !== undefined ? v.id : v.object !== undefined ? v.object.id : null; }
										}
									}
									required={destinationActions ? destinationActions.isReq : false}
									disabled={destinationActions ? destinationActions.isDisable : checkRestrictions('destination')}
									unlockAppealForm={unlockAppealForm}
									onChange={setAppealRoute}
									resetActions={props.resetActions}
									additionDisabled={!props.canAddExecutors}
								/>
							</div>
						}
						{
							shouldRenderField('solution') &&
							<div className={cx(styles.inputWrapper, styles.textArea)}>
								<Field
									name='solution'
									id='solution'
									component={TextArea}
									label={t('appeal.solution')}
									placeholder={t('appeal.selectPlaceholder')}
									required={solutionActions ? solutionActions.isReq : false}
									disabled={solutionActions ? solutionActions.isDisable : checkRestrictions('solution')}
									onChange={unlockAppealForm}
									maxLength={2000}
									autosize
									rows={1}
								/>
							</div>
						}
					</FormBlock>
					{renderDynamicParams(order => order >= 50)}
				</div>
			</div>
		</ReduxForm>
	);
}

export default reduxForm({
	enableReinitialize: false,
	keepDirtyOnReinitialize: false,
	validate,
	destroyOnUnmount: false,
})(Form);
