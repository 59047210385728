import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import CommentItem from './CommentItem';
import CommentForm from './CommentForm';
import { setEditableComment, getComments, resetComments } from 'actions/comments';
import styles from 'styles/modules/appealOperatePage.module.scss';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import { translate } from 'react-i18next';
import cx from 'classnames';
import { KeyCodes } from 'constants/index';

const mapStateToProps = state => ({
    comments: state.comments.comments,
    commentsLoading: state.comments.commentsLoading,
    requestedForAppeal: state.comments.requestedForAppeal
});

const mapDispatchToProps = (dispatch) => ({
    setEditableComment: comment => dispatch(setEditableComment(comment)),
    getComments: (requestData, appealId) => dispatch(getComments(requestData, appealId)),
    resetComments: () => dispatch(resetComments())
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Comments extends React.Component {

    state = {
        formIsOpen: false
    };

    componentDidMount() {
        this.props.resetComments();
        this.requestComments();
    }

    componentDidUpdate (prevProps) {
		if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
			this.props.resetComments();
			this.requestComments();
		}
    }

    requestComments = () => {
        const appealId = this.props.match.params.appealId;

        const requestData = {
            appealId,
            isChild: 0,
            limit: 25
        };

        return this.props.getComments(requestData, appealId);
    };

    handleEditComment = commentId => {
        const editableComment = commentId ? this.props.comments.find(comment => comment.id === commentId) : null;
        this.props.setEditableComment(editableComment);
        this.openForm();
    };

    openForm = () => this.setState({ formIsOpen: true });

    closeForm = () => this.setState({ formIsOpen: false });

    handleKeyDown = event => {
        if (event.keyCode === KeyCodes.ESCAPE) {
            this.closeForm();
        }
    };

    handleClickOutside = (e) => {
        const inputClick = this.fileInput && this.fileInput.contains(e.target);
        const checkboxClick = this.checkbox && this.checkbox.contains(e.target);
        const sendButtonClick = this.sendButton && this.sendButton.contains(e.target);

        if (!inputClick && !checkboxClick && !sendButtonClick) {
            this.closeForm();
        }
    };

    renderComment = comment => (
        <CommentItem
            key={comment.id}
            handleEditComment={this.handleEditComment}
            {...comment}
        />
    );

    render() {
        const { formIsOpen } = this.state;
        const { t, commentsLoading, comments } = this.props;

        const footerClassName = cx(styles.commentFooter, !formIsOpen && styles.withButton);

        return (
            <ClickOutsideHolder
                onClickOutside={this.handleClickOutside}
                className={styles.tabContent}
            >
                <div className={cx('scrollbox', styles.commentContent)}>
                    <div className={cx('scrollbox-content', styles.commentContentScrollbox)}>
                        <div className={styles.comments}>
                            {
                                commentsLoading
                                    ? <Loader withContainer={true}/>
                                    : comments.map(this.renderComment)
                            }
                        </div>
                    </div>
                </div>

                <div className={footerClassName}>
                    {formIsOpen ? (
                        <CommentForm
                            refresh={this.requestComments}
                            handleEditComment={this.handleEditComment}
                            toggleForms={this.toggleForms}
                            closeOnSubmit={() => { this.setState({ formIsOpen: false }) }}
                        />
                    ) : (
                        <button className='btn-save' onClick={() => this.handleEditComment(null)}>
                            {t('comments.add')}
                        </button>
                    )}
                </div>
            </ClickOutsideHolder>
        );
    }
}

export default Comments;
