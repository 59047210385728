import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputField from 'components/Common/InputField';
import TextArea from 'components/Common/TextArea';
import ComboBox from 'components/Common/ComboBox';
import CheckBox from 'components/Common/CheckBox';
import FileInput from './components/FileInput';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import DatePicker from 'components/Common/DatePicker';
import ComboBoxCreatable from 'components/Common/ComboBoxCreatable';
import AjaxCombo from 'components/Common/AjaxCombo';
import styles from 'styles/modules/appealOperatePage.module.scss';

class DinamicParamsForm extends Component {
    
    getWidgetOptions = values => values.map(value => ({
        ...value,
        label: value.value,
        value: value.key,
        id: value.key
    }));
    
    createWidget = widget => {
        const { key, widgetType, defaultValue } = widget;
        const label = widget.title;
        let required = widget.isReq;
        const { checkRestrictions, order, unlockAppealForm, blockKey, selectedAppealAction } = this.props;
        let disabled = checkRestrictions(key);
        const actionWidgetOptions = selectedAppealAction.find(i => i.key === key);
        
        if (actionWidgetOptions) {
            required = actionWidgetOptions.isReq;
            disabled = actionWidgetOptions.isDisable;
        }
        
        switch (widgetType) {
        case 'input':
            return (
                <Field
                    name={`blocks.${key}`}
                    id={key}
                    required={required}
                    label={label}
                    placeholder={widget.placeholder}
                    component={InputField}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                    maxLength='2000'
                />
            );
        case 'checkbox':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    component={CheckBox}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'checkboxToggle':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    component={CheckBoxToggle}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'select':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    options={this.getWidgetOptions(widget.values)}
                    placeholder={widget.placeholder || 'Не вибрано'}
                    component={ComboBox}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'date':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    placeholder={widget.placeholder}
                    component={DatePicker}
                    popperClassName='appealDate'
                    onlyDate={true}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'datetime':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    placeholder={widget.placeholder}
                    component={DatePicker}
                    popperClassName='appealDate'
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'multiline':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    placeholder={widget.placeholder}
                    component={TextArea}
                    disabled={disabled}
                    textAreaClassName='multiline'
                    onChange={unlockAppealForm}
                    order={order}
                    maxLength='2000'
                />
            );
        
        case 'combo':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    value={defaultValue}
                    options={this.getWidgetOptions(widget.values)}
                    placeholder={widget.placeholder || 'Не вибрано'}
                    component={ComboBoxCreatable}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        
        case 'searchbox':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    value={defaultValue}
                    options={this.getWidgetOptions(widget.values)}
                    placeholder={widget.placeholder || 'Не вибрано'}
                    component={ComboBox}
                    searchable={true}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'ajaxCombo':
            return (
                <Field
                    name={`blocks.${key}`}
					id={key}
                    required={required}
                    label={label}
                    optionsUrl='appeal_form_ajax'
                    placeholder={widget.placeholder || 'Не вибрано'}
                    component={AjaxCombo}
                    attributeKey={key}
                    blockKey={blockKey}
                    disabled={disabled}
                    onChange={unlockAppealForm}
                    order={order}
                />
            );
        case 'file':
            return <Field
                name={`blocks.${key}`}
				id={key}
                required={required}
                label={label}
                value={defaultValue}
                placeholder={widget.placeholder}
                component={FileInput}
                multiple
                disabled={disabled}
                onChange={unlockAppealForm}
                order={order}
            />;
        case 'fileMultiple':
            return <Field
                name={`blocks.${key}`}
				id={key}
                required={required}
                label={label}
                placeholder={widget.placeholder}
                component={FileInput}
                multiple
                disabled={disabled}
                onChange={unlockAppealForm}
                order={order}
            />;
        
        default:
            return null;
        }
    };
    
    renderWidget = widget => {
        const shouldRender = this.props.shouldRenderField && this.props.shouldRenderField(widget.key);
        if (!shouldRender) return null;
        
        return (
            <div key={widget.key} className={styles.inputWrapper}>
                {this.createWidget(widget)}
            </div>
        );
    };
    
    render () {
        const { widgets } = this.props;
        return (
            <Fragment>
                {widgets.map(this.renderWidget)}
            </Fragment>
        );
    }
}

DinamicParamsForm.propTypes = {
    handleSubmit: PropTypes.func,
    widgets: PropTypes.array,
    blockKey: PropTypes.string
};

export default DinamicParamsForm;
