import React from 'react';
import PropTypes from 'prop-types';

const StyledRadio = props => {
  const { name, defaultChecked, value, onChange, title } = props;
  return (
    <label className='styled-radio-container'>
      <input type='radio' className='styled-radio' onChange={onChange} name={name} defaultChecked={defaultChecked} value={value}/>
      {title}
      <span className='styled-radio-mark'/>
    </label>
  );
};

StyledRadio.propTypes = {
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default StyledRadio;
