import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';


export default function HeaderMainCell({ children }) {
  return <div className={styles.headerMainCell}>{children}</div>;
}


HeaderMainCell.propTypes = {
  children: PropTypes.any
};
