import React, { Fragment } from 'react';
import cx from 'classnames';
import ComboBox from 'components/Common/ComboBox';
import { appealHistoryFilters, HistoryItemTypes } from 'constants/index';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { withRouter } from 'react-router-dom';
import ChangeItem from './ChangeItem';
import EmailItem from './EmailItem';
import NotificationItem from './NotificationItem';
import moment from 'moment/moment';
import CommentItem from './CommentItem';
import debounce from 'lodash/debounce';
import Loader from 'components/Loader';
import Highlight from 'react-highlighter';
import { translate } from 'react-i18next';
import Avatar from 'components/Avatar';
import CallItem from './CallItem';
import SelfCareItem from './SelfCareItem';
import DefaultItem from './DefaultItem';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { connect } from 'react-redux';
import * as sessionSelectors from 'reducers/session';
import {
    getAppealHistory,
    updateAppealHistoryFilter,
    updateSearchQuery,
    resetAppealHistory
} from 'actions/session';

const mapStateToProps = state => ({
    historyGroups: sessionSelectors.getHistoryGroups(state),
    query: sessionSelectors.getSearchQuery(state),
    filter: sessionSelectors.getFilter(state),
    highlightedText: sessionSelectors.getHighlightedText(state),
    isLoading: sessionSelectors.isHistoryLoading(state),
    requestedForAppeal: sessionSelectors.getRequestedForAppeal(state)
});

const mapDispatchToProps = dispatch => ({
    getAppealHistory: (requestData, appealId) => dispatch(getAppealHistory(requestData, appealId)),
    updateAppealHistoryFilter: newFilter => dispatch(updateAppealHistoryFilter(newFilter)),
    updateSearchQuery: newQuery => dispatch(updateSearchQuery(newQuery)),
    resetAppealHistory: () => dispatch(resetAppealHistory())
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AppealHistory extends React.Component {

    componentDidMount() {
		this.props.resetAppealHistory();
		this.getAppealHistory();
	}

	componentDidUpdate (prevProps) {
		if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
			this.props.resetAppealHistory();
			this.getAppealHistory();
		}
	}

    getAppealHistory = (filter = 0, query = '') => {
        const appealId = this.props.match.params.appealId;
        const requestData = { appealId, filter, query };

        return this.props.getAppealHistory(requestData, appealId);
    };

    getFilterItem = () => appealHistoryFilters.find(item => item.value === this.props.filter);

    onFilterChange = filter => {
        this.getAppealHistory(filter.value, this.props.query);
        this.props.updateAppealHistoryFilter(filter.value);
    };

    makeSearchRequest = debounce(query => this.getAppealHistory(this.props.filter, query), SEARCH_TIMER_INTERVAL);

    onSearchChange = event => {
        const query = event.target.value;
        this.makeSearchRequest(query);
        this.props.updateSearchQuery(query);
    };

    highlight = text => (
        <Highlight search={this.props.highlightedText} matchClass={styles.highlight} matchElement={'span'}>
            {text}
        </Highlight>
    );

    getHistoryItemComponent = type => {
        const componentMap = {
            [HistoryItemTypes.CHANGE]: ChangeItem,
            [HistoryItemTypes.EMAIL]: EmailItem,
            [HistoryItemTypes.NOTIFICATION]: NotificationItem,
            [HistoryItemTypes.COMMENT]: CommentItem,
            [HistoryItemTypes.CALL]: CallItem,
            [HistoryItemTypes.PRECRM]: CallItem,
            [HistoryItemTypes.SELF_CARE]: SelfCareItem
        };

        return componentMap[type] || DefaultItem;
    };

    renderHistoryItem = ({ type, orderDate, item }, index) => {
        const ItemComponent = this.getHistoryItemComponent(type);
        if (ItemComponent) {
            return <ItemComponent orderDate={orderDate} data={item} key={index} highlight={this.highlight}/>;
        } else {
            throw new Error(`Unknown type of appeal history item. Type: [${type}]`);
        }
    };

    getAuthorName = items => {
        const historyItem = items.find(item => item.type === HistoryItemTypes.CHANGE || item.type === HistoryItemTypes.COMMENT);
        return historyItem && historyItem.item.author.name || 'Системне повідомлення';
    };

    renderHistoryGroup = ([orderDate, items]) => {
        const formattedDate = moment(parseInt(orderDate)).format('DD.MM.YYYY в HH:mm:ss');
        return (
            <div className={styles.historyGroup} key={orderDate}>
                <div className={styles.authorAvatarColumn}>
                    {(this.getAuthorName(items) !== 'Системне повідомлення') ?
                        <Avatar name={this.getAuthorName(items)}/> :
                        <div className={styles.systemAuthorAvatar}>
                            <i className='icon-logo'/>
                        </div>
                    }
                </div>

                <div className={styles.historyGroupContent}>
                    <header className={styles.groupHeader}>
                        <span className={styles.authorName}>{this.highlight(this.getAuthorName(items))}</span>
                        <span className={styles.orderDate}>{formattedDate}</span>
                    </header>

                    <ul className={styles.historyItemList}>
                        {items.map(this.renderHistoryItem)}
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        const { t, isLoading, query, historyGroups } = this.props;
        return (
            <Fragment>
                <div className={cx(styles.tabHeader, styles.history)}>
                    <ComboBox
                        options={appealHistoryFilters}
                        value={this.getFilterItem()}
                        onChange={this.onFilterChange}
                    />
                    <div className={styles.searchInputWrapper}>
                        <input
                            type='text'
                            className={styles.searchInput}
                            placeholder={t('Search')}
                            onChange={this.onSearchChange}
                            value={query}
                            autoFocus
                        />
                        <i className={'icon-search-maginfer'}/>
                    </div>
                </div>
                {
                    isLoading
                        ? <Loader withContainer={true}/>
                        : (
                            <div className='scrollbox'>
                                <div className='scrollbox-content'>
                                    <div className={styles.historyContent}>
                                        {Object.entries(historyGroups).map(this.renderHistoryGroup)}
                                    </div>
                                </div>
                            </div>
                        )
                }
            </Fragment>
        );
    }
}

export default AppealHistory;
