import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';
import styles from 'styles/modules/createNewCustomer.module.scss';
import cx from 'classnames';
import { isOrganization } from 'helpers';

function FormWrapper ({ title, onSubmit, customerType, children }) {
  const formClassName = cx(styles.formWrapper, isOrganization(customerType) && styles.organization);
  return (
    <Form onSubmit={onSubmit} className={formClassName}>
      <header>{title}</header>
      {children}
    </Form>
  );
}

FormWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  customerType: PropTypes.string
};

export default FormWrapper;
