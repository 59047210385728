import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import classNames from 'classnames';
import ClickOutsideHolder from 'components/ClickOutsideHolder';

class ButtonSelectPopup extends Component {
	
	state = {
		popupIsOpen: false
	};
	
	openPopUp = () => this.setState(prevState => ({ popupIsOpen: !prevState.popupIsOpen }));
	
	onOptionClick = func => event => {
		func && func(event);
		this.closePopup();
	};
	
	renderPopUpChildren = () => {
		return this.props.popupConfig.map((item, idx) => (
			<div className='popup-row' key={idx}>
				<button
					className='popup-item'
					type={item.type}
					onClick={this.onOptionClick(item.clickHandler)}
					disabled={item.disabled}
				>
					{item.label}
				</button>
			</div>
		));
	};
	
	closePopup = () => this.setState({ popupIsOpen: false });
	
	render () {
		const { text, onClickBtn, up, disabled } = this.props;
		const { popupIsOpen } = this.state;
		
		const popupPlace = classNames('select-button', {
			'popup-up': up,
			'popup-down': !up
		});
		
		const buttonClassName = classNames('_arrow', {
			'icon-down': !popupIsOpen,
			'icon-up': popupIsOpen
		});
		
		return (
			<ClickOutsideHolder
				className={classNames('btn-withDropdown', { 'disabled': disabled })}
				onClickOutside={this.closePopup}
			>
				<button className='_btn' type='submit' onClick={onClickBtn}>
					{text}
				</button>
				<span className='_period'>|</span>
				<button type='button' className={buttonClassName} onClick={this.openPopUp} />
				{
					popupIsOpen &&
					<Popup place={popupPlace}>
						{this.renderPopUpChildren()}
					</Popup>
				}
			</ClickOutsideHolder>
		);
	}
}

ButtonSelectPopup.propTypes = {
	popupConfig: PropTypes.array,
	text: PropTypes.string,
	onClickBtn: PropTypes.func,
	up: PropTypes.bool
};

export default ButtonSelectPopup;
