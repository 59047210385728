import React from 'react';
import { Field, reset, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { CSSTransition } from 'react-transition-group';
import {
    getFilterAttrsSuppose,
    saveFilter,
    getInfoFilterSuppose,
    addFilterAttrs,
    clearFilterAttrs,
    closeFilterParams,
    openParamsModal,
    closeParamsModal,
    getDataFilterSuppose
} from 'actions/filter';
import ModalPortal from 'components/ModalPortal';
import ParamsModal from 'components/ParamsModal';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import DatePicker from 'components/Common/DatePicker';
import styles from 'styles/modules/filters.module.scss';
import get from 'lodash/get';
import SelectTree from 'components/Common/SelectTree/SelectTree';
import { reduxFormWrapper, buildThreePath } from 'helpers';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { configureFilterObject } from '../../../SearchFilters/helpers';
import permissions, { checkPermissions } from '../../../../config/permissions';
import validate from './validate';
import MultiSelectWithSearch from '../../../Common/MultiSelectWithSearch/ReduxFormDecorator';
import BaseService from 'services/BaseService';

const mapStateToProps = (state) => ({
    filter: state.filter,
    showMessageModal: state.ui.showMessageModal,
    formValues: getFormValues('appeal-filters')(state),
    saved: state.filter.saved,
    appealTypes: get(state, 'filter.appealTypes[0]'),
    optionsHistory: state.filter.optionsHistory,
    isCanCreatePublicFilter: checkPermissions(permissions.FilterOperations.create_public_filter),
});

const mapDispatchToProps = (dispatch) => ({
    closeFilterParams: () => dispatch(closeFilterParams()),
    openParamsModal: () => dispatch(openParamsModal()),
    closeParamsModal: () => dispatch(closeParamsModal()),
    getFilterAttrsSuppose: (params) => dispatch(getFilterAttrsSuppose(params)),
    saveFilter: (params, onSuccess) => dispatch(saveFilter(params, onSuccess)),
    getInfoFilterSuppose: (params) => dispatch(getInfoFilterSuppose(params)),
    addFilterAttrs: (result) => dispatch(addFilterAttrs(result)),
    clearFilterAttrs: () => dispatch(clearFilterAttrs()),
    getDataFilterSuppose: (params) => dispatch(getDataFilterSuppose(params))
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'appeal-filters', enableReinitialize: true, validate })
class FiltersForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
        this.asyncParams = this.asyncParams.bind(this);
    }

    componentDidMount () {
        const { getFilterAttrsSuppose, filterId, getInfoFilterSuppose, clearFilterAttrs } = this.props;
        getFilterAttrsSuppose();

        if (filterId) {
            const data = {
                id: filterId,
                code: 'INTERACTION_REQUEST'
            };
            getInfoFilterSuppose({
                data: data
            });

            clearFilterAttrs();
        }
        let state = {};
        this.props.filter.filterAttrsResults.filter((f) => f.dict_search).forEach((i) => {
            state = { ...state, [i.code]: [] };
        })
    }

    asyncParams (search, code, id) {
        if (!search) {
            this.setState({ [code]: [] });
        } else {
            BaseService.post('async_search_attributes', { data: { id, search }, jsonType: true }).then((resp) => {
                this.setState({ [code]: resp.result.map(field => ({ label: field.value || '', value: field.key || '' })) });
            });
        }
    }

    componentDidUpdate (prevProps) {
        const { filter } = this.props;

        if (prevProps.filterId !== this.props.filterId) {
            if (!filter.newFilter) {
                const { getInfoFilterSuppose, clearFilterAttrs, dispatch } = this.props;

                const data = {
                    id: this.props.filterId,
                    code: 'INTERACTION_REQUEST'
                };
                getInfoFilterSuppose({
                    data: data
                });

                clearFilterAttrs();
                dispatch(reset('params-modal'));
                dispatch(reset('appeal-filters'));
            }
        }
    }

    renderDefaultFields = () => {
        const { predefined, filter, filterId, isCanCreatePublicFilter } = this.props;
        const disabled = filterId ? true : (predefined && !filter.newFilter);

        return [
            <Field
                component={InputField}
                name='filterName'
                label='Назва фільтру'
                disabled={disabled}
            />,
            isCanCreatePublicFilter && <Field
                component={CheckBoxToggle}
                name='isPublic'
                label='Загальний для всіх'
                disabled={disabled}
            />

        ];
    };

    getComponentByType = (type, search) => {
        let components = {
            LIST: ComboBox,
            NUMBER: InputField,
            STRING: InputField,
            DATE: DatePicker
        };

        if (search) {
            components = {
                THREE: MultiSelectWithSearch,
                LIST: MultiSelectWithSearch,
            };
        }

        return components[type] || InputField;
    };

    convertFieldConfigToProps = fieldConfig => {
        const { predefined, filter, filterId } = this.props;
        const disabled = filterId ? true : (predefined && !filter.newFilter);
        const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
        const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
        let options = fieldConfig.type === 'THREE' ? {children: buildThreePath(fieldConfig.dict, false, {children: 'children'})} : getOptions(fieldConfig.dict || []);

        let config = {
            key: fieldConfig.id,
            component: this.getComponentByType(fieldConfig.type, fieldConfig.search),
            name: fieldConfig.code,
            label: fieldConfig.name,
            type: fieldConfig.type,
            options,
            multi,
            disabled,
            tree: fieldConfig.type === 'THREE',
            errorPlaceholder: true,
            valueField: 'key',
            leafField: fieldConfig.type === 'THREE'? 'leaf' : 'children',
        };

        if (fieldConfig.dict_search) {
            config = {
                ...config,
                options: this.state[fieldConfig.code] || [],
                async: {
                    callback: this.asyncParams,
                    code: fieldConfig.code,
                    id: fieldConfig.id,
                }
            };
        }

        return config;
    };

    renderAppealTypeInput = ({ name }) => {
        const { t, appealTypes, filterId } = this.props;
        return (
            <Field
                name={name}
                key={name}
                label={t('filter.typeFullName')}
                placeholder={t('appeal.selectPlaceholder')}
                component={SelectTree}
                initialOptions={get(appealTypes, 'children', [])}
                disabled={!!filterId}
            />
        );
    };

    renderDateField = (fieldConfig, fieldProps) => {
        if (fieldConfig.def_condition === 'BTW') {
            const fromDateProps = {
                ...fieldProps,
                name: `${fieldConfig.code}@FROM`,
                label: `${fieldProps.label} з:`,
                key: `${fieldConfig.id}@FROM`
            };
            const toDateProps = {
                ...fieldProps,
                name: `${fieldConfig.code}@TO`,
                label: `${fieldProps.label} по:`,
                key: `${fieldConfig.id}@TO`
            };

            return (
                <div key={fieldConfig.name} className={styles.wrapElements}>
                    <Field {...fromDateProps} />
                    <Field {...toDateProps} />
                </div>
            );
        }

        return <Field {...fieldProps} />;
    };

    renderField = fieldConfig => {
        const fieldProps = this.convertFieldConfigToProps(fieldConfig);

        if (fieldConfig.type === 'DATE') {
            return this.renderDateField(fieldConfig, fieldProps);
        }

        return (
            <Field {...fieldProps}/>
        );
    };

    closeFilterParams = (e) => {
        const { closeFilterParams } = this.props;
        e.preventDefault();
        e.stopPropagation();
        closeFilterParams();
    };

    addFilterParams = (e) => {
        const { openParamsModal } = this.props;
        e.preventDefault();
        e.stopPropagation();
        openParamsModal();
    };

    handleSubmit = (value) => {
        const { formValues, initialValues, filters, filter: { filterAttrsResults = [] }, saveFilter } = this.props;
        const filterObject = configureFilterObject(value, filterAttrsResults);

        const data = {
            query: '',
            filterObject,
            name: value.filterName,
            isPublic: Boolean(value.isPublic).toString(),
            code: 'INTERACTION_REQUEST'
        };

        const filtersNames = filters.filter(el => el.name === value.filterName);

        if (filtersNames.length) {
            if (filtersNames[0].predefined) {
                this.props.openModal('savePredefined');
            } else {
                !isEqual(formValues, initialValues) && this.props.openModal('saveUser', data);
            }
        } else if (filterObject.length > 0) {
            const onSuccess = savedFilter => this.props.history.push(`/appeal/filter/${savedFilter}`);
            saveFilter({ data }, onSuccess);
        }
        return false;
    };

    renderBtn () {
        const { filter, t } = this.props;

        if (filter.newFilter) {
            return (
                <div className={styles.buttonsWrapper}>
                    <button className='btn btn-primary'>
                        {t('filters.save')}
                    </button>
                </div>
            );
        }
    }

    render () {
        const { handleSubmit, filter } = this.props;

        const fields = filter.filterAttrsResults || [];

        return (
            <div className={styles.wrapper}>
        <span className={styles.btnClose} onClick={this.closeFilterParams}>
          <i className='icon icon-close'/>
        </span>
                <div className='scrollbox'>
                    <div className='scrollbox-content'>
                        <form
                            className={styles.formWrapper}
                            onSubmit={handleSubmit(this.handleSubmit)}
                        >
                            <div className={styles.formFields}>
                                {this.renderDefaultFields()}
                                {fields.map(this.renderField)}
                            </div>
                            {this.renderBtn()}
                        </form>
                    </div>
                </div>
                <CSSTransition
                    in={filter.showParamsModal}
                    classNames='fade'
                    appear={true}
                    enter={true}
                    exit={true}
                    timeout={500}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <ModalPortal
                        onClose={this.props.closeParamsModal}
                        className='modal-small'
                    >
                        <ParamsModal filterCode='appeal'/>
                    </ModalPortal>
                </CSSTransition>

            </div>
        );
    }
}

FiltersForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    submit: PropTypes.func,
    filter: PropTypes.object,
    closeFilterParams: PropTypes.func,
    openParamsModal: PropTypes.func,
    getFilterAttrsSuppose: PropTypes.func,
    closeParamsModal: PropTypes.func,
    isCanCreatePublicFilter: PropTypes.bool
};

export default FiltersForm;
