import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';
import cx from 'classnames';
import { toggleSideMenuAdditions } from 'actions/ui';
import { customerEdit, getCustomerAddress, clearCustomerAddress } from 'actions/customer';
import { customerFormConfig, customerEntityFormConfig, FieldTypes } from 'constants/index';
import { isOrganization, createCheckPropsFunction } from 'helpers';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import styles from 'styles/modules/parameters.module.scss';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';
import moment from 'moment';
import i18n from 'util/i18n';
import FilledForm from './FilledForm';
import EmptyForm from './EmptyForm';
import CustomerAddressModal from './components/CustomerAddressModal';
import { extractCustomerFromState } from '../../../helpers';


const getConfigByCustomerType = customerType => (isOrganization(customerType) ? customerEntityFormConfig : customerFormConfig);

const getInitialValues = (customer, t, dictionary) => {
    const customerType = customer.party.partyType;
    const values = {};
    const fieldsConfig = getConfigByCustomerType(customerType);
    fieldsConfig.forEach((config) => {
        let fieldValue = get(customer, config.valueField || config.field);

        if (config.formatter) {
            fieldValue = config.formatter(fieldValue);
        }

        // for "bankRequisites" field
        if (config.names) {
            values[config.name] = fieldValue;
            fieldValue && config.names.forEach((name) => {
                values[name] = fieldValue[name];
            });

            // for field type is "select", we need to create object like {value, label}
        } else if (config.type === FieldTypes.SELECT) {
            const fieldDictionary = get(dictionary, config.options, {});
            values[config.name] = fieldValue && { value: fieldValue, label: fieldDictionary[fieldValue] };
        } else {
            values[config.name] = fieldValue;
        }
    });

    return values;
};

const initialValuesSelector = createSelector(
    (state, props) => extractCustomerFromState(state, props.id).currentCustomer,
    (state, props) => props.t,
    // on the start dictionary is empty, so when dictionary is loaded, we need to update form
    () => i18n.getResourceBundle('uk', 'dictionary'),
    getInitialValues,
);


/**
 * Return object of not-empty values
 */
const filledFieldsSelector = createSelector(
    initialValuesSelector,
    initialValues => Object.keys(initialValues)
        .reduce((acc, key) => {
            const currentValue = initialValues[key];

            const isNotEmptyObject = isObjectLike(currentValue) && !isEmpty(currentValue);
            const isNotEmptyPrimitive = currentValue && !isObjectLike(currentValue);

            if (isNotEmptyPrimitive || isNotEmptyObject) {
                acc[key] = currentValue;
            }

            return acc;
        }, {}),
);

function mapStateToProps (state, props) {
    const customer = extractCustomerFromState(state, props.id);
    
    return {
        showSideMenuAdditions: state.ui.showSideMenuAdditions,
        customer: customer.currentCustomer,
        customerType: _.get(customer, 'currentCustomer.party.partyType'),
        primaryAddress: customer.primaryAddress,
        initialValues: initialValuesSelector(state, props),
        filledFields: filledFieldsSelector(state, props),
        customerLoading: customer.customerLoading,
    }
}

const mapDispatchToProps = {
    toggleSideMenuAdditions,
    clearCustomerAddress,
    customerEdit,
    getCustomerAddress,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Parameters extends React.Component {
    constructor(props) {
        super(props);

        this.wait = false;
    }

    componentDidMount() {
        const { id } = this.props;
        
        if (this.shouldRequestAddress()) {
            this.requestCustomerAddress();
        } else if (this.props.primaryAddress) {
            this.props.clearCustomerAddress(id);
        }
    }

    componentDidUpdate(prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if ((isPropChanged('customer.id') || isPropChanged('customer.timestamp')) && this.wait) {
            this.wait = false;
        }

        if (isPropChanged('initialValues.primaryAddress') && this.props.initialValues.primaryAddress) {
            this.requestCustomerAddress();
        }
    }

    shouldRequestAddress = () => Boolean(this.props.initialValues.primaryAddress);

    requestCustomerAddress = () => {
        const { id } = this.props;
        return this.props.getCustomerAddress({ customerId: this.props.customer.id }, id);
    };

    handleCollapse = (event) => {
        event.stopPropagation();
        this.props.toggleSideMenuAdditions(this.props.id);
    };

    handleHideSideMenu = (e) => {
        e.stopPropagation();
    };
    
    normalizeFieldName = (fieldName) => {
        const arrayFieldNameRegexp = /^[\w]+\[\d+\]$/;
        if (arrayFieldNameRegexp.test(fieldName)) {
            return fieldName.substring(0, fieldName.indexOf('['));
        }
        return fieldName;
    };
    
    configureArrayFieldRequestData = (payload, fieldConfig) => ({
        customerId: this.props.customer.id,
        field: fieldConfig.field,
        id: payload.value.map(element => element.id),
        value: payload.value.map(element => element.value),
    });

    /**
     * Create request data object for fields of all types except "array"
     */
    configureCommonFieldRequestData = (payload, fieldConfig) => {
        let { value } = payload;

        if (moment.isMoment(value)) {
            value = value.valueOf();
        }

        if (fieldConfig.type === FieldTypes.SELECT && value) {
            // select value format = {value, label}
            value = value.value;
        }

        return {
            customerId: this.props.customer.id,
            field: fieldConfig.field,
            text: value,
        };
    };

    /**
     * Create request data object
     */
    getRequestData = (payload, fieldConfig) => {
        switch (fieldConfig.type) {
        case FieldTypes.ARRAY:
            return this.configureArrayFieldRequestData(payload, fieldConfig);

        default:
            return this.configureCommonFieldRequestData(payload, fieldConfig);
        }
    };

    handleChangeValue = (payload, callback) => {
        const { id } = this.props;
        
        if (this.wait) {
            return false;
        }
        this.wait = true;

        const fieldConfig = getConfigByCustomerType(this.props.customerType).find(config => config.name === payload.name);

        if (fieldConfig.required && !payload.value) return false;

        const requestData = this.getRequestData(payload, fieldConfig);

        this.props.customerEdit({ data: requestData, onError: () => { this.wait = false }, id });
    };

    /**
     * Return array of empty field names.
     */
    getEmptyFields = () => {
        const formConfig = getConfigByCustomerType(this.props.customerType);

        return formConfig.reduce((acc, fieldConfig) => {
            const currentValue = this.props.initialValues[fieldConfig.name];
            const isEmptyValue = typeof currentValue === 'number' ? false :isEmpty(currentValue);

            const isEnabled = !fieldConfig.disabled;
            if (isEmptyValue && isEnabled) {
                acc.push(fieldConfig.name);
            }

            return acc;
        }, []);
    };

    render() {
        const {
            filledFields, customer, customerType, showSideMenuAdditions, headerText, footerText, t,
            readOnly, isViewAddress, isEditAddress, customerLoading, id
        } = this.props;
        const emptyFormWrapperClassName = cx(styles.additionalData, { show: showSideMenuAdditions });
        const formConfig = getConfigByCustomerType(customerType);

        return (
            <ClickOutsideHolder onClickOutside={this.handleHideSideMenu}>
                <div className={cx(styles.asideCustomer, 'aside-customer', headerText ? 'scroll' : '')}>
                    {
                        headerText
                        && (
                            <header className={styles.asideCustomerHeader}>
                                {t(`customerInfoLabel.${headerText}`)}
                            </header>
                        )
                    }

                    <div className={cx(styles.cardData, headerText ? 'p-r-0' : '')}>
                        <FilledForm
                            form={`${id}-customer-filled`}
                            initialValuesObject={filledFields}
                            updateTimestamp={customer.timestamp}
                            config={formConfig}
                            handleChangeValue={this.handleChangeValue}
                            readOnly={readOnly}
                            isViewAddress={isViewAddress === undefined ? true : isViewAddress}
                            isEditAddress={isEditAddress === undefined ? false : isEditAddress}
                            loading={customerLoading}
                        />
                        {
                            readOnly ? null : (
                                <div className={emptyFormWrapperClassName}>
                                    <EmptyForm
                                        form={`${id}-customer-empty`}
                                        fieldList={this.getEmptyFields()}
                                        config={formConfig}
                                        handleChangeValue={this.handleChangeValue}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {
                        readOnly ? null : (
                            <button className='btn-collapse' onClick={this.handleCollapse}>
                                <i className={`icon icon-${showSideMenuAdditions ? 'back' : 'list'}`} />
                            </button>
                        )
                    }

                </div>

                {
                    footerText
                    && (
                        <footer className={styles.asideCustomerFooter}>
                            <p className={styles.company}>{footerText}</p>
                        </footer>
                    )
                }

                <CustomerAddressModal id={id} />

            </ClickOutsideHolder>
        );
    }
}

Parameters.propTypes = {
    showSideMenuAdditions: PropTypes.bool,
    toggleSideMenuAdditions: PropTypes.func,
    customerEdit: PropTypes.func,
    footerText: PropTypes.string,
    avatar: PropTypes.bool,
};

export default Parameters;
