const ACTIONS = {
    ORDERS_REQUEST: 'ORDERS_REQUEST',
    ORDERS_REQUEST_SUCCESS: 'ORDERS_REQUEST_SUCCESS',
    ORDERS_REQUEST_FAILURE: 'ORDERS_REQUEST_FAILURE',
    ORDERS_FILTERS_REQUEST: 'ORDERS_FILTERS_REQUEST',
    ORDERS_FILTERS_REQUEST_SUCCESS: 'ORDERS_FILTERS_REQUEST_SUCCESS',
    ORDERS_FILTERS_REQUEST_FAILURE: 'ORDERS_FILTERS_REQUEST_FAILURE',
    ORDERS_SET_ACTIVE_FILTER: 'ORDERS_SET_ACTIVE_FILTER',
};

/* initial state */
const initialState = {
    filters: [],
    activeFilter: null,
    errors: []
};

/* reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ORDERS_FILTERS_REQUEST_SUCCESS:
            return { ...state, filters: action.filters };
        case ACTIONS.ORDERS_SET_ACTIVE_FILTER:
            return { ...state, activeFilter: action.filter }
        default:
            return state;
    }
};

/* action creators */
export const setFilters = filters => ({
    type: ACTIONS.ORDERS_FILTERS_REQUEST_SUCCESS,
    filters
});

export const setActiveFilter = filter => ({
    type: ACTIONS.ORDERS_SET_ACTIVE_FILTER,
    filter
});


