import get from 'lodash/get';
import {
	CREATE_NEW_APPEAL, CREATE_NEW_APPEAL_SUCCESS, CREATE_NEW_APPEAL_ERROR,
    GET_APPEAL_TYPES_LIST_SUCCESS, GET_APPEAL_TYPES_LIST_ERROR, GET_DINAMIC_FORM_PARAMS,
    GET_DINAMIC_FORM_PARAMS_SUCCESS, GET_DINAMIC_FORM_PARAMS_ERROR,
    GET_APPEAL_STATUS_LIST, GET_APPEAL_STATUS_LIST_SUCCESS, GET_APPEAL_STATUS_LIST_ERROR,
    SAVE_APPEAL, SAVE_APPEAL_SUCCESS, SAVE_APPEAL_ERROR, APPEAL_DESTINATIONS_REQUEST,
    APPEAL_DESTINATIONS_REQUEST_SUCCESS, APPEAL_DESTINATIONS_REQUEST_FAILURE, GET_KNOWLEDGE_BASE_DATA,
    GET_KNOWLEDGE_BASE_DATA_SUCCESS, GET_KNOWLEDGE_BASE_DATA_ERROR, UNLOCK_APPEAL_FORM,
    UNLOCK_APPEAL_FORM_SUCCESS, UNLOCK_APPEAL_FORM_ERROR, LOCK_APPEAL_FORM, GET_APPEAL_PRIORITY_LIST,
    GET_APPEAL_PRIORITY_LIST_SUCCESS, GET_APPEAL_PRIORITY_LIST_ERROR, GET_APPEAL_FEEDBACK, GET_APPEAL_FEEDBACK_SUCCESS,
    GET_APPEAL_FEEDBACK_ERROR, CLEAR_APPEAL_FEEDBACK, APPEAL_REQUEST, APPEAL_REQUEST_SUCCESS,
    APPEAL_REQUEST_FAILURE, CLEAR_APPEAL_STATE, GET_APPEAL_ACTIONS, SET_APPEAL_ACTIONS,
    SET_FIRST_LEVEL_ACTION, SET_RESOLUTION_ACTION, SET_APPEAL_VERSION, REMOVE_REFRESH_APPEAL_MODAL,
    ADD_TABS, SET_MODAL_STATUS_LIST, SET_STATUS_MODAL_VISIBILITY, SET_CURRENT_TAB_NAME_BLOCKED,
    APPEAL_FETCHING, REMOVE_CUSTOMER_FROM_APPEAL, ADD_CUSTOMER_TO_APPEAL, CLEAR_ALL_APPEALS,
	SAVE_AND_CREATE_NEW, ADD_CREATED_APPEAL,
} from 'constants/actions';
import baseService from 'services/BaseService';
import { showNotification } from './ui';
import { NotificationTypes } from 'constants/index';
import BaseService from '../services/BaseService';


export function removeCustomerFromAppeal (id) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_CUSTOMER_FROM_APPEAL,
            id,
        });
    };
}

export function addCustomerToAppeal (id, customerId, contactPersonId) {
    return (dispatch) => {
        dispatch({
            type: ADD_CUSTOMER_TO_APPEAL,
            id,
            customerId,
            contactPersonId,
        });
    };
}

export function saveFeedback (saveFeedbackData) {
    BaseService.post('appeal_feedback_save', saveFeedbackData)
        .catch(console.error);
}

export function setStatusModalVisibility (payload, id) {
    return (dispatch) => {
        dispatch({
            type: SET_STATUS_MODAL_VISIBILITY,
            payload,
            id,
        });
    };
}

export function setModalStatusLIst (payload, id) {
    return (dispatch) => {
        dispatch({
            type: SET_MODAL_STATUS_LIST,
            payload,
            id,
        });
    };
}

export function removeRefreshAppealModal (id) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_REFRESH_APPEAL_MODAL,
            id,
        });
    };
}

export function getAppealVersion (id) {
    return (dispatch) => {
        baseService.get('get_appeal_version', { data: { id }, jsonType: true })
            .then((response) => {
                dispatch({
                    type: SET_APPEAL_VERSION,
                    payload: response.result.version,
                    id,
                });
            });
    };
}

export function unlockAppeal (data) {
    baseService.get('unlock_appeal', { data, jsonType: true });
}

export function setResolutionActions (data, id) {
    return (dispatch) => {
        dispatch({
            type: SET_RESOLUTION_ACTION,
            payload: data,
            id,
        });
    };
}

export function setFirstLevelActions (data, id) {
    return (dispatch) => {
        dispatch({
            type: SET_FIRST_LEVEL_ACTION,
            payload: data,
            id,
        });
    };
}

export function getAppealActions (data, destinations, seDefault) {
    return (dispatch) => {
        const requestData = {
            statusCode: data.status.id,
            curOperatorId: data.curOperatorId,
            routeCode: data.route.key,
            destinationIdList: destinations,
        };

        return baseService.post('get_appeal_actions', { data: requestData, jsonType: true })
            .then((response) => {
                dispatch({
                    type: GET_APPEAL_ACTIONS,
                    payload: response.result,
                    id: data.id,
                });
                if (seDefault && response.result && response.result.length > 0) {
                    const action = response.result.find(i => i.action === 'toSave');
                    const setAppeal = setAppealAction({
                        appealId: data.id,
                        statusCode: action.toState,
                        action: action.action,
                    }, action.label);
                    return setAppeal(dispatch);
                } else {
                    return true
                }
            }).catch();
    };
}

export function setAppealAction (data, label) {

    return (dispatch) => {
        return baseService.get('set_appeal_action', { data, jsonType: true })
            .then((response) => {
                dispatch({
                    type: SET_APPEAL_ACTIONS,
                    payload: response.result,
                    label,
                    id: data.appealId,
                });
                return true;
            });
    };
}

export function showValidationNotification () {
    return (dispatch) => {
        dispatch(showNotification({
            type: NotificationTypes.APPEAL_CHANGE_FAILURE,
            options: { field: 'Заповніть обов\'язкові поля' },
        }));
    };
}

export function clearAppealState (id) {
    return (dispatch) => {
        dispatch({
            type: CLEAR_APPEAL_STATE,
            id,
        })
    };
}


export const getAppeal = (requestData, dirty) => dispatch => {
    if (!dirty) {
        dispatch({
            type: APPEAL_REQUEST,
            id: requestData.id,
        });
    }

    const params = {
        data: requestData
    };

    return baseService.get('appeal_by_id', params, true)
        .then(response => {
            let result = null;
            if (response.success && response.result) {
                result = { ...response.result, timestamp: response.timestamp };
                dispatch({
                    type: APPEAL_REQUEST_SUCCESS,
                    appealData: result
                });
            } else {
                if (response.response.data.errorCode === -20403) {
                    dispatch({
                        type: APPEAL_REQUEST_FAILURE,
                        appealIsBlocked: true,
                        id: requestData.id,
                    });
                    dispatch({ type: SET_CURRENT_TAB_NAME_BLOCKED });
                } else {
                    dispatch({
                        type: APPEAL_REQUEST_FAILURE,
                        id: requestData.id,
                    });
                }

            }
            return result;
        })
        .catch(() => {
            dispatch({
                type: APPEAL_REQUEST_FAILURE,
                id: requestData.id,
            });
            return null;
        });
};

export const createInteraction = (interactionRequestData = { typeCode: 'PRECRM' }) => {
    const params = { data: interactionRequestData };

    return baseService.post('interaction', params)
        .then(response => {
            const interactionId = get(response, 'result[0].id');

            if (response.success && interactionId) {
                return interactionId;
            } else {
                throw new Error('Interaction request error');
            }
        });
};

export const createAppeal = appealRequestData => {
    const params = { data: appealRequestData };

    return baseService.post('appeal_create', params)
        .then(response => {
            if (response.success && response.result) {
                return response.result;
            } else {
                throw new Error('Appeal creation request error');
            }
        });
};

export function createNewAppeal(
	{appealRequestData = {}, interactionRequestData} = {}, intId) {
	return async (dispatch) => {
		let interactionId = intId;

		dispatch({
			type: CREATE_NEW_APPEAL,
		});

		const requestData = {
			appealType: 0,
			appealId: '',
			classCode: 0,
			...appealRequestData,
		};

		if (!interactionId) {
			interactionId = await createInteraction(interactionRequestData);
		}

		const data = {
			...requestData,
			interactionId: interactionId,
		};

		try {
			const createdAppeal = await createAppeal(data);

			if (intId) {
				dispatch({
					type: ADD_CREATED_APPEAL,
					payload: {
						appeal: {
							id: createdAppeal.id,
							name: createdAppeal.id,
						},
					},
				});
			}

			return createdAppeal;
		} catch (e) {
			dispatch({
				type: CREATE_NEW_APPEAL_ERROR,
				error: 'Something went wrong',
			});
		}
	};
}

/**
 * @param requestData - { repeatRequestId, appealId, typeCode }
 * @param onSuccess - callback with created appeal as param
 * @returns Promise of request
 */
export const repeatAppeal = (requestData, onSuccess) => {

    const params = { data: requestData };

    return baseService.post('interaction', params)
        .then(response => {
            const interactionId = get(response, 'result[0].id');
            if (response.success && interactionId) {
                const params = {
                    data: {
                        interactionId,
                        appealType: 0,
                        classCode: 0,
                        typeCode: requestData.typeCode,
                        repeatRequestId: requestData.repeatRequestId
                    }
                };

                return baseService.post('appeal_create', params);
            } else {
                throw new Error('mw/interaction/create error');
            }
        })
        .then(response => {
            if (response.success && response.result) {
                onSuccess && onSuccess(response.result);
            }
        })
        .catch(console.error);
};

/**
 * @param requestData - { repeatRequestId, appealId }
 * @param onSuccess - callback with created appeal as param
 * @returns Promise of request
 */
export const appointAppeal = (requestData, onSuccess) => {
    const params = { data: requestData, jsonType: true };

    return baseService.post('appeal_save', params)
        .then(response => {
            if (response.success) {
                onSuccess && onSuccess();
            }
        })
        .catch(console.error);
};

export function getAppealTypesList (id) {
    return (dispatch) => {
        const params = {
            data: {
                classCode: 0,
                node: 'root'
            }
        };

        return baseService.get('appeal_type', params)
            .then(data => {
                dispatch({
                    type: GET_APPEAL_TYPES_LIST_SUCCESS,
                    result: data,
                    id,
                });
                return true;
            })
            .catch(error => {
                console.log(error, id);
                dispatch({
                    type: GET_APPEAL_TYPES_LIST_ERROR,
                    error,
                    id,
                });
                return false;
            });
    };
}

export function getDynamicFormParams (data) {
    const {
        ctxObjectId, objectType, objectId, objectTypeId, objectTypeCode,
        prevObjectTypeId, openMode,
    } = data;

    return (dispatch) => {
        const params = {
            data: {
                ctxObjectType: 'INTERACTION_REQUEST',
                ctxObjectId,
                objectType,
                objectTypeId,
                objectId,
                objectTypeCode,
                openMode,
                curStepNum: '1',
                prevObjectTypeId
            }
        };

        dispatch({
            type: GET_DINAMIC_FORM_PARAMS,
            id: ctxObjectId
        });

        return baseService.get('appeal_form_params', params)
            .then(data => {
                dispatch({
                    type: GET_DINAMIC_FORM_PARAMS_SUCCESS,
                    result: data,
                    id: ctxObjectId,
                });

                return true;
            })
            .catch(error => dispatch({
                type: GET_DINAMIC_FORM_PARAMS_ERROR,
                error,
                id: ctxObjectId,
            }));
    };
}

export const getAppealStatusList = (appealId, appealType = '') => (dispatch) => {
    const params = {
        data: {
            appealType,
            appealId,
        },
    };

    dispatch({
        type: GET_APPEAL_STATUS_LIST,
        id: appealId,
    });

    return baseService.get('appeal_status_list', params)
        .then(data => {
            dispatch({
                type: GET_APPEAL_STATUS_LIST_SUCCESS,
                result: data.result,
                id: appealId,
            });

            return true;
        }).catch(error => dispatch({
            type: GET_APPEAL_STATUS_LIST_ERROR,
            error,
            id: appealId,
        }));
};


export const saveAppeal = (requestData) => dispatch => {
    const id = requestData.appealId;
    dispatch({
        type: SAVE_APPEAL,
        id,
    });

    const params = {
        data: requestData,
        jsonType: true
    };

    return baseService.post('appeal_save', params)
        .then(response => {
            if (response.success) {
                dispatch({
                    type: SAVE_APPEAL_SUCCESS,
                    id,
                });
                const messages = response.result ? response.result.map(appeal => appeal.num).join(', ') : null;
                if (messages) {
                    dispatch({
                        type: ADD_TABS,
                        payload: response.result
                    });
                }
                dispatch(showNotification({
                    type: NotificationTypes.SUCCESS,
                    options: { message: `Звернення збережено успішно ${messages ? messages : ''}` }
                }));
            }
        })
        .catch(error => {
            dispatch({
                type: SAVE_APPEAL_ERROR,
                error,
                id,
            });
        });
};

export const getAppealDestinations = (appealId, appealType) => (dispatch) => {
    dispatch({
        type: APPEAL_DESTINATIONS_REQUEST,
        id: appealId,
    });

    const params = {
        data: {
            appealType,
            appealId,
            key: 'ALL'
        }
    };

    return baseService.get('appeal_dest_list', params)
        .then(response => {
            if (response.success && response.result) {
                dispatch({
                    type: APPEAL_DESTINATIONS_REQUEST_SUCCESS,
                    destinations: response.result,
                    id: appealId,
                });
                return response.result;
            } else {
                dispatch({
                    type: APPEAL_DESTINATIONS_REQUEST_FAILURE,
                    id: appealId,
                });
            }
        })
        .catch(error => {
            console.error(error);
            dispatch({
                type: APPEAL_DESTINATIONS_REQUEST_FAILURE,
                id: appealId,
            });
        });
};

const getKnowledgeBaseDataRequire = () => ({
    type: GET_KNOWLEDGE_BASE_DATA
});

const getKnowledgeBaseDataSuccess = (result) => ({
    type: GET_KNOWLEDGE_BASE_DATA_SUCCESS,
    result
});

const getKnowledgeBaseDataError = (error) => ({
    type: GET_KNOWLEDGE_BASE_DATA_ERROR,
    error
});

export const getKnowledgeBaseData = (id) => (dispatch) => {
    const params = {
        data: {
            objectId: id,
            objectType: 'REQUEST_TYPE',
            limit: 25
        }
    };

    dispatch(getKnowledgeBaseDataRequire());

    baseService.get('knowledge_base', params)
        .then(data => dispatch(getKnowledgeBaseDataSuccess(data)))
        .catch(err => dispatch(getKnowledgeBaseDataError(err)));
};


export function lockAppealForm (id) {
    return (dispatch) => {
        dispatch({
            type: LOCK_APPEAL_FORM,
            id,
        });
    }
}

export function unlockAppealForm (id) {
    return (dispatch) => {
        const params = {
            data: {
                id,
                mode: 'edit',
                duration: 100
            }
        };

        dispatch({
            type: UNLOCK_APPEAL_FORM,
            id,
        });

        baseService.get('unlock_appeal_form', params)
            .then(data => {
                if (data.success) {
                    console.log('success', data.success);
                    dispatch({
                        type: UNLOCK_APPEAL_FORM_SUCCESS,
                        version: data.result.version,
                        id,
                    });
                } else {
                    dispatch({
                        type: LOCK_APPEAL_FORM,
                        id,
                    });
                }
            })
            .catch(error => dispatch({
                type: UNLOCK_APPEAL_FORM_ERROR,
                error,
                id,
            }));
    }
}

export function getAppealPriorityList () {
    return (dispatch) => {
        dispatch({
            type: GET_APPEAL_PRIORITY_LIST,
        });

        return baseService.get('appeal_priority_list')
            .then(data => {
                dispatch({
                    type: GET_APPEAL_PRIORITY_LIST_SUCCESS,
                    result: data,
                });
                return true;
            }).catch(error => {
                dispatch({
                    type: GET_APPEAL_PRIORITY_LIST_ERROR,
                    error,
                });
                return false;
            });
    };
}

export function getAppealFeedback ({ appealId, customerId }) {
    return (dispatch) => {
        dispatch({ type: GET_APPEAL_FEEDBACK, id: appealId });

        const params = {
            data: {
                appealId,
                customerId
            }
        };

       return baseService.get('appeal_feedback', params)
            .then(res => {
                dispatch({
                    type: GET_APPEAL_FEEDBACK_SUCCESS,
                    result: res.result,
                    id: appealId,
                });
                return res.result;
            })
            .catch(error => {
                dispatch({
                    type: GET_APPEAL_FEEDBACK_ERROR,
                    error,
                    id: appealId,
                });
                return false;
            });
    };
}

export function clearAppealFeedback (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_APPEAL_FEEDBACK,
			id,
		});
	}
}

export function appealFetching (id) {
    return (dispatch) => {
        dispatch({
            type: APPEAL_FETCHING,
            id,
        });
        return true;
    }
}

export function setSaveAndCreateNew (id, payload) {
	return (dispatch) => {
		dispatch({
			type: SAVE_AND_CREATE_NEW,
			id,
			payload,
		});
	}
}


export function clearAllAppeals () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_APPEALS,
        });
    }
}
