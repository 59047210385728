import {
  CONTENT_UPDATE,
  CONTENT_RESET,
  REMOVE_PREVIOUS_TAB_DATA
} from 'constants/actions';


export const contentUpdate = () => ({
  type: CONTENT_UPDATE
});

export const contentReset = (params) => ({
  type: CONTENT_RESET,
  params
});

export const queriesContentUpdate = ({ page, query, ...rest }) => {
  return ({
    type: CONTENT_UPDATE,
    page: query ? 1 : page,
    query,
    ...rest
  });
};

export const removePreviousTabData = subTab => ({
  type: REMOVE_PREVIOUS_TAB_DATA,
  subTab
});
