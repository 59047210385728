import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import { translate } from 'react-i18next';
import get from 'lodash/get';

@translate()
class ComboBoxCreatable extends React.Component {
	constructor () {
		super();
		
		this.state = {
			createdOptions: []
		};
		
	}
	
	UNSAFE_componentWillReceiveProps (newProps) {
		if (this.props.options.length !== newProps.options.length) {
			this.setState({ createdOptions: [] });
		}
	}
	
	arrowRender = values => {
		if (values.isOpen) {
			return <i className='icon-up' />;
		}
		
		return <i className='icon-down' />;
	};
	
	/**
	 * Keep created options in local state or they will be lost
	 * on re-render
	 */
	onNewOptionClick = (option) => {
		const { props, select } = this.creatable;
		const { options } = props;
		
		options.unshift(option);
		select.selectValue(option);
		
		this.setState({
			createdOptions: [option]
		});
		
	};
	
	onBlur = value => {
		if (get(this.props, 'input.onBlur')) {
			this.props.input.onBlur(value);
		}
	};
	
	render () {
		const {
			label,
			meta,
			input,
			options,
			multi,
			placeholder,
			newOptionCreator,
			isValidNewOption,
			noResultsText = this.props.t('noResultsFound'),
			t,
			...rest
		} = this.props;
		const { value, onChange, name } = input;
		const allOptions = [
			...this.state.createdOptions,
			...options
		];
		const transformedValue = transformValue(value, allOptions, multi);
		
		return (
			<div id={rest.id} className='input-element'>
				<div className='input-label'>{label}</div>
				<Select.Creatable
					className={cx('container-comboBox', meta && meta.touched && (meta.error || meta.warning) && 'input-field__error')}
					arrowRenderer={this.arrowRender}
					isValidNewOption={isValidNewOption}
					noResultsText={noResultsText}
					multi={multi}
					name={name}
					placeholder={placeholder}
					newOptionCreator={newOptionCreator}
					onSelectResetsInput={false}
					onBlurResetsInput={false}
					options={allOptions}
					onChange={multi
						? multiChangeHandler(onChange)
						: singleChangeHandler(onChange)
					}
					
					onBlur={this.onBlur}
					onFocus={input && input.onFocus}
					onNewOptionClick={this.onNewOptionClick}
					promptTextCreator={(name) => `${t('filters.createNewOption')} "${name}"`}
					value={transformedValue}
					valueKey='value'
					ref={node => this.creatable = node}
					{...meta}
				/>
			
			</div>
		);
	}
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler (func) {
	return function handleSingleChange (option) {
		func(option ? option.value : '');
	};
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler (func) {
	return function handleMultiHandler (values) {
		func(values.map(value => value.value));
	};
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue (value, options, multi) {
	if (multi && typeof value === 'string') return [];
	
	const filteredOptions = options.filter(option => {
		return multi
			? value.indexOf(option.value) !== -1
			: option.value === value;
	});
	
	return multi ? filteredOptions : filteredOptions[0];
}

ComboBoxCreatable.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	input: PropTypes.object,
	multi: PropTypes.bool,
	meta: PropTypes.object,
	onNewOptionClick: PropTypes.func
};

export default ComboBoxCreatable;

