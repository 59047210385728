import React from 'react';
import Popup from 'components/Popup';
import ComboBox from 'components/Common/ComboBox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCallReceptionStatus, subscribeToCalls, setAuthorizationSuccess, getOperatorStatus } from 'actions/call';
import PropTypes from 'prop-types';
import styles from 'styles/modules/userBox.module.scss';
import { translate } from 'react-i18next';
import URLS from 'config/urls';
import baseService from 'services/BaseService';
import { sessionStorageService } from 'services/settingsService';
import UserCallForm from './UserCallForm';
import ClickOutsideHolder from '../ClickOutsideHolder';
import permissions, { checkPermissions } from 'config/permissions';

import pcg from '../../../package.json';

const mapStateToProps = state => ({
    phoneNumber: state.call.phoneNumber,
    callReceptionStatus: state.call.callReceptionStatus,
    isAuthorized: state.call.isAuthorized,
    operatorActions: state.call.operatorActions,
    callStation: state.call.callStation,
    user: state.user,
    shouldReadStatus: checkPermissions(permissions.CallOperations.action_workPbx)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCallReceptionStatus,
        subscribeToCalls,
        setAuthorizationSuccess,
        getOperatorStatus,
    }, dispatch);
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class UserPopup extends React.Component {
    constructor(props) {
        super(props);
        this.authorizing = false;
        this.onStatusChange = this.onStatusChange.bind(this);
    }

    componentDidMount() {
        const isOpenedBefore = sessionStorageService.get('isUserPopupOpenedBefore');
        if (!isOpenedBefore) {
            sessionStorageService.set('isUserPopupOpenedBefore', true);
        }
    }

    componentDidUpdate(prevProps) {
        const { operatorActions, user } = this.props;
        if (user.login && !prevProps.user.login && operatorActions.length === 0) {
            this.props.getOperatorStatus(this.props.user.login);
        }
    }

    componentWillUnmount() {
        if (this.isActiveCallReceptionsStatus() && !this.props.isAuthorized) {
            this.props.setCallReceptionStatus('inactive');
        }
    }

    logOut = () => {
        sessionStorage.clear();
        window.location.href = URLS.core.logout.url;
    };

    isActiveCallReceptionsStatus = () => this.props.callReceptionStatus === 'returntowork';

    getPhotoSource = () => `data:image/png;base64,${this.props.user.user_photo}`;

    getPopupAvatar = () => (this.props.user.user_photo
        ? (
            <div className={styles.popupAvatarWrapper}>
                <img
                    alt=''
                    className='popup-user-image'
                    src={this.getPhotoSource()}
                />
            </div>
        )
        : (
            <div className='popup-circle-wrapper'>
                <div className='icon-user-wrapper'>
                    <i className='icon-user' />
                </div>
            </div>
        ));

    onStatusChange({ value }) {
        const { user, setCallReceptionStatus } = this.props;
        setCallReceptionStatus(value, user.login);
    };

    handleClickOutside = (event) => {
        if (!this.authorizing) this.props.handleClickOutside(event);
    };

    onCallFormSubmit = ({ phoneNumber, agentName, agentPassword }) => {
        const { callStation } = this.props;
        this.authorizing = true;

        const params = {
            data: {
                phone: phoneNumber,
                login: agentName,
                authType: 'Basic',
                authValue: agentPassword,
            },
            jsonType: true,
        };

        return baseService.post('sse_auth', params, true)
            .then((response) => {
                if (response.success && response.result.confirmed) {
                    this.authorizing = false;
                    baseService.post('phone_register', { data: { phone: phoneNumber } });
                    this.props.setAuthorizationSuccess(phoneNumber);
                    this.props.subscribeToCalls();
                    this.props.onClose();
                } else {
                    throw new Error('SSE authentication error');
                }
            })
            .catch(() => {
                this.authorizing = false;
                return callStation === 'asterisk' ? 'Некоректний номер телефону'
                    : 'Некоректний логін або пароль';
            });
    };

    render() {
        const {
            user, callReceptionStatus, formRef, t, operatorActions, shouldReadStatus,
        } = this.props;
        return (
            <ClickOutsideHolder onClickOutside={this.handleClickOutside}>
                <Popup place='user-info-popup'>
                    <div className='popup-main'>
                        <div className='popup-wrapper'>
                            {this.getPopupAvatar()}

                            <div className='popup-text-wrapper'>
                                <div className='popup-name'>{user.login}</div>
                                <div className='popup-on-line' />
                            </div>
                        </div>

                        <div className='popup-select' ref={formRef}>
                            {
                                shouldReadStatus ? (
                                    <div className='row'>
                                        <div className='input-element'>
                                            <div className='input-label'>{t('status_name')}</div>
                                            <ComboBox
                                                name='status'
                                                placeholder={t('call.chooseStatus')}
                                                options={operatorActions}
                                                value={callReceptionStatus}
                                                onChange={this.onStatusChange}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }
                            
                            {
                                this.isActiveCallReceptionsStatus() && (
                                    <UserCallForm
                                        onSubmit={this.onCallFormSubmit}
                                        user={user}
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div className='popup-footer exit'>
                        <button type='button' className='menu-item' onClick={this.logOut}>
                            <i className='icon-sign-out'/>
                            <span className='menu-item-text'>{t('userExit')}</span>
                        </button>
                    </div>

                    <div className='popup-footer version'>
                        <span className='version-text'>
                            {`${t('version')}: ${pcg.version} | ${pcg.date} @Priocom`}
                        </span>
                    </div>

                </Popup>
            </ClickOutsideHolder>
        );
    }
}

UserPopup.propTypes = {
    handleClickOutside: PropTypes.func,
    user: PropTypes.object,
    formRef: PropTypes.object,
    onClose: PropTypes.func,
};

export default UserPopup;
