import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import styles from 'styles/modules/appealOperatePage.module.scss';
import 'draft-js/dist/Draft.css';
import { translate } from 'react-i18next';
import { b64DecodeUnicode } from 'helpers';

@translate()
export default class MailingItem extends React.Component {
    state = {
        isOpen: false
    };
    
    onChange = (editorState) => this.setState({editorState});
    
    componentDidMount () {
        if (this.props.shouldOpen) {
            this.props.openEmailSuccess();
            this.setState({isOpen: true});
        }
    }
	
	UNSAFE_componentWillReceiveProps (nextProps) {
        if (this.props.toggleAllMails !== nextProps.toggleAllMails) {
            this.setState({isOpen: nextProps.toggleAllMails});
        }
        
        if (!this.props.shouldOpen && nextProps.shouldOpen) {
            this.props.openEmailSuccess();
            this.setState({isOpen: true});
        }
    }
    
    toggleOpen = () => this.setState({isOpen: !this.state.isOpen});
    
    createMarkup = () => {
        return <span dangerouslySetInnerHTML={{ __html: b64DecodeUnicode(this.props.mailBody) }} />;
    };
    
    onReply = () => {
        const { onClickReply, id, interactionId, mailBody } = this.props;
        onClickReply(id, interactionId, mailBody);
    };
    
    onForward = () => {
        const { onClickForward, id, interactionId, mailBody } = this.props;
        onClickForward(id, interactionId, mailBody);
    };
    
    renderAvatar = () => {
        return this.props.image
            ? <img src={this.props.image} alt=''/>
            : <i className='icon-user'/>;
    };
    
    renderAttachment = ({name, id}) => (
        <div className={styles.fileLinkWrapper} key={id}>
            <a href={`../mw/file?fileId=${id}`} download className={styles.fileLink}>{name}</a>
            <i className={cx('icon-file', styles.saveIcon)}/>
        </div>
    );
    
    render () {
        const { subject, date, mailFrom, mailTo, attachments, t, id, emailType, interactionId } = this.props;
        const { isOpen } = this.state;
        const hasAttachments = attachments && attachments.length > 0;
        const attachIconStyle = hasAttachments ? 'icon-attach' : cx('icon-attach', styles.hidden);
        return (
            <div className={cx(styles.borderedBlock, isOpen && styles.isOpen)} data-id={id} >
                <div className={styles.mailHeader}>
                    <div className={cx(styles.mailTitle, isOpen && styles.top)}>
                        <div className={styles.avatarWrapper}>
                            {this.renderAvatar()}
                        </div>
                        <div>
                            {
                                emailType === 'outgoing'
                                    ? <div className={styles.lightText}>{t('toWhom')}: {mailTo}</div>
                                    : <div className={styles.lightText}>{t('from')}: {mailFrom}</div>
                            }
                            <div className={styles.mailTopic}>{subject}</div>
                        </div>
                    </div>
                    <div className={styles.mailIconsWrapper}>
                        <div className={styles.lightText}>{moment(date).format('DD MMMM YYYY, HH:mm')}</div>
                        <i className={attachIconStyle} onClick={this.toggleOpen}/>
                        <i className='icon-down' onClick={this.toggleOpen}/>
                    </div>
                </div>

                <div
                    className={cx(styles.mailText, isOpen && styles.openedMailText)}
                >{this.createMarkup()}</div>
                
                <div className={cx(styles.mailBody, isOpen && styles.openedMailBody)}>
                    <div className={styles.mailButtons}>
                        <div className={styles.mailButtonsLeftBar}>
                            <button type='button' className={styles.mailButton} onClick={this.onReply}>
                                <i className='icon-send'/>
                                {t('answer')}
                            </button>
                            
                            <button type='button' className={styles.mailButton} onClick={this.onReply}>
                                <i className='icon-send-all'/>
                                {t('answerAll')}
                            </button>
                            
                            <button type='button' className={styles.mailButton} onClick={this.onForward}>
                                <i className='icon-arrow-alt-right'/>
                                {t('forwardEmail')}
                            </button>
                        </div>
                    </div>
                    
                    {hasAttachments && (
                        <div>
                            <hr className={styles.fileSeparator}/>
                            <div className={styles.filesWrapper}>
                                <div className={styles.filesList}>
                                    {attachments.map(this.renderAttachment)}

                                </div>
                                <div className={styles.filesButtons}>
                                    <a href={`../mw/appeal/file/getFiles?typeCode=MAILATTACHMENT&id=${interactionId}`} download className={styles.filesButton}>{t('comments.uploadAll')}</a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

MailingItem.propTypes = {
    image: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.number,
    attach: PropTypes.string,
    text: PropTypes.string,
    mailFrom: PropTypes.string,
    mailTo: PropTypes.string,
    subject: PropTypes.string,
    shouldOpen: PropTypes.bool
};
