const mwPrefix = '../mw';
const snPrefix = '../sn';

function buildPath (path, sn) {
    const prefix = sn ? snPrefix : mwPrefix;
    return {
        url: `${prefix}${path}`
    };
}

module.exports = {
    /**
     *   Prefix path for root
     */
    prefix: '',

    // Core URLs
    /**
     * Core web services urls
     * @property lock
     */
    core: {
        /**
         * lock entity url
         * @property url
         */

        async_search_attributes: buildPath('/request/attributes/dict'),

        sn_alive_request: {
            url: `${snPrefix}/server/alive`,
        },

        phone_conf: {
            url: `${snPrefix}/phone/conf`,
        },

        sse_auth: {
            url: `${snPrefix}/CallEvent/auth`,
        },

        phone_register: {
            url: `${mwPrefix}/operator/set`,
        },

        sse_stream: {
            url: `${snPrefix}/CallEvent/stream`,
        },

        dialog_answer: {
            url: `${snPrefix}/Dialog/answer`,
        },

        dialog_hangup: {
            url: `${snPrefix}/Dialog/drop`,
        },

        dialog_hold: {
            url: `${snPrefix}/Dialog/hold`,
        },

        dialog_retrieve: {
            url: `${snPrefix}/Dialog/retrieve`,
        },

        dialog_call: {
            url: `${snPrefix}/Dialog/call`,
        },

        dialog_transfer: {
            url: `${snPrefix}/Dialog/transfer`,
        },

        operator_status: {
            url: `${mwPrefix}/admin/state/list`,
        },

        set_operator_status: {
            url: `${mwPrefix}/admin/state/set`,
        },

        article_delete: {
            url: `${mwPrefix}/kb/delete`,
        },

        get_appeal_actions: {
            url: `${mwPrefix}/appeal_label/action/list`,
        },

        set_appeal_action: {
            url: `${mwPrefix}/appeal/action/params`,
        },

        customer: {
            url: `${mwPrefix}/customer`,
        },

        create_individual_customer: {
            url: `${mwPrefix}/customer/create/individual`,
        },

        create_organization: {
            url: `${mwPrefix}/customer/create/organization`,
        },
	
		customer_address_type: {
			url: `${mwPrefix}/address/type/list`,
		},

        customer_edit: {
            url: `${mwPrefix}/customer/edit`,
        },

        customer_edit_multi: {
            url: `${mwPrefix}/customer/edit/mult`,
        },

        customer_persons: {
            url: `${mwPrefix}/customer/person`,
        },

        person: {
            url: `${mwPrefix}/person`,
        },

        person_unlink: {
            url: `${mwPrefix}/customer/person/unlink`,
        },
        
        person_edit: {
            url: `${mwPrefix}/customer/person/edit`,
        },

        issues_edit: {
            url: `${mwPrefix}/customer/person/issues/save`,
        },

        person_edit_multi: {
            url: `${mwPrefix}/customer/person/edit/mult`,
        },

        person_save_contact: {
            url: `${mwPrefix}/person/contact/save`,
        },

        person_delete_contact: {
            url: `${mwPrefix}/person/contact/delete`,
        },

        person_contact: {
            url: `${mwPrefix}/person/contact`,
        },

        person_link: {
            url: `${mwPrefix}/person/link`,
        },

        persons_save: {
            url: `${mwPrefix}/customer/person/save`,
        },

        customer_appeals: {
            url: `${mwPrefix}/appeal/customer`,
        },

        customer_appeals_info: {
            url: `${mwPrefix}/appeal/customer/info`,
        },

        customer_address: {
            url: `${mwPrefix}/customer/addresses`,
        },

        customer_services: {
            url: `${mwPrefix}/customer/services`,
        },

        customer_services_types: {
            url: `${mwPrefix}/customer/services/type/list`,
        },

        customer_accounts: {
            url: `${mwPrefix}/customer/accounts`,
        },

        // the same url as "customer_accounts"
        customer_contracts: {
            url: `${mwPrefix}/customer/accounts`,
        },

        customer_tasks: {
            url: `${mwPrefix}/item/instance`,
        },

        customer_info: {
            url: `${mwPrefix}/customer/info`,
        },

        customer_bank: {
            url: `${mwPrefix}/customer/bank`,
        },

        applicants_search: {
            url: `${mwPrefix}/CallHistory/search`,
        },

        customer_suggest: {
            url: `${mwPrefix}/CallHistory/search`,
        },

        call_history: {
            url: `${mwPrefix}/CallHistory/phone`,
        },

        user_info: {
            // return info about current user
            url: `${mwPrefix}/auth`,
        },

        user_get_phones_list: {
            url: `${mwPrefix}/user/get/phones/list`,
        },

        user_edit_phones_list: {
            url: `${mwPrefix}/user/edit/phones/list`,
        },

        not_auth: {
            // server not_authenticated (for redirect)
            url: `${mwPrefix}/auth/not_auth`,
            redirect_url() {
                return `${this.url}?next=${encodeURI(window.location.href)}`;
            },
        },

        profile: {
            url: '../api/auth/profile',
            redirect: true,
        },

        logout: {
            url: `${mwPrefix}/auth/logout_idm`,
            redirect: true,
        },

        titles_menu: {
            url: '/data/precrmEntities.json',
        },

        apps_menu: {
            url: '/data/applications.json',
        },

        local_customer: {
            url: '/data/customer.json',
        },
        local_appeal: {
            url: '/data/appeal.json',
        },
        local_task: {
            url: '/data/task.json',
        },
        dictionary: {
            url: `${mwPrefix}/app/dictionaries`,
        },
        search: {
            url: `${mwPrefix}/search`,
        },
        search_csv: {
            url: `${mwPrefix}/search/csv`,
        },

        appeal_interaction_csv: {
            url: `${mwPrefix}/appeal/progress/interactions/csv`,
        },

        appeal_customer_csv: {
            url: `${mwPrefix}/appeal/customer/csv`,
        },

        filters: {
            url: `${mwPrefix}/request/presets`,
        },

        appeal_filters: {
            url: `${mwPrefix}/request/templates`,
        },

        filter_fields: {
            url: `${mwPrefix}/request/filter`,
        },
        filter_apply: {
            url: `${mwPrefix}/request/filter/apply`,
        },
        filter_apply_native: {
            url: `${mwPrefix}/request/filter/native`,
        },
        preset_apply: {
            url: `${mwPrefix}/request/preset/apply`,
        },
        preset_apply_csv: {
            url: `${mwPrefix}/request/filter/apply/csvOneStep`,
        },
        preset_apply_export_to_csv: {
            url: `${mwPrefix}/request/filter/apply/csvexport`,
        },
        preset_apply_export_to_xlsx: {
            url: `${mwPrefix}/request/filter/apply/xlsxexport`,
        },
        attributes: {
            url: `${mwPrefix}/request/attributes`,
        },

        save_filter: {
            url: `${mwPrefix}/request/preset/save`,
        },

        info_filter: {
            url: `${mwPrefix}/request/preset/info`,
        },

        delete_filter: {
            url: `${mwPrefix}/request/filter/delete`,
        },

        knowledge_base_tree: {
            url: `${mwPrefix}/kb/tree`,
        },

        knowledge_base: {
            url: `${mwPrefix}/kb/list`,
        },

        kb_status_list: {
            url: `${mwPrefix}/kb/status/list`,
        },

        kb_detail: {
            url: `${mwPrefix}/kb/detail`,
        },

        knowledge_base_save: {
            url: `${mwPrefix}/kb/save`,
        },

        comments: {
            url: `${mwPrefix}/appeal/comment`,
        },

        // Update comment text.
        comment_update: {
            url: `${mwPrefix}/appeal/comment/update`,
            method: 'post',
        },

        // Attach file to comment.
        comment_attach: {
            url: `${mwPrefix}/appeal/comment/attach`,
        },

        // Detach file from comment.
        comment_detach: {
            url: `${mwPrefix}/appeal/comment/detach`,
        },

        download_file: {
            url: `${mwPrefix}/file`,
        },

        delete_file: {
            url: `${mwPrefix}/file/delete`,
        },

        save_file: {
            url: `${mwPrefix}/file/upload`,
        },

        // Delete file
        file_delete: {
            url: `${mwPrefix}/appeal/file/delete`,
        },

        // Get all files attached to comment
        file_list: {
            url: `${mwPrefix}/appeal/file/list`,
        },
        comment_add: {
            url: `${mwPrefix}/appeal/comment`,
        },

        upload_file: {
            url: `${mwPrefix}/appeal/file/upload`,
        },

        upload_file_email: {
            url: `${mwPrefix}/email/attach/upload`,
        },
        delete_file_email: {
            url: `${mwPrefix}/email/attach/delete`,
        },

        get_files_list: {
            url: `${mwPrefix}/email/attach/list`,
        },

        interaction_create: {
            url: `${mwPrefix}/interaction/create`,
        },

        interaction_close: {
            url: `${mwPrefix}/interaction/close`,
        },

        interaction_appeal: {
            url: `${mwPrefix}/interaction/appeal`,
        },

        appeal_create: {
            url: `${mwPrefix}/interaction/appeal/create`,
        },

        appeal_save: {
            url: `${mwPrefix}/interaction/appeal/save`,
        },

        appeal_interactions: {
            url: `${mwPrefix}/appeal/interactions`,
        },

        appeal_interaction_proof: {
            url: `${mwPrefix}/appeal/interactions/proof`,
        },

        appeal_type: {
            url: `${mwPrefix}/appeal/filteredType`,
        },

        appeal_form_params: {
            url: `${mwPrefix}/form/params`,
        },

        appeal_form_ajax: {
            url: `${mwPrefix}/form/values/search`,
        },

        appeal_by_id: {
            url: `${mwPrefix}/appeal`,
        },

        get_appeal_version: {
            url: `${mwPrefix}/appeal/version`,
        },

        appeal_status_list: {
            url: `${mwPrefix}/appeal/status/list`,
        },

        appeal_dest_list: {
            url: `${mwPrefix}/appeal/dest/list`,
        },

        appeal_priority_list: {
            url: `${mwPrefix}/appeal/priority/list`,
        },

        appeal_feedback: {
            url: `${mwPrefix}/appeal/contactPersons`,
        },

        appeal_feedback_save: {
            url: `${mwPrefix}/appeal/contacts/save`,
        },

        appeal_customer_save: {
            url: `${mwPrefix}/interaction/customer/save`,
        },

        unlock_appeal_form: {
            url: `${mwPrefix}/appeal/lock`,
        },

        unlock_appeal: {
            url: `${mwPrefix}/appeal/unlock`,
        },

        appeal_update: {
            url: `${mwPrefix}/appeal/update`,
        },

        appeal_timer_save: {
            url: `${mwPrefix}/appeal/timer/save`,
        },

        mailing: {
            url: `${mwPrefix}/email/inbox/short`,
        },

        send_email: {
            url: `${mwPrefix}/email/reply`,
        },

        forward_email: {
            url: `${mwPrefix}/email/forward`,
        },

        get_template_list: {
            url: `${mwPrefix}/notificationTemplate/list`,
        },

        get_template_email: {
            url: `${mwPrefix}/notificationTemplate/test`,
        },

        create_new_email: {
            url: `${mwPrefix}/email/create`,
        },

        history: {
            url: `${mwPrefix}/appeal/progress/interactions/list`,
        },

        interaction: {
            url: `${mwPrefix}/interaction/create`,
        },

        task: {
            url: `${mwPrefix}/order/task`,
        },

        callhistory_list: {
            url: `${mwPrefix}/order/task/filter/list`,
        },

        doc_template_default: {
            url: `${mwPrefix}/doctempl/default`,
        },

        doc_template_create: {
            url: `${mwPrefix}/request/document/make`,
        },

        doc_download: {
            url: `${mwPrefix}/doctempl/file`,
        },

        search_settlement: {
            url: `${mwPrefix}/geo/settlement`,
        },

        search_settlement_by_zip_code: {
            url: `${mwPrefix}/geo/zip/search`,
        },

        search_street: {
            url: `${mwPrefix}/geo/street`,
        },

        add_street: {
            url: `${mwPrefix}/addresses/street/add`,
        },

        search_building: {
            url: `${mwPrefix}/geo/build`,
        },

        search_zip_code: {
            url: `${mwPrefix}/geo/zip`,
        },

        quality_questions_list: {
            url: `${mwPrefix}/interaction/item/type/list`,
        },
        quality_questions: {
            url: `${mwPrefix}/interaction/item/rows`,
        },
        dashboard_templates: {
            url: `${mwPrefix}/dashboard/templates`,
        },
        dashboard_filter: {
            url: `${mwPrefix}/dashboard/filter`,
        },
        dashboard_kpi: {
            // url:  'data/dashboard/summary.json'
            url: `${mwPrefix}/dashboard/appealkpi`,
        },
        dashboard_map: {
            url: `${mwPrefix}/dashboard/filter/interactappealmap`,
        },
        dashboard_pie: {
            // url:  'data/dashboard/pieChart.json'
            url: `${mwPrefix}/dashboard/filter/appealtoptenq`,
        },
        dashboard_line: {
            // url:  'data/dashboard/lineChart.json'
            url: `${mwPrefix}/dashboard/filter/appealcount`,
        },
        dashboard_statuses_by_destination: {
            url: `${mwPrefix}/dashboard/filter/apealstatusesbydest`,
        },
        dashboard_statuses_by_type: {
            url: `${mwPrefix}/dashboard/filter/apealstatusesbytype`,
        },
        dashboard_appeal_top_ten_by_dest: {
            url: `${mwPrefix}/dashboard/filter/appealtoptendest`,
        },
        dashboard_appeal_top_ten_by_oper: {
            url: `${mwPrefix}/dashboard/filter/appealtoptenoper`,
        },

        get_blank_html: {
            url: `${mwPrefix}/request/document/getDocument`,
        },

        // Settings.

        admin_user_all: {
            url: `${mwPrefix}/admin/user/all`,
        },
        admin_group_all: {
            url: `${mwPrefix}/admin/group/all`,
        },
        admin_area_all: {
            url: `${mwPrefix}/admin/area/list`,
        },
        admin_domain_all: {
            url: `${mwPrefix}/admin/space/list`,
        },
        admin_access_rules: {
            url: `${mwPrefix}/admin/space/rules`,
        },
        add_access_rule: {
            url: `${mwPrefix}/admin/space/rule/add`,
        },
        delete_access_rule: {
            url: `${mwPrefix}/admin/space/rule/del`,
        },
        add_child_access_rule: {
            url: `${mwPrefix}/admin/child/rule/add`,
        },
        delete_child_access_rule: {
            url: `${mwPrefix}/admin/child/rule/del`,
        },
        get_access_rule_tables: {
            url: `${mwPrefix}/admin/space/table/list`,
        },
        get_access_rule_columns: {
            url: `${mwPrefix}/admin/space/column/list`,
        },
        get_access_rule_conditions: {
            url: `${mwPrefix}/admin/space/condition/list`,
        },
        get_rule_access: {
            url: `${mwPrefix}/admin/space/rule/info`,
        },
        get_child_rule_access: {
            url: `${mwPrefix}/admin/child/rules`,
        },
        save_rule_access: {
            url: `${mwPrefix}/admin/space/rule/set`,
        },
        get_group_domains: {
            url: `${mwPrefix}/admin/user/space/list`,
        },
        add_user_group: {
            url: `${mwPrefix}/admin/area/add`,
        },
        delete_user_group: {
            url: `${mwPrefix}/admin/area/del`,
        },
        // Settings/BuissnesUnits
        get_buisness_units: {
            url: `${mwPrefix}/order/task/bu/list`,
        },
        delete_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/del`,
        },
        add_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/add`,
        },
        edit_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/set`,
        },
        get_buisness_unit_users: {
            url: `${mwPrefix}/order/task/bu/emp/list`,
        },
        search_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/search`,
        },
        delete_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/del`,
        },
        add_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/set`,
        },
        get_buisness_unit_spaces: {
            url: `${mwPrefix}/space/list`,
        },
        get_buisness_unit_spaces_all: {
            url: `${mwPrefix}/space/all`,
        },
        search_buisness_unit_space: {
            url: `${mwPrefix}/space/all`,
        },
        add_buisness_unit_space: {
            url: `${mwPrefix}/space/add`,
        },
        delete_buisness_unit_space: {
            url: `${mwPrefix}/space/del`,
        },
        get_buisness_unit_restrs: {
            url: `${mwPrefix}/admin/space/restr`,
        },
        get_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/info`,
        },
        get_buisness_unit_restr_types: {
            url: `${mwPrefix}/admin/space/attr/restr`,
        },
        edit_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/set`,
        },
        delete_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/del`,
        },
        sync_buisness_unit_users: {
            url: `${mwPrefix}/admin/user/sync`,
        },
        sync_buisness_unit_domains: {
            url: `${mwPrefix}/admin/domain/sync`,
        },

        // Orders

        get_order_list: {
            url: `${mwPrefix}/order/task`,
        },
        get_order_csv: {
            url: `${mwPrefix}/form/params/csv`,
        },
        get_order_type_options: {
            url: `${mwPrefix}/order/task/type/list`,
        },
        create_new_order: {
            url: `${mwPrefix}/order/task/new?version=111-`,
        },
        get_public_orders_filters: {
            url: `${mwPrefix}/order/task/filter/list`,
        },
        get_private_orders_filters: {
            url: `${mwPrefix}/order/search/filters/list`,
        },
        get_order_comments: {
            url: `${mwPrefix}/order/task/comment`,
        },
        get_order_params: {
            url: `${mwPrefix}/order/task/row/params`
        },
        get_order_history_all: {
            url: `${mwPrefix}/changes`
        },
        get_order_history_system: {
            url: `${mwPrefix}/changes/system`
        },
        get_order_comments_users_emails: {
            url: `${mwPrefix}/order/task/comment/email/user/search`,
        },
        get_order_comments_email_history: {
            url: `${mwPrefix}/order/task/comment/email/get`,
        },
        get_order_actions: {
            url: `${mwPrefix}/order/task/actions`,
        },
        set_order_action: {
            url: `${mwPrefix}/order/task/action`,
        },
        track_order: {
            url: `${mwPrefix}/track`
        },
        get_order_suspend_reason_list: {
            url: `${mwPrefix}/order/task/suspend/reason/list`
        },
        get_sub_orders: {
            url: `${mwPrefix}/order/task/subtasks/`
        }
    }
};
