import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Confirm from '../Confirm';

export default class DeleteInstanceButton extends Component {
  state = {
    confirm: false
  };

  handleClick = (e) => {
    e.preventDefault();

    if (this.props.params.confirm && this.props.params.confirm.includes('remove')) {
      this.setState({ confirm: true });
    } else {
      this.action();
    }
  };

  action = () => this.props.params.remove && this.props.params.remove({
    id: this.props.id,
    message: this.props.message
  });

  render = () => {
    const { target } = this.props;
    const { confirm } = this.state;

    return (
      <td>
        <i className="a icon-times" onClick={this.handleClick} data-target={target}/>
        {confirm && <Confirm action={this.action} cancel={() => this.setState({ confirm: false })}/>}
      </td>
    );
  };
}

DeleteInstanceButton.defaultProps = {
  target: '#submitDeleteModal'
};

DeleteInstanceButton.propTypes = {
  id: PropTypes.number,
  params: PropTypes.object,
  target: PropTypes.string,
  message: PropTypes.string
};
