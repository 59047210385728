import React from 'react';
import {
    CompactDatePicker,
    CompactSelect,
    HeaderAppealInfo,
    HeaderMainCell,
    HeaderRow,
    HeaderSecondaryCell,
    HeaderSeparator
} from './components';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';

class Header extends React.Component {
    
    getPriorityOptions = () => this.props.priorityList.map(item => ({
        label: item.name,
        value: item.id
    }));
    
    render () {
        const { t, unlockAppealForm, checkRestrictions, shouldRenderField } = this.props;
        return (
            <header className={styles.formHeader}>
                <HeaderRow>
                    <HeaderMainCell>
                        <HeaderAppealInfo />
                    </HeaderMainCell>
                    
                    <HeaderSecondaryCell>
                        {
                            shouldRenderField('priorityId') &&
                            <Field
                                component={CompactSelect}
                                name='priority'
                                label={t('appeal.priority')}
                                placeholder={t('appeal.selectPlaceholder')}
                                options={this.getPriorityOptions()}
                                onChange={unlockAppealForm}
                                required={checkRestrictions('priorityId')}
                                disabled={checkRestrictions('priorityId')}
                            />
                        }
                    </HeaderSecondaryCell>
                    
                    <HeaderSecondaryCell>
                        <HeaderSeparator />
                    </HeaderSecondaryCell>
                    
                    <HeaderSecondaryCell>
                        {
                            shouldRenderField('resolveDateResolveDate') &&
                            <Field
                                name='resolveDate'
                                component={CompactDatePicker}
                                label={t('appeal.resolveDate')}
                                required
                                disabled={checkRestrictions('resolveDateResolveDate')}
                                onChange={unlockAppealForm}
                            />
                        }
                    </HeaderSecondaryCell>
                </HeaderRow>
            </header>
        );
    }
}

Header.propTypes = {
    unlockAppealForm: PropTypes.func,
    checkRestrictions: PropTypes.func,
    shouldRenderField: PropTypes.func,
    repeatRequestId: PropTypes.number,
    operatorName: PropTypes.string,
    stateName: PropTypes.string,
    creationDate: PropTypes.number,
    repeatCustomerId: PropTypes.number,
    author: PropTypes.string,
    priorityList: PropTypes.array
    // regnum: PropTypes.number
};

export default Header;
