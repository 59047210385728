import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import cx from 'classnames';

const CurrentAppealChangeNote = ({ userName, avatar, onClose }) => (
  <div className={styles.currentAppealChangeNote}>
    <div className={styles.userAvatar}/>
    <div className={styles.noteText}>
      <span className={styles.userName}>{userName}</span>
      <span>вніс зміни у це звернення</span>
    </div>
    <button className={cx('btn', 'btn-primary')} onClick={onClose}>Зрозуміло</button>
  </div>
);

// CurrentAppealChangeNote.defaultProps = {
//   userName: 'Підлипський Антон',
//   avatar: ''
// };

CurrentAppealChangeNote.propTypes = {
  userName: PropTypes.string,
  avatar: PropTypes.string,
  onClose: PropTypes.func
};

export default CurrentAppealChangeNote;

