import { StreetModes, customerAddressFormConfigWithType } from 'constants/index';
import { zipCodeValidation } from 'util/form';

const comboBoxWithInputFields = ['buildingNumber', 'zipCode'];
const validate = values => {
    const errors = {};

	customerAddressFormConfigWithType.forEach(field => {
		
        if (field.required && !values[field.name]) {
            errors[field.name] = 'Обов\'язкове поле';
        }
		
        if (values.mode === StreetModes.MANUAL) {
            errors.street = undefined;
	
			if (!values.streetName) {
                errors.streetName = 'Обов\'язкове поле';
            }
            else if (field.name === 'street' && values.streetName.length > field.inputProps.maxLength) {
                errors.streetName = `Занадто велика назва(${field.inputProps.maxLength} максимальна кількість символів)`;
            }
            
            if (!values.streetType) {
                errors.streetType = 'Обов\'язкове поле';
            }
        }
    });
    
    comboBoxWithInputFields.forEach(field => {
        if (errors[field] && values[`${field}Input`]) {
            errors[field] = undefined;
        }
    });
    
    if (values.zipCodeInput && zipCodeValidation(values.zipCodeInput)) {
        errors.zipCode = zipCodeValidation(values.zipCodeInput);
    }
    
    return errors;
};

export default validate;
