import get from 'lodash/get';
import { findPropertyInArray, isOrganization } from 'helpers';
import { createSelector } from 'reselect';
import moment from 'moment';

export const findType = (typeId, parentArr, siblings, treeParam = 'children', valueField = (v) => v.id) => {
    let type;
    let siblingsArr;
    const findTypeById = (id, arr) => (
        arr.forEach(el => {
            if (valueField(el) === id) {
                type = el;
                siblingsArr = arr;
            } else {
                (el[treeParam]) && findTypeById(id, el[treeParam]);
            }
        }));
    findTypeById(typeId, parentArr);
    return (siblings ? siblingsArr : type);
};

export const findParent = (el, arr, treeParam = 'children', valueField = (v) => v.id) => {
    let parent;
    const findParentForElem = (elem, parentArr) => (
        parentArr.forEach(item => {
            if (item[treeParam]) {
                if (item[treeParam].find(child => valueField(child) === valueField(elem))) {
                    parent = item;
                } else {
                    findParentForElem(elem, item[treeParam]);
                }
            }
        }));
    findParentForElem(el, arr);
    return parent;
};

const getAppealType = (appeal, rootAppealType) => {
    
    /**  'appealTypes.id' is root appeal type  **/
    if (appeal.typeId === rootAppealType.id) return null;
    
    const getFullLabelPath = (typeFullName = '') => typeFullName.split('\\').filter(Boolean).join(' / ');
    
    return {
        id: appeal.typeId,
        fullLabelPath: getFullLabelPath(appeal.typeFullName),
        code: appeal.typeCode,
    };
};

export function initialValues (appeal, appealType, formParams, appealFeedback, customer) {
    const resolveDate = appeal.resolveDate.resolveDate;
    const priority = { value: appeal.priority.id, label: appeal.priority.name };
    let status = appeal.status;
    const destinationId = appeal.destinationId;
    const type = getAppealType(appeal, appealType);
    
    if (status && status.code === 'beingCreated') {
        status = { code: 'Created', name: 'Новий', id: 1205 };
    }
    
    return {
        ...appeal,
        resolveDate,
        type,
        status,
        timer: get(appeal.timer, 'date'),
        nextState: get(appeal.timer, 'nextState.code'),
        priority,
        blocks: initializeDynamicParams(formParams),
        destinationFeedback: appealFeedback && customer ? initializeAppealFeedBack(appealFeedback, customer, appeal) : [],
        destination: destinationId ? [destinationId] : ['']
    };
}

function initializeDynamicParams (formParams) {

    const blocks = {};

    if (Array.isArray(formParams.blocks)) {
        formParams.blocks.forEach(block => {
            if (Array.isArray(block.widgets)) {
                block.widgets.forEach(widget => {
                    const specificWidgetTypes = ['file', 'fileMultiple', 'date', 'datetime', 'ajaxCombo'];
                    let value = widget.savedValue || widget.defaultValue;

                    // set common fields
                    if (!specificWidgetTypes.includes(widget.widgetType)) {
                        blocks[widget.key] = value;
                    }

                    // set date fields
                    if (widget.widgetType === 'date' || widget.widgetType === 'datetime') {

                        if (moment(value, 'x', true).isValid()) {
                            value = parseInt(value);
                        }

                        value = value ? moment(value) : value;

                        blocks[widget.key] = value;
                    }

                    // set file fields
                    if (widget.widgetType === 'file' || widget.widgetType === 'fileMultiple') {
                        if (value && Array.isArray(widget.values)) {
                            blocks[widget.key] = value.split('|').map(fileId => {
                                const fileObject = widget.values.find(value => value.key === fileId);
                                return fileObject && {
                                    name: fileObject.value,
                                    id: fileObject.key,
                                };
                            }).filter(Boolean);
                        } else {
                            blocks[widget.key] = [];
                        }
                    }

                    // set ajaxCombo fields
                    if (widget.widgetType === 'ajaxCombo') {
                        if (value && Array.isArray(widget.values) && widget.values.length > 0) {
                            const foundValue = widget.values.find(option => option.key === value);
                            blocks[widget.key] = {
                                value: foundValue.key,
                                label: foundValue.value,
                                key: foundValue.key
                            };
                        }
                    }
                });
            }
        });
    }
    return blocks;
}

function initializeAppealFeedBack (appealFeedback, customer, appeal) {
    // if (!customer || !appealFeedback) return this.props.change('destinationFeedback', undefined);
    let initialContacts = [];
    const statusCode = _.get(appeal, 'status.code');
    
    const foundCustomer = findPropertyInArray(appealFeedback, 'type', 'customer');

    if (foundCustomer) {
        // if (foundCustomer.contacts && foundCustomer.contacts.length > 0) {
        //     foundCustomer.contacts.forEach(contact => {
        //         if (this.props.callPhoneNumber === contact.value && statusCode === 'beingCreated') {
        //             initialContacts.push(contact.id);
        //         }
        //     });
        // }
        initialContacts = [...initialContacts, ...foundCustomer.selectedContacts];
    }
    
    if (isOrganization(customer.party.partyType)) {
        const gerContactPersons = appealFeedback.filter(i => (i.type === 'contactPerson' && foundCustomer.id !== i.id));
        if (gerContactPersons.length > 0) {
            gerContactPersons.forEach(contact => {
                // if (contact.contacts && contact.contacts.length > 0) {
                //     contact.contacts.forEach(item => {
                //         if (this.props.callPhoneNumber === item.value && statusCode === 'beingCreated') {
                //             initialContacts.push(item.id);
                //         }
                //     });
                // }
                initialContacts = [...initialContacts, ...contact.selectedContacts];
            });
        }
    }
    
    return [...new Set(initialContacts)];
}


export function findDefaultDestination(destinationsArray, result = []) {
	for (let i = 0; i < destinationsArray.length; i++) {
		const curr = destinationsArray[i];
		if (curr.object && curr.object.isDefault == false) {
			result.push({id:curr.object.id, text: curr.object.text, route:curr.object.route});
			return result;
		}
		if (curr.result) {
			result = [ ...result, ...findDefaultDestination(curr.result)];
		}
	}
	return result;
}

export function hasDestinationWithId(destinationsArray, id) {
	let result = false;
	for (let i = 0; i < destinationsArray.length; i++) {
		const curr = destinationsArray[i];
		if (curr.object && curr.object.id === id) {
			return true;
		}
		if (curr.result) {
			result = hasDestinationWithId(curr.result, id);
		}
	}
	return result;
}

