import React, { Fragment } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { change, formValueSelector } from 'redux-form';
import Popup from 'components/Popup';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { connect } from 'react-redux';
import cx from 'classnames';
import { translate } from 'react-i18next';
import StatusModal  from './StatusModal';
import { withRouter } from 'react-router-dom';
import { ModalFields } from './constants';
import { setModalStatusLIst, setStatusModalVisibility } from '../../../actions/appeal';
import { extractAppealFromState } from '../../../helpers';


function mapStateToProps (state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    
    return {
        ...formValueSelector(`appeal-form-${id}`)(
            state, 'status', 'resolution', ...Object.values(ModalFields)
        ),
        customerId: get(state, 'customer.currentCustomer.id'),
        firstLevelAppealAction: appeal.firstLevelAppealAction,
        resolutionAppealAction: appeal.resolutionAppealAction,
        modalStatusList: appeal.modalStatusList,
        modalVisibility: appeal.modalVisibility,
        statusList: appeal.statusList,
        id,
    }
}

const mapDispatchToProps = {
    setModalStatusLIst,
    setStatusModalVisibility,
    change: (id, field, value) => change(`appeal-form-${id}`, field, value),
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class StatusSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusPopupIsOpen: false,
            resolutionPopupIsOpen: false,
            selectedStatus: null,
            selectedResolution: null,
        };

        this.selectStatus = this.selectStatus.bind(this);
        this.selectResolution = this.selectResolution.bind(this);
        this.selectStatusOnly = this.selectStatusOnly.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onModalSubmit = this.onModalSubmit.bind(this);
        this.checkAndSaveChanges = this.checkAndSaveChanges.bind(this);
        this.openStatusPopup = this.openStatusPopup.bind(this);
        this.renderStatusLink = this.renderStatusLink.bind(this);
        this.renderResolutionLink = this.renderResolutionLink.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closePopups = this.closePopups.bind(this);
        this.saveModalValues = this.saveModalValues.bind(this);
    }

    componentDidUpdate(prevProps) {
        let { statusList, id } = this.props;
        const firstLevelAppealAction = this.props.firstLevelAppealAction || {};
        const resolutionAppealAction = this.props.resolutionAppealAction || {};
        const prevFirstLevelAppealAction = prevProps.firstLevelAppealAction || {};
        const prevResolutionAppealAction = prevProps.resolutionAppealAction || {};
        
        if ((firstLevelAppealAction.key !== prevFirstLevelAppealAction.key) && (id === prevProps.id)) {
            const status = statusList.find(i => i.code === firstLevelAppealAction.toStateName);
            if (status) {
                this.checkAndSaveChanges({ status });
            }
        }
        
        if ((resolutionAppealAction.key !== prevResolutionAppealAction.key) && (id === prevProps.id)) {
            const status = statusList.find(i => i.code === firstLevelAppealAction.toStateName);
            if (status) {
                const resolution = status.resolution.length > 0 ? status.resolution.find(i => i.id === resolutionAppealAction.id) : null;
                this.checkAndSaveChanges({ status, resolution });
            }
        }
    }

    selectStatus(status) {
        return event => {
            event.stopPropagation();
            if (status.resolution.length) {
                this.setState({
                    selectedStatus: status,
                    statusPopupIsOpen: false,
                    resolutionPopupIsOpen: true
                });
            } else {
                this.setState({
                    statusPopupIsOpen: false,
                    resolutionPopupIsOpen: false
                });
                this.checkAndSaveChanges({ status });
            }
        };
    }

    selectStatusOnly(event) {
        event.stopPropagation();
        this.setState({
            statusPopupIsOpen: false,
            resolutionPopupIsOpen: false,
        });
        this.checkAndSaveChanges({ status: this.state.selectedStatus });
    }

    checkAndSaveChanges({ status, resolution, disableModal }) {
        const fieldList = [];
        
        if (['pause', 'wait'].includes(status.code)) {
            fieldList.push(ModalFields.TIMER_DATE, ModalFields.NEXT_STATUS);
        }

        if (status.isNeedComment && !disableModal) {
            fieldList.push(ModalFields.COMMENT);
        }
        
        if (fieldList.length > 0) {
            this.props.setModalStatusLIst(fieldList, this.props.id);
            this.setState({
                selectedResolution: resolution,
                selectedStatus: status
            });
        } else {
            this.props.setModalStatusLIst([], this.props.id);
        }
        
        this.saveChanges(status, resolution);
    }

    saveChanges(status, resolution) {
        const { id } = this.props;
        
        this.props.unlockAppealForm();
        this.props.change(id, 'status', status);
        this.props.change(id, 'resolution', resolution || null);

        this.saveModalValues();
    }

    saveModalValues(values = {}) {
        const { id } = this.props;
        
        const saveValue = field => {
            const value = values[field] || null;

            if (this.props[field] !== value) {
                this.props.change(id, field, value);
            }
        };

        Object.values(ModalFields).forEach(saveValue);
    }

    onModalSubmit(values) {
        const { selectedStatus, selectedResolution } = this.state;
        
        this.props.unlockAppealForm(this.props.id);
        this.props.change(this.props.id, 'status', selectedStatus);
        this.props.change(this.props.id, 'resolution', selectedResolution || null);

        this.saveModalValues(values);
        this.props.setModalStatusLIst([], this.props.id);
        this.props.onSubmitButtonClick();
    }

    selectResolution(resolution) {
        return (event) => {
            event.stopPropagation();
            this.setState({ resolutionPopupIsOpen: false });
            this.checkAndSaveChanges({ status: this.state.selectedStatus, resolution });
        };
    }

    closeModal() {
        this.props.setStatusModalVisibility(false, this.props.id);
        this.props.onSubmitButtonClick();
    }

    closePopups(event) {
        event.stopPropagation();
        this.setState({
            statusPopupIsOpen: false,
            resolutionPopupIsOpen: false
        });
    };

    openStatusPopup(event) {
        event.stopPropagation();
        if (!this.props.disabled) {
            this.setState({ statusPopupIsOpen: true, resolutionPopupIsOpen: false });
        }
    };

    renderStatusLink(status) {
        return (
            <div key={status.code} className={'popup-status-link-wrapper'} onClick={this.selectStatus(status)}>
                <div className={`popup-status-link ${status.code}`}>
                    {status.name}
                </div>
                {status.resolution.length > 0 && <i className='icon-arrow-right' />}
            </div>
        );
    }

    renderResolutionLink(resolution) {
        return (
            <div
                key={resolution.id}
                className='popup-link'
                onClick={this.selectResolution(resolution)}
            >
                {resolution.title}
            </div>
        );
    }

    render() {
        const { statusList, status, resolution, t, permission, modalStatusList, modalVisibility } = this.props;
        const { selectedStatus } = this.state;
        const statusCode = get(status, 'code', '');
        const statusName = get(status, 'name', '');
        const title = get(resolution, 'title');
        let permissionClass = '';
        if (permission === 0) {
            permissionClass = 'hidden';
        } else if (permission === 1) {
            permissionClass = 'disabled';
        }

        return (
            <Fragment>
                <span className={cx('m-r-10', permissionClass)}>{t('appeal.appealTypeTitle')}</span>
                <div className={cx(styles.statusSelectWrapper, permissionClass)} onClick={this.openStatusPopup}>
                      <span className={`sticker sticker-${statusCode}`}>
                        {statusName}
                      </span>
                    {
                        title &&
                        <Fragment>
                            <span className={styles.statusDivider}>|</span>
                            <span className={styles.resolution}>{title}</span>
                        </Fragment>
                    }

                    <i className='icon-down' />

                    {
                        this.state.statusPopupIsOpen && statusList &&
                        <Popup
                            place='status-popup'
                            onClickOutside={this.closePopups}
                        >
                            {statusList.map(this.renderStatusLink)}
                        </Popup>
                    }

                    {
                        this.state.resolutionPopupIsOpen &&
                        <Popup
                            onClickOutside={this.closePopups}
                            place='status-popup'
                        >
                            <div className={styles.resolutionBackLink} onClick={this.openStatusPopup}>
                                <i className='icon-arrow-left' />
                                <div key={'back'} className='popup-link'>
                                    {t('back')}
                                </div>
                            </div>
                            <div
                                key={selectedStatus.code}
                                className={`popup-status-link bold ${selectedStatus.code}`}
                                onClick={this.selectStatusOnly}
                            >
                                {selectedStatus.name}
                            </div>

                            {selectedStatus.resolution.map(this.renderResolutionLink)}
                        </Popup>
                    }
                    {
                        modalStatusList.length > 0 && modalVisibility &&
                        <StatusModal
                            onClose={this.closeModal}
                            nextStatusList={selectedStatus && selectedStatus.nextState}
                            saveChanges={this.onModalSubmit}
                            fieldList={modalStatusList}
                        />
                    }
                </div>
            </Fragment>
        );
  }
}

StatusSelect.propTypes = {
  statusList: PropTypes.array,
  status: PropTypes.object,
  unlockAppealForm: PropTypes.func,
  change: PropTypes.func,
  checkRestrictions: PropTypes.func
};

export default StatusSelect;
