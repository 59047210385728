import _ from 'lodash';
import store from '../store';

const CustomerOperations = 'CustomerOperations';
const action_createCustomer = 'action_createCustomer';
const action_editCustomer = 'action_editCustomer';
const view_customer = 'view_customer';
const view_contactPersons = 'view_contactPersons';
const view_contacts = 'view_contacts';
const view_addresses = 'view_addresses';
const action_manageAddresses = 'action_manageAddresses';
const action_manageContactPersons = 'action_manageContactPersons';

const SearchOperations = 'SearchOperations';
const search_contactPersons = 'search_contactPersons';
const search_customer = 'search_customer';

const AdminGrantOperations = 'AdminGrantOperations';
const view_permissions = 'view_permissions';
const action_manage_access = 'action_manage_access';

const UserOperations = 'UserOperations';
const action_clientPartViewKnowledgeBase = 'action_clientPartViewKnowledgeBase';
const action_clientPartEditKnowledgeBase = 'action_clientPartEditKnowledgeBase';

const CallOperations = 'CallOperations';
const record_listening = 'record_listening';
const action_workPbx = 'action_workPbx';

const FileOperations = 'FileOperations';
const generate_file = 'generate_file';

const AppealOperations = 'AppealOperations';
const action_createAppeal = 'action_createAppeal';
const action_createAppealRepeat = 'action_createAppealRepeat';
const action_createAppealDuplet = 'action_createAppealDuplet';

const DashboardOperations = 'DashboardOperations';
const dashboard_workWithDashboard = 'dashboard_workWithDashboard';

const FilterOperations = 'FilterOperations';
const create_public_filter = 'create_public_filter';

const InteractionOperations = 'InteractionOperations';
const action_AddAssessments = 'action_AddAssessments';
const action_unlinkContactPersons = 'action_unlinkContactPersons';

const OrderOperations = 'OrderOperations';
const view_order = 'view_order';
const view_Item = 'view_Item';
const action_createOrder = 'action_createOrder';

const permissions = {
    [AdminGrantOperations]: {
        [view_permissions]: `${AdminGrantOperations}.${view_permissions}`,
        [action_manage_access]: `${AdminGrantOperations}.${action_manage_access}`,
    },
    [UserOperations]: {
        [action_clientPartViewKnowledgeBase]: `${UserOperations}.${action_clientPartViewKnowledgeBase}`,
        [action_clientPartEditKnowledgeBase]: `${UserOperations}.${action_clientPartEditKnowledgeBase}`,
    },
    [CallOperations]: {
        [record_listening]: `${CallOperations}.${record_listening}`,
        [action_workPbx]: `${CallOperations}.${action_workPbx}`,
    },
    [CustomerOperations]: {
        [action_createCustomer]: `${CustomerOperations}.${action_createCustomer}`,
        [action_editCustomer]: `${CustomerOperations}.${action_editCustomer}`,
        [view_customer]: `${CustomerOperations}.${view_customer}`,
        [view_contactPersons]: `${CustomerOperations}.${view_contactPersons}`,
        [view_contacts]: `${CustomerOperations}.${view_contacts}`,
        [action_manageContactPersons]: `${CustomerOperations}.${action_manageContactPersons}`,
        [view_addresses]: `${CustomerOperations}.${view_addresses}`,
        [action_manageAddresses]: `${CustomerOperations}.${action_manageAddresses}`,
        [action_unlinkContactPersons]: `${CustomerOperations}.${action_unlinkContactPersons}`,
        [view_Item]: `${CustomerOperations}.${view_Item}`,
    },
    [SearchOperations]: {
        [search_contactPersons]: `${SearchOperations}.${search_contactPersons}`,
        [search_customer]: `${SearchOperations}.${search_customer}`,
    },
    [FileOperations]: {
        [generate_file]: `${FileOperations}.${generate_file}`,
    },
    [AppealOperations]: {
        [action_createAppeal]: `${AppealOperations}.${action_createAppeal}`,
        [action_createAppealRepeat]: `${AppealOperations}.${action_createAppealRepeat}`,
        [action_createAppealDuplet]: `${AppealOperations}.${action_createAppealDuplet}`,
    },
    [DashboardOperations]: {
        [dashboard_workWithDashboard]: `${DashboardOperations}.${dashboard_workWithDashboard}`,
    },
    [FilterOperations]: {
        [create_public_filter]: `${FilterOperations}.${create_public_filter}`,
    },
    [InteractionOperations]: {
        [action_AddAssessments]: `${InteractionOperations}.${action_AddAssessments}`,
    },
    [OrderOperations]: {
        [view_order]: `${OrderOperations}.${view_order}`,
        [action_createOrder]: `${OrderOperations}.${action_createOrder}`
    }
};

export function checkPermissions (permission) {
    return store.getState().user.operations.includes(permission);
}

export default permissions;
