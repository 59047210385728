import React from 'react';
import ComboBox from 'components/Common/ComboBox';
import { Field } from 'redux-form';
import { translate } from 'react-i18next';
import InputField from 'components/Common/InputField';
import styles from 'styles/modules/modal.module.scss';
import PropTypes from 'prop-types';

@translate()
class ManualStreetField extends React.Component {
    
    constructor (props) {
        super(props);
        
        this.streetTypeOptions = this.getStreetTypeOptions();

		this.maxLength = this.maxLength.bind(this);
		this.onClose = this.onClose.bind(this);
		this.getStreetTypeOptions = this.getStreetTypeOptions.bind(this);

    }
    
    componentWillUnmount () {
        this.props.change('streetType', '');
        this.props.change('streetName', '');
    }
    
    getStreetTypeOptions () {
        const streetTypes = this.props.t('dictionary:streetType', {returnObjects: true});
        return Object.keys(streetTypes).map(key => ({value: key, label: streetTypes[key]}));
    }
    
    onClose (event) {
        event.preventDefault();
        this.props.onClose();
    }

	maxLength (value, old, all, type, max = 255)  {
		return value.substr(0, max);
	}

    render () {
        const {required} = this.props;
        return (
            <div className={styles.manualStreetContainer}>
                <Field
                    name={'streetType'}
                    component={ComboBox}
                    options={this.streetTypeOptions}
                    label={'Тип'}
                    required={required}
                />
                <Field
                    name={'streetName'}
                    label={'Назва'}
                    component={InputField}
                    required={required}
                    onClose={this.onClose}
					normalize={this.maxLength}
                />
            </div>
        );
    }
}

ManualStreetField.propTypes = {
    onClose: PropTypes.func,
    change: PropTypes.func
};

export default ManualStreetField;
