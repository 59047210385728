import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector, change, destroy } from 'redux-form';

import AppealMainContent from './AppealMainContent';
import Customer from './Customer';
import Loader from 'components/Loader';
import BlockedAppeal from './BlockedAppeal';
import styles from 'styles/modules/appealOperatePage.module.scss';
import {
    clearCustomerState,
    getCustomer,
    getContactPerson,
    clearContactPersonState,
	clearEntireCustomerState,
	clearAllCustomers,
} from 'actions/customer';
import { removeTab, setTabName } from 'actions/tabs';
import { setCurrentTab } from 'actions';
import {
    getAppealTypesList,
    getAppealStatusList,
    getAppealPriorityList,
    getAppeal,
    getDynamicFormParams,
    getAppealFeedback,
    lockAppealForm,
    appealFetching,
    getAppealActions,
    getAppealDestinations,
	createNewAppeal,
	setSaveAndCreateNew,
	clearAppealState,
	clearAllAppeals,
} from 'actions/appeal';
import { updateCreatedAppealName } from 'actions/call';
import { extractAppealFromState, extractCustomerFromState, closeAppeal, searchTree } from 'helpers';
import { getTabs } from 'reducers/tabs';


function Appeal (props) {
    const { appeal, appealIsBlocked, appealTypes, priorityList, id, fetching, match, history } = props;
    
    useEffect(() => {
        if (!fetching) {
            requestInitialData();
        }
    }, [id]);
    
    async function requestInitialData (destroyForm) {
        if (destroyForm) {
            props.destroyForm(`appeal-form-${id}`);
        }
        
        const appeal = await props.getAppeal({ id });
        
        if (!appeal) {
            return;
        }
        
        let appealDataSet = [
            props.getAppealStatusList(appeal.id, appeal.typeId),
            props.getAppealDestinations(appeal.id, appeal.typeCode),
            props.getDynamicFormParams({
                ctxObjectId: appeal.id,
                objectType: 'appeal',
                objectTypeId: appeal.typeId,
                objectId: appeal.id,
                objectTypeCode: appeal.typeCode,
                openMode: 'create',
            }),
            props.getAppealActions(appeal, [appeal.destinationId], true),
        ];
        
        if (!appealTypes) {
            appealDataSet = [...appealDataSet, props.getAppealTypesList(id)];
        }
        
        if (!priorityList) {
            appealDataSet = [...appealDataSet, props.getAppealPriorityList(id)];
        }
        
        if (appeal.customerId > 0) {
            appealDataSet = [
                ...appealDataSet,
                props.getCustomer({ customerId: appeal.customerId }, appeal.customerId),
                props.getAppealFeedback({ appealId: appeal.id, customerId: appeal.customerId }),
            ];
            
            if (appeal.contactPersonId > 0) {
                appealDataSet = [
                    ...appealDataSet,
                    props.getContactPerson({ id: appeal.contactPersonId }, appeal.customerId),
                ];
            }
        }
        
        const dataSetReady = await Promise.all(appealDataSet);
        
        if (dataSetReady) {

            props.setCurrentTab({
                ...match,
                displayedName: appeal.regnum,
                name: () => `appeals_${id}`,
                tabType: `appeals_${id}`,
            });
            props.appealFetching(id);
        }
    }
    
    async function postSave ({ saveAndCreateNew }) {
        const saveAndClose = localStorage.getItem('saveAndClose') === 'true';
        const success = await props.getAppeal({ id: appeal.id }, true);
        
        if (saveAndClose) {
			props.removeTab(
				{ tabType: props.currentTab.type, },
				{ history, tabs: props.tabs, current: props.currentTab },
			);
			closeAppeal({ ...props, id: appeal.id, lastTab: props.tabs.length === 1 });
		} else {
			await Promise.all([
				props.change(appeal.id, 'destination', [success.destinationId]),
				props.change(appeal.id, 'resolveDate', success.resolveDate.resolveDate),
				props.getAppealActions(success, [success.destinationId], true),
				props.getAppealStatusList(success.id, success.typeId),
				props.setTabName(`appeals_${success.id}`, success.regnum),
				props.updateCreatedAppealName({ id: success.id, name: success.regnum }),
			]);
		}
        
        if (saveAndCreateNew) {
        	const appealRequestData = {};
   
        	if (success.customerId) {
				appealRequestData.customerId = success.customerId;
			}
			
			if (success.contactPersonId) {
				appealRequestData.contactPersonId = success.contactPersonId;
			}
   
			const newAppeal = await props.createNewAppeal({ appealRequestData });
			await props.history.push(`/appeals/${newAppeal.id}/knowledge_base`)
		}
		
    }
    
    async function resetAppealType (appealType, previousType) {
    	let newSelectedDestinations = [],
            appealRoute = null
    	
    	const [newDestinations] = await Promise.all([
			props.getAppealDestinations(appeal.id, appealType.code),
			props.getDynamicFormParams({
				ctxObjectId: appeal.id,
				objectType: 'appeal',
				objectTypeId: appealType.id,
				objectId: appeal.id,
				objectTypeCode: appealType.code,
				prevObjectTypeId: previousType.id,
				openMode: 'create',
			}),
		]);
	
		await props.destination.forEach((i) => {
			const isFounded = searchTree(newDestinations, 'result', (item) => {
				return parseInt(item.object.id) === parseInt(i) || item.object.isDefault === true;
			});
			
			if (isFounded) {
                appealRoute = isFounded.object.route;
				newSelectedDestinations = [...newSelectedDestinations, isFounded.object.id];
			}
		});
        props.change(appeal.id, 'route', appealRoute);
		await props.change(appeal.id, 'destination', newSelectedDestinations.length ? newSelectedDestinations : ['']);

    }
    
    function resetActions ({ remove, change, position }) {
        const requestDestination = [...props.destination];
        
        if (requestDestination[remove]) {
            props.getAppealActions(appeal, requestDestination.splice(remove, 1));
        }
        
        if (change) {
            requestDestination[position] = change;
            props.getAppealActions(appeal, requestDestination);
        }
    }
    
    if (appealIsBlocked) {
        return <BlockedAppeal />;
    }
    
    if (!props.fetching || !appeal) {
        return <div className={styles.wrapper}><Loader withContainer /></div>;
    }
    
    return (
        <div className={styles.wrapper}>
            <Customer
                appeal={appeal}
                customer={props.customer}
                isCustomerLoading={props.isCustomerLoading}
            />
            <AppealMainContent
                resetAppealType={resetAppealType}
                requestInitialData={requestInitialData}
                resetActions={resetActions}
                postSave={postSave}
            />
        </div>
    );
}

function mapStateToProps (state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const customer = extractCustomerFromState(state, _.get(appeal, 'currentAppeal.customerId'));
    
    return {
        id,
        appeal: appeal.currentAppeal,
        appeals: state.appeal.appeals,
		saveAndCreateNew: appeal.saveAndCreateNew,
        fetching: appeal.fetching,
        appealLoading: appeal.appealLoading,
        appealIsBlocked: appeal.appealIsBlocked,
        appealTypes: state.appeal.appealTypes,
        priorityList: state.appeal.priorityList,
		
        destination: formValueSelector(`appeal-form-${id}`)(state, 'destination'),
        route: formValueSelector(`appeal-form-${id}`)(state, 'route.key'),

		forms: state.form,
	
		tabs: getTabs(state),
		currentTab: state.tabs.current,
        
        customer: customer.currentCustomer,
        isCustomerLoading: customer.customerLoading,
    };
}

const mapDispatchToProps = {
    getAppeal,
    getAppealTypesList,
    lockAppealForm,
    getAppealStatusList,
    getAppealPriorityList,
    appealFetching,
    getAppealFeedback,
    getDynamicFormParams,
    getAppealDestinations,
    getAppealActions,
	createNewAppeal,
	setSaveAndCreateNew,
	clearAppealState,
	clearAllAppeals,
    
    getCustomer,
    clearCustomerState,
    getContactPerson,
    clearContactPersonState,
	clearEntireCustomerState,
	clearAllCustomers,
    
    setTabName,
    setCurrentTab,
	removeTab,
	
	updateCreatedAppealName,
    
    change: (id, field, value) => change(`appeal-form-${id}`, field, value),
    destroyForm: (form) => destroy(form),
};

export default connect(mapStateToProps, mapDispatchToProps)(Appeal);
