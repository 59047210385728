import {
	SET_CALL_RECEPTION_STATUS, OPEN_CALL_CONTROL_WIDGET, CLOSE_CALL_CONTROL_WIDGET,
	SET_POSSIBLE_CUSTOMERS, CLEAR_POSSIBLE_CUSTOMERS, SET_LAST_SSE_MESSAGE,
	CLEAR_CALL_TEMP_STATE, ADD_CREATED_APPEAL, SET_AUTHORIZATION_SUCCESS, ADD_NEW_INTERACTION,
	CLOSE_INTERACTION, CLEAR_SESSION, SET_TIMER, SET_OPERATOR_ACTIONS, SET_CALL_STATION,
	OUT_OF_SERVICE, CLEAR_ERROR_MSG, SET_OUT_PHONE_ERROR_MSG, SET_IN_PHONE_ERROR_MSG,
	UPDATE_CREATED_APPEAL_NAME,
} from 'constants/actions';
import { CallStates, HoldStatuses, SseEvents } from 'constants/index';
import pick from 'lodash/pick';

/* #######   Initial state   ####### */

const initialState = {
	callReceptionStatus: 'empty',
	operatorActions: [],
	phoneNumber: '',
	incomePhoneNumber: '',
	
	isWidgetOpen: false,
	customers: [],
	lastMessage: {},
	createdAppeals: [],
	callId: '',
	callInteractionId: '',
	
	currentCallId: '',
	callState: CallStates.DEFAULT,
	isTransferActive: false,
	holdStatus: HoldStatuses.OFF,
	
	isAuthorized: false,
	timer: '00:00',
	callStation: null,
	
	outPhoneErrorMSg: '',
	inPhoneErrorMSg: '',
};

/* #######   Helper functions   ####### */

const setCallReceptionStatus = (state, payload) => {
	let changes = { callReceptionStatus: payload.callReceptionStatus, isWidgetOpen: false };
	if (payload.callReceptionStatus !== 'returntowork') {
		changes = { ...changes, phoneNumber: '', isAuthorized: false };
	}
	return { ...state, ...changes };
};

const getHoldStatus = (state, payload) => {
	switch (payload.message.event) {
	case SseEvents.TALKING:
		return HoldStatuses.OFF;
	
	case SseEvents.HOLD:
		return HoldStatuses.ON;
	
	default:
		return state.holdStatus;
	}
};

const getTransferState = (state, payload) => {
	switch (payload.message.event) {
	case SseEvents.HANGUP:
	case SseEvents.RINGING:
		return false;
	
	case SseEvents.ANSWER:
		return true;
	
	default:
		return state.isTransferActive;
	}
};

const getCallState = (state, payload) => {
	switch (payload.message.event) {
	case SseEvents.RINGING:
		return CallStates.RINGING;
	
	case SseEvents.ANSWER:
		return CallStates.TALKING;
	
	case SseEvents.WORK_READY:
		return CallStates.WAITING_WITH_COUNTER;
	
	case SseEvents.WORK:
	case SseEvents.HOLD:
		return CallStates.WAITING;
	
	case SseEvents.HANGUP:
		return CallStates.DEFAULT;
	
	case SseEvents.ALIVE:
		return CallStates.ALIVE;
	
	default:
		return state.callState;
	}
};

const getCurrentCallId = (state, payload) => {
	const callState = getCallState(state, payload);
	
	if (callState !== CallStates.ALIVE) return state.currentCallId;
	
	if (callState !== CallStates.TALKING) return '';
	
	if (payload.message.event.startsWith('user_state')) return state.currentCallId;
	return payload.message.uniqueId || '';
};

const setLastMessage = (state, payload) => {
	const callState = getCallState(state, payload);
	let incomePhoneNumber = payload.message.phone ? payload.message.phone : state.incomePhoneNumber;
	
	if ([CallStates.DEFAULT, CallStates.WAITING_WITH_COUNTER].includes(callState)) {
		incomePhoneNumber = '';
	}
	
	return {
		...state,
		incomePhoneNumber,
		callId: Number(payload.message.uniqueId) ? Number(payload.message.uniqueId) : state.callId,
		lastMessage: payload.message,
		callState: getCallState(state, payload) === CallStates.ALIVE ? state.callState : getCallState(state, payload),
		holdStatus: getHoldStatus(state, payload),
		isTransferActive: getTransferState(state, payload),
		currentCallId: getCurrentCallId(state, payload)
	};
};

// const addNewInteraction = (state, payload) => {
//     if (state.interactionsMap[payload.uniqueId] !== payload.interactionId) {
//         return {
//             ...state,
//             interactionsMap: {
//                 ...state.interactionsMap,
//                 [payload.uniqueId]: payload.interactionId,
//             }
//         };
//     }
//
//     return state;
// };
//
// const removeInteraction = (state, payload) => {
//     if (state.interactionsMap.hasOwnProperty(payload.uniqueId)) {
//         const updatedInteractionsMap = { ...state.interactionsMap };
//         delete updatedInteractionsMap[payload.uniqueId];
//
//         return { ...state, interactionsMap: updatedInteractionsMap };
//     }
//
//     return state;
// };

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case SET_CALL_RECEPTION_STATUS:
		return setCallReceptionStatus(state, payload);
	case OPEN_CALL_CONTROL_WIDGET:
		return { ...state, isWidgetOpen: true };
	case CLOSE_CALL_CONTROL_WIDGET:
		return { ...state, isWidgetOpen: false };
	case SET_POSSIBLE_CUSTOMERS:
		return { ...state, customers: payload.customers };
	case CLEAR_POSSIBLE_CUSTOMERS:
		return { ...state, customers: [] };
	case SET_LAST_SSE_MESSAGE:
		return setLastMessage(state, payload);
	case ADD_CREATED_APPEAL:
		return { ...state, createdAppeals: [...state.createdAppeals, payload.appeal] };
	case UPDATE_CREATED_APPEAL_NAME:
		const appealIndex = state.createdAppeals.findIndex((apl) => apl.id === payload.appeal.id);
		const newCreatedAppeals = [...state.createdAppeals];
		
		if (appealIndex >= 0) {
			newCreatedAppeals[appealIndex] = payload.appeal;
		}
		
		return { ...state, createdAppeals: newCreatedAppeals };
	case SET_AUTHORIZATION_SUCCESS:
		return { ...state, isAuthorized: true, phoneNumber: payload.phoneNumber };
	case ADD_NEW_INTERACTION:
		return { ...state, callInteractionId: payload };
	case CLOSE_INTERACTION:
		return { ...state, callInteractionId: '' };
	
	case CLEAR_CALL_TEMP_STATE:
		return {
			...state,
			isWidgetOpen: false,
			customers: [],
			createdAppeals: [],
		};
	
	case SET_TIMER:
		return {
			...state, timer: payload,
		};
	
	case CLEAR_SESSION:
		return {
			...state,
			...pick(initialState, [
				'isWidgetOpen',
				'customers',
				'createdAppeals',
				'interactionsMap',
				'currentCallId',
				'callState',
				'isTransferActive',
				'holdStatus',
				'isAuthorized',
			]),
		};
	case SET_OPERATOR_ACTIONS:
		const selected = payload.find(i => i.isSet);
		return {
			...state,
			operatorActions: payload.map(i => (
				{ label: i.stateName, value: i.stateCode, isSet: i.isSet })),
			callReceptionStatus: selected ? selected.stateCode : undefined,
		};
	
	case SET_CALL_STATION:
		return {
			...state,
			callStation: payload,
		};
	
	case OUT_OF_SERVICE:
		return {
			...state,
			callState: CallStates.DEFAULT,
		};
	case SET_OUT_PHONE_ERROR_MSG:
		return {
			...state,
			outPhoneErrorMSg: payload,
		};
	case SET_IN_PHONE_ERROR_MSG:
		return {
			...state,
			inPhoneErrorMSg: payload,
		};
	case CLEAR_ERROR_MSG:
		return {
			...state,
			outPhoneErrorMSg: '',
			inPhoneErrorMSg: '',
		};
	default:
		return state;
	}
};
