import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getFirstLetters } from 'helpers';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';
import cx from 'classnames';

moment.locale('uk');

@translate()
class CommentItem extends React.Component {
    
    state = {
        opened: false,
        editIconIsVisible: false
    };
    
    toggleOpen = () => this.setState(prevState => ({opened: !prevState.opened}));
    
    displayEditIcon = () => this.setState({editIconIsVisible: true});
    
    hideEditIcon = () => this.setState({editIconIsVisible: false});
    
    editComment = () => this.props.handleEditComment(this.props.id);
    
    renderAvatar = name => {
        return this.props.image
            ? <img src={this.props.image} alt=''/>
            : getFirstLetters(name);
    };
    
    renderAttachment = ({name, id}) => (
        <div className={styles.fileLinkWrapper} key={id}>
            <a href={`../mw/file?fileId=${id}`} download className={styles.fileLink}>{name}</a>
            <i className={cx('icon-save', styles.saveIcon)}/>
        </div>
    );
    
    render () {
        const {author, date, text, attachments, t, id} = this.props;
        const {opened, editIconIsVisible} = this.state;
        
        const name = author && author.name;
        const formattedDate = moment(date).format('LLL');
        const hasAttachments = attachments && attachments.length;
        const editIconStyle = cx('icon-edit', {[styles.hidden]: !editIconIsVisible});
        const attachIconStyle = cx('icon-attach', {[styles.hidden]: !hasAttachments});
        
        return (
            <div className={styles.comment} onMouseOver={this.displayEditIcon} onMouseLeave={this.hideEditIcon}>
                <div className={styles.commentHeader}>
                    <div className={styles.commentTitle}>
                        <div className={styles.avatarWrapper}>
                            {this.renderAvatar(name)}
                        </div>
                        <div className={styles.authorDateWrapper}>
                            <div className={styles.commentAuthor}>{name}</div>
                            <div className={styles.commentDate}>
                                {formattedDate}
                            </div>
                            <div className={styles.commentButtons}>
                                <i className={attachIconStyle} onClick={this.toggleOpen}/>
                                {/*<i className={editIconStyle} onClick={this.editComment}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.mailText}>{text}</div>
                
                {opened && (
                    <div>
                        <hr className={styles.fileSeparator}/>
                        <div className={styles.filesWrapper}>
                            <div className={styles.filesList}>
                                {hasAttachments && attachments.map(this.renderAttachment)}
                            </div>
                            <div className={styles.filesButtons}>
                                <a href={`../mw/appeal/file/getFiles?typeCode=COMMENT&id=${id}`}
                                   className={styles.filesButton}>{t('comments.uploadAll')}</a>
                            </div>
                        </div>
                    </div>
                )}
            
            </div>
        );
    }
}

CommentItem.propTypes = {
    image: PropTypes.string,
    author: PropTypes.object,
    date: PropTypes.number,
    text: PropTypes.string,
    attachments: PropTypes.array
};

export default CommentItem;
