import React from 'react';
import { connect } from 'react-redux';
import { closeCustomerAddressModal } from 'actions/customer';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';
import CustomerAddressForm from './CustomerAddressForm';
import { KeyCodes } from 'constants/index';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
    isAddressModalOpen: state.customer.isAddressModalOpen
});

const mapDispatchToProps = dispatch => ({
    closeCustomerAddressModal: () => dispatch(closeCustomerAddressModal()),
});

@connect(mapStateToProps, mapDispatchToProps)
class CustomerAddressModal extends React.Component {
    
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    
    onClose = () => this.props.closeCustomerAddressModal();
    
    handleKeyPress = event => {
        if (event.keyCode === KeyCodes.ESCAPE) {
            this.onClose();
        }
    };
    
    render () {
        const { isAddressModalOpen, id } = this.props;

        return (
            <CSSTransition
                in={isAddressModalOpen}
                classNames='fade'
                appear={true}
                enter={true}
                exit={true}
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <ModalPortal
                    onClose={this.onClose}
                    className='create-customer-modal'
                >
                    <CustomerAddressForm onClose={this.onClose} id={id} />
                </ModalPortal>
            </CSSTransition>
        );
    }
}

CustomerAddressModal.propTypes = {
    isAddressModalOpen: PropTypes.bool,
    closeCustomerAddressModal: PropTypes.func
};

export default CustomerAddressModal;
