import moment from 'moment';
import { ModalFields } from './constants';

function validateDate (value) {
	const isInvalidMoment = moment.isMoment(value) && !value.isValid();
	if (!value || isInvalidMoment) return 'Обов\'язкове поле';
	
	const duration = moment.duration(value - Date.now());
	
	if (duration.asMinutes() < 5) {
		return 'Запланований час зміни статусу повинен бути пізніше поточного';
	}
	
	return null;
}

function validateComment (value = '') {
	return !value.trim() ? 'Обов\'язкове поле' : null;
}

function validateStatus (value) {
	return !value ? 'Обов\'язкове поле' : null;
}

const validations = {
	[ModalFields.TIMER_DATE]: validateDate,
	[ModalFields.NEXT_STATUS]: validateStatus,
	[ModalFields.COMMENT]: validateComment
};

function validate (values, props) {
	const errors = {};
	
	if (!props.anyTouched) {
		return errors;
	}
	
	props.fieldList.forEach(field => {
		const error = validations[field](values[field]);
		if (error) {
			errors[field] = error;
		}
	});
	
	return errors;
}

export default validate;
