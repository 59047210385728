import React from 'react';
import { withRouter } from 'react-router-dom';

import NotDefinedClient from './NotDefinedClient';
import CustomerInfo from './CustomerInfo';
import Loader from 'components/Loader';
import styles from 'styles/modules/appealOperatePage.module.scss';


export default function Customer ({ appeal, customer, isCustomerLoading }) {
    
    if (!customer && !isCustomerLoading) {
        return <NotDefinedClient customerRemoveRestriction={appeal.restriction.remove_customer} />
    }
    
    if (!customer && isCustomerLoading) {
        return (
            <div className={styles.parametersWrapper}>
                <div className={styles.customerParameters}>
                    <Loader withContainer />
                </div>
            </div>
        );
    }
    
    return <CustomerInfo />
}

