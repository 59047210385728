import pick from 'lodash/pick'
import content from 'reducers/content'
import customer from 'reducers/customer'
import appeal from 'reducers/appeal'

export const loadState = storeKey => {
    try {
        const serializedState = sessionStorage.getItem(storeKey)
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (error) {
        return undefined
    }
}

export const saveState = (state, storeKey) => {
    try {
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem(storeKey, serializedState)
    } catch (error) {
        console.error(error)
    }
}

export const removeState = storeKey => {
    try {
        sessionStorage.removeItem(storeKey)
    } catch (error) {
        console.error(error)
    }
}

export function saveCurrentTabChanges (state) {
    const currentTab = state.tabs.current
    const isAuthenticated = Boolean(state.user.id)
    
    if (isAuthenticated && currentTab && !currentTab.isSuperTab && state.tabs.tabCachingEnabled) {
        
        const stateToSave = getStateToSave(state)
        saveState(stateToSave, currentTab.type)
    }
}

function getStateToSave (state) {
    return pick(state, [
        // 'content',
        // 'form',
        // 'customer',
        // 'appeal',
        // 'client',
        // 'session',
    ])
}

