import {
    DASHBOARD_FILTERS_REQUEST,
    DASHBOARD_FILTERS_REQUEST_SUCCESS,
    DASHBOARD_FILTERS_REQUEST_FAILURE,
    DASHBOARD_FILTER_OBJECT_UPDATE,
    DASHBOARD_APPEAL_TYPES_REQUEST,
    DASHBOARD_APPEAL_TYPES_REQUEST_FAILURE,
    DASHBOARD_APPEAL_TYPES_REQUEST_SUCCESS,
} from 'constants/actions';

/*#######   Initial state   #######*/

const initialState = {
    currentTemplate: null,
    filterList: [],
    isFilterListLoading: false,
    filterObject: [{
        id: '283',
        condition: 'EQU',
        value: '11',
    }],
    appealTypes: [],
    areAppealTypesLoading: false,
};

/*#######   Helper functions   #######*/



/*#######   Reducer   #######*/

export default (state = initialState, { type, payload }) => {

    switch(type){

        /********  Dashboard filters request  ********/

        case DASHBOARD_FILTERS_REQUEST:
            return {...state, isFilterListLoading: true};

        case DASHBOARD_FILTERS_REQUEST_SUCCESS:
            return {
                ...state,
                isFilterListLoading: false,
                currentTemplate: payload.currentTemplate,
                filterList: payload.filterList,
            };

        case DASHBOARD_FILTERS_REQUEST_FAILURE:
            return {
                ...state,
                isFilterListLoading: false,
                currentTemplate: null,
                filterList: null,
            };

        /********  Dashboard filter object update  ********/

        case DASHBOARD_FILTER_OBJECT_UPDATE:
            return {...state, filterObject: payload.filterObject};

        /********  Appeal types request  ********/

        case DASHBOARD_APPEAL_TYPES_REQUEST:
            return {...state, areAppealTypesLoading: true};

        case DASHBOARD_APPEAL_TYPES_REQUEST_SUCCESS:
            return {...state, areAppealTypesLoading: false, appealTypes: payload.appealTypes};

        case DASHBOARD_APPEAL_TYPES_REQUEST_FAILURE:
            return {...state, areAppealTypesLoading: false, appealTypes: []};

        default: return state;
    }
}

export const dashboardSelectors = {
    getCurrentTemplate: state => state.dashboard.currentTemplate,
    getFilterList: state => state.dashboard.filterList,
    getFilterListLoadingStatus: state => state.dashboard.isFilterListLoading,
    getFilterObject: state => state.dashboard.filterObject,
    getAppealTypes: state => state.dashboard.appealTypes,
};
