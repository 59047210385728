import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { createNewContactPerson } from 'actions/customer';
import { createNewContactPersonConfig } from 'constants/index';
import styles from 'styles/modules/createNewCustomer.module.scss';
import { capitalize, fill, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { capitalizeProps, trimProps, } from 'util/form';
import validate from './validate';
import moment from 'moment';
import { translate } from 'react-i18next';
import { reduxFormWrapper } from 'helpers';

const initialBody = {
	lastName: '',
	firstName: '',
	patronymic: '',
	lang_code: 'NA',
	validFrom: moment(),
	contactsType: [],
	contactsValue: [],
	issues: ['undefined'],
	personId: '',
	linkId: '',
	description: '',
	contactsId: [],
	customerId: ''
};

const setLanguage = ({ data, values }) => {
	if ('lang_code' in values) {
		data.lang_code = values.lang_code.value;
	}
};

const setProps = ({ data, values, props }) => {
	props.forEach(prop => {
		if (prop in values) {
			data[prop] = values[prop];
		}
	});
};

const setContacts = ({ data, values, props }) => {
	data.contactsType = [];
	data.contactsValue = [];
	props.forEach(prop => {
		if (prop in values) {
			data.contactsType.push(prop);
			data.contactsValue.push(values[prop]);
		}
	});
	data.contactsType.push('undefined');
	data.contactsId = fill(Array(data.contactsType.length), '');
};

const mapStateToProps = state => ({
	formContext: state.ui.newContactPersonModalContext
});

const mapDispatchToProps = { createNewContactPerson };

@translate()
@withRouter
@reduxFormWrapper({ form: 'create-new-contact-person', destroyOnUnmount: false, validate, initialValues: initialBody })
@connect(mapStateToProps, mapDispatchToProps)
export default class CreateNewContactPersonForm extends React.Component {
	constructor (props) {
		super(props);
		
		this.languages = this.convertDictionaryObject(props.t('dictionary:languages', { returnObjects: true }));
		this.issues = this.convertDictionaryObject(props.t('dictionary:contactIssue', { returnObjects: true }));
		
		this.wait = false;
	}
	
	convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
		value: prop,
		label: value
	}));
	
	onSubmit = values => {
		if (this.wait) return;
		
		this.wait = true;
		
		const data = { ...initialBody, customerId: this.props.formContext.customerId };
		setProps({ data, values, props: ['lastName', 'firstName', 'patronymic', 'issues', 'validFrom'] });
		setLanguage({ data, values });
		setContacts({ data, values, props: ['workPhone', 'email'] });
		trimProps(data);
		capitalizeProps(data, ['lastName', 'firstName', 'patronymic']);
		
		const params = {
			data,
			path: 'save',
			jsonType: true
		};
		
		this.props.createNewContactPerson(params)
			.then(contactPersonId => {
				this.wait = false;
				
				if (contactPersonId) {
					const onSuccess = this.props.formContext.onSuccess;
					onSuccess && onSuccess(this.props.formContext.customerId);
				}
			});
	};
	
	onIssuesChange = (event, newValue, previousValue, name) => {
		if (Array.isArray(newValue) && newValue.length === 0) {
			setTimeout(() => this.props.change('issues', ['undefined']), 0);
		}
		
		if (Array.isArray(newValue) && newValue.length > 1 && newValue.includes('undefined')) {
			setTimeout(() => this.props.change('issues', newValue.filter(value => value !== 'undefined')), 0);
		}
	};
	
	getFieldPropsWithOptions = fieldProps => {
		switch (fieldProps.name) {
		case 'lang_code':
			return { ...fieldProps, options: this.languages };
		case 'issues':
			return { ...fieldProps, options: this.issues.slice(1), onChange: this.onIssuesChange };
		
		default:
			return fieldProps;
		}
	};
	
	// fieldProps = { name, label, component, required, maxLength, options, multi, defaultValue }
	renderInputField = fieldProps => {
		return (<Field key={fieldProps.name}{...this.getFieldPropsWithOptions(fieldProps)} />);
	};
	
	render () {
		const { handleSubmit } = this.props;
		return (
			<div className={styles.createCustomer}>
				<div className={styles.createCustomerHeader}>
					<div className={styles.createCustomerTitle}>Нова контактна особа</div>
				</div>
				<div className={styles.createCustomerForm}>
					<form onSubmit={handleSubmit(this.onSubmit)}>
						{createNewContactPersonConfig.map(this.renderInputField)}
						<button className='btn btn-primary'>
							<i className='icon icon-check' />
							Створити
						</button>
					</form>
				</div>
			</div>
		);
	}
}

CreateNewContactPersonForm.propTypes = {
	handleSubmit: PropTypes.func
};
