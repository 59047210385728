import {
	EMAILS_REQUEST,
	EMAILS_REQUEST_SUCCESS,
	EMAILS_REQUEST_FAILURE,
	SEND_EMAIL_REQUEST,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_FAILURE,
	TOGGLE_ALL_MAILS,
	UPLOAD_EMAIL_FILE_REQUEST,
	UPLOAD_EMAIL_FILE_SUCCESS,
	UPLOAD_EMAIL_FILE_FAILURE,
	DELETE_EMAIL_FILE_REQUEST,
	DELETE_EMAIL_FILE_SUCCESS,
	DELETE_EMAIL_FILE_FAILURE,
	UPLOAD_EMAIL_FILE_LIST_REQUEST,
	UPLOAD_EMAIL_FILE_LIST_SUCCESS,
	UPLOAD_EMAIL_FILE_LIST_FAILURE,
	CREATE_NEW_EMAIL_REQUEST,
	CREATE_NEW_EMAIL_SUCCESS,
	CREATE_NEW_EMAIL_FAILURE,
	GET_TEMPLATE_EMAIL_REQUEST,
	GET_TEMPLATE_EMAIL_SUCCESS,
	GET_TEMPLATE_EMAIL_FAILURE,
	GET_TEMPLATE_LIST_REQUEST,
	GET_TEMPLATE_LIST_SUCCESS,
	GET_TEMPLATE_LIST_FAILURE,
	SET_FILES_LENGTH,
	SET_FORM_FORWARD,
	SET_FORM_REPLY,
	RESET_EMAIL_FORM,
	OPEN_EMAIL,
	OPEN_EMAIL_SUCCESS,
	EMAILS_SEARCH_QUERY_UPDATE,
	APPEAL_MAILING_RESET
} from 'constants/actions';
import baseService from '../services/BaseService';
import { showNotification } from './ui';
import { NotificationTypes } from 'constants/index';

export const openEmail = emailId => ({
	type: OPEN_EMAIL,
	payload: { id: emailId }
});

export const openEmailSuccess = () => ({
	type: OPEN_EMAIL_SUCCESS
});

/********  Emails request  ********/

const requestEmails = appealId => ({
	type: EMAILS_REQUEST,
	appealId
});

const requestEmailsSuccess = emails => ({
	type: EMAILS_REQUEST_SUCCESS,
	emails
});

const requestEmailsFailure = () => ({
	type: EMAILS_REQUEST_FAILURE
});

export const getEmails = (requestData, appealId) => dispatch => {
	dispatch(requestEmails(appealId));
	
	const params = { data: requestData };
	
	return baseService.get('mailing', params)
		.then(response => {
			if (response.success && response.result) {
				const sortedEmails = response.result.sort((a, b) => b.date - a.date);
				dispatch(requestEmailsSuccess(sortedEmails));
			} else {
				dispatch(requestEmailsFailure());
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestEmailsFailure());
		});
};

/********  Search query update  ********/

export const updateSearchQuery = newQuery => ({
	type: EMAILS_SEARCH_QUERY_UPDATE,
	query: newQuery
});

/********  Appeal mailing reset  ********/

export const resetAppealMailing = () => ({
	type: APPEAL_MAILING_RESET
});

/**************************/

const sendEmailRequest = () => ({
	type: SEND_EMAIL_REQUEST
});

const sendEmailSuccess = (data) => ({
	type: SEND_EMAIL_SUCCESS,
	payload: data
});

const sendEmailFailure = (data) => ({
	type: SEND_EMAIL_FAILURE
});

export const sendEmail = (params) => dispatch => {
	dispatch(sendEmailRequest());
	
	return baseService.post(params.key, params)
		.then(data => {
			dispatch(sendEmailSuccess(data.success));
			dispatch(showNotification({
				type: NotificationTypes.SUCCESS,
				options: {
					message: 'Лист видправлений успішно'
				}
			}));
			return true;
		})
		.catch(err => dispatch(sendEmailFailure(err)));
};

export const toggleAllMails = () => ({
	type: TOGGLE_ALL_MAILS
});

const getFilesListRequest = () => ({
	type: UPLOAD_EMAIL_FILE_LIST_REQUEST
});

const getFilesListSuccess = (data) => ({
	type: UPLOAD_EMAIL_FILE_LIST_SUCCESS,
	payload: data
});

const getFilesListFailure = (data) => ({
	type: UPLOAD_EMAIL_FILE_LIST_FAILURE
});

export const getFilesList = (appealId) => (dispatch) => {
	dispatch(getFilesListRequest());
	
	const params = {
		data: {
			interactionId: appealId,
			page: 1,
			start: 0,
			limit: 25
		}
	};
	
	baseService.get('get_files_list', params)
		.then(data => dispatch(getFilesListSuccess(data.result)))
		.catch(err => dispatch(getFilesListFailure(err)));
};

const uploadFileRequest = () => ({
	type: UPLOAD_EMAIL_FILE_REQUEST
});

const uploadFileSuccess = () => ({
	type: UPLOAD_EMAIL_FILE_SUCCESS
});

const uploadFileFailure = (data) => ({
	type: UPLOAD_EMAIL_FILE_FAILURE
});

export const uploadFile = (file, appealId) => (dispatch) => {
	dispatch(uploadFileRequest());
	
	const params = {
		data: {
			data: {
				interactionId: appealId,
				fileName: file.name
			},
			file
		},
		file: true
	};
	
	baseService.post('upload_file_email', params)
		.then(data => dispatch(uploadFileSuccess(data.result)))
		.catch(err => dispatch(uploadFileFailure(err)));
};

export function deleteFile (fileId, callback) {
	
	return (dispatch) => {
		
		const params = {
			data: {
				attachmentId: fileId,
			}
		};
		
		dispatch({ type: DELETE_EMAIL_FILE_REQUEST });
		
		baseService.post('delete_file_email', params)
			.then(data => {
				dispatch({ type: DELETE_EMAIL_FILE_SUCCESS });
				callback();
			})
			.catch(err => dispatch({ type: DELETE_EMAIL_FILE_FAILURE }));
	};
}

const createNewEmailRequest = () => ({
	type: CREATE_NEW_EMAIL_REQUEST
});

const createNewEmailSuccess = (data) => ({
	type: CREATE_NEW_EMAIL_SUCCESS,
	payload: data
});

const createNewEmailFailure = (data) => ({
	type: CREATE_NEW_EMAIL_FAILURE,
	payload: data
});

export const createNewEmail = parentInteractionId => dispatch => {
	dispatch(createNewEmailRequest());
	
	const params = { data: { parentInteractionId: parentInteractionId || 1 } };
	
	baseService.post('create_new_email', params)
		.then(data => dispatch(createNewEmailSuccess(data.result)))
		.catch(err => dispatch(createNewEmailFailure(err)));
};

const getTemplateEmailRequest = () => ({
	type: GET_TEMPLATE_EMAIL_REQUEST
});

function getTemplateEmailSuccess (data, body) {
	return {
		type: GET_TEMPLATE_EMAIL_SUCCESS,
		body,
		payload: data
	};
}

const getTemplateEmailFailure = (data) => ({
	type: GET_TEMPLATE_EMAIL_FAILURE,
	payload: data
});

export function getTemplateEmail (value, id, body) {
	return dispatch => {
		dispatch(getTemplateEmailRequest());
		
		const params = {
			data: {
				objectId: id,
				templateId: value
			}
		};
		
		baseService.post('get_template_email', params)
			.then(data => dispatch(getTemplateEmailSuccess(data.result, body)))
			.catch(err => dispatch(getTemplateEmailFailure(err)));
	};
}

const getTemplateListRequest = () => ({
	type: GET_TEMPLATE_LIST_REQUEST
});

const getTemplateListSuccess = (data) => ({
	type: GET_TEMPLATE_LIST_SUCCESS,
	payload: data
});

const getTemplateListFailure = (data) => ({
	type: GET_TEMPLATE_LIST_FAILURE,
	payload: data
});

export const getTemplateList = () => dispatch => {
	dispatch(getTemplateListRequest());
	
	const params = {
		data: {
			templateObjectType: 'INTERACTION_REQUEST_MANUAL_TEMPLATE'
		}
	};
	
	baseService.get('get_template_list', params)
		.then(data => dispatch(getTemplateListSuccess(data.result)))
		.catch(err => dispatch(getTemplateListFailure(err)));
};

export const setFilesLength = (length) => ({
	type: SET_FILES_LENGTH,
	payload: length
});

export const setFormForward = (id) => (dispatch, getState) => {
	const email = getState().emails.emails.find(item => item.id === id);
	
	dispatch({
		type: SET_FORM_FORWARD,
		payload: id,
		email
	});
};

export const setFormReply = (id) => (dispatch, getState) => {
	const email = getState().emails.emails.find(item => item.id === id);
	
	dispatch({
		type: SET_FORM_REPLY,
		payload: id,
		email
	});
};

export const resetEmailForm = () => ({
	type: RESET_EMAIL_FORM
});
