import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'redux-form';

import ComboBox from 'components/Common/ComboBox';
import TextArea from 'components/Common/TextArea';
import DatePicker from 'components/Common/DatePicker';
import { reduxFormWrapper } from 'helpers';
import { validate } from './validation';

import styles from 'styles/modules/appealForm.module.scss';
import { translate } from 'react-i18next';

const options = [
  { value: 'qqq', label: 'qqq' },
  { value: 'www', label: 'www' },
  { value: 'eee', label: 'eee' }
];

const prioriryOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 }
];
// TODO Needs change for real data

@translate()
@reduxFormWrapper({ form: 'ProcessingForm', validate })
export default class ProcessingForm extends React.Component {
  submitHandler = (values) => {
    this.props.submitForm({
      ...values,
      selectedItems: this.props.checkedItems
    });
  };

  renderComboBox = (value, params) => {
    const translations = this.props.t('appealForm', { returnObjects: true });
    return (
      <Field
        name={value}
        component={ComboBox}
        label={translations[value]}
        placeholder={translations[value]}
        {...params}
      />
    );
  };

  render() {
    const { invalid, isFormSubmitting, handleSubmit, t } = this.props;
    const translations = t('appealForm', { returnObjects: true });

    return (
      <form
        className={styles.processingFormWrapper}
        onSubmit={handleSubmit(this.submitHandler)}
      >

        {this.renderComboBox('priority', { options: prioriryOptions })}

        <div className={styles.separateField}>
          <Field
            name='decisionDate'
            component={DatePicker}
            label={translations.decisionDate}
          />
        </div>

        <div className={cx(styles.separateField, styles.commentTextarea)}>
          <Field
            name='comment'
            component={TextArea}
            label={translations.comment}
            placeholder={translations.text}
          />
        </div>

        <div className={styles.separateField}>
          {this.renderComboBox('appealType', { options: options })}
        </div>

        <div className={styles.separateField}>
          {this.renderComboBox('executor', { options: options })}
        </div>

        <button
          type='submit'
          className={cx(styles.processingSubmitButton, styles.separateField)}
          disabled={invalid}
        >
          { isFormSubmitting
            ? <i className='fa fa-spinner fa-pulse fa-2x' />
            : translations.applyChanges.toUpperCase()
          }
        </button>

      </form>
    );
  }
}

ProcessingForm.propTypes = {
  invalid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  fields: PropTypes.array
};
