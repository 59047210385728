import React from 'react';
import PropTypes from 'prop-types';

const CheckBoxToggle = (props) => {
    const {label, input, id, checked, disabled} = props;
    const inputId = id || `input_${props.input.name}`;
    return (
        <div className='input-element container-checkbox-toggle'>
            <input
                id={inputId}
                type='checkbox'
                checked={input ? input.value : checked}
                onChange={props.onChange}
                {...input}
                disabled={disabled}
            />
            <label className='checkbox-label' htmlFor={inputId}>
                <div className='checkbox-toggle'>
                    <span className='checkbox-toggle-slider round'/>
                </div>
                <div className='checkbox-toggle-title'>{label}</div>
            </label>
        </div>
    );
};

CheckBoxToggle.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object
};

export default CheckBoxToggle;
