import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import styles from 'styles/modules/parameters.module.scss';
import {Portal} from 'react-overlays';
import Popup from 'components/Popup';
import cx from 'classnames';
import SearchBox from './SearchBox';
import SelectedOption from './SelectedOption';
import ReactDOM from 'react-dom';

@translate()
export default class SearchableComboBoxArray extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPopupOpen: false,
            left: null,
            top: null,
        };
        this.buttonRef = React.createRef();
        this.renderedContainerRef = React.createRef();
        this.posCorrection = 0;
    }

    togglePopup = () => {
        const rect = this.buttonRef.current.getBoundingClientRect();
        const left = window.scrollX + rect.left + rect.width + 25;
        const top = window.scrollY + rect.top - 18;

        if (top !== this.state.top || left !== this.state.left) {
            this.setState(prevState => ({left, top, isPopupOpen: !prevState.isPopupOpen}));
        } else {
            this.setState(prevState => ({isPopupOpen: !prevState.isPopupOpen}));
        }
    };
    updatePos = () => {
        let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let popUpBoundingClientRect = ReactDOM.findDOMNode(this.renderedContainerRef.current).getBoundingClientRect();
        let delta = h - popUpBoundingClientRect.bottom;

        if(popUpBoundingClientRect.bottom > h){
            this.posCorrection = delta;
            this.setState({top:this.state.top + delta - 5});
        }
    };
    onRemoveField = fieldIndex => {
        const values = this.props.fields.getAll().filter((element, index) => index !== fieldIndex);
        const isSuccess = this.props.handleChangeValue({ value: values, name: this.props.fields.name });
        if (isSuccess && this.props.fields.length > 1) {
            this.props.fields.remove(fieldIndex);
        }
    };

    onSelect = option => {
        const {fields} = this.props;
        this.togglePopup();
        const values = fields.getAll().slice().concat(option);
        const isSuccess = this.props.handleChangeValue({value: values, name: this.props.fields.name});
        isSuccess && fields.push(option);
    };

    getLabelByValue = value => {
        const selectedValue = this.props.options.find(option => option.value === value);
        return selectedValue && selectedValue.label;
    };

    renderField = (value, index) => (
        <SelectedOption
            disabled={this.props.disabled}
            key={value}
            value={this.getLabelByValue(value)}
            onRemove={() => this.onRemoveField(index)}
        />
    );

    getRelevantOptions = () => {
        const values = this.props.fields.getAll() || [];
        return this.props.options.filter(option => !values.includes(option.value));
    };

    render() {
        const { label, t, fields } = this.props;
        const container = document.getElementById('root');
        const options = this.getRelevantOptions();
        const trialgleStyle = {top: (21 - this.posCorrection + 5 ) + 'px'};
        this.posCorrection = 0;
        return (
            <div className={cx('input-element', styles.fieldArrayContainer)}>
                <header className={styles.header}>
                    <label className={styles.label}>
                        {t(`customerInfoLabel.${label}`)}
                    </label>
                    {
                        options.length > 0 && !this.props.disabled &&
                        <span
                            className={styles.addItemButton}
                            onClick={this.togglePopup}
                            ref={this.buttonRef}
                        >
                            + {t('add')}
                        </span>
                    }
                    {
                        this.state.isPopupOpen &&
                        <Portal container={container}>
                            <Popup
                                place={styles.searchBoxPopup}
                                left={this.state.left}
                                top={this.state.top}
                                onClickOutside={this.togglePopup}
                                onMounted={this.updatePos}
                            >
                                <i className={styles.trianglePin} style={trialgleStyle} />
                                <SearchBox ref={this.renderedContainerRef} options={options} onSelect={this.onSelect}/>
                            </Popup>
                        </Portal>
                    }
                </header>
                {
                    fields.length > 0 &&
                    <div className={styles.content}>
                        {fields.getAll().map(this.renderField)}
                    </div>
                }
            </div>
        );
    }
}

SearchableComboBoxArray.propTypes = {
    handleChangeValue: PropTypes.func,
    options: PropTypes.array
};

SearchableComboBoxArray.defaultProps = {
    options: []
};
