import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { translate } from 'react-i18next';

import CONF from '../../config/conf';
import { clearEntireCustomerState, clearAllCustomers } from '../../actions/customer';
import { getTabs } from '../../reducers/tabs';
import ModalPortal from '../ModalPortal';
import MessageModal from '../MessageModal';
import styles from '../../styles/modules/mainTabs.module.scss';
import {
    createCheckPropsFunction, cropTabTypeToIconName, safeQuery, customerFormNameBuilder,
    searchCustomersInAppeals, destroyFormsAppealsAndCustomers,
	closeAppeal,
} from 'helpers';
import { closeMessageModal, openMessageModal, showMenu } from '../../actions/ui';
import { closeAllExceptCurrent, removeTab, resetTab } from '../../actions/tabs';
import { clearAppealState, clearAllAppeals } from '../../actions/appeal';
import { addCardsToHistory, addCardToHistory } from '../../actions/user';
import { loadState, removeState } from '../../util/storage';
import TabCloseMenu from './TabCloseMenu';
import TicketIcon from '../SVG/TicketIcon';
import HiddenTabs from './HiddenTabs';
import NewTab from './NewTab';
import { destroy } from 'redux-form';

function mapStateToProps (state) {
    return {
        tabs: getTabs(state),
        currentTab: state.tabs.current,
        isAddingActive: state.tabs.isAddingActive,
        showMessageModal: state.ui.showMessageModal,
        ui: state.ui,
        isAppealUnlocked: state.appeal.unlockedAppeal,
        appeals: state.appeal.appeals,
        isRequestLoading: state.app.requests.length > 0,
        forms: state.form
    }
}

const mapDispatchToProps = {
    addCardToHistory,
    addCardsToHistory,
    removeTab,
    resetTab,
    closeAllExceptCurrent,
    showMenu,
    openMessageModal,
    closeMessageModal,
    clearAppealState,
    clearEntireCustomerState,
    clearAllCustomers,
    clearAllAppeals,
    destroyForm: (name) => destroy(name),
    loadState: (nextState) => ({ type: 'LOAD_STATE', nextState }),
};

@translate()
@connect(mapStateToProps, mapDispatchToProps )
class MainTabs extends React.Component {
    state = {
        width: null,
        showMessageModal: false,
		modalTitle: '',
    };
    
    componentDidMount () {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        if (this.props.tabs.length === 0 && sessionStorage.length > 0) {
            sessionStorage.clear();
        }
    }
    
    componentDidUpdate (prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        
        if (isPropChanged('currentTab.type') && !this.props.currentTab.isSuperTab) {
            this.loadTabStateIfExist();
        }
    }
    
    componentWillUnmount () {
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    loadTabStateIfExist = () => {
        const tabType = this.props.currentTab.type;
        const loadedState = loadState(tabType);
        if (loadedState) {
            this.props.loadState(loadedState);
        }
    };
    
    updateDimensions = () => this.setState({ width: window.innerWidth });
    
    getMainTabMaxLength = () => {
        const { width } = this.state;
        
        if (width > 1420) {
            return 5;
        }
        if (width > 1300) {
            return 4;
        }
        if (width > 1200) {
            return 3;
        }
        if (width > 1110) {
            return 2;
        }
        return 1;
    };
    
    removeTab = (e, tab) => {
        e.preventDefault();
        e.stopPropagation();
        
        const { history, tabs } = this.props;
        this.tab = tab;
        
        const path = tab.url.split('/');
        const appeal = tab.name.split('_');
        const lastTab = tabs.length === 1;
        
        const { name, url, type } = tab;
    
        if (path[1] && path[1] === 'appeals' && _.get(this.props.appeals, `[${appeal[1]}].unlockedAppeal`)) {
            return this.openMessageModal(tab.displayedName || tab.regnum);
        }

        if (path[1] && path[1] === 'customer') {
            this.closeCustomer(path[2], lastTab);
        }

        if (path[1] && path[1] === 'person') {
            this.closePerson(path[2], lastTab);
        }

        if (path[1] && path[1] !== 'search') {
            this.props.addCardToHistory({ url, name });
        }

        if (type !== 'home') {
            this.props.removeTab({ tabType: type, history });
            if (appeal[0] === 'appeals') {
				closeAppeal({ ...this.props, id: appeal[1], lastTab });
            }
        }
    };
    
    closeCustomer = (id, lastTab) => {
        const {
        	destroyForm, clearEntireCustomerState, appeals, forms, clearAllAppeals,
			clearAllCustomers,
		} = this.props;
        let formSet = [`${id}-edit-customer-name`];
	
		if (lastTab) {
			destroyFormsAppealsAndCustomers({ forms, clearAllAppeals, destroyForm, clearAllCustomers });
		} else {
			if (searchCustomersInAppeals(appeals, id).length === 0) {
				formSet = [
					...formSet,
					...customerFormNameBuilder(id, 'customer'),
				];
				clearEntireCustomerState(id);
			}
			
			setTimeout(() => {
				formSet.forEach((name) => destroyForm(name));
			}, 1000);
		}
    };
    
    closePerson = (id, lastTab) => {
        const { destroyForm, clearEntireCustomerState, forms, clearAllAppeals, clearAllCustomers } = this.props;
	
		if (lastTab) {
			destroyFormsAppealsAndCustomers({ forms, clearAllAppeals, destroyForm, clearAllCustomers });
		} else {
			const formSet = [`${id}-edit-person-name`, ...customerFormNameBuilder(id, 'person')];
			clearEntireCustomerState(id);
			setTimeout(() => {
				formSet.forEach((name) => destroyForm(name));
			}, 1000);
		}
    };
    
    removeTabStates = (tabs) =>
        new Promise((resolve, reject) => {
            try {
                tabs.forEach((tab) => removeState(tab.type));
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    
    removeAllTabs = (e) => {
        e.preventDefault();
        
        const tabs = this.props.tabs.filter((el) => el.type !== 'home');
        
        this.props.addCardsToHistory(tabs);
        this.props.resetTab();
        
        this.removeTabStates(tabs);
        
        this.props.history.push(this.props.tabs[0].url);
    };
    
    removeAllTabsExceptCurrent = (e, tab) => {
        e.preventDefault();
        
        const tabList = this.props.tabs;
        const tabs = tabList.filter((el) => el.type !== 'home' && el.url !== tab.url);
        
        this.props.addCardsToHistory(tabs);
        this.props.closeAllExceptCurrent({ url: tab.url });
        
        this.removeTabStates(tabs);
        
        this.props.history.push(tabList[tabList.length - 1].url);
    };
    
    showMenu = (id) => (e) => {
        if (!id) return;
        e.preventDefault();
        const { showMenu } = this.props;
        showMenu && showMenu({ id });
    };
    
    closeModal = () => {
        const { history, tabs } = this.props;
		const lastTab = tabs.length === 1;
        
        this.closeMessageModal();
        removeState(this.tab.type);
        const appeal = this.tab.name.split('_');
        if (appeal[0] === 'appeals') {
			closeAppeal({ ...this.props, id: appeal[1], lastTab });
        }
        this.props.removeTab({ tabType: this.tab.type, history });
    };
    
    translateName = (name) => {
        const names = ['search', 'appeals', 'customer', 'person'];
        const firstPart = name.split('_')[0];
        if (names.indexOf(firstPart) !== -1) {
            const secondPart = name
                .split('_')
                .slice(1)
                .join('_');
            return secondPart ? `${secondPart}` : '';
        }
        return name;
    };
    
    isEmptyQuery = (url) => !/^\/\w+\/\w+\/\d+\/.+$/.test(url);
    
    getTabText = (tab) => {
        if (tab.type.startsWith('search')) {
            if (this.isEmptyQuery(tab.url)) {
                return this.props.t('Search');
            }
            return safeQuery(
                tab.url
                    .split('/')
                    .slice(4)
                    .join('/'),
                true,
            );
        }
        
        if (tab.type.startsWith('appeals')) {
            return tab.regnum || tab.displayedName;
        }
        
        if (tab.displayedName) {
            return tab.displayedName;
        }
        
        return tab.name !== 'SEARCH' ? this.translateName(tab.name) : '';
    };
    
    getTabTitle = (tab) => {
        if (tab.type.startsWith('appeals')) {
            return tab.regnum || '';
        }
        return tab.name.length > 10 && tab.name !== 'SEARCH' ? this.translateName(tab.name) : '';
    };
    
    openMessageModal = (modalTitle) => this.setState({ showMessageModal: true, modalTitle });
    
    closeMessageModal = () => this.setState({ showMessageModal: false, modalTitle: '' });
    
    goToTab = (nextTab) => {
        const { history } = this.props;
        
        history.push(nextTab.url);
    };
    
    renderTab = (val, key) => {
        const { ui, currentTab } = this.props;
        
        const icon = cropTabTypeToIconName(val.type);
        return (
            <div
                key={key}
                onClick={() => this.goToTab(val)}
                className={cx('main-tab', { active: val.type === currentTab.type })}
                title={this.getTabTitle(val)}
            >
                {CONF.icons[icon] === 'ticket' ? (
                    <TicketIcon />
                ) : (
                    <i className={`icon-${CONF.icons[icon] || 'search'}`} />
                )}
                
                <span className='main-tab-text'>{this.getTabText(val)}</span>
                <TabCloseMenu
                    removeTab={this.removeTab}
                    removeAll={this.removeAllTabs}
                    removeAllTabsExceptCurrent={this.removeAllTabsExceptCurrent}
                    val={val}
                    data={ui}
                />
            </div>
        );
    };
    
    render () {
        const { ui, user, tabs, isAddingActive, t } = this.props;
        const { modalTitle } = this.state;
        let dropDownTabs = [];
        let mainTabs = [];
        const tabsLength = this.getMainTabMaxLength();
        const checkTabsOverflow = tabs.length > tabsLength;
        const tabsCount = tabs.length;
        
        if (checkTabsOverflow) {
            dropDownTabs = [...tabs.slice(0, tabs.length - tabsLength)];
            mainTabs = [...tabs.slice(tabs.length - tabsLength)];
        } else {
            mainTabs = [...tabs];
        }
        
        return (
            <nav className={styles.headTabs}>
                {checkTabsOverflow && (
                    <HiddenTabs
                        hiddenTabs={dropDownTabs}
                        removeTab={this.removeTab}
                        removeAll={this.removeAllTabs}
                        removeAllTabsExceptCurrent={this.removeAllTabsExceptCurrent}
                        ui={ui}
                        tabs={tabs}
                        translateName={this.translateName}
                    />
                )}
                {mainTabs.map(this.renderTab)}
                <NewTab ui={ui} user={user} isAddingActive={isAddingActive} tabsEmpty={!tabsCount} />
                <CSSTransition
                    in={this.state.showMessageModal}
                    classNames='fade'
                    appear
                    enter
                    exit
                    timeout={500}
                    mountOnEnter
                    unmountOnExit
                >
                    <ModalPortal onClose={this.closeMessageModal} className='modal-small'>
                        <MessageModal
                            contentModalText={t('appeal.closeModalText')}
							titleModal={modalTitle}
                            primaryButton
                            primaryButtonText={t('appeal.yes')}
                            onClickPrimaryButton={this.closeModal}
                            secondaryButton
                            secondaryButtonText={t('appeal.no')}
                            onClickSecondaryButton={this.closeMessageModal}
                        />
                    </ModalPortal>
                </CSSTransition>
            </nav>
        );
    }
}

export default withRouter(MainTabs);

MainTabs.propTypes = {
    // from withRouter HOC
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    staticContext: PropTypes.bool,
    // ownProps
    ui: PropTypes.object.isRequired,
    user: PropTypes.object,
    // from connect
    tabs: PropTypes.object,
    isAddingActive: PropTypes.bool,
    addCardToHistory: PropTypes.func,
    addCardsToHistory: PropTypes.func,
    removeTab: PropTypes.func,
    resetTab: PropTypes.func,
    closeAllExceptCurrent: PropTypes.func,
    showMenu: PropTypes.func, // for TabCloseMenu component
    currentTab: PropTypes.object,
    isRequestLoading: PropTypes.bool,
};
