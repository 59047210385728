import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import 'styles/modules/cardContent.module.scss';
import Appeal from 'components/Cards/Appeal';
import AppealOperatePage from 'components/Appeal';
import { preloadLazy } from '../../helpers';

// async components
const Dashboard = preloadLazy(import(/* webpackPreload: true */ 'components/Dashboard'));
const KnowledgeBase = preloadLazy(import(/* webpackPreload: true */ 'components/KnowledgeBase'));
const Settings = preloadLazy(import(/* webpackPreload: true */ 'components/Settings'));
const SearchPage = preloadLazy(import(/* webpackPreload: true */ 'components/SearchPage'));
const CustomerPage = preloadLazy(import(/* webpackPreload: true */ 'components/CustomerPage'));
const PersonPage = preloadLazy(import(/* webpackPreload: true */ 'components/PersonPage'));
const Orders = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Orders })));
const Order = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Order })));


@withRouter
class Content extends React.Component {
  render() {
    return (
      <Suspense fallback=''>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/appeal" />} />
          <Route path="/search/:entity/:hash/*" component={SearchPage} />
          <Route path="/appeal" component={Appeal} />
          <Route path="/appeals/:appealId" component={AppealOperatePage} />
          <Route path="/customer/:id/:subTab?" component={CustomerPage} />
          <Route path="/person/:id/:subTab?" component={PersonPage} />
          <Route path="/knowledge_base/:knowledgeId?" component={KnowledgeBase} />
          <Route path="/settings/:group?/:section?/:id?/:part?/:childId?" component={Settings} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/orders" component={Orders} />
          <Route path="/order/:orderId/:subTab?" component={Order} />
        </Switch>
      </Suspense>
    );
  }
}

export default Content;
