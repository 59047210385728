import {
  SEARCH_HEADER_EXPAND,
  SEARCH_HEADER_REDUCE,
  SEARCH_HEADER_GET_SUPPOSE,
  SEARCH_HEADER_GET_SUPPOSE_SUCCESS,
  SEARCH_HEADER_GET_SUPPOSE_FAIL,
  SEARCH_HEADER_CLEAR_RESULT
} from 'constants/actions';

const initialState = {
  loading: false,
  loaded: false,
  searchResults: [],
  query: '',
  expandedSearch: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SEARCH_HEADER_EXPAND:
      return { ...state, expandedSearch: true };

    case SEARCH_HEADER_REDUCE:
      return { ...state, expandedSearch: false };

    case SEARCH_HEADER_GET_SUPPOSE:
      return { ...state, loading: true, loaded: false, query: action.query };

    case SEARCH_HEADER_GET_SUPPOSE_SUCCESS:
      const { result, facets } = action.result;
      return {
        ...state,
        loading: false,
        loaded: false,
        searchResults: Array.isArray(result) ? result : [],
        facets
      };

    case SEARCH_HEADER_GET_SUPPOSE_FAIL:
      return { ...state, loading: false, loaded: false, error: action.err, query: initialState.query };


    case SEARCH_HEADER_CLEAR_RESULT:
      return { ...state, searchResults: [] };

    default: return state;
  }
};
