import { zipCodeValidation } from 'util/form';
import { requiredFieldsValidation } from 'util/form';
import { StreetModes } from '../../../../../constants';

const validate = (values, props) => {
    const errors = {};
    let config = [...props.config];
    const streetIndex = config.findIndex((i) => (i.name === 'street'));

    if (config[streetIndex].required && props.mode === StreetModes.MANUAL) {
        config[streetIndex] = {...config[streetIndex], required: false};
        config = [...config, { name: 'streetName', required: true }, { name: 'streetType', required: true }];
    }
    
    requiredFieldsValidation(values, config, errors);
    
    if (values.zipCodeInput && zipCodeValidation(values.zipCodeInput)) {
        errors.zipCode = zipCodeValidation(values.zipCodeInput);
    }
    
    if (values.buildingNumberInput) {
        delete errors.buildingNumber
    }
    
    return errors;
};

export default validate;
