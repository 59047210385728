import React from 'react';
import styles from 'styles/modules/parameters.module.scss';
import { Field } from 'redux-form';

function FormField (props) {
  return (
    <div className={styles.fieldBlock}>
      <Field {...props}/>
    </div>
  );
}

export default FormField;
