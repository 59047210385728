export default {
  icons: {
    'none': 'battery-unknown',
    customer: 'customer-icon',
    person: 'client',
    appeal: 'printing',
    appeals: 'ticket',
    order: 'file-alt',
    documents: 'calendar',
    knowledge_base: 'open-book',

    'public': 'person',
    admin: 'shield',

    create: 'add',

    apps: 'apps',

    dashboard: 'dash',
    site: 'place',
    keys: 'vpn-key',
    equipment: 'devices',
    request: 'work',
    map: 'map',

    party: 'people-outline',
    report: 'report',
    report2: 'report',
    report3: 'report',
    report4: 'report',
    settings: 'settings2',

    groups: 'group',

    spec_dict: 'library-books',
    act_process: 'settings-applications',
    act_task: 'work',
    act_scheduler: 'timer',
    search: 'search-maginfer'
  },

  comments: {
    attachFormats: 'chm, doc, docx, dot, dotx, m3u, pdf, pub, rtf, txt, xml, xps',
    attachMIMETypes: '.chm,.doc,.docx,.dot,.dotx,.m3u,.pdf,.pub,.rtf,.txt,.xml,.xps'
  }

};

export const SUMY = 'sumycity';
export const UABET = 'uabet';
