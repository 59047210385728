import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';

@translate()
class SelfCareItem extends React.Component {

  getOptionalParts = () => {
    const { data: { comment } } = this.props;
    return { comment };
  };

  render(){
    const { comment } = this.getOptionalParts();
    return (
      <li className={styles.historyItem}>
        <i className='icon-envelope' />
        <div className={styles.historyItemContent}>
          <span className={styles.historyTitleText}> - </span>
          <span className={styles.historyContentText}>{comment}</span>
        </div>
      </li>
    );
  }
}

SelfCareItem.propTypes = {
  data: PropTypes.object
};

export default SelfCareItem;
