import {
  SEARCH_HEADER_EXPAND,
  SEARCH_HEADER_REDUCE,
  SEARCH_HEADER_GET_SUPPOSE,
  SEARCH_HEADER_GET_SUPPOSE_SUCCESS,
  SEARCH_HEADER_GET_SUPPOSE_FAIL,
  SEARCH_HEADER_CLEAR_RESULT
} from 'constants/actions';
import baseService from '../services/BaseService';


export const expandSearch = () => ({
  type: SEARCH_HEADER_EXPAND
});

export const reduceSearch = () => ({
  type: SEARCH_HEADER_REDUCE
});

const getSuppose = (query) => ({
  type: SEARCH_HEADER_GET_SUPPOSE,
  query
});

const getSupposeSuccess = (result) => ({
  type: SEARCH_HEADER_GET_SUPPOSE_SUCCESS,
  result
});

const getSupposeFail = (error) => ({
  type: SEARCH_HEADER_GET_SUPPOSE_FAIL,
  error
});

export const clearSearchResult = () => ({
  type: SEARCH_HEADER_CLEAR_RESULT
});

export const getHeaderSearchSuppose = (params) => (dispatch) => {
  dispatch(getSuppose(params.data.query));
  baseService.post('search', { data: params.data }, 'post')
    .then(data => dispatch(getSupposeSuccess(data)))
    .catch(err => dispatch(getSupposeFail(err)));
};
