import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import styles from 'styles/modules/appealOperatePage.module.scss';

export default function BlockedAppeal () {
    return (
        <div className={styles.wrapper}>
            <div className={styles.appealMask}>
                <div className={styles.appealMaskMsgBlock}>
                    <FontAwesomeIcon className='mask-msg-icon' icon={faExclamationTriangle} />
                    <p className='title'>Робота з цим зверненням неможлива</p>
                    <p>
                        Наразі ви не маєте прав доступу до цього звернення. <br />
                        Ви можете закрити цю вкладку.
                    </p>
                    <p>Якщо ви вважаєте цю дію помилковою, зверніться до адміністратора.</p>
                </div>
            </div>
        </div>
    );
}
