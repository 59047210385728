import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/Common/DatePicker';
import moment from 'moment';

class CompactDatePickerInput extends PureComponent {

   state = {
      isOpened: false
   };

   // Lifecycle methods:

   componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
   }

   // Arrow control methods:

   openDropdown = () => this.setState(({ isOpened: true }));

   closeDropdown = () => this.setState(({ isOpened: false }));

   toggleDropdown = () => this.setState(state => ({ isOpened: !state.isOpened }));
   
   // Event handlers:
   
   handleFocus = (event) => {
      this.openDropdown();
      this.props.onFocus && this.props.onFocus(event);
   };
	
	 handleBlur = (event) => {
      this.closeDropdown();
	    this.props.onBlur && this.props.onBlur(event);
	 };
	
	 handleClickOutside = (event) => {
	   if (this.element && !this.element.current.contains(event.target)) {
	     this.closeDropdown();
	   }
	 };
  
	 handleSelectOption = (value) => {
	   this.closeDropdown();
	   // this.select.current.value = value;
	   this.props.input.onChange && this.props.input.onChange(value);
	 };
  
	 preventEvent = (event) => {
	   event.preventDefault();
	   this.toggleDropdown();
	 };
	 
	 // Render methods:
  
	 arrowRender = isOpen => isOpen ? <i className='icon-up' /> : <i className='icon-down' />;
  
	 render = () => {
	   const { label, input, placeholder = 'Не вибрано', value, require, ...restProps } = this.props;
		 const { isOpened } = this.state;
	   return (
		 <div className='compactSelectWrapper' ref={this.element}>
		   <div className='compactSelectLabel'>
			 	{label}{require && <span className='requiredField'>*</span>}
		   </div>
		  
		   <div className='compactSelectBox'>
          <input
            className='compactDatePickerInput'
            placeholder={placeholder}
            {...restProps}
            value={typeof value === 'number' ? moment.unix(value).format('DD.MM.YYYY HH:mm') : value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <div className='compactSelectArrow'>{this.arrowRender(isOpened)}</div>
		   </div>
   
		 </div>
	   );
	 }
}


CompactDatePickerInput.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
	input: PropTypes.object,
	value: PropTypes.any,
	require: PropTypes.bool
};


export default ({ label, placeholder, required, ...props }) => (	// eslint-disable-line react/no-multi-comp
  <DatePicker
    {...props}
    dayFormat='DD.MM.YYYY'
    dayAndTimeFormat='DD.MM.YYYY HH:mm'
    customInput={ <CompactDatePickerInput label={label} placeholder={placeholder} require={required} {...props} /> }
    inputClass={'compactDatePickerInput'}
  />
);
