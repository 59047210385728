import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'moment/locale/uk';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Content from './components/Content';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { cancelFormChange, repeatFormChange } from 'actions/changeHistory';
import { getCallStation } from 'actions/call';
import { killEvent } from 'helpers';
import AudioPlayer from './components/Appeal/History/AudioPlayer';
import NotificationBox from './components/Notification';
import NotificationPanel from './components/NotificationPanel';
import baseService from './services/BaseService';
import i18n from './util/i18n';
import { openUserPopup } from './actions/ui';
import { sessionStorageService } from './services/settingsService';
import { setCallReceptionStatus } from './actions/call';
import * as pj from '../package.json';
import { UABET } from 'config/conf';
import CreateNewContactPersonModal from './components/CreateNewContactPersonModal';
import CreateNewContactPersonModalCommon from 'components/CreateNewCustomerModal/common';
import CreateNewContactPersonModalUabet from 'components/CreateNewCustomerModal/uabet';
import permissions, { checkPermissions } from './config/permissions';

// import 'ui-core-modules/dist/SDS-icons.css';

import './styles/shared.scss';
import './styles/style.scss';
import 'react-select/dist/react-select.css';

// looks like that coz of named export issues: https://github.com/facebook/react/issues/14603
const CreateNewOrderModal = lazy(() => import('pages/Ordering/').then(module => ({ default: module.CreateNewOrderModal })));

let CreateNewCustomerModal = CreateNewContactPersonModalCommon;
if (ENVIRONMENT === UABET) {
    CreateNewCustomerModal = CreateNewContactPersonModalUabet;
}


const mapStateToProps = (state) => ({
    lang: get(state, 'user.lang_default'),
    errors: get(state, 'ui.errors'),
    successMessage: get(state, 'ui.successMessage'),
    showCreateNewCustomerModal: get(state, 'ui.showCreateNewCustomerModal'),
    showCreateNewOrderModal: get(state, 'ui.showCreateNewOrderModal'),
    data: get(state, 'content.data'),
    notifications: state.ui.notifications,
    showNotificationPanel: state.ui.showNotificationPanel,
    showCreateNewContactPersonModal: state.ui.showCreateNewContactPersonModal,
    operations: state.user.operations
});

const mapDispatchToProps = (dispatch) => ({
    cancelFormChange: () => dispatch(cancelFormChange()),
    repeatFormChange: () => dispatch(repeatFormChange()),
    openUserPopup: () => dispatch(openUserPopup()),
    setCallReceptionStatus: status => dispatch(setCallReceptionStatus(status)),
    getCallStation: () => dispatch(getCallStation()),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.constructor.checkVersion();
        this.requestDictionary();
    }

    componentDidUpdate (prevProps) {
        if (this.props.operations.length && !prevProps.operations.length && checkPermissions(permissions.CallOperations.action_workPbx)) {
            this.props.getCallStation();
        }
    }

    componentDidMount() {
        // Custom Ctrl+Z and Ctrl+Shift+Z handlers.
        document.addEventListener('keyup', debounce(event => {
          if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
            if (event.shiftKey) {
              this.props.repeatFormChange();
            } else {
              this.props.cancelFormChange();
            }
            killEvent(event);
          }
        }, 50));

        // Preventing default browser handlers for Ctrl+Z, Ctrl+Shift+Z and Ctrl+Cmd+Z.
        document.addEventListener('keydown', event => {
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
              killEvent(event);
            }
        });
        document.addEventListener('keypress', event => {
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
              killEvent(event);
            }
        });

        const isOpenedBefore = sessionStorageService.get('isUserPopupOpenedBefore');
        if (!isOpenedBefore) {
            this.props.setCallReceptionStatus('active');
            this.props.openUserPopup();
        }
    }

    requestDictionary = () => {
        baseService.get('dictionary')
          .then(response => i18n.addResourceBundle('uk', 'dictionary', response.result))
          .catch(console.error);
    };

    static checkVersion() {
        const storageVersion = localStorage.getItem('version');
        if (storageVersion !== pj.version) {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('version', pj.version);
        }
    }

    render() {
        const {
            showCreateNewCustomerModal,
            showCreateNewContactPersonModal,
            showCreateNewOrderModal,
            showNotificationPanel,
            notifications
        } = this.props;
        return (
            <HashRouter>
                <div className='main'>
                    <Navigation />
                    <section className='main-block'>
                        <Header />
                        <Content />
                    </section>

                    {showCreateNewContactPersonModal && <CreateNewContactPersonModal />}
                    {showCreateNewCustomerModal && <CreateNewCustomerModal />}
                    {showCreateNewOrderModal &&  <Suspense fallback=""><CreateNewOrderModal /></Suspense>}
                    <AudioPlayer />
                    <NotificationBox notifications={notifications} />
                    {showNotificationPanel && <NotificationPanel />}
                </div>
            </HashRouter>
        );
    }
}

App.propTypes = {
    requestDictionary: PropTypes.func,
    repeatFormChange: PropTypes.func,
    cancelFormChange: PropTypes.func,
    showCreateNewCustomerModal: PropTypes.bool
};
