import {
  CHANGE_CHECKED_KNOWLEDGE,
  UPDATE_FOUND_KNOWLEDGES,
  UPDATE_DIRECTORIES_STRUCTURE,
  DISABLE_SEARCH_MODE,
  ENABLE_SEARCH_MODE,
  UPDATE_KNOWLEDGES_SEARCH_QUERY,
  SUBMIT_KNOWLEDGE_SEARCH,
  CLEAR_KNOWLEDGE_BASE_TEMP_STATE,
  SET_KNOWLEDGE_ITEM_TO_CREATE,
  CLEAR_KNOWLEDGE_ITEM_TO_CREATE,
  KNOWLEDGE_ITEM_REQUEST,
  KNOWLEDGE_ITEM_REQUEST_SUCCESS,
  KNOWLEDGE_ITEM_REQUEST_FAILURE,
  KNOWLEDGE_ITEM_MODIFY,

  KNOWLEDGES_REQUEST,
  KNOWLEDGES_REQUEST_SUCCESS,
  KNOWLEDGES_REQUEST_FAILURE,

  STATUS_LIST_REQUEST,
  STATUS_LIST_REQUEST_SUCCESS,
  STATUS_LIST_REQUEST_FAILURE
} from 'constants/actions';

const initialState = {
  knowledgeId: null,
  breadcrumbs: [],

  // TODO remove 'foundKnowledges' field
  foundKnowledges: [],

  searchMode: false,
  directoriesStructure: [],
  searchQuery: '',
  previousSearchQuery: null,

  statusList: [],
  statusListLoading: false,

  knowledgeBase: [],
  knowledgeBaseLoading: false,

  knowledgeItem: null,
  knowledgeItemLoading: false,
  knowledgeItemModificationCount: 0,

  itemToCreate: null
};

export default function knowledgesReducer(state = initialState, action) {
  switch (action.type) {

    /********  Knowledge item request  ********/

    case KNOWLEDGE_ITEM_REQUEST:
      return {...state, knowledgeItemLoading: true};

    case KNOWLEDGE_ITEM_REQUEST_SUCCESS:
      return {...state, knowledgeItemLoading: false, knowledgeItem: action.knowledgeItem};

    case KNOWLEDGE_ITEM_REQUEST_FAILURE:
      return {...state, knowledgeItemLoading: false, knowledgeItem: null};

    case KNOWLEDGE_ITEM_MODIFY:
      return {...state, knowledgeItemModificationCount: state.knowledgeItemModificationCount + 1};

    /********  Knowledge base request  ********/

    case KNOWLEDGES_REQUEST:
      return {...state, knowledgeBaseLoading: true};

    case KNOWLEDGES_REQUEST_SUCCESS:
      return {...state, knowledgeBaseLoading: false, knowledgeBase: action.knowledgeBase};

    case KNOWLEDGES_REQUEST_FAILURE:
      return {...state, knowledgeBaseLoading: false, knowledgeBase: []};

    /********  Status list request  ********/

    case STATUS_LIST_REQUEST:
      return {...state, statusListLoading: true};

    case STATUS_LIST_REQUEST_SUCCESS:
      return {...state, statusListLoading: false, statusList: action.statusList};

    case STATUS_LIST_REQUEST_FAILURE:
      return {...state, statusListLoading: false, statusList: []};

    /*****************************************/

    case UPDATE_FOUND_KNOWLEDGES:
      return {...state, foundKnowledges: action.foundKnowledges, searchMode: true};

    case UPDATE_DIRECTORIES_STRUCTURE:
      return {...state, directoriesStructure: action.directoriesStructure};

    case CHANGE_CHECKED_KNOWLEDGE:
      return {...state, knowledgeId: action.knowledgeId, breadcrumbs: action.breadcrumbs};

    case DISABLE_SEARCH_MODE:
      return {...state, searchMode: false, searchQuery: '', previousSearchQuery: ''};

    case ENABLE_SEARCH_MODE:
      return {...state, searchMode: true};

    case UPDATE_KNOWLEDGES_SEARCH_QUERY:
      return {...state, searchQuery: action.searchQuery};

    case SUBMIT_KNOWLEDGE_SEARCH:
      return {...state, previousSearchQuery: state.searchQuery, knowledgeId: null, breadcrumbs: [], searchMode: true};

    case SET_KNOWLEDGE_ITEM_TO_CREATE:
      return {...state, itemToCreate: { parentId: action.parentId, typeCode: action.itemType }};

    case CLEAR_KNOWLEDGE_ITEM_TO_CREATE:
      return {...state, itemToCreate: null};

    case CLEAR_KNOWLEDGE_BASE_TEMP_STATE:
      return initialState;

    default:
      return state;
  }
}
