import baseService from '../services/BaseService';

export default function downloadXLSX({ requestData, key }, callBack) {
    const params = {
        data: requestData,
        jsonType: true,
        waitFile: true
    };
    return baseService.post(key, params)
        .then((response) => {
            if (!(response.response && response.response.status !== 200)) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${key}.xlsx`);
                document.body.appendChild(link);
                link.click();
                callBack ? callBack() : null;
            }
            else callBack ? callBack() : null;
        })

}
