import React, { Fragment } from 'react';
import ComboBoxRemovable from 'components/Common/ComboBoxRemovable';
import { connect } from 'react-redux';
import { updatePhonesList } from 'actions/user';
import { translate } from 'react-i18next';
import { v4 } from 'node-uuid';
import baseService from 'services/BaseService';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { atou, utoa } from 'helpers';
import { SseEvents } from '../../constants';
import { setLastSseMessage } from '../../actions/call';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({
    phoneNumber: state.call.phoneNumber,
    phoneNumberList: state.user.userPhones,
    lastMessage: state.call.lastMessage,
    isAuthorized: state.call.isAuthorized,
    callStation: state.call.callStation,
    outPhoneErrorMSg: state.call.outPhoneErrorMSg,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updatePhonesList,
    setLastSseMessage,
}, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class UserCallForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: props.phoneNumber,
            ciscoLogin: this.getDecodedStringFromStorage('ciscoLogin'),
            ciscoPassword: this.getDecodedStringFromStorage('ciscoPassword'),
            error: '',
        };

        this.wait = false;
    }


    componentDidMount() {
        if (this.props.lastMessage.event === SseEvents.DISCONNECTION) {
            this.showDisconnectionError();
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.isDisconnected(prevProps.lastMessage.event) && this.isDisconnected(this.props.lastMessage.event)) {
            this.showDisconnectionError();
        }
    }

    showDisconnectionError = () => {
        this.setState({ error: 'Зв\'язок розірвано' });
        this.props.setLastSseMessage({});
    };

    isDisconnected = (event) => event === SseEvents.DISCONNECTION;

    encodeString = string => `${utoa(string)}?${v4()}`;

    updateCredentialsStorage = () => {
        const { ciscoLogin, ciscoPassword } = this.state;

        localStorage.setItem('ciscoLogin', this.encodeString(ciscoLogin));
        localStorage.setItem('ciscoPassword', this.encodeString(ciscoPassword));
    };

    getDecodedStringFromStorage = itemName => {
        const string = localStorage.getItem(itemName);

        if (string && string.indexOf('?') !== -1) {
            const questionPosition = string.indexOf('?');

            return atou(string.substring(0, questionPosition));
        }

        return '';
    };

    onCredentialsChange = event => {
        const value = event.target.value;
        this.setState({ [event.target.name]: value }, this.updateCredentialsStorage);
    };

    createPhoneNumber = option => {
        const newPhonesList = [option.value, ...this.props.phoneNumberList];
        this.updatePhonesList(newPhonesList);
    };

    removePhoneNumber = option => {
        const newPhonesList = this.props.phoneNumberList.filter(number => number !== option.value);
        this.updatePhonesList(newPhonesList);
    };

    updatePhonesList = newPhonesList => {
        const params = {
            data: {
                id: this.props.user.id,
                phones: newPhonesList
            },
            jsonType: true
        };
        return baseService.post('user_edit_phones_list', params)
            .then(response => this.props.updatePhonesList(newPhonesList))
            .catch(console.error);
    };

    getPhoneNumbers = () => this.props.phoneNumberList.map(number => ({ label: number, value: number }));

    onPhoneNumberChange = option => this.setState({ phoneNumber: option ? option.value : '' });

    onNumberInputChange = inputValue => inputValue.replace(/[^0-9]/g, '');

    onSubmit = event => {
        event.preventDefault();
        if (this.wait) return;
        this.wait = true;

        if (this.state.error) {
            this.setState({ error: '' });
        }

        const values = {
            phoneNumber: this.state.phoneNumber,
            agentName: this.state.ciscoLogin,
            agentPassword: this.state.ciscoPassword
        };

        this.props.onSubmit(values)
            .then(error => {
                if (error) {
                    this.setState({ error });
                }
                this.wait = false;
            });
    };

    isSubmitDisabled = () => {
        if (['asterisk', 'binotel'].includes(this.props.callStation)) {
            return !this.state.phoneNumber;
        }
        return !(this.state.phoneNumber && this.state.ciscoLogin && this.state.ciscoPassword);
    };

    render() {
        const { ciscoLogin, ciscoPassword, phoneNumber, error } = this.state;
        const { t, isAuthorized, callStation, outPhoneErrorMSg } = this.props;
        console.log(outPhoneErrorMSg);
        const buttonClassName = cx('btn btn-primary full-width', {
            ['btn-disabled']: this.isSubmitDisabled()
        });

        const inputFieldClassName = cx('input-field', {
            ['input-field__error']: error,
        });

        return (
            <form onSubmit={this.onSubmit}>
                <div className='row'>
                    <div className='input-element'>
                        <div className='input-label'>{t('phone')}</div>
                        <ComboBoxRemovable
                            name='phoneNumber'
                            placeholder={t('call.choosePhoneNumber')}
                            noResultsText={t('call.numbersNotFound')}
                            options={this.getPhoneNumbers()}
                            value={phoneNumber}
                            onRemoveOption={this.removePhoneNumber}
                            onCreateOption={this.createPhoneNumber}
                            onChange={this.onPhoneNumberChange}
                            onInputChange={this.onNumberInputChange}
                            error={error}
                        />
                    </div>
                </div>
                {
                    callStation && callStation === 'finesse' ? (
                        <Fragment>
                            <div className='row'>
                                <div className='input-element'>
                                    <div className='input-label'>{t('login')}</div>
                                    <input
                                        className={inputFieldClassName}
                                        name='ciscoLogin'
                                        type='text'
                                        placeholder={t('call.enterLogin')}
                                        onChange={this.onCredentialsChange}
                                        value={ciscoLogin}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='input-element'>
                                    <div className='input-label'>{t('access_password')}</div>
                                    <input
                                        className={inputFieldClassName}
                                        name='ciscoPassword'
                                        type='password'
                                        placeholder={t('call.enterPassword')}
                                        onChange={this.onCredentialsChange}
                                        value={ciscoPassword}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : null
                }
                { error && <span className='error-block'>{error}</span> }
                { outPhoneErrorMSg && <span className='error-block'>{outPhoneErrorMSg}</span> }
                {
                    !isAuthorized ? (
                        <button
                            className={buttonClassName}
                            disabled={this.isSubmitDisabled()}
                        >
                            {t('call.enter')}
                        </button>
                    ) : null
                }

            </form>
        );
    }
}

UserCallForm.propTypes = {
    onSubmit: PropTypes.func,
    phoneNumber: PropTypes.string,
    phoneNumberList: PropTypes.array,
    updatePhonesList: PropTypes.func,
    user: PropTypes.object
};

export default UserCallForm;
