import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import OptionsListContainer from './OptionsListContainer';
import OptionsTreeContainer from './OptionsTreeContainer';
import isEmpty from 'lodash/isEmpty';

class MultiSelectWithSearch extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.optionsRef = React.createRef();
        this.closeOptions = this.closeOptions.bind(this);
        this.getSelectedOptionsAsArray = this.getSelectedOptionsAsArray.bind(this);
    }

    componentDidUpdate (prevProps, prevState) {
        if (!prevState.isOpen && this.state.isOpen) {
            this.scrollIntoView();
            this.onFocus();
        }

        if (prevState.isOpen && !this.state.isOpen) {
            this.onBlur();
        }
    };

    scrollIntoView ()  {
     if(!this.props.tree) {
         const selectOptions = this.optionsRef.current;

         if (selectOptions.scrollIntoViewIfNeeded) {
             selectOptions.scrollIntoViewIfNeeded(false);
         } else {
             selectOptions.scrollIntoView(false);
         }
     }
    };

    toggleOptions = () => {
        if (!this.props.disabled) {
            this.setState(prevState => ({ isOpen: !prevState.isOpen }));
        }
    };

    closeOptions () {
              if(!this.props.tree && this.state.isOpen) {
                this.setState({isOpen: false});
            }

    };

    renderPlaceholder () {
        if (!isEmpty(this.props.selectedOptions)) return null;

        return (
            <div className={'placeholder'}>
                {this.props.placeholder}
            </div>
        );
    };

    onSelectOption = selectedOption => {
        if (this.props.onChange) {
			const result = this.props.multi ? this.props.tree ?  [...selectedOption] : [...this.props.selectedOptions, selectedOption] : selectedOption;
            this.props.onChange(result);
            this.props.tree ? this.toggleOptions() : null;
        }

        if (!this.props.multi) {
            this.closeOptions();
        }
    };

    onRemoveOption = removedOption => {
        const result = this.props.multi
            ? this.props.selectedOptions.filter(option => option.value !== removedOption.value)
            : null;

        if (this.props.onChange) {
            this.props.onChange(result);
        }

        if (!this.state.isOpen && this.props.blurOnRemoveWhileClosed && this.props.onBlur) {
            this.props.onBlur(result);
        }
    };

    onFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    onBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    renderOption = option => {
        const { removeDisabled, disabled } = this.props;

        const onClick = event => {
            event.stopPropagation();
            this.onRemoveOption(option);
        };


        return (
            <div className={'selected-option'}>
                <span title={option.label}>
                    {option.label}
                </span>
                {option.path && <div className='path'>{option.path}</div>}
                {
                    (!removeDisabled && !disabled) &&
                    <i className={'icon-times'} onClick={onClick}/>
                }
            </div>
        );
    };

    getSelectedOptionsAsArray = () => {
        const { selectedOptions, multi } = this.props;
        if (multi) {
            if (Array.isArray(selectedOptions)) {
                return [...selectedOptions];
            }
            return [...selectedOptions];
        } else {
            return isEmpty(selectedOptions) ? [] : [selectedOptions];
        }
    };

    renderSelectedOptions = () => {
        if (isEmpty(this.props.selectedOptions)) return null;

        const selectedOptionsClassName = cx('selected-options-wrapper', {
            'open': this.state.isOpen
        });

        return (
            <div className={selectedOptionsClassName}>
                {this.getSelectedOptionsAsArray().map(this.renderOption)}
            </div>
        );
    };

    render () {
        const { label, required, error, options, tree, inline, valueField, leafField, disabled, async } = this.props;
        const { isOpen } = this.state;
        const rootClassName = cx('input-element multi-select-search', {
            inline,
            disabled
        });
        const innerWrapperClassName = cx('inner-wrapper', {
            'focus': isOpen
        });

        const valueClassName = cx('input-field', {
            'input-field__error': error,
            'focus': isOpen
        });
        return (
            <div className={rootClassName}>

                <div className='input-label'>
                    {label}
                    {required && <span className='required-field'>*</span>}
                </div>

                {!tree ? <ClickOutsideHolder className='outer-wrapper' onClickOutside={this.closeOptions}>
                        <div className={innerWrapperClassName}>

                            <div className={valueClassName} onClick={this.toggleOptions}>
                                {this.renderPlaceholder()}
                                {this.renderSelectedOptions()}
                                <div className={'icon-container'}>
                                    <i className={isOpen ? 'icon-up' : 'icon-down'}/>
                                </div>
                            </div>

                            <OptionsListContainer
                                isOpen={isOpen}
                                options={options}
                                selectedOptions={this.getSelectedOptionsAsArray()}
                                containerRef={this.optionsRef}
                                onSelect={this.onSelectOption}
                                onRemove={this.onRemoveOption}
                                valueField={valueField}
                                leafField={leafField}
								async={async}
                            />

                        </div>
                    </ClickOutsideHolder>
                    :
                    <div className={innerWrapperClassName}>

                        <div className={valueClassName} onClick={this.toggleOptions}>
                            {this.renderPlaceholder()}
                            {this.renderSelectedOptions()}
                        </div>

                        <OptionsTreeContainer
                            isOpen={isOpen}
                            options={options}
                            selectedOptions={this.getSelectedOptionsAsArray()}
                            onSelect={this.onSelectOption}
                            onRemove={this.onRemoveOption}
                            valueField={valueField}
                            leafField={leafField}
                            onClose={this.toggleOptions}
							async={async}
                        />

                    </div>
                }
            </div>
        );
    }
}

MultiSelectWithSearch.defaultProps = {
    selectedOptions: [],
    disabled: false,
    tree: false,
    multi: false,
    inline: false,
    blurOnRemoveWhileClosed: false,
};

MultiSelectWithSearch.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })),
        PropTypes.object
    ]).isRequired,
    tree: PropTypes.bool,
    selectedOptions: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    inline: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    multi: PropTypes.bool,
    blurOnRemoveWhileClosed: PropTypes.bool,
    removeDisabled: PropTypes.bool,
};

export default MultiSelectWithSearch;
