import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';
import { NavLink } from 'react-router-dom';

import styles from 'styles/modules/appealsContent.module.scss';

class Parameters extends React.Component {
  sortData = (data) => {
    const predefined = [];
    const notdefined = [];

    data && data.forEach(item => {
      return item.predefined ? predefined.push(item) : notdefined.push(item);
    });
    return { predefined, notdefined };
  };

  render() {
    const { data } = this.props;
    const sortedData = this.sortData(data);
    const { predefined, notdefined } = sortedData;

    return (
      <div className={styles.asideContent}>
        <strong className='heading'>Звернення</strong>
        <div className={styles.wrapperFilter}>
          <div className={styles.filtersData}>
            <ul>
              {predefined && predefined.map(item =>
                <li key={item.value}>
                  <NavLink to={`/appeal/filter/${item.value}`} className={styles.filtersLink}>
                    <span className={styles.filterName} title={item.name}>{item.name}</span>
                    <span className='count' />
                  </NavLink>
                </li>)}
            </ul>
          </div>
          <div className={styles.filtersData}>
            <ul>
              {notdefined && notdefined.map(item =>
                <li key={`${item.value} || ${item.id}`}>
                  <NavLink to={`/appeal/filter/${item.value}`} className={styles.filtersLink}>
                    <span className={styles.filterName} title={item.name}>{item.name}</span>
                    <span className='count' />
                  </NavLink>
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Parameters.propTypes = {
  data: PropTypes.array
};

export default Parameters;
