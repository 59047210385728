import React from 'react';
import PropTypes from 'prop-types';
import {NavLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import cx from 'classnames';
import CONF from '../../config/conf';
import {toggleHiddenTabs} from '../../actions/ui';
import {tabsReverse} from '../../actions/tabs';
import TabCloseMenu from './TabCloseMenu';
import Popup from '../Popup';
import {MAX_SHOW_HIDDEN_TABS} from '../../constants';
import styles from 'styles/modules/mainTabs.module.scss';

@withRouter
@connect(null, { toggleHiddenTabs, tabsReverse })
export default class HiddenTabs extends React.Component {

    state = {
        popupIsOpen: false,
        isShowAllActive: false
    };

    reverseTab = (e, tab) => this.props.tabsReverse({url: tab.url});

    togglePopup = () => this.setState(prevState => ({popupIsOpen: !prevState.popupIsOpen}));

    closePopup = (e) => {
        const toggleButtonClick = this.toggleButton.contains(e.target);
        this.hideAllTabs();
        return toggleButtonClick ? null : this.togglePopup();
    };

    showAllTabsHandler = event => {
        event.stopPropagation();
        this.setState({isShowAllActive: true});
    };

    hideAllTabs = event => this.setState({isShowAllActive: false});

    render() {
        const { removeTab, removeAll, removeAllTabsExceptCurrent, ui, hiddenTabs, translateName } = this.props;
        const {popupIsOpen, isShowAllActive} = this.state;
        const hiddenTabsCount = hiddenTabs.length;
        const showedTabs = hiddenTabsCount > MAX_SHOW_HIDDEN_TABS && !isShowAllActive ? MAX_SHOW_HIDDEN_TABS : hiddenTabsCount;

        return (
            <div
                className={styles.hiddenTabs}
                onClick={this.togglePopup}
                ref={(node) => (this.toggleButton = node)}
            >
                <i className={cx('icon-arrow-right', {'down': popupIsOpen})}/>
                { popupIsOpen &&
                    <Popup
                        onClickOutside={this.closePopup}
                        place='dropdown-tabs'
                    >
                        <div className={styles.hiddenTabsWrapper}>
                            {hiddenTabs && [...hiddenTabs].reverse().slice(0, showedTabs).map((val, key) => {
                                const icon = val.url.split('/')[1];

                                return (
                                    <NavLink
                                        exact
                                        to={`${val.url}`}
                                        key={key}
                                        className={styles.hiddenTabsItem}
                                        onClick={(e) => this.reverseTab(e, val)}
                                    >
                                        <div className={styles.hiddenTabsTextWrapper}>
                                            {val.name !== 'HOME' && icon && (
                                                <i className={`icon-${CONF.icons[icon] || 'search'}`}/>
                                            )}
                                            <span className={styles.hiddenTabsText}>
                                                {val.name === 'search'  ? val.url.split('/').pop() : val.regnum || val.displayedName }
                                            </span>

                                            {val.name !== 'HOME' ?
                                                <TabCloseMenu
                                                    removeTab={removeTab}
                                                    removeAll={removeAll}
                                                    removeAllTabsExceptCurrent={removeAllTabsExceptCurrent}
                                                    val={val}
                                                    place='hidden-hidden-menu'
                                                    data={ui}
                                                />
                                                : ''}
                                        </div>
                                    </NavLink>
                                );
                            })}
                        </div>

                        {!isShowAllActive && hiddenTabsCount > MAX_SHOW_HIDDEN_TABS && (
                            <div className={styles.hiddenTabsMoreLink}>
                                <i className='icon-kebab-hor' onClick={this.showAllTabsHandler}/>
                            </div>
                        )}
                    </Popup>
                }
            </div>
        );
    }
}

HiddenTabs.propTypes = {
    toggleHiddenTabs: PropTypes.func,
    tabsReverse: PropTypes.func,
    ui: PropTypes.object.isRequired,
    tabs: PropTypes.array,
    removeTab: PropTypes.func,
    removeAll: PropTypes.func,
    removeAllTabsExceptCurrent: PropTypes.func,
    hiddenTabs: PropTypes.array,
    translateName: PropTypes.func.isRequired
};

