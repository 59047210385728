import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/parameters.module.scss';
import { translate } from 'react-i18next';


function InputLabel ({readOnly, label, onAddClick, t, loading = false, isTouched}) {
    const addButtonCondition = !readOnly && onAddClick;
    return (
        <header className={styles.header}>
            <label className={styles.label}>
                {t(`customerInfoLabel.${label}`)}
            </label>
            {
                addButtonCondition && !(loading && isTouched) &&
                <button className='add-button' onClick={onAddClick}>
                    + {t('add')}
                </button>
            }
        </header>
    );
}

InputLabel.propTypes = {
    label: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    onAddClick: PropTypes.func
};

export default translate()(InputLabel);
