import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import { getFilterAttrsSuppose, addFilterAttrs, closeParamsModal } from 'actions/filter';
import styles from 'styles/modules/modal.module.scss';
import cx from 'classnames';
import { translate } from 'react-i18next';
import { reduxFormWrapper } from 'helpers';

const mapStateToProps = (state) => {
	const initialValues = () => {
		const filterAttrsResults = state.filter.filterAttrsResults;
		const infoFilterParams = state.filter.infoFilterParams;
		const checkedFilterAttrs = state.filter.checkedFilterAttrs;
		const paramsIdArr = infoFilterParams.map(el => el.paramId);
		const paramsNameArray = filterAttrsResults.filter(el => paramsIdArr.includes(el.id));
		const initObj = {};
		paramsNameArray.forEach(el => {
			const nameFieled = el.code;
			return (
				initObj[nameFieled] = true
			);
		});
		return Object.keys(checkedFilterAttrs).length ? checkedFilterAttrs : initObj;
	};
	
	return {
		filter: state.filter,
		initialValues: initialValues()
	};
};

const mapDispatchToProps = (dispatch) => ({
	getFilterAttrsSuppose: (params) => dispatch(getFilterAttrsSuppose(params)),
	addFilterAttrs: (result) => dispatch(addFilterAttrs(result)),
	closeParamsModal: () => dispatch(closeParamsModal())
});

@translate()
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'params-modal', destroyOnUnmount: false, enableReinitialize: true })
class ParamsModal extends React.Component {
	
	UNSAFE_componentWillMount () {
		const { filterCode } = this.props;
		
		this.props.getFilterAttrsSuppose({
			key: 'attributes',
			data: { code: this.renderSwitch(filterCode) }
		});
	}
	
	renderSwitch (params) {
		switch (params) {
		case 'customer': {
			return 'CUSTOMER';
		}
		case 'appeal': {
			return 'INTERACTION_REQUEST';
		}
		default: {
			return 'INTERACTION_REQUEST';
		}
		}
	}
	
	handleSubmit = values => {
		this.props.addFilterAttrs(values);
		this.props.closeParamsModal();
	};
	
	renderInputField = ({ id, name, code }) => (
		<Field
			component={CheckBoxToggle}
			key={name}
			name={code}
			label={name}
		/>
	);
	
	renderFormFields = () => {
		const { filterAttrsResults } = this.props.filter;
		return filterAttrsResults && filterAttrsResults.map(field =>
			this.renderInputField({ ...field })
		);
	};
	
	render () {
		const { handleSubmit, t } = this.props;
		
		return (
			<div className={styles.modalWrapper}>
				<div className={styles.modalHeader}>
					<div className={styles.modalTitle}>{t('filters.addRemoveParameters')}</div>
				</div>
				<div className={cx(styles.modalContent, styles.modalContentFilters)}>
					<form onSubmit={handleSubmit(this.handleSubmit)}>
						{this.renderFormFields()}
						<button className='btn btn-primary'>
							{t('filters.apply')}
						</button>
					</form>
				</div>
			</div>
		);
	}
}

ParamsModal.protTypes = {
	handleSubmit: PropTypes.func,
	closeParamsModal: PropTypes.func,
	filter: PropTypes.object
};

export default ParamsModal;
