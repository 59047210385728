import {
	CREATE_NEW_APPEAL,
	CREATE_NEW_APPEAL_ERROR,
	GET_APPEAL_TYPES_LIST_SUCCESS,
	GET_APPEAL_TYPES_LIST_ERROR,
	GET_DINAMIC_FORM_PARAMS,
	GET_DINAMIC_FORM_PARAMS_SUCCESS,
	GET_DINAMIC_FORM_PARAMS_ERROR,
	GET_APPEAL_STATUS_LIST,
	GET_APPEAL_STATUS_LIST_SUCCESS,
	GET_APPEAL_STATUS_LIST_ERROR,
	SAVE_APPEAL,
	SAVE_APPEAL_SUCCESS,
	SAVE_APPEAL_ERROR,
	APPEAL_DESTINATIONS_REQUEST,
	APPEAL_DESTINATIONS_REQUEST_SUCCESS,
	APPEAL_DESTINATIONS_REQUEST_FAILURE,
	LOCK_APPEAL_FORM,
	UNLOCK_APPEAL_FORM,
	UNLOCK_APPEAL_FORM_SUCCESS,
	UNLOCK_APPEAL_FORM_ERROR,
	GET_APPEAL_PRIORITY_LIST_SUCCESS,
	GET_APPEAL_PRIORITY_LIST_ERROR,
	GET_APPEAL_FEEDBACK,
	GET_APPEAL_FEEDBACK_SUCCESS,
	APPEAL_REQUEST,
	APPEAL_REQUEST_SUCCESS,
	APPEAL_REQUEST_FAILURE,
	CLEAR_APPEAL_STATE,
	GET_APPEAL_FEEDBACK_ERROR,
	CLEAR_APPEAL_FEEDBACK,
	GET_APPEAL_ACTIONS,
	SET_APPEAL_ACTIONS,
	SET_FIRST_LEVEL_ACTION,
	SET_RESOLUTION_ACTION,
	REMOVE_REFRESH_APPEAL_MODAL,
	SET_MODAL_STATUS_LIST,
	SET_STATUS_MODAL_VISIBILITY,
	APPEAL_FETCHING,
	REMOVE_CUSTOMER_FROM_APPEAL,
	ADD_CUSTOMER_TO_APPEAL,
	CLEAR_ALL_APPEALS,
	SAVE_AND_CREATE_NEW,
} from 'constants/actions';
import { keyGen } from 'helpers';

const appealModel = {
	loadingParams: false,
	loadedParams: false,
	formParams: null,
	formParamsError: null,
	
	refreshAppealModal: false,
	
	statusList: null,
	loadingStatusList: false,
	loadedStatusList: false,
	
	savingAppeal: false,
	savedAppeal: false,
	saveAppealError: null,
	
	loadingDestinations: false,
	destinationHash: null,
	destinations: [],
	
	loadingAppealFeedback: false,
	appealFeedback: null,
	appealFeedbackError: null,
	
	unlockAppeal: false,
	unlockedAppeal: false,
	
	closeAppealFlag: false,
	
	currentAppeal: null,
	
	appealAction: [],
	selectedAppealAction: [],
	
	modalStatusList: [],
	modalVisibility: false,
	
	firstLevelAppealAction: null,
	resolutionAppealAction: null,
	selectedAction: '--',
	
	appealIsBlocked: false,
	saveAndCreateNew: false,
	appealKey: null,
	fetching: false,
};

const initialState = {
	appealTypes: null,
	priorityList: null,
	creating: false,
	error: null,
	appeals: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		/********  Creating Process  ********/
	
	case CREATE_NEW_APPEAL:
		return { ...state, creating: true };
	
	case CREATE_NEW_APPEAL_ERROR: {
		return {
			...state,
			creating: false,
			error: action.error,
		};
	}
		
		/********  Appeal request  ********/
	
	case APPEAL_REQUEST:
		return setDataToAppeal(action.id, state, { ...appealModel });
	
	case APPEAL_REQUEST_SUCCESS:
		return setDataToAppeal(action.appealData.id, state, {
			currentAppeal: action.appealData,
		});
	
	case APPEAL_REQUEST_FAILURE:
		
		return setDataToAppeal(action.id, state, {
			currentAppeal: null,
			appealIsBlocked: action.appealIsBlocked ? action.appealIsBlocked : false
		});
	
	case APPEAL_FETCHING:
		return setDataToAppeal(action.id, state, { fetching: true }, { creating: false });
		
		/********  Appeal saving  ********/
	
	case SAVE_APPEAL:
		return setDataToAppeal(action.id, state, {
			savingAppeal: true,
			savedAppeal: false,
			saveAppealError: null,
			unlockAppeal: false,
			unlockedAppeal: false,
		});
	
	case SAVE_APPEAL_SUCCESS:
		return setDataToAppeal(action.id, state, {
			savingAppeal: false,
			savedAppeal: true,
			knowledgeBase: null,
		});
	
	case SAVE_APPEAL_ERROR:
		return setDataToAppeal(action.id, state, {
			savingAppeal: false,
			savedAppeal: false,
			saveAppealError: action.error && action.error.message,
		});
		
		/********  Appeal lock/unlock form  ********/
	
	case LOCK_APPEAL_FORM:
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: false,
		});
	
	case UNLOCK_APPEAL_FORM:
		return setDataToAppeal(action.id, state, {
			unlockAppeal: true,
			unlockedAppeal: false,
		});
	
	case UNLOCK_APPEAL_FORM_SUCCESS: {
		const refreshAppealModal = Number(action.version) > Number(state.appeals[action.id].currentAppeal.version);
		return setDataToAppeal(action.id, state, {
			refreshAppealModal,
			unlockAppeal: false,
			unlockedAppeal: !refreshAppealModal,
		});
	}
	
	case UNLOCK_APPEAL_FORM_ERROR:
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: true,
		});
		
		/********  Appeal destinations request  ********/
	
	case APPEAL_DESTINATIONS_REQUEST:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: true,
		});
	
	case APPEAL_DESTINATIONS_REQUEST_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: false,
			destinations: action.destinations,
			destinationHash: keyGen(),
		});
	
	case APPEAL_DESTINATIONS_REQUEST_FAILURE:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: false,
			destinations: [],
		});
	
	case GET_APPEAL_TYPES_LIST_SUCCESS: {
		const { result } = action;
		return {
			...state,
			appealTypes: _.get(result.children, '[0]'),
		};
	}
	
	case GET_APPEAL_TYPES_LIST_ERROR: {
		return {
			...state,
			loadingTypes: false,
			loadedTypes: false,
			appealTypes: null,
			appealTypesError: action.error,
		};
	}
	
	case GET_APPEAL_PRIORITY_LIST_SUCCESS: {
		const { result } = action.result;
		return {
			...state,
			priorityList: result,
		};
	}
	
	case GET_APPEAL_PRIORITY_LIST_ERROR: {
		return {
			...state,
			priorityList: null,
			priorityListError: action.error,
		};
	}
	
	case GET_DINAMIC_FORM_PARAMS:
		return setDataToAppeal(action.id, state, {
			loadingParams: true,
			loadedParams: false,
		});
	
	case GET_DINAMIC_FORM_PARAMS_SUCCESS: {
		const { result } = action.result;
		
		return setDataToAppeal(action.id, state, {
			loadingParams: false,
			loadedParams: true,
			formParams: result,
		});
	}
	
	case GET_DINAMIC_FORM_PARAMS_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingParams: false,
			loadedParams: false,
			formParams: null,
			formParamsError: action.error,
		});
	
	case GET_APPEAL_STATUS_LIST:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: true,
			loadedStatusList: false,
		});
	
	case GET_APPEAL_STATUS_LIST_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: false,
			loadedStatusList: true,
			statusList: action.result,
		});
	
	case GET_APPEAL_STATUS_LIST_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: false,
			loadedStatusList: true,
			statusList: null,
			statusListError: action.error,
		});
	
	case GET_APPEAL_FEEDBACK:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: true,
			appealFeedback: null,
			appealFeedbackError: null,
		});
	
	case GET_APPEAL_FEEDBACK_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: false,
			appealFeedback: action.result,
			appealFeedbackError: null,
		});
	
	case GET_APPEAL_FEEDBACK_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: false,
			appealFeedback: null,
			appealFeedbackError: action.error,
		});
	
	case CLEAR_APPEAL_FEEDBACK:
		return setDataToAppeal(action.id, state, {
			appealFeedback: null,
			loadingAppealFeedback: false,
			appealFeedbackError: null,
		});
		
		
		/********** customer link  ***********/
	
	case REMOVE_CUSTOMER_FROM_APPEAL: {
		const currentAppeal = {
			...state.appeals[action.id].currentAppeal,
			customerId: 0,
			contactPersonId: 0,
		};
		
		return setDataToAppeal(action.id, state, {
			currentAppeal,
		});
	}
	
	case ADD_CUSTOMER_TO_APPEAL: {
		const currentAppeal = {
			...state.appeals[action.id].currentAppeal,
			customerId: action.customerId,
			contactPersonId: action.contactPersonId,
		};
		
		return setDataToAppeal(action.id, state, {
			currentAppeal,
		});
	}
	
	case GET_APPEAL_ACTIONS:
		return setDataToAppeal(action.id, state, {
			appealAction: action.payload || [],
		});
	
	case SET_APPEAL_ACTIONS:
		let selectedActionLabel = {};
		if (action.label) {
			selectedActionLabel = { selectedAction: action.label };
		}
		
		return setDataToAppeal(action.id, state, {
			selectedAppealAction: action.payload,
			...selectedActionLabel,
		});
	
	case SET_FIRST_LEVEL_ACTION:
		return setDataToAppeal(action.id, state, {
			firstLevelAppealAction: { key: keyGen(), ...action.payload },
			selectedAction: action.payload.label,
		});
	
	case SET_RESOLUTION_ACTION:
		return setDataToAppeal(action.id, state, {
			resolutionAppealAction: { key: keyGen(), ...action.payload },
			selectedAction: action.payload.name,
		});
	
	case REMOVE_REFRESH_APPEAL_MODAL:
		return setDataToAppeal(action.id, state, {
			refreshAppealModal: false,
		});
	
	case SET_MODAL_STATUS_LIST:
		return setDataToAppeal(action.id, state, {
			modalStatusList: action.payload,
		});
	
	case SET_STATUS_MODAL_VISIBILITY:
		return setDataToAppeal(action.id, state, {
			modalVisibility: action.payload,
		});
		
		/*******************************/
	
	case SAVE_AND_CREATE_NEW: {
		return setDataToAppeal(action.id, state, {
			saveAndCreateNew: action.payload,
		});
	}
	
	case CLEAR_APPEAL_STATE: {
		const appeals = { ...state.appeals };
		delete appeals[action.id];
		
		return {
			...state,
			appeals: {
				...appeals,
			},
		};
	}
	case CLEAR_ALL_APPEALS: {
		return {
			...state,
			appeals: {},
		};
	}
	
	default:
		return state;
	}
}

function setDataToAppeal (id, state, appealData, data = {}) {
	return {
		...state,
		...data,
		appeals: {
			...state.appeals,
			[id]: { ...state.appeals[id], ...appealData },
		},
	};
}
