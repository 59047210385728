import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Select from 'react-select';
import SelectTreeOption from './SelectTreeOption';
import { translate } from 'react-i18next';
import { findParent, findType } from 'components/Appeal/helpers';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

/**
 * clone deeply object and memoize result!
 */
const getInitialOptionsSelector = () => createSelector(
    ownProps => ownProps.initialOptions,
    initialOptions => cloneDeep(initialOptions)
);

/**
 * create factory of "mapStateToProps", because each component must have own selector with own memoized results
 */
const mapStateToPropsFactory = () => {
    const initialOptionsSelector = getInitialOptionsSelector();
    
    const mapStateToProps = (state, ownProps) => ({
        initialOptionsClone: initialOptionsSelector(ownProps)
    });
    
    return mapStateToProps;
};

@translate()
@connect(mapStateToPropsFactory)
class SelectTree extends Component {
    constructor (props) {
        super(props);
        this.state = {
            placeholderInput: props.placeholder,
            options: this.transformOptionsForSelect(props.initialOptionsClone),
            searching: false,
            searchOptions: this.flatOptions(props.initialOptionsClone),
        };
        this.searchValue = this.constructor.searchValue;
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }
	
	UNSAFE_componentWillReceiveProps (nextProps) {
        if (nextProps.initialOptionsClone !== this.props.initialOptionsClone) {
            this.setState({
                options: this.transformOptionsForSelect(nextProps.initialOptionsClone),
                searchOptions: this.flatOptions(nextProps.initialOptionsClone),
            });
        }
    }
    
    onRemoveClick () {
        this.props.removeField(this.props.position);
        this.props.unlockAppealForm();
    }
    
    flatOptions = initialOptions => {
        const searchableOptions = [...initialOptions];
        const { treeParam } = this.props;
        
        const addChildren = options => {
            options.forEach(element => {
                searchableOptions.push(element);
                element[treeParam] && addChildren(element[treeParam]);
            });
        };
        
        initialOptions.forEach(element => element[treeParam] && addChildren(element[treeParam]));
        
        return this.transformOptionsForSelect(searchableOptions);
    };
    
    selectLink = link => {
        const { initialOptionsClone, t, treeParam, valueField, textField } = this.props;
        if (link.selectedLink) {
            const selectedType = findType(valueField(link), initialOptionsClone, true, treeParam, valueField);
            const parent = findParent(link, initialOptionsClone, treeParam, valueField);
            
            const newOptions = this.transformOptionsForSelect(selectedType);
            
            if (parent) {
                const backLink = {
                    ...parent,
                    label: t('appealForm.back'),
                    name: t('appealForm.back'),
                    value: 0,
                    selectedLink: true,
                    back: true
                };
                newOptions.unshift({
                    ...parent,
                    value: valueField(parent),
                    label: parent[textField],
                    leaf: true,
                    group: false,
                    parent: true
                });
                newOptions.unshift(backLink);
            }
            this.setState({ options: newOptions });
            
        } else if (link[treeParam]) {
            const newLink = {
                ...link,
                value: valueField(link),
                label: link[textField],
                leaf: true,
                group: false,
                parent: true
            };
            const backLink = {
                ...link,
                label: t('appealForm.back'),
                name: t('appealForm.back'),
                value: 0,
                selectedLink: true,
                back: true
            };
            const newOptions = this.transformOptionsForSelect(link[treeParam]);
            newOptions.unshift(newLink);
            newOptions.unshift(backLink);
            this.setState({ options: newOptions });
        }
    };
    
    transformOptionsForSelect = (options) => {
        return options.map(option => ({
            ...option,
            label: option[this.props.textField],
            value: this.props.valueField(option)
        }));
    };
    
    arrowRender = values => {
        return values.isOpen
            ? <i className='icon-up' />
            : <i className='icon-down' />;
    };
    
    onOpen = () => {
        const select = document.querySelector('.Select-menu-outer');
        if (select.scrollIntoViewIfNeeded) {
            select.scrollIntoViewIfNeeded(false);
        } else {
            select.scrollIntoView(false);
        }
    };
    
    onSearch = selectedOption => this.setState({ options: this.getOptionsBySelectedOption(selectedOption) });
    
    getOptionsBySelectedOption = selectedOption => {
        const { t, initialOptionsClone, treeParam, valueField, textField } = this.props;
        const parent = findParent(selectedOption, initialOptionsClone, treeParam, valueField);
        if (parent) {
            const newLink = {
                ...parent,
                value: valueField(parent),
                label: parent[textField],
                leaf: true,
                group: false,
                parent: true
            };
            const backLink = {
                ...parent,
                label: t('appealForm.back'),
                name: t('appealForm.back'),
                value: 0,
                selectedLink: true,
                back: true
            };
            const newOptions = this.transformOptionsForSelect(parent[treeParam]);
            
            newOptions.unshift(newLink);
            newOptions.unshift(backLink);
            return newOptions;
        } else {
            return this.transformOptionsForSelect(initialOptionsClone);
        }
    };
    
    onFocus = () => this.setState({ placeholderInput: this.props.t('Search') });
    
    onBlur = () => this.setState({ options: this.getOptionsBySelectedOption(this.props.input.value) });
    
    onChange = (item) => {
        const value = _.get(item, 'value');
        this.props.input.onChange(value || null);
        this.props.unlockAppealForm();
        this.props.resetActions({ change: value, position: this.props.position });
    };
    
    onInputChange = inputValue => {
        if (inputValue && !this.state.searching) {
            this.setState({ searching: true });
        }
        if (!inputValue && this.state.searching) {
            this.setState({ searching: false });
        }
        return inputValue;
    };
    
    renderOption = (props) => (
        <SelectTreeOption
            selectLink={this.selectLink}
            parentNode={this.Select}
            {...props}
            searching={this.state.searching}
            onSearch={this.onSearch}
            partyType={props.option.object && props.option.object.partyType}
        />
    );
    
    static searchValue (id, options) {
        let queue = [...options];
        let result;
        let condition = true;
        while (condition) {
            const item = queue[0];
            if (item && item.object.id === id) {
                result = item;
                condition = false;
            }
            if (item && item.result) {
                queue.push(...item.result);
            }
            queue.shift();
            condition = condition === false ? false : queue.length;
        }
        return result;
    }
    
    render () {
        const { options, searching, searchOptions } = this.state;
        const {
            t, label, input, meta, treeParam, required,
            noResultsText = this.props.t('noResultsFound'),
            className, position, ...restProps
        } = this.props;
        const defaultOption = {
            id: 0, label: t('dontSelect'), name: t('dontSelect'), value: 0, placeholder: true
        };
        defaultOption[treeParam] = null;
        let value = input.value;
        const selectOptions = options && [defaultOption, ...options];
        if (typeof this.props.input.value === 'number' && this.props.initialOptionsClone.length > 0) {
            const selectFromTree = this.searchValue(this.props.input.value, this.props.initialOptionsClone);
            if (selectFromTree) {
                value = { ...selectFromTree, label: selectFromTree.text, value: selectFromTree.object.id };
            }
        }
        
        const error = typeof meta.error === 'string' ? meta.error : undefined;
        
        return (
            <div className='input-element'>
                <div className='input-label'>
                    {label}{required && <span className='required-field'>*</span>}
                    {
                        position > 0 && (
                            <span>
                                <button onClick={this.onRemoveClick} className='btn-danger text' type='button'>
                                    {t('remove')}
                                </button>
                            </span>
                        )
                    }
                </div>
                <Select
                    options={searching ? searchOptions : selectOptions}
                    {...restProps}
                    className={cx('container-comboBox', 'select-tree', className, error && 'input-field__error')}
                    searchable={true}
                    clearable={false}
                    arrowRenderer={this.arrowRender}
                    placeholder={this.state.placeholderInput}
                    optionComponent={this.renderOption}
                    {...input}
                    {...meta}
                    onChange={this.onChange}
                    value={value}
                    required={required}
                    onOpen={this.onOpen}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    ref={node => { this.Select = node; }}
                    onInputChange={this.onInputChange}
                    noResultsText={noResultsText}
                />
                {
					error ? <span className='error-text'>{error}</span> : null
                }
            </div>
        );
    }
}

SelectTree.defaultProps = {
    treeParam: 'children',
    textField: 'name',
    valueField: (v) => v.id,
};

SelectTree.propTypes = {
    initialOptions: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    selectLink: PropTypes.func,
    required: PropTypes.bool,
    treeParam: PropTypes.string,
    textField: PropTypes.string,
    valueField: PropTypes.func,
    removable: PropTypes.bool,
    position: PropTypes.number,
};

export default SelectTree;

