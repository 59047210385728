import React from 'react';
import cx from 'classnames';
import modalStyles from 'styles/modules/modal.module.scss';
import styles from 'styles/modules/customerContent.module.scss';
import { reduxFormWrapper } from 'helpers';
import baseService from 'services/BaseService';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Field } from 'redux-form';
import ComboBox from 'components/Common/ComboBox';
import DatePicker from 'components/Common/DatePicker';

@translate()
@withRouter
@reduxFormWrapper({ form: 'add-customer-service' })
class CustomerServiceForm extends React.Component {

  constructor(){
    super();

    this.state = {
      isEditing: false,
      serviceTypes: []
    };
  }

  componentDidMount(){
    this.requestServiceTypes();
  }

  requestServiceTypes = () => {
    const params = {
      data: {
        customerId: this.props.match.params.id,
        page: 1,
        start: 0,
        limit: 25
      }
    };
    baseService.get('customer_services_types', params)
      .then(response => {
        response.success && this.setState({ serviceTypes: response.result })
      });
  };

  requestDynamicParams = ({ id, name, typeName }) => {
    const params = {
      data: {
        ctxObjectType: 'customer',
        ctxObjectId: this.props.match.params.id,
        objectType: 'task',
        objectTypeId: id,
        objectTypeCode: typeName,
        // objectId: this.props.match.params.id,
        openMode: 'update',
        curStepNum: 1,
        overrideIsReq: ''
      }
    };
    baseService.get('appeal_form_params', params)
      .then(response => {
        console.log('RESPONSE: ', response);
      })
  };

  onTypeChange = (event, newValue, previousValue, fieldName) => {
    if (newValue) {
      // this.requestDynamicParams(newValue);
    }
  };

  getFieldPropsWithOptions = fieldProps => {
    switch (fieldProps.name) {
      case 'type': return {
        ...fieldProps,
        options: this.state.serviceTypes,
        onChange: this.onTypeChange
      };
    }

    return fieldProps;
  };

    renderInputField = fieldProps => {
    return ( <Field key={fieldProps.name}{...this.getFieldPropsWithOptions(fieldProps)} /> );
  };

  onSubmit = values => {
    console.log('VALUES: ', values);
  };

  render(){
    const { isEditing } = this.state;
    const { t, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={modalStyles.modalHeader}>
          <div className={modalStyles.modalTitle}>Нова послуга</div>
        </div>

        <form className={cx(modalStyles.modalContent, styles.customerServiceForm)} onSubmit={handleSubmit(this.onSubmit)}>

          <Field
            name={'type'}
            label={'Послуги'}
            component={ComboBox}
            valueKey={'id'}
            labelKey={'name'}
            options={this.state.serviceTypes}
            onChange={this.onTypeChange}
            required
          />

          <div className={styles.threeInputRow}>
            <Field
              name={'status'}
              label={'Статус'}
              component={ComboBox}
              options={[{value: 3, label: 'Активний'}, {value: 5, label: 'Відключений'}]}
              required
            />
            <Field
              name='dateFrom'
              component={DatePicker}
              label={'Дата з'}
              required
            />
            <Field
              name='dateTo'
              component={DatePicker}
              label={'Дата по'}
              required
            />
          </div>

          <button className={'btn btn-primary'}>
            <i className='icon icon-check' />
            {isEditing ? t('save') : t('create')}
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default CustomerServiceForm;
