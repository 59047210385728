import {
    SET_CURRENT_TAB,
    CONTENT_SET,
    CONTENT_LOADING,
    CONTENT_ERROR,
    USER_INIT,
    ENABLE_TAB_CACHING,
    DISABLE_TAB_CACHING,
    UPDATE_CURRENT_TAB_URL
} from 'constants/actions';

import commonService from 'services/CommonService';
import baseService from 'services/BaseService';
import settingsService, { SORT_POSTFIX } from 'services/settingsService';

export const initUser = data => ({
    type: USER_INIT,
    info: data
});

export const getUserInfo = () => dispatch => {
    baseService.get('user_info')
        .then(response => {
            if (response.success && response.result) {
                dispatch(initUser({
                    ...response.result.user,
                    user_photo: response.result.avatar,
                    userPhones: response.result.userPhones,
                    operations: response.result.operations || []
                }));
            }
        });
};

export const setUserInfo = data => {
    const params = {
        path: 'lang',
        data: { lang: data.lang_default }
    };
    
    baseService.post('user_settings', params);
    
    return { type: USER_INIT, info: data };
};

export const enableTabCaching = () => ({
    type: ENABLE_TAB_CACHING
});

export const disableTabCaching = () => ({
    type: DISABLE_TAB_CACHING
});

export function setCurrentTab (data) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_TAB,
            ...data
        });
    }
}

export const updateCurrentTabUrl = currentUrl => ({
    type: UPDATE_CURRENT_TAB_URL,
    currentUrl
});

const contentLoading = (storeName) => ({
    type: CONTENT_LOADING,
    storeName
});

const contentError = (data) => ({
    type: CONTENT_ERROR,
    ...data
});

export const initProxy = (api, options, url) => (dispatch) => {
    if (!api.key) return null;
    
    const isPost = api.method && api.method.toLowerCase() === 'post';
    const storeName = options.storeName(options.params);
    const queryKey = options.queryKey && options.queryKey(options.params);
    
    // Load sort settings.
    const cachedSortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
    const apiData = { ...api };
    if (apiData.data.limit) {
        apiData.data = { ...apiData.data, page: apiData.data.page || 1, start: apiData.data.start || 0 };
    }
    
    if (cachedSortSettings) {
        apiData.data = { ...apiData.data, sort: cachedSortSettings };
    }
    
    dispatch(contentLoading());
    commonService.initProxy(api.key, apiData, isPost)
        .then(data => {
            const dispatchData = { type: CONTENT_SET, url, api: apiData };
            // debugger
            dispatchData.query = {
                ...apiData.data,
                total: data && data.total
            };
            
            // this hack need to save filter state after CONTENT_UPDATE
            if (dispatchData.query.filter && dispatchData.query.filter.length) {
                delete dispatchData.query.filter;
            }
            
            // mainTab name (tabs reducer)
            if (options.name) {
                dispatchData.name = options.name({ ...(data && data.result), ...options.params, api });
            }
            
            // mainTab type (unique tab) (tabs reducer)
            if (options.tabType) {
                dispatchData.tabType = options.tabType(options.params);
            }
            
            // key for determination of request data
            if (queryKey) {
                dispatchData.queryKey = queryKey;
            }
            
            dispatchData.storeName = storeName;
            if (data) {
                data.tabName = storeName;
            }
            dispatchData.data = data;
            
            // debugger;
            dispatch(dispatchData);
        })
        .catch(error => {
            // If aborted.
            if (error.name === 'AbortError') {
                return;
            }
            
            // If wrong.
            dispatch(contentError({ error, storeName }));
            console.error(`initProxy: ${error.message}`);
        });
};
