import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { extractAppealFromState } from '../../../../helpers';

function mapStateToProps (state, props) {
    const [appeal] = extractAppealFromState(state, props);
    
    return {
        appeal: appeal.currentAppeal,
    };
}

@withRouter
@translate()
@connect(mapStateToProps)
class HeaderAppealInfo extends PureComponent {

    onUserClick = event => {
        event.preventDefault();
        // alert('Checked user');
    };

    renderLink = () => {
        const { appeal, t } = this.props;
        let label;

        switch (appeal.childType) {
        case 'DOUBLET': {
            label = t('appeal.appealDoubletFor');
            break;
        }

        case 'REPET': {
            label = t('appeal.appealRepeatFor');
            break;
        }
        }

        if (!label) return null;

        return (
            <span> (
                {label}
                <Link to={`/appeals/${appeal.rootRequestId}/knowledge_base`} className={styles.appealLink}>
                    {appeal.rootRequestRegnum}
                </Link>
                )
            </span>
        );
    };

    render() {
        const { appeal } = this.props;

        const creationDate = moment(appeal.validFrom).format('DD.MM.YYYY HH:mm');

        return (
            <div className={styles.headerAppealInfoBlock}>
                <div className={styles.headerAppealInfoStatusRow}>
                    <span>Створено&nbsp;</span>
                    {this.renderLink()}
                </div>
                <div className={styles.headerAppealInfoDataRow}>
                    <span>{creationDate} / <span className={styles.operatorName}>{appeal.operatorName}</span></span>
                </div>
            </div>
        );
    };
}

HeaderAppealInfo.propTypes = {
    appeal: PropTypes.object
};

export default HeaderAppealInfo;

