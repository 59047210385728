import React from 'react';

export default function Cancel({ onClick }) {
    return (
        <svg onClick={onClick} className='cancel-edit-contact-input' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10.06'>
            <defs>
                <style>{'.cls-1{fill:#1c1000;}'}</style>
            </defs>
            <title>Скасувати</title>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_90' data-name='Layer 90'>
                    <path className='cls-1'
                          d='M.25.45A.39.39,0,0,1,.71.54l.85.85a5.12,5.12,0,0,1,1.59-1A4.88,4.88,0,0,1,5,0,4.78,4.78,0,0,1,6.94.4a5.1,5.1,0,0,1,1.6,1.07A5.12,5.12,0,0,1,9.6,3.08,4.83,4.83,0,0,1,10,5a4.83,4.83,0,0,1-.4,2,5.08,5.08,0,0,1-1.06,1.6,5.13,5.13,0,0,1-1.6,1.08,5,5,0,0,1-1.94.4,4.88,4.88,0,0,1-2.13-.48A5,5,0,0,1,1.15,8.24a.24.24,0,0,1,0-.14A.23.23,0,0,1,1.17,8l.89-.9A.21.21,0,0,1,2.22,7a.19.19,0,0,1,.15.08A3.39,3.39,0,0,0,3.54,8a3.26,3.26,0,0,0,2.75.07A3.28,3.28,0,0,0,7.36,7.4a3.45,3.45,0,0,0,.71-1.07A3.25,3.25,0,0,0,8.33,5a3.18,3.18,0,0,0-.26-1.3,3.45,3.45,0,0,0-.71-1.07,3.47,3.47,0,0,0-1.07-.72A3.17,3.17,0,0,0,5,1.68a3.25,3.25,0,0,0-2.27.89l.9.91a.38.38,0,0,1,.09.45.4.4,0,0,1-.39.26H.42a.42.42,0,0,1-.3-.12A.44.44,0,0,1,0,3.77V.84A.39.39,0,0,1,.25.45Z' />
                </g>
            </g>
        </svg>
    );
}