import React from 'react';
import styles from 'styles/modules/appealOperatePage.module.scss';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { CustomerTypes } from 'constants/index';
import get from 'lodash/get';
import cx from 'classnames';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import { isOrganization, createCheckPropsFunction } from 'helpers';
import shallowEqual from 'util/shallowEqual';
import { connect } from 'react-redux';
import { customerEdit, personEdit } from 'actions/customer';

const mapDispatchToProps = {
    customerEdit,
    personEdit,
};

@translate()
@connect(null, mapDispatchToProps)
class CustomerNameField extends React.PureComponent {

    constructor (props) {
        super(props);

        this.state = {
            isEditing: false,
            customerNameObject: this.getCustomerNameState(props.customer)
        };

        this.firstInputRef = React.createRef();
        this.wait = false;
        this.startEditing = this.startEditing.bind(this);
    }

    componentDidUpdate (prevProps, prevState) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if (!prevState.isEditing && this.state.isEditing) {
            this.firstInputRef.current.focus();
        }

        if (isPropChanged('customer.party')) {
            this.wait = false;
            this.setState({
                customerNameObject: this.getCustomerNameState(this.props.customer)
            });
        }
    }

    getCustomerNameState = customer => {
        return {
            lastName: get(customer, 'party.lastName', ''),
            firstName: get(customer, 'party.firstName', ''),
            patronymic: get(customer, 'party.patronymic', '')
        };
    };

    startEditing () {
        const isEditingForbidden = this.state.isEditing || this.props.disabled;
        if (!isEditingForbidden && !this.props.isEditRestricted) this.setState({isEditing: true});
    };

    finishEditing = () => {
        if (this.state.isEditing) {
            if (!shallowEqual(this.state.customerNameObject, this.getCustomerNameState(this.props.customer))) {
                this.saveCustomerName();
            }
            this.setState({isEditing: false});
        }
    };

    saveCustomerName = () => {
        if (this.wait) return;
        this.wait = true;

        const {lastName, firstName, patronymic} = this.state.customerNameObject;
        const requestData = {
            edit: [
                {text: lastName, field: 'party.lastName'},
                {text: firstName, field: 'party.firstName'},
                {text: patronymic, field: 'party.patronymic'}
            ]
        };

        if (this.props.isContactPerson) {
            requestData.personId = this.props.customer.id;
            this.props.personEdit({ data: requestData, id: this.props.customerId, isMulti: true });
        } else {
            requestData.customerId = this.props.customer.id;
            this.props.customerEdit({ data: requestData, id: this.props.customerId, isMulti: true });
        }
        this.props.unlockAppealForm(this.props.appealId);
    };

    getLabel = () => {
        const {isContactPerson, customer, t} = this.props;
        let translateField = isContactPerson ? 'contactPerson' : customer.party.partyType;
        return t(`customerInfoLabel.${translateField}`);
    };

    getCustomerName = customer => {
        if (!customer) return '';

        const names = {
            [CustomerTypes.NATURAL_PERSON]: get(customer, 'party.fullName', ''),
            [CustomerTypes.ORGANIZATION]: get(customer, 'party.officialName', '')
        };
        return names[customer.party.partyType] || '';
    };

    onCustomerChange = event => {
        const updatedName = {[event.target.name]: event.target.value};

        this.setState(state => ({
            customerNameObject: {
                ...state.customerNameObject,
                ...updatedName
            }
        }));
    };

    render () {
        const {isEditing, customerNameObject} = this.state;
        const {customer, t} = this.props;
        const formClassName = cx(styles.customerNameForm, {
            [styles.focused]: isEditing
        });

        const nameClassName = cx(styles.customerName, {
            /**  onClick is disabled for organization  **/
            [styles.disabled]: isOrganization(customer.party.partyType)
        });

        const customerName = this.getCustomerName(customer);
        return (
            <div className={styles.customerNameFormContainer}>
                <label>
                    {this.getLabel()}
                </label>
                {
                    !isEditing &&
                        <span
                            className={nameClassName}
                            onClick={this.startEditing}
                            title={customerName}
                        >
                            {customerName.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase())}
                        </span>
                }
                {
                    isEditing &&
                    <ClickOutsideHolder className={formClassName} onClickOutside={this.finishEditing}>
                        <input
                            name='lastName'
                            type='text'
							maxlength="50"
                            ref={this.firstInputRef}
                            onChange={this.onCustomerChange}
                            value={customerNameObject.lastName}
                            placeholder={t('customerInfoLabel.lastName')}
                        />
                        <input
                            name='firstName'
                            type='text'
							maxlength="50"
                            onChange={this.onCustomerChange}
                            value={customerNameObject.firstName}
                            placeholder={t('customerInfoLabel.firstName')}
                        />
                        <input
                            name='patronymic'
                            type='text'
							maxlength="50"
                            onChange={this.onCustomerChange}
                            value={customerNameObject.patronymic}
                            placeholder={t('customerInfoLabel.patronymic')}
                        />
                    </ClickOutsideHolder>
                }
            </div>
        );
    }
}

CustomerNameField.propTypes = {
    isContactPerson: PropTypes.bool,
    customer: PropTypes.object,
    disabled: PropTypes.bool
};

export default CustomerNameField;

