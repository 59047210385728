import {
    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_REQUEST_SUCCESS,
    SEARCH_RESULTS_REQUEST_FAILURE,
    SEARCH_QUERY_UPDATE,
    SEARCH_RESULTS_POPUP_OPEN,
    SEARCH_RESULTS_POPUP_CLOSE,
    SEARCH_TAB_HASH_UPDATE,
    SEARCH_PAGINATION_UPDATE,
} from 'constants/actions';

const initialState = {
    tabHash: '0',
    query: '',
    
    results: {},
    isResultsLoading: false,
    total: 0,
    pagination: {
        start: 0,
        limit: 20,
        page: 1,
    },
    
    isResultsPopupOpen: false,
};

function searchReducer (state = initialState, action) {
    
    switch (action.type) {
    
    case SEARCH_RESULTS_REQUEST:
        return { ...state, results: { ...state.results, [action.entityType]: [] }, isResultsLoading: true };
    
    case SEARCH_RESULTS_REQUEST_SUCCESS:
        return {
            ...state, isResultsLoading: false,
            results: { ...state.results, [action.entityType]: action.results },
            total: action.total,
        };
    
    case SEARCH_RESULTS_REQUEST_FAILURE:
        return { ...state, isResultsLoading: false, results: {}, total: 0 };
    
    case SEARCH_QUERY_UPDATE:
        return { ...state, query: action.query };
    
    case SEARCH_RESULTS_POPUP_OPEN:
        return { ...state, isResultsPopupOpen: true };
    
    case SEARCH_RESULTS_POPUP_CLOSE:
        return { ...state, isResultsPopupOpen: false };
    
    case SEARCH_TAB_HASH_UPDATE:
        return { ...state, tabHash: action.tabHash };
    
    case SEARCH_PAGINATION_UPDATE:
        return { ...state, pagination: { ...state.pagination, ...action.pagination } };
    
    default:
        return state;
    }
}

export default searchReducer;
