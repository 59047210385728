import React from 'react';
import PropTypes from 'prop-types';
import { CustomerTypes, customerTypeOptions, StreetModes } from 'constants/index';
import styles from 'styles/modules/createNewCustomer.module.scss';
import { translate } from 'react-i18next';
import ComboBox from 'components/Common/ComboBox';
import PersonForm from './PersonForm/index';
import { isOrganization } from 'helpers';
import OrganizationForm from './OrganizationForm/index';
import AddressForm from './AddressForm/index';
import { getFormSyncErrors, submit, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import baseService from 'services/BaseService';
import { withRouter, matchPath } from 'react-router-dom';
import { closeCreateNewCustomerModal } from 'actions/ui';
import { getCustomer, getContactPerson } from 'actions/customer';
import { unlockAppealForm, addCustomerToAppeal, getAppealFeedback } from 'actions/appeal';
import Loader from 'components/Loader/index';

function mapStateToProps (state) {
    const match = matchPath(window.location.hash.substring(1), {
        path: '/appeals/:appealId/:tab',
        exact: true,
        strict: false
    });
    
    return {
        personErrors: getFormSyncErrors('person-form')(state),
        organizationErrors: getFormSyncErrors('organization-form')(state),
        physicalAddressErrors: getFormSyncErrors('physical-address-form')(state),
        juridicalAddressErrors: getFormSyncErrors('juridical-address-form')(state),
        personValues: getFormValues('person-form')(state),
        organizationValues: getFormValues('organization-form')(state),
        physicalAddressValues: getFormValues('physical-address-form')(state),
        juridicalAddressValues: getFormValues('juridical-address-form')(state),
        match,
        newCustomerModalContext: state.ui.newCustomerModalContext,
    };
}

const mapDispatchToProps = {
    submitForm: form => submit(form),
    onClose: closeCreateNewCustomerModal,
    getCustomer,
    getContactPerson,
    unlockAppealForm,
    addCustomerToAppeal,
    getAppealFeedback,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CustomerFormManager extends React.Component {
    static createAddressObject(value, type = 'physical') {
        return {
            'parentId': _.get(value, 'settlement.id'),
            'parentType': 'location',
            'streetId': _.get(value, 'street.value'),
            'name': _.get(value, 'street.label') || value.streetName,
            'streetTypeId': _.get(value, 'street.type') ||  _.get(value, 'streetType.value'),
            'addressType': type,
            'buildingNumber': _.get(value, 'buildingNumber.label') || value.buildingNumberInput,
            'apartmentNumber': value.apartmentNumber,
            'description': value.description,
            'zipCode': _.get(value, 'zipCode.label') || value.zipCodeInput,
            'buildingSecondNumber': value.buildingSecondNumber,
        };
    }
    
    constructor() {
        super();

        this.state = {
            customerType: CustomerTypes.NATURAL_PERSON,
            isLoading: false
        };
        this.createAddressObject = this.constructor.createAddressObject;
        this.wait = false;
    }

    handleChangeCustomerType = option => this.setState({ customerType: option.value });

    isValuesCorrect = () => {
        const { personErrors, organizationErrors, physicalAddressErrors, juridicalAddressErrors } = this.props;

        let errors = [personErrors, physicalAddressErrors];

        if (isOrganization(this.state.customerType)) {
            errors = [...errors, organizationErrors, juridicalAddressErrors];
        }

        for (const errorObject of errors) {
            if (isEmpty(errorObject)) continue;

            if (!Object.values(errorObject).every(isEmpty)) return false;
        }

        return true;
    };

    startLoading = () => {
        this.wait = true;
        this.setState({ isLoading: true });
    };

    finishLoading = () => {
        this.wait = false;
        this.setState({ isLoading: false });
    };

    onOrganizationSubmit = () => {
        const {
            submitForm, physicalAddressValues, personValues, juridicalAddressValues, organizationValues,
            match, newCustomerModalContext,
        } = this.props;
		const appealId = _.get(match, 'params.appealId');

        /** To highlight errors **/
        submitForm('person-form');
        submitForm('organization-form');
        submitForm('physical-address-form');
        submitForm('juridical-address-form');

        if (!this.isValuesCorrect()) return;

        this.startLoading();
        const contactsType = ['workPhone'];
        const contactsValue = [personValues.phone];
        if (personValues.email) {
            contactsType.push('email');
            contactsValue.push(personValues.email);
        }
        
        
        const params = {
            data: {
                party: {
                    shortName: organizationValues.shortName,
                    businessType: _.get(organizationValues, 'businessType.value'),
                    ownershipType: _.get(organizationValues, 'ownershipType.value'),
                    institutionId: organizationValues.institutionId,
                    taxid: organizationValues.taxid,
                },
                contactPerson: {
                    lastName: personValues.lastName,
                    firstName: personValues.firstName,
                    patronymic: personValues.patronymic,
                    contactsType,
                    contactsValue,
                    issues: personValues.issues,
                },
                address: [
                    this.createAddressObject(physicalAddressValues),
                    this.createAddressObject(juridicalAddressValues, 'juridical'),
                ]
            },
            jsonType: true,
        };
    
        return baseService.post('create_organization', params)
            .then(response => {
                if (response.success && response.result) {
                    const { customerId, personId } = response.result;
                    this.finishLoading();
                    this.props.onClose();
                    
                    if (_.get(newCustomerModalContext, 'openNewTab') === false) {
                        this.props.addCustomerToAppeal(appealId, customerId, personId);
                        this.props.getCustomer({ customerId: customerId }, customerId);
                        this.props.getContactPerson({ id: personId }, customerId);
                        this.props.getAppealFeedback({ appealId, customerId });
                        this.props.unlockAppealForm(appealId);
                    }
                    
                    if (_.get(newCustomerModalContext, 'openNewTab') === true) {
                        this.props.history.push(`/customer/${response.result.customerId}/appeals`);
                    }
                    
                    return response.result;
                } else {
                    this.finishLoading();
                    throw new Error('Natural person creation error!');
                }
            });
    };


    onNaturalPersonSubmit = () => {
        const { submitForm, personValues, physicalAddressValues,  match, newCustomerModalContext } = this.props;
        const appealId = _.get(match, 'params.appealId');
        submitForm('person-form');
        submitForm('physical-address-form');

        if (!this.isValuesCorrect()) return;

        this.startLoading();
    
        const contactsType = ['homePhone'];
        const contactsValue = [personValues.phone];
        if (personValues.email) {
            contactsType.push('email');
            contactsValue.push(personValues.email);
        }

        const params = {
            data: {
                party: {
                    lastName: personValues.lastName,
                    firstName: personValues.firstName,
                    patronymic: personValues.patronymic,
                    contactsType,
                    contactsValue,
                },
                address: [
                    this.createAddressObject(physicalAddressValues),
                ]
            },
            jsonType: true,
        };

        return baseService.post('create_individual_customer', params)
            .then(response => {
                if (response.success && response.result) {
                    const { customerId } = response.result;
                    this.finishLoading();
                    this.props.onClose();
                    
                    if (_.get(newCustomerModalContext, 'openNewTab') === false) {
                        this.props.addCustomerToAppeal(appealId, customerId, 0);
                        this.props.getCustomer({ customerId: customerId }, customerId);
                        this.props.getAppealFeedback({ appealId, customerId });
                        this.props.unlockAppealForm(appealId);
                    }
                    
                    if (_.get(newCustomerModalContext, 'openNewTab') === true) {
                        this.props.history.push(`/customer/${response.result.customerId}/appeals`);
                    }
                    
                    return response.result;
                } else {
                    this.finishLoading();
                    throw new Error('Natural person creation error!');
                }
            });
    };

    onSubmit = () => {
        if (this.wait) return;

        switch (this.state.customerType) {
        case CustomerTypes.NATURAL_PERSON:
            return this.onNaturalPersonSubmit();
        case CustomerTypes.ORGANIZATION:
            return this.onOrganizationSubmit();
        }
    };

    emptySubmit = () => {
    };

    render() {
        const { t, enableWarning } = this.props;
        const { customerType, isLoading } = this.state;
        
        return (
            <div>
                {isLoading && <Loader/>}

                <header className={styles.createCustomerHeader}>
                    <div className={styles.createCustomerTitle}>Новий заявник</div>
                </header>

                <div className={styles.customerTypeZone}>
                    <ComboBox
                        options={customerTypeOptions}
                        label={'Тип заявника'}
                        value={customerType}
                        onChange={this.handleChangeCustomerType}
                    />
                </div>

                <div className={styles.formContainer}>
                    <PersonForm customerType={customerType} onSubmit={this.emptySubmit} enableWarning={enableWarning} />
                    {
                        isOrganization(customerType) &&
                        <OrganizationForm customerType={customerType} onSubmit={this.emptySubmit} enableWarning={enableWarning} />
                    }
                    <AddressForm
                        customerType={customerType}
                        onSubmit={this.emptySubmit}
                        form={'physical-address-form'}
                        addressType='physical'
                        enableWarning={enableWarning}
                        initialValues={{ mode: StreetModes.AUTO }}
                    />
                    {
                        isOrganization(customerType) &&
                        <AddressForm
                            customerType={customerType}
                            onSubmit={this.emptySubmit}
                            form='juridical-address-form'
                            addressType='juridical'
                            enableWarning={enableWarning}
                            initialValues={{ mode: StreetModes.AUTO }}
                        />
                    }
                </div>

                <footer className={styles.createCustomerFooter}>
                    <button className='btn btn-primary' onClick={this.onSubmit}>
                        <i className='icon icon-check'/>
                        {t('Створити')}
                    </button>
                </footer>

            </div>
        );
    }
}

CustomerFormManager.propTypes = {
    isAppealPage: PropTypes.bool
};

export default CustomerFormManager;
