import {
    ADD_COMMENT,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_ERROR,
    UPDATE_COMMENT,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_ERROR,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_ERROR,
    SET_EDITABLE_COMMENT,
    UPLOAD_FILE_TO_COMMENT,
    UPLOAD_FILE_TO_COMMENT_SUCCESS,
    UPLOAD_FILE_TO_COMMENT_ERROR,
    DELETE_FILE_FROM_COMMENTS,
    DELETE_FILE_FROM_COMMENTS_SUCCESS,
    DELETE_FILE_FROM_COMMENTS_ERROR,
    COMMENTS_REQUEST,
    COMMENTS_REQUEST_SUCCESS,
    COMMENTS_REQUEST_FAILURE,
    APPEAL_COMMENTS_RESET,
} from 'constants/actions';
import baseService from '../services/BaseService';

/** ******  Appeal comments request  ******* */

const requestComments = appealId => ({
    type: COMMENTS_REQUEST,
    appealId,
});

const requestCommentsSuccess = comments => ({
    type: COMMENTS_REQUEST_SUCCESS,
    comments,
});

const requestCommentsFailure = () => ({
    type: COMMENTS_REQUEST_FAILURE,
});

export const getComments = (requestData, appealId) => (dispatch) => {
    dispatch(requestComments(appealId));

    const params = { data: requestData };

    return baseService.get('comments', params)
        .then((response) => {
            if (response.success && response.result) {
                dispatch(requestCommentsSuccess(response.result));
            } else {
                dispatch(requestCommentsFailure());
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(requestCommentsFailure());
        });
};

/** ******  Appeal comments reset  ******* */

export const resetComments = () => ({
    type: APPEAL_COMMENTS_RESET,
});

/** ******************************* */

const addCommentSuccess = result => ({
    type: ADD_COMMENT_SUCCESS,
});

const addCommentError = error => ({
    type: ADD_COMMENT_ERROR,
    error,
});

const fireAddComment = () => ({
    type: ADD_COMMENT,
});

export const addComment = ({ text, appealId, attachment }, callback) => (dispatch) => {
    const params = {
        data: {
            text,
            appealId,
            attachment,
        },
        jsonType: true,
    };

    dispatch(fireAddComment());

    baseService.post('comment_add', params)
        .then((data) => {
            dispatch(addCommentSuccess(data));
            if (callback) callback();
        })
        .catch(err => dispatch(addCommentError(err)));
};

const updateCommentSuccess = result => ({
    type: UPDATE_COMMENT_SUCCESS,
});

const updateCommentError = error => ({
    type: UPDATE_COMMENT_ERROR,
    error,
});

const fireUpdateComment = () => ({
    type: UPDATE_COMMENT,
});

export const updateComment = ({ id, text, attachmen }, callback) => (dispatch) => {
    const params = {
        data: {
            text,
            id,
            attachmen,
        },
        jsonType: true,
    };

    dispatch(fireUpdateComment());

    baseService.post('comment_update', params)
        .then((data) => {
            dispatch(updateCommentSuccess(data));
            if (callback) callback();
        })
        .catch(err => dispatch(updateCommentError(err)));
};


const uploadFileSuccess = (id, name) => ({
    type: UPLOAD_FILE_SUCCESS,
    id,
    name,
});

const uploadFileError = error => ({
    type: UPLOAD_FILE_ERROR,
    error: error.message,
});


const fireUploadFile = () => ({
    type: UPLOAD_FILE,
});

export const uploadFile = (file, appealId) => (dispatch) => {
    const params = {
        data: {
            data: {
                appealId,
                fileName: file.name,
            },
            file,
        },
        file: true,
    };

    dispatch(fireUploadFile());

    baseService.post('upload_file', params)
        .then(data => dispatch(uploadFileSuccess(data.result, file.name)))
        .catch(err => dispatch(uploadFileError(err)));
};

export const uploadFileToCommentSuccess = (id, name) => ({
    type: UPLOAD_FILE_TO_COMMENT_SUCCESS,
    id,
    name,
});


export const uploadFileToCommentError = error => ({
    type: UPLOAD_FILE_TO_COMMENT_ERROR,
    error,
});

const fireUploadFileToComment = () => ({
    type: UPLOAD_FILE_TO_COMMENT,
});

export const uploadFileToComment = (file, appealId, commentId) => (dispatch) => {
    const uploadParams = {
        data: {
            data: {
                appealId,
                fileName: file.name,
            },
            file,
        },
        file: true,
    };

    dispatch(fireUploadFileToComment());

    baseService.post('upload_file', uploadParams)
        .then((data) => {
            const fileId = data.result;
            const params = {
                data: {
                    id: commentId,
                    fileId,
                },
                jsonType: true,
            };

            baseService.post('comment_attach', params)
                .then(() => dispatch(uploadFileToCommentSuccess(fileId, file.name)))
                .catch(err => dispatch(uploadFileToCommentError(err)));
        })
        .catch(err => dispatch(uploadFileToCommentError(err)));
};

export const deleteFileFromCommentSuccess = (id, name) => ({
    type: DELETE_FILE_FROM_COMMENTS_SUCCESS,
    id,
});

export const deleteFileFromCommentError = error => ({
    type: DELETE_FILE_FROM_COMMENTS_ERROR,
    error,
});

const fireDeleteFileFromComment = () => ({
    type: DELETE_FILE_FROM_COMMENTS,
});

export const deleteFileFromComment = (fileId, editableCommentId) => (dispatch) => {
    const detachParams = {
        data: {
            id: editableCommentId,
            fileId,
        },
        jsonType: true,
    };

    dispatch(fireDeleteFileFromComment());

    baseService.post('comment_detach', detachParams)
        .then(() => {
            dispatch(deleteFileFromCommentSuccess(fileId));
        })
        .catch(err => dispatch(deleteFileFromCommentError(err)));
};

export const deleteFileSuccess = response => ({
    type: DELETE_FILE_SUCCESS,
    id: response,
});

export const deleteFileError = error => ({
    type: DELETE_FILE_ERROR,
    error,
});

const fireDeleteFile = () => ({
    type: DELETE_FILE,
});

export const deleteFile = fileId => (dispatch) => {
    const deleteParams = {
        data: {
            fileId,
        },
    };

    dispatch(fireDeleteFile());

    baseService.post('file_delete', deleteParams)
        .then(data => dispatch(deleteFileSuccess(data.result)))
        .catch(err => dispatch(deleteFileError(err)));
};

export const setEditableComment = editableComment => ({
    type: SET_EDITABLE_COMMENT,
    editableCommentId: editableComment ? editableComment.id : null,
    attachment: editableComment ? editableComment.attachments : [],
});
