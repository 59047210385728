import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'styles/modules/modal.module.scss';
import { KeyCodes } from 'constants/index';
import cx from 'classnames';

class ModalWindow extends Component {
	
	constructor (props) {
		super(props);
		this.state = {
			isOpen: true,
			isMinimized: false
		};
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.toggleHandleMinModal = this.toggleHandleMinModal.bind(this);
		this.handleModalClick = this.handleModalClick.bind(this);
	}
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		!nextProps.isOpen && this.setState({ isOpen: true });
	}
	
	handleCloseModal = (e) => {
		if (this.props.onClose || (e.keyCode === KeyCodes.ESCAPE)) {
			return this.props.onClose();
		}
		
		this.setState({ isOpen: false });
	};
	
	toggleHandleMinModal () {
		this.setState(prevState => ({ isMinimized: !prevState.isMinimized }));
	};
	
	handleModalClick = ev => ev.stopPropagation();
	
	render () {
		if (!this.state.isOpen) return null;
		
		const { children, className, onMin } = this.props;
		const { isMinimized } = this.state;
		
		let combineClassName = cx('modal-window-overlay', { [className]: className, 'minimized': isMinimized });
		
		return (
			<div className={combineClassName}>
				<div className='modal-window' onClick={this.handleModalClick}>
					<i className='modal-window-close icon-close no-print' onClick={this.handleCloseModal} />
					{onMin && <i className={cx('modal-window-min  no-print', isMinimized ? 'icon-unmin' : 'icon-min')}
								 onClick={this.toggleHandleMinModal} />}
					{children}
				</div>
			</div>
		);
	}
}

ModalWindow.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func
};

export default ModalWindow;
