import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from 'styles/modules/appealQuality.module.scss';

@translate()
export default class Rank extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rank: props.rank
    };
  }

  onRankChange = event => {
    const { onChange, name } = this.props;
    const rankValue = event.target.value;
    this.setState({ rank: rankValue });
    onChange(name, rankValue);
  };

  renderStarItem = value => {
    const checked = this.state.rank >= value ? 1 : 0;
    return (
      <label className={styles.appealQualityStar} key={value.toString()}>
        {checked ?
          <input
            type='checkbox'
            className={styles.appealQualityChecker}
            onChange={this.onRankChange}
            value={value}
            defaultChecked={true}
            checked
          /> :
          <input
            type='checkbox'
            className={styles.appealQualityChecker}
            onChange={this.onRankChange}
            value={value}
          />
        }
        <span>☆</span>
        <div className={styles.appealQualityRank}>
          ({value})
        </div>
      </label>
    );
  };

  render() {
    const { t, title, numbers } = this.props;
    // TODO refactor this expression
    const numberedArray = Array.from(new Array(numbers), (v, i) => i + 1);
    numberedArray.reverse();

    return (
      <Fragment>
        <div className={styles.appealQualityRow}>
          <span className={styles.appealQualityFormQuestion}>{t('quality.Question')}</span>
          <li />
          <br />
          <b>{title}</b>
          <br />
          <div className={styles.appealQualityGalactica}>
            {numberedArray.map(this.renderStarItem)}
          </div>
        </div>
      </Fragment>
    );
  }
}

Rank.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  numbers: PropTypes.number,
  name: PropTypes.string,
  rank: PropTypes.number
};
