import {
  APPEAL_HISTORY_REQUEST,
  APPEAL_HISTORY_REQUEST_SUCCESS,
  APPEAL_HISTORY_REQUEST_FAILURE,
  APPEAL_HISTORY_FILTER_UPDATE,
  APPEAL_HISTORY_SEARCH_QUERY_UPDATE,
  APPEAL_HISTORY_RESET
} from 'constants/actions';
import baseService from 'services/BaseService';
import groupBy from 'lodash/groupBy';

/********  Appeal history request  ********/

const requestAppealHistory = appealId => ({
  type: APPEAL_HISTORY_REQUEST,
  appealId
});

const requestAppealHistorySuccess = groupedResult => ({
  type: APPEAL_HISTORY_REQUEST_SUCCESS,
  historyGroups: groupedResult
});

const requestAppealHistoryFailure = () => ({
  type: APPEAL_HISTORY_REQUEST_FAILURE
});

export const getAppealHistory = (requestData, appealId) => dispatch => {
  dispatch(requestAppealHistory(appealId));

  const params = { data: requestData };

  return baseService.get('history', params)
    .then(response => {
      if (response.success && response.result) {
        dispatch(requestAppealHistorySuccess(groupBy(response.result, 'orderDate')))
      } else {
        dispatch(requestAppealHistoryFailure());
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(requestAppealHistoryFailure());
    });
};

/********  Appeal history filter update  ********/

export const updateAppealHistoryFilter = newFilter => ({
  type: APPEAL_HISTORY_FILTER_UPDATE,
  filter: newFilter
});

/********  Appeal history search query update  ********/

export const updateSearchQuery = newQuery => ({
  type: APPEAL_HISTORY_SEARCH_QUERY_UPDATE,
  query: newQuery
});

/********  Appeal history reset  ********/

export const resetAppealHistory = () => ({
  type: APPEAL_HISTORY_RESET
});



