import { START_REQUEST, END_REQUEST } from 'constants/actions';

const initialState = {
    requests: [],
    loadedStateId: '',
};

export default (state = initialState, action) => {
    
    switch (action.type) {
    
    case START_REQUEST:
        return { ...state, requests: [...state.requests, action.request] };
    
    case END_REQUEST:
        return { ...state, requests: state.requests.filter(request => request.id !== action.requestId) };
    
    default:
        return state;
    }
};
