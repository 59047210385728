import React from 'react';
import PropTypes from 'prop-types';


export default function ControlPanel({ children }) {
  return (
    <div className='controlPanel' >{children}</div>
  );
}

ControlPanel.propTypes = {
  children: PropTypes.any
}
;
