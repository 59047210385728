const actions = {
    INIT_GRID_API: 'INIT_GRID_API',
    UPDATE_GRID_API: 'UPDATE_GRID_API',
    UPDATE_GRID_FIELDS: 'UPDATE_GRID_FIELDS',
    UPDATE_GRID_DATA: 'UPDATE_GRID_DATA',
    RESET_GRID: 'RESET_GRID'
};

const initData = {
    api: {
        key: null,
        path: null,
        page: 1,
        limit: 20,
        start: 0,
        query: undefined,
        sort_field: undefined,
        sort_order: undefined,
        checkedItems: [],
        filter: [],
        destroyOnUnmount: true
    },
    params: {
        fields: null,
        total: null,
        t: () => {},
        filter: { onChange: () => {} },
        sort: { onChange: () => {} },
        pagination: {},
        onChangeFields: () => {},
        title: null
    },
    update: false,
    result: null
};

/* selectors */
export const getGridData = (grid, gridKey) => {
    const { api, params, result, update } = grid[gridKey] || initData;
    return { api, params, result, update };
};

/* updaters */
const updateApi = (prevApi, nextApi) => {
    const { field, order, ...rest } = nextApi;
    const api = { ...prevApi, ...rest };

    if (nextApi.query) {
        api.page = prevApi.query === nextApi.query ? api.page : 1;
    }

    if (field) {
        api.sort_field = field || undefined;
        api.sort_order = order || undefined;
        /** for CRM API **/
        api.sort = [{
            property: field,
            direction: order.toUpperCase()
        }];
        /** end **/
    }

    api.start = ((api.page || prevApi.page) - 1) * api.limit;

    return api;
};

const updateParams = (prevParams, nextParams, api) => {
    const params = { ...prevParams, ...nextParams };
    const { limit, page } = api;

    params.pagination.data = { limit, page, total: nextParams.total };
    params.filter = { ...params.filter, defaultValue: api.query };
    params.sort = {
        ...params.sort,
        field: api.sort_field,
        order: api.sort_order
    };

    return params;
};

/* reducer */
export default function secondReducer(state = {}, action) {
    if (action.type in actions) {
        const { type, gridKey, ...rest } = action;
        const { api, params, result } = rest;
        const prevGrid = state[gridKey] || initData;

        switch (type) {
            case actions.INIT_GRID_API: {
                const grid = { api: updateApi(prevGrid.api, api) };
                grid.params = { ...prevGrid.params, ...params };
                // eslint-disable-next-line max-len
                grid.params.pagination.data = prevGrid.params.pagination.data || params.pagination.data;
                return {
                    ...state,
                    gridKey,
                    [gridKey]: { ...prevGrid, ...rest, ...grid }
                };
            }

            case actions.UPDATE_GRID_API: {
                const grid = { api: updateApi(prevGrid.api, api) };
                return { ...state, [gridKey]: { ...prevGrid, ...rest, ...grid } };
            }

            case actions.UPDATE_GRID_FIELDS: {
                const grid = { params: { ...prevGrid.params, ...params } };
                return { ...state, [gridKey]: { ...prevGrid, ...rest, ...grid } };
            }

            case actions.UPDATE_GRID_DATA: {
                const grid = {
                    result,
                    params: updateParams(prevGrid.params, params, prevGrid.api)
                };

                return { ...state, [gridKey]: { ...prevGrid, ...rest, ...grid } };
            }

            case actions.RESET_GRID: {
                prevGrid.result = prevGrid.api.destroyOnUnmount ? null : prevGrid.result;
                return { ...state, gridKey: null, [gridKey]: { ...prevGrid } };
            }

            default:
                return state;
        }
    }

    return state;
}

/* action creators */
export const init = ({ api, params, gridKey, update }) => ({
    type: actions.INIT_GRID_API,
    gridKey,
    api,
    params,
    update
});

// eslint-disable-next-line max-len
export const setApi = (data, gridKey) => ({ type: actions.UPDATE_GRID_API, gridKey, api: data, update: true });

export const setFields = (fields, gridKey) => ({
    type: actions.UPDATE_GRID_FIELDS,
    gridKey,
    params: { fields },
    update: false
});

export const setData = (result, gridKey) => ({
    type: actions.UPDATE_GRID_DATA,
    gridKey,
    result,
    params: { total: result.total },
    update: false
});

export const reset = gridKey => ({
    type: actions.RESET_GRID,
    gridKey
});
