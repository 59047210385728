import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { closeCreateNewContactPersonModal } from 'actions/ui';
import ModalPortal from '../ModalPortal';
import MessageModal from '../MessageModal';
import { CSSTransition } from 'react-transition-group';
import { destroy, reset } from 'redux-form';
import CreateNewContactPersonForm from './CreateNewContactPersonForm';
import { translate } from 'react-i18next';
import { KeyCodes } from 'constants/index';

const mapStateToProps = state => ({
    showCreateNewContactPersonModal: state.ui.showCreateNewContactPersonModal,
    values: getFormValues('create-new-contact-person')(state)
});

const mapDispatchToProps = dispatch => ({
    closeCreateNewContactPersonModal: () => dispatch(closeCreateNewContactPersonModal()),
    destroyForm: (form) => dispatch(destroy(form))
});

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class CreateNewContactPersonModal extends React.Component {
    state = {
        warningPopupOpen: false
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        this.props.destroyForm('create-new-contact-person');
    }

    isFormValuesEmpty = () => {
        const { values } = this.props;

        if (values) {
            return !Object.values(values)
                .some(value => {
                    if (typeof value === 'string') {
                        return Boolean(value.trim());
                    }
                    return Boolean(value);
                });
        }
        return true;
    };

    onClose = () => {
        this.isFormValuesEmpty()
            ? this.props.closeCreateNewContactPersonModal()
            : this.handleOpenWarningPopup();
    };

    handleKeyPress = (e) => {
        if (e.keyCode === KeyCodes.ESCAPE) this.onClose();
    };

    handleOpenWarningPopup = () => this.setState({ warningPopupOpen: true });

    handleCloseWarningPopup = () => this.setState({ warningPopupOpen: false });

    onClickDangerButton = () => {
        this.handleCloseWarningPopup();
        this.props.closeCreateNewContactPersonModal();
    };

    render() {
        const { showCreateNewContactPersonModal, t } = this.props;
        return (
            <React.Fragment>
                {
                    !this.state.warningPopupOpen &&
                    <CSSTransition
                        in={showCreateNewContactPersonModal}
                        classNames='fade'
                        appear={true}
                        enter={true}
                        exit={true}
                        timeout={500}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <ModalPortal
                            onClose={this.onClose}
                            className='create-customer-modal'
                        >
                            <CreateNewContactPersonForm />
                        </ModalPortal>
                    </CSSTransition>
                }
                {
                    this.state.warningPopupOpen &&
                    <ModalPortal
                        onClose={this.handleCloseWarningPopup}
                        className='modal-small'
                    >
                        <MessageModal
                            titleModal={'Зверніть увагу!'}
                            contentModalText={'Всі незбережені дані будуть втрачені. Ви дійсно бажаєте закрити?'}
                            dangerButton={true}
                            dangerButtonText={'Закрити'}
                            onClickDangerButton={this.onClickDangerButton}
                            secondaryButton={true}
                            secondaryButtonText={t('cancel')}
                            onClickSecondaryButton={this.handleCloseWarningPopup}
                        />
                    </ModalPortal>
                }
            </React.Fragment>
        );
    }
}

export default CreateNewContactPersonModal;
