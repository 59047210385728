import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import { Link } from 'react-router-dom';

const AppealChangeSuccessNote = ({ appealId, customerId }) => (
  <div className={styles.appealChangesSuccessNote}>
    <i className='icon-check-circle'/>
    <span>Зміни для звернення <Link className={styles.appealID} to={`/appeals/${appealId}/knowledge_base`}>{appealId}</Link> збережені</span>
  </div>
);

// AppealChangeSuccessNote.defaultProps = {
//   appealId: 123889,
//   customerId: 'no_customer'
// };

AppealChangeSuccessNote.propTypes = {
  appealId: PropTypes.number,
  customerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default AppealChangeSuccessNote;
