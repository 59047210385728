import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ label, input, meta, ...restProps }) => {
  return (
    <div className="input-element">
      <div className="input-label">{label}</div>
      <label className="container-checkbox">
        <input
          type="checkbox"
          {...input}
          {...restProps}
        />
        <span className="check-mark" />
      </label>
    </div>
  )

};

CheckBox.propTypes = {
  label: PropTypes.string
}

export default CheckBox;
