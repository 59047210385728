import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { reduxFormWrapper, createCheckPropsFunction } from 'helpers';
import { StreetModes } from 'constants/index';
import { Field, formValueSelector, getFormValues, isPristine } from 'redux-form';
import baseService from 'services/BaseService';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import StreetField from './StreetField/index';
import PropTypes from 'prop-types';
import FormWrapper from '../FormWrapper';
import { translate } from 'react-i18next';
import validate from './validate';
import TextArea from '../../../../Common/TextArea';
import ComboBox from '../../../../Common/ComboBox';
import InputField from '../../../../Common/InputField';

const settlement = { name: 'settlement', label: 'Населений пункт', component: ComboBox, searchable: true };
const street = { name: 'street', label: 'Вулиця', component: ComboBox, searchable: true };
const buildingNumber = { name: 'buildingNumber', label: 'Номер будинку', component: ComboBox, searchable: true };
const buildingSecondNumber = { name: 'buildingSecondNumber', label: 'Номер корпусу', component: InputField  };
const apartmentNumber = { name: 'apartmentNumber', label: 'Номер квартири', component: InputField };
const zipCode = { name: 'zipCode', label: 'Індекс', component: ComboBox, searchable: true };
const description = {
    name: 'description',
    label: 'Коментар',
    component: TextArea,
    autosize: true,
    maxLength: 1500,
    textAreaClassName: 'description',
};

export const customerAddressFormConfig = [
    settlement,
    street,
    buildingNumber,
    buildingSecondNumber,
    apartmentNumber,
    zipCode,
    description,
];

const customerAddressFormConfigPristine = [
    { ...settlement, required: true },
    { ...street, required: true },
    { ...buildingNumber, required: true },
    buildingSecondNumber,
    apartmentNumber,
    zipCode,
    description,
];

function mapStateToProps(state, props) {
    const formSelector = formValueSelector(props.form);
    const pristine = isPristine(props.form)(state);
    const configs = {
        true: customerAddressFormConfig,
        false: customerAddressFormConfigPristine
    };
    
    return {
        ...formSelector(state, 'settlement', 'street', 'buildingNumber', 'zipCode', 'isSameAsPhysical'),
        physicalAddressValues: getFormValues('physical-address-form')(state),
        juridicalAddressValues: getFormValues('juridical-address-form')(state),
        config: configs[pristine] || [],
    };
}

@translate()
@connect(mapStateToProps)
@reduxFormWrapper({ validate })
class AddressForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            settlementOptions: [],
            streetOptions: [],
            buildingNumberOptions: [],
            zipCodeOptions: [],
        };
        
        this.linkedFields = ['settlement', 'street', 'buildingNumber', 'zipCode'];
    }
    
    componentDidUpdate(prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        const { addressType, enableWarning } = this.props;
        
        if (isPropChanged('isSameAsPhysical') && !isPropChanged('addressType') && addressType === 'juridical') {
            this.synchronizeWithPhysical();
        }
        const key = addressType === 'juridical'? 'juridicalAddress' : 'address';
        if (!this.props.pristine && prevProps.pristine && enableWarning) {
            enableWarning({ [key]: true });
        } else if (this.props.pristine && !prevProps.pristine && enableWarning) {
            enableWarning({ [key]: false });
        }
    }
    
    
    onStreetFieldRefReady = streetFieldRef => {
        this.streetFieldRef = streetFieldRef;
    };
    
    onZipCodeFieldRefReady = zipCodeFieldRef => {
        this.zipCodeRef = zipCodeFieldRef;
    };
    
    onBuildingNumberFieldRefReady = buildingNumberFieldRef => {
        this.buildingNumberRef = buildingNumberFieldRef;
    };
    
    resetLinkedFields = field => {
        const fieldsForReset = this.linkedFields.slice(this.linkedFields.indexOf(field) + 1);
        fieldsForReset.forEach(field => this.props.change(field, null));
        
        const nextState = {};
        fieldsForReset.reduce((acc, field) => {
            acc[`${field}Options`] = [];
            return acc;
        }, nextState);
        this.setState({ ...nextState });
    };
    
    // *** onChange handlers ***
    
    onInputChange = field => inputValue => {
        
        const query = !inputValue && this.props[field] ? this.props[field].label : inputValue;
        
        switch (field) {
        case 'settlement':
            this.onSettlementChange(query);
            break;
        case 'street':
            this.onStreetChange(query);
            break;
        case 'buildingNumber':
            this.props.change(`${field}Input`, inputValue);
            break;
        
        case 'zipCode':
            this.props.change(`${field}Input`, inputValue);
            this.onZipCodeChange(query);
            break;
        }
        
        return inputValue;
    };
    
    onSettlementChange = debounce(query => {
        if (!query || query.length < 3) return;
        return this.requestSettlements(query)
            .then(response => {
                if (response.success) {
                    this.setState({ settlementOptions: this.convertSettlementResponse(response.result) });
                } else {
                    this.setState({ settlementOptions: [] });
                }
            });
    }, 500);
    
    onStreetChange = debounce(query => {
        if (!this.props.settlement || !query || query.length < 2 || get(this.props, 'street.label') === query) return;
        
        return this.requestStreets(query, this.props.settlement.id)
            .then(response => {
                if (response.success) {
                    this.setState({ streetOptions: this.convertStreetResponse(response.result) });
                    
                } else {
                    this.setState({ streetOptions: [] });
                }
            });
    }, 500);
    
    onZipCodeChange = debounce(query => {
        
        const isQueryShort = !query || query.length < 5;
        const areSettlementAndStreetEmpty = !this.props.settlement && !this.props.street;
        
        if (isQueryShort || !areSettlementAndStreetEmpty || this.props.mode !== StreetModes.AUTO) {
            return;
        }
        
        return this.requestZipCodesSearch(query)
            .then(response => {
                if (response.success && response.result && response.result.length > 0) {
                    this.setAddressFoundByIndex(response.result);
                }
            });
    }, 300);
    
    setAddressFoundByIndex = results => {
        const streetOptions = this.convertStreetResponse(results[0].street);
        const settlement = this.convertSettlementResponse(results.slice(0, 1))[0];
        
        const focusStreetInput = () => this.streetFieldRef.current.input.input.focus();
        
        this.props.change('street', streetOptions[0]);
        this.props.change('settlement', settlement);
        this.setState({ settlementOptions: [], streetOptions }, focusStreetInput);
    };
    
    convertSettlementResponse = result => result.map(settlement => ({
        label: settlement.name,
        value: settlement.fullName,
        id: settlement.id,
        fullName: settlement.fullName
    }));
    
    convertStreetResponse = result => result.map(street => ({ label: street.name, value: street.id, type: street.type }));
    
    convertBuildingResponse = result => result.map(building => ({ label: building.name, value: building.id }));
    
    convertZipCodeResponse = result => result.map(zipCode => ({ label: zipCode.zip_code, value: zipCode.id }));
    
    // *** Requests ***
    
    requestSettlements = query => {
        const data = { query, page: 1, start: 0, limit: 10 };
        return baseService.get('search_settlement', { data });
    };
    
    requestStreets = (query, settlementId) => {
        const data = { query, settlementId, page: 1, start: 0, limit: 10 };
        return baseService.get('search_street', { data });
    };
    
    requestBuildings = streetId => {
        const data = { streetId };
        return baseService.get('search_building', { data });
    };
    
    requestZipCodes = (streetId, buildId) => {
        const data = { streetId, buildId };
        return baseService.get('search_zip_code', { data });
    };
    
    requestZipCodesSearch = query => {
        const data = { query };
        return baseService.get('search_settlement_by_zip_code', { data });
    };
    
    getSettlementOptionComponent = () => props => {
        return (
            <div className={cx(props.className, 'settlement-option')} onClick={() => props.selectValue(props.option)}>
                <div className={'settlementLabel'}>{props.option.label}</div>
                <div className={'settlementFullName'}>{props.option.fullName}</div>
            </div>
        );
    };
    
    handleChange = (event, newValue, previousValue, fieldName) => {
        this.resetLinkedFields(fieldName);
        
        if (fieldName === 'street' && newValue) {
            this.requestBuildings(newValue.value)
                .then(response => {
                    this.setState({ buildingNumberOptions: response.success ? this.convertBuildingResponse(response.result) : [] });
                });
        }
        
        if (fieldName === 'buildingNumber' && newValue) {
            this.requestZipCodes(this.props.street.value, newValue.value)
                .then(response => {
                    this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] });
                });
        }
    };
    
    onStreetModeChange = newStreetMode => this.props.changeMode(newStreetMode);
    
    getFieldPropsWithOptions = fieldProps => {
        
        switch (fieldProps.name) {
        case 'settlement':
            return {
                ...fieldProps,
                options: this.state.settlementOptions,
                onInputChange: this.onInputChange('settlement'),
                optionComponent: this.getSettlementOptionComponent(),
                onChange: this.handleChange
            };
        case 'street':
            return {
                mode: this.props.mode,
                onModeChange: this.onStreetModeChange,
                fieldConfig: fieldProps,
                options: this.state.streetOptions,
                onInputChange: this.onInputChange('street'),
                onChange: this.handleChange,
                changeField: this.props.change,
                id: 'streetInput',
                openOnFocus: true,
                onRefReady: this.onStreetFieldRefReady,
                required: fieldProps.required
            };
        case 'buildingNumber':
            return {
                ...fieldProps,
                onRefReady: this.onBuildingNumberFieldRefReady,
                options: this.state.buildingNumberOptions,
                onChange: this.handleChange,
                onInputChange: this.onInputChange('buildingNumber'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };
        case 'zipCode':
            return {
                ...fieldProps,
                onRefReady: this.onZipCodeFieldRefReady,
                options: this.state.zipCodeOptions,
                onInputChange: this.onInputChange('zipCode'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };
        
        default:
            return fieldProps;
        }
    };
    
    renderInputField = fieldProps => {
        switch (fieldProps.name) {
        case 'street':
            return <StreetField key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        default:
            return <Field key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        }
    };
    
    render() {
        const { handleSubmit, customerType, config } = this.props;
        return (
            <FormWrapper
                title='Фактична адреса'
                onSubmit={handleSubmit}
                customerType={customerType}
            >
                {config.map(this.renderInputField)}
            </FormWrapper>
        );
    }
}

AddressForm.propTypes = {
    onSubmit: PropTypes.func,
    form: PropTypes.string.isRequired,
    customerType: PropTypes.string,
    addressType: PropTypes.string
};

export default AddressForm;
