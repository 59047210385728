import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PriorityArrowsIcons from "../../SVG/PriorityArrowsIcons";
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import { translate } from "react-i18next";
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { personUnlinkContact } from 'actions/customer';
import permissions, { checkPermissions } from 'config/permissions';

@translate()
export default class GridCell extends Component {
    constructor (props) {
        super(props);
        let ToggleChecked = null;
        if (props.data.links)ToggleChecked = props.data.links[0].actual;
		else if (props.data.actual) ToggleChecked = JSON.parse(props.data.actual);
		
        this.state = {
            width: props.fixedWidth,
            toggleChecked: ToggleChecked,
			showMessageModal: false,
			currentLinkId: null,
			customerId: null,
			personId: null,
			canUnlink: !checkPermissions(permissions.CustomerOperations.action_unlinkContactPersons)
        };
		this.handleToggleCheckBox = this.handleToggleCheckBox.bind(this);
		this.closeMessageModal = this.closeMessageModal.bind(this);
		this.unlinkPersonRequest = this.unlinkPersonRequest.bind(this);
    }
    
    static getDerivedStateFromProps(nextProps) {
        if (nextProps.forceWidth) {
            return { width: nextProps.forceWidth };
        }
        return null;
    }
    
    parseHtmlContent = html => String(html).replace(/<\/?[^>]+>/g, '');
    
	handleToggleCheckBox(currentLinkId, customerId, personId, disabled) {

		if(!disabled) {
			this.setState({
				showMessageModal: true,
				currentLinkId: currentLinkId,
				customerId: customerId,
				personId: personId
			})
		}
		else return null;
	}
	
	closeMessageModal() {
		this.setState({ showMessageModal: false	});
	}
	
	unlinkPersonRequest() {
		const {currentLinkId, customerId, personId} = this.state;
		let data = {
			personId: personId,
			customerId: customerId,
			linkId: currentLinkId
		};
		
		personUnlinkContact(data, this.closeMessageModal);
		this.setState(prevState =>({
			toggleChecked: !prevState.toggleChecked,
			personId: null,
			customerId: null,
			linkId: null
		}))
	}
	
    
    displayCell = (value, params, data) => {
		
        if(params.togglePersonLink){
			const { id, idLinkedWith } = data;
			const { toggleChecked, canUnlink } = this.state;
			const idPerson = data.idLinkedWith;
			const disabled = !toggleChecked;

			return <CheckBoxToggle
						id={id}
						checked={toggleChecked}
						disabled={disabled || canUnlink}
						onChange={() => this.handleToggleCheckBox(id, idLinkedWith, idPerson, disabled )}
					/>
        }
        
        if(params.toggleCustomerLink){
            const { id, idLinkedWith } = data.links[0];
            const { toggleChecked, canUnlink } = this.state;
            const idPerson = data.id;
            const disabled = !toggleChecked;

            return <CheckBoxToggle
                        id={id}
                        checked={toggleChecked}
                        disabled={disabled || canUnlink}
                        onChange={() => this.handleToggleCheckBox(id, idLinkedWith, idPerson, disabled )}
			        />
        }

        if (params.displayStatus) {
            const {strings} = this.props;
            const status = value ? strings.active : strings.disabled;
            return (
                <div className='data-cell' title={status}>
                    <i className={`icon-check-circle grid-icon ${value ? 'green-grid-icon' : 'red-grid-icon' }`}/>
                    {status}
                </div>
            );
        }
        else if (params.html) {
            return <div className='data-cell' title={this.parseHtmlContent(value)}
                        dangerouslySetInnerHTML={{ __html: value }}/>;
        }
        else if (params.value) {
            return params.value(data);
        }
        else if (params.component) {
            return <params.component data={data}/>;
        }
        else if (params.hasPriority) {
            const {value} = this.props;
            const {priorityId} = this.props.data;

            return (
                <div className='data-cell' title={value}>
                    {PriorityArrowsIcons (priorityId)}
                </div>
            );
        }
        else {
            return <div className='data-cell' title={value}>{value}</div>;
        }
    };
    
    render = () => {
        const { t, data, params, value, widthClassName, hidden, headerName, fixedWidth, preventClickToCheckbox } = this.props;
        const { width, showMessageModal } = this.state;
        const forcedWidthStyle = {
            minWidth: `${width || params.width || fixedWidth}px`,
            maxWidth: `${width || params.width || fixedWidth}px`,
            width: `${width || params.width || fixedWidth}px`,
        };
        return hidden ? null : (
            <td onClick={params.toggleCustomerLink && preventClickToCheckbox} className={`grid-cell-${widthClassName}`} style={forcedWidthStyle} data-header-name={headerName}>
				<CSSTransition
					in={showMessageModal}
					classNames="fade"
					appear
					enter
					exit
					timeout={500}
					mountOnEnter
					unmountOnExit
				>
					<ModalPortal onClose={this.closeMessageModal} className="modal-small modal-center">
						<MessageModal
							titleModal={t("persons.attention")}
							contentModalText={t("persons.linkModalMessage")}
							primaryButton
							primaryButtonText={t("persons.allow")}
							onClickPrimaryButton={this.unlinkPersonRequest}
							secondaryButton
							secondaryButtonText={t("persons.disallow")}
							onClickSecondaryButton={this.closeMessageModal}
						/>
					</ModalPortal>
				</CSSTransition>
				<div className='item-cell'>
                    {params.link && typeof params.link === 'function'
                        ? <Link className='data-cell' to={params.link(data)}>{value}</Link>
                        : this.displayCell(value, params, data)
                    }
                </div>
            </td>
        );
    };
}

GridCell.propTypes = {
    params: PropTypes.object,
    item: PropTypes.object,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    name: PropTypes.string,
    widthClassName: PropTypes.string,
    forceWidth: PropTypes.number,
    hidden: PropTypes.bool,
    strings: PropTypes.object
};
