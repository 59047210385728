import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const configureStore = () => {
    return createStore(
        rootReducer,
        MODE === 'development' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
    );
};

const store = configureStore();

export default store;
