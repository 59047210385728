import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import get from 'lodash/get';
import cx from 'classnames';
import styles from 'styles/modules/parameters.module.scss';
import ContactInput from './ContactInput';
import InputLabel from '../InputLabel';
import { isValid } from '../../validate';

class ContactArrayComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInputFocused: false,
            isTouched: false,
        };
        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);
        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
        this.onRemoveField = this.onRemoveField.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.renderInputs = this.renderInputs.bind(this);
        this.parseFieldName = this.constructor.parseFieldName;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.fields.length !== nextProps.fields.length) {
            return true;
        }

        if (this.props.loading !== nextProps.loading) {
            return true;
        }

        if (this.props.readOnly !== nextProps.readOnly) {
            return true;
        }
    
        if (this.state.isInputFocused !== nextState.isInputFocused) {
            return true;
        }

        return null;
    }

    enter() {
        if (!this.state.isTouched) {
            this.setState({ isTouched: true });
        }
    }

    leave() {
        if (this.state.isTouched) {
            this.setState({ isTouched: false });
        }
    }

    focus() {
        this.setState({ isInputFocused: true });
    }

    blur() {
        this.setState({ isInputFocused: false });
    }

    static parseFieldName(fieldName) {
        const position = fieldName.indexOf('[');

        return {
            index: Number.parseInt(fieldName.slice(position + 1, -1)),
            name: fieldName.slice(0, position),
        };
    }
    
    onRemoveField(fieldValue, fieldName, fieldOnly, callback) {
        const { index, name } = this.parseFieldName(fieldName);

        if (fieldOnly) {
            this.props.fields.remove(index);
            return;
        }
        const values = this.props.fields.getAll().filter((element, i) => i !== index);
        const options = { action: 'delete', index, fieldValue };
        this.props.handleChangeValue({ value: values, name, options }, callback);
    }

    handleChangeValue(fieldValue, fieldName) {
        const { index, name } = this.parseFieldName(fieldName);
        const values = this.props.fields.getAll().slice();
        values[index] = fieldValue;
        if (!isValid(values, name)) return;
        const options = { action: 'save', index, fieldValue };
        this.props.handleChangeValue({ value: values, name, options });
    }

    onAddClick() {
        const { single, fields } = this.props;
        if (single) return;

        const firstInputValue = get(fields.get(0), 'value');
        if (!firstInputValue) return;

        return () => {
            fields.unshift({ id: 0, value: '', added: true });
        };
    }

    renderInputs(name) {
        const { loading, single, readOnly, fields, field, required } = this.props;
		const maxLengthEmail = (field === 'contacts.email' || field === 'email' )? {maxLength:'255'} : null;
		return (
            <Field
                key={name}
                name={name}
                disabled={readOnly}
                required={required}
                component={ContactInput}
                handleChangeValue={this.handleChangeValue}
                onRemove={this.onRemoveField}
                onBlur={this.blur}
                onFocus={this.focus}
                single={single}
                fields={fields}
                removeFirst={this.props.fields.shift}
                loading={loading}
                {...maxLengthEmail}
            />
        );
    }

    render() {
        const { label, fields, loading } = this.props;
        const { isTouched, isInputFocused } = this.state;
        const contentClassName = cx(styles.content, {
            [styles.errorBorder]: (!isInputFocused
                                  && !isValid(fields.getAll(), fields.name)),
            [styles.focused]: isInputFocused
        });
        
        return (
            <div onMouseEnter={this.enter} onMouseLeave={this.leave} className={styles.fieldArrayContainer}>
                <InputLabel
                    readOnly={this.props.readOnly}
                    label={label}
                    onAddClick={this.onAddClick()}
                    loading={loading}
                    isTouched={isTouched}
                />
                {
                    fields.length > 0
                    && (
                        <div className={contentClassName}  onFocus={this.focus} onBlur={this.blur}>
                            {fields.map(this.renderInputs)}
                        </div>
                    )
                }
            </div>
        );
    }
}

ContactArrayComponent.propTypes = {
    handleChangeValue: PropTypes.func,
    single: PropTypes.bool,
};

export default ContactArrayComponent;
