import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Field, getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { closeAdvancedSearchModal, getClientsSuppose, clearClientGetSuppose } from 'actions/client';
import { openCreateNewCustomerModal } from 'actions/ui';
import InputField from 'components/Common/InputField';
import GridWrapper from 'components/GridWrapper';
import styles from 'styles/modules/modal.module.scss';
import { translate } from 'react-i18next';
import { reduxFormWrapper } from 'helpers';
import { advancedSearchClientFormConfig } from 'constants/index';
import { queriesContentUpdate } from 'actions/content';
import permissions, { checkPermissions } from 'config/permissions';

const mapStateToProps = state => {
    const initVal = () => {
        const query = state.client.query;
        const values = {};
        if (query) {
            values.name = query;
        }
        return values;
    };
    
    return {
        clients: state.client.clients,
        total: state.client.total,
        formValues: getFormValues('advancedSearchClient')(state),
        tabs: state.tabs,
        query: state.client.query,
        initialValues: initVal(),
        isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer),
    };
};

const mapDispatchToProps = dispatch => ({
    closeAdvancedSearchModal: () => dispatch(closeAdvancedSearchModal()),
    getClientsSuppose: requestData => dispatch(getClientsSuppose(requestData)),
    openCreateNewCustomerModal: context => dispatch(openCreateNewCustomerModal(context)),
    clearClientGetSuppose: () => dispatch(clearClientGetSuppose()),
    queriesContentUpdate: params => dispatch(queriesContentUpdate(params))
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'advancedSearchClient', enableReinitialize: true })
export default class AdvancedSearchModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            page: 1,
        };
    }
    
    componentDidMount () {
        const { initialValues } = this.props;
        const requestData = !isEmpty(initialValues) && { limit: 10, ...initialValues };
        requestData && this.props.getClientsSuppose(requestData);
    }
    
    componentWillUnmount () {
        this.props.clearClientGetSuppose();
    }
    
    onPageChange = (params) => {
        const fieldsValues = this.getFormValues();
        this.setState(params);
        const start = (params.page - 1) * 10;
        this.props.getClientsSuppose({
            limit: 10,
            start,
            ...fieldsValues,
        });
    };
    
    onSelectItem = selectedApplicant => {
        const applicant = this.props.clients[Number.parseInt(selectedApplicant.index, 10)];
        
        this.props.closeAdvancedSearchModal();
        
        this.props.onSelect(applicant);
        
        this.props.clearClientGetSuppose();
    };
    
    getFormValues = () => {
        const { formValues } = this.props;
        const data = {};
        
        if (formValues && formValues.phone && formValues.phone.length > 3) {
            data.phone = formValues.phone;
        }
        if (formValues && formValues.name && formValues.name.length > 2) {
            data.name = formValues.name;
        }
        if (formValues && formValues.address && formValues.address.length > 2) {
            data.address = formValues.address;
        }
        if (formValues && formValues && formValues.email && formValues.email.length > 2) {
            data.email = formValues.email;
        }
        return data;
    };
    
    handleInput = debounce(() => {
        const fieldsValues = this.getFormValues();
        const requestData = !isEmpty(fieldsValues) && { limit: 10, ...fieldsValues };
        !isEmpty(fieldsValues) && this.props.getClientsSuppose(requestData);
    }, SEARCH_TIMER_INTERVAL);
    
    renderInputField = ({ name, label, placeholder, maxLength }) => (
        <Field
            component={InputField}
            key={name}
            name={name}
            label={label}
            placeholder={placeholder}
            onKeyUp={this.handleInput}
            maxLength={maxLength}
        />
    );
    
    createNewCustomer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.closeAdvancedSearchModal();
        this.props.openCreateNewCustomerModal({ openNewTab: false });
    };
    
    configureGridData = () => {
        const result = this.props.clients.map((client, index) => {
            return {
                id: `${client.customer.customerId}/${client.appl.contactPersonId}`,
                partyType: this.props.t(`searchClient.${client.customer.party.partyType}`),
                status: client.customer.socialStatusName,
                custName: client.customer.custName,
                phone: client.appl.phone,
                address: client.customer.phyAddr.name,
                email: client.appl.email,
                contactPersonId: client.appl.contactPersonId,
                contactPersonName: client.appl.firstName + ' ' + client.appl.lastName,
                index
            };
        });
        
        return { result, total: this.props.total };
    };
    
    render () {
        const { total, tabs, t, match, queriesContentUpdate, isCustomerCreationAllowed } = this.props;
        return (
            <div className={styles.modalWrapper}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>{t('searchClient.searchResultClient')} ({total})</div>
                </div>
                <div className={cx(styles.modalContent, styles.modalContentClients)}>
                    <main className={styles.mainWrapper}>
                        <div className={styles.gridColumn}>
                            <GridWrapper
                                data={this.configureGridData()}
                                tabs={tabs}
                                fields='searchclient'
                                onPageChange={this.onPageChange}
                                match={match}
                                groups={{ selectItem: this.onSelectItem }}
                                page={this.state.page}
                                disabledChange
                            />
                        </div>
                        <div className={styles.formColumn}>
                            <form className={styles.formWrapper}>
                                {advancedSearchClientFormConfig.map(this.renderInputField)}
                            </form>
                        </div>
                    </main>
                    {
                        isCustomerCreationAllowed &&
                        <footer className={styles.modalFooter}>
                            <button className='btn btn-primary' onClick={this.createNewCustomer}>
                                + {t('searchClient.createNew')}
                            </button>
                        </footer>
                    }
                
                </div>
            </div>
        );
    }
}

AdvancedSearchModal.propTypes = {
    clients: PropTypes.array,
    total: PropTypes.number,
    onSelect: PropTypes.func,
    openCreateNewCustomerModal: PropTypes.func,
    closeAdvancedSearchModal: PropTypes.func,
    getClientsSuppose: PropTypes.func,
    clearClientGetSuppose: PropTypes.func
};
