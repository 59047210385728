export const validate = values => {
  const errors = {};
  const fields = Object.keys(values);

  if (fields.includes('comment') && !values.comment) {
    errors.comment = 'Required';
  }

  if (fields.includes('decisionDate') && !values.decisionDate) {
    errors.decisionDate = 'Required';
  }

  return errors;
};
