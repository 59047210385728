import moment from 'moment';

export default function validate(values) {
    let errors = {};

    const error = (field) => (`"${field} з" не може бути пізніше ніж "${field} по"`);
    const CORRECT_DATE = 'Введіть коректний формат дати (ДД.ММ.РРРР ГГ:ХХ)';

    if (values['INTERACTION_REQUEST_FINISH_DATE@FROM'] && values['INTERACTION_REQUEST_FINISH_DATE@TO']) {
        if (moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isBefore(values['INTERACTION_REQUEST_FINISH_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@FROM': error('Закрито')}
        }
    }

    if (values['INTERACTION_START_DATE@FROM'] && values['INTERACTION_START_DATE@TO']) {
        if (moment(values['INTERACTION_START_DATE@TO']).isBefore(values['INTERACTION_START_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_START_DATE@FROM': error('Створено')}
        }
    }

    if (values['INTERACTION_END_DATE@FROM'] && values['INTERACTION_END_DATE@TO']) {
        if (moment(values['INTERACTION_END_DATE@TO']).isBefore(values['INTERACTION_END_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_END_DATE@FROM': error('Вирішити до')}
        }
    }

    if (values['ATTR_8@FROM'] && values['ATTR_8@TO']) {
        if (moment(values['ATTR_8@TO']).isBefore(values['ATTR_8@FROM'])) {
            errors = {...errors, 'ATTR_8@FROM': error('Планова дата зворотнього зв\'язку')}
        }
    }
    if (values['INTERACTION_ACCEPTED_DATE@FROM'] && values['INTERACTION_ACCEPTED_DATE@TO']) {
        if (moment(values['INTERACTION_ACCEPTED_DATE@TO']).isBefore(values['INTERACTION_ACCEPTED_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_ACCEPTED_DATE@FROM': error('Прийнято')}
        }
    }

    if (!moment(values['INTERACTION_END_DATE@FROM']).isValid()){
        errors = {...errors, 'INTERACTION_END_DATE@FROM': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_END_DATE@TO']).isValid()){
        errors = {...errors, 'INTERACTION_END_DATE@TO': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@TO': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_REQUEST_FINISH_DATE@FROM']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@FROM': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_START_DATE@FROM']).isValid() ){
        errors = {...errors, 'INTERACTION_START_DATE@FROM': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_START_DATE@TO']).isValid() ){
        errors = {...errors, 'INTERACTION_START_DATE@TO': CORRECT_DATE}
    }

    if (!moment(values['ATTR_8@TO']).isValid() ){
        errors = {...errors, 'ATTR_8@TO': CORRECT_DATE}
    }

    if (!moment(values['ATTR_8@FROM']).isValid() ){
        errors = {...errors, 'ATTR_8@FROM': CORRECT_DATE}
    }
    if (!moment(values['INTERACTION_ACCEPTED_DATE@TO']).isValid()) {
        errors = {...errors, 'INTERACTION_ACCEPTED_DATE@TO': CORRECT_DATE}
    }

    if (!moment(values['INTERACTION_ACCEPTED_DATE@FROM']).isValid()) {
        errors = {...errors, 'INTERACTION_ACCEPTED_DATE@FROM': CORRECT_DATE}
    }
    return errors;
}
