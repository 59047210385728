import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { tabsAppeal } from 'constants/appealForm';

import styles from 'styles/modules/appealForm.module.scss';
import { translate } from 'react-i18next';

@translate()
export default class AppealTab extends React.Component {
  renderTab(el) {
    return (
      <button
        type='button'
        value={el.name}
        className={cx(styles.tab, { [styles.activeTab]: el.name === this.props.activeTab })}
        key={el.name}
        onClick={this.props.changeTabs}
      >
        {this.props.t(`appealForm.${el.value}`).toUpperCase()}
      </button>
    );
  }

  render() {
    return (
      <div className={styles.appealTabWrapper}>
        {tabsAppeal.map(el => this.renderTab(el))}
      </div>
    );
  }
}

AppealTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeTabs: PropTypes.func.isRequired
};
