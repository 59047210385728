import React from 'react';

export default function SubmitEditButton({ onClick }) {
    return (
        <svg onClick={onClick} className='submit-edit-contact-input' xmlns='http://www.w3.org/2000/svg'  viewBox=' 0 0 10.38 7.96' >
            <defs>
                <style>{'.cls-1{fill:#1c1000;}'}</style>
            </defs>
            <title>Зберегти</title>
            <g id=' Layer_2'  data-name=' Layer 2' >
                <g id=' Layer_90'  data-name=' Layer 90' >
                    <path className=' cls-1'
                          d=' M10.19,1.1,9.28.19A.6.6,0,0,0,8.83,0a.62.62,0,0,0-.46.19L4,4.59l-2-2a.66.66,0,0,0-.46-.19.62.62,0,0,0-.45.19l-.91.91A.62.62,0,0,0,0,4a.58.58,0,0,0,.19.45L2.61,6.86l.91.91A.66.66,0,0,0,4,8a.62.62,0,0,0,.45-.19l.91-.91L10.19,2a.62.62,0,0,0,.19-.46A.6.6,0,0,0,10.19,1.1Z' />
                </g>
            </g>
        </svg>
    );
}