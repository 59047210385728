import moment from 'moment';
import get from 'lodash/get';

const REQUIRED = 'Обов\'язкове поле';
const CORRECT_DATE = 'Введіть коректний формат дати (ДД.ММ.РРРР)';

function shouldCheck (field, props) {
    const ACTIVE_RESTRICTION = 3;
    return get(props, `appeal.restriction[${field}]`, 0) === ACTIVE_RESTRICTION;
}

export function warn (values, props) {
    const warnings = {};
    warnings.blocks = {};
    
    const blocks = get(props, 'formParams.blocks');
    blocks && blocks.forEach(item => {
        if (item && item.widgets) {
            item.widgets.forEach(widget => {
                if (widget && !widget.isReq && shouldCheck(widget.key)) {
                    if (widget && (widget.widgetType === 'date' || widget.widgetType === 'datetime') && values.blocks && values.blocks[widget.key] && !moment(values.blocks[widget.key]).isValid()) {
                        warnings.blocks[widget.key] = CORRECT_DATE;
                    }
                    if (widget && widget.rule && values.blocks) {
                        const rule = widget.rule.split('$');
                        const hint = rule[0];
                        const regexp = rule[1];
                        
                        const formattedRegexp = new RegExp(`^${regexp}$`);
                        if (!formattedRegexp.test(values.blocks[widget.key])) {
                            warnings.blocks[widget.key] = hint;
                        }
                    }
                }
            });
        }
    });
    
    return warnings;
}

export function validate (values,) {
    //redux SyncValidation
    let errors = {};
    
    for (let key in values.blocks) {
        
        if (moment.isMoment(values.blocks[key]) && !values.blocks[key].isValid()) {
            errors = { ...errors, blocks: { ...errors.blocks, [key]: CORRECT_DATE } };
        }
    }
    
    return errors;
}

export default function validates (values, props, restrictions) {
    const { priority, resolveDate, type, destination } = values;
    
    let errors = {};
    
    function checkRestriction (key, restrictionList) {
        return ![0, 2].includes(restrictionList[key]);
    }
    
    const blocks = get(props, 'formParams.blocks');
    blocks && blocks.forEach(item => {
        if (item && item.widgets) {
            item.widgets.forEach(widget => {
                if (widget && widget.isReq && shouldCheck(widget.key)) {
                    if (widget && widget.isReq && (!values.blocks || !values.blocks[widget.key])) {
                        if (checkRestriction(widget.key, restrictions)) {
                            errors = { ...errors, blocks: { ...errors.blocks, [widget.key]: REQUIRED } };
                        }
                    }
                    
                    if (widget && (widget.widgetType === 'date' || widget.widgetType === 'datetime') &&
                        values.blocks && values.blocks[widget.key] && !moment(values.blocks[widget.key]).isValid()) {
                        if (checkRestriction(widget.key, restrictions)) {
                            errors = { ...errors, blocks: { ...errors.blocks, [widget.key]: CORRECT_DATE } };
                        }
                    }
                    
                    if (widget && widget.rule && values.blocks) {
                        const rule = widget.rule.split('$');
                        const hint = rule[0];
                        const regexp = rule[1];
                        
                        const formattedRegexp = new RegExp(`^${regexp}$`);
                        if (!formattedRegexp.test(values.blocks[widget.key])) {
                            errors = { ...errors, blocks: { ...errors.blocks, [widget.key]: hint } };
                        }
                    }
                }
            });
        }
    });
    
    if (!priority && shouldCheck('priorityId', props)) {
        errors = { ...errors, priority: REQUIRED };
    }
    
    if (shouldCheck('resolveDateResolveData')) {
        if (!resolveDate) {
            errors = { ...errors, resolveDate: REQUIRED };
        } else if (resolveDate && !moment(resolveDate).isValid()) {
            errors = { ...errors, resolveDate: CORRECT_DATE };
        }
    }
    
    if (shouldCheck('type', props) && !type) {
        errors = { ...errors, type: REQUIRED };
    }
    
    if (props.selectedAppealAction.length > 0) {
        props.selectedAppealAction.forEach((field) => {
            const blocks = values.blocks || {};
            if (field.isReq) {
                const isBlock = field.key.includes('bp');
                if (!values[field.key] && !isBlock) {
                    if (checkRestriction(field.key, restrictions)) {
                        errors = { ...errors, [field.key]: REQUIRED };
                    }
                    
                }
                
                if (isBlock && !(Array.isArray(blocks[field.key]) ? blocks[field.key].length : blocks[field.key])) {
                    if (checkRestriction(field.key, restrictions)) {
                        errors = { ...errors, blocks: { ...errors.blocks, [field.key]: REQUIRED } };
                    }
                }
            }
        });
    }
    
    if (destination.length > 0) {
        destination.forEach((value, index, self) => {
            if (!value && !index) {
                errors = { ...errors, destination: { ...errors.destination, [index]: 'Треба обрати виконавця.' } };
            } else if (self.indexOf(value) !== index) {
                errors = { ...errors,
                    destination: {
                        ...errors.destination,
                        [index]: 'Неможливо вибрати кілька однакових виконавців.'
                    }
                };
            }
        });
    }
    
    // console.log(errors);
    
    return Object.keys(errors).length === 0 ? null : errors;
}

