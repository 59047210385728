import React from 'react';
import { connect } from 'react-redux';
import { closeCustomerAddressModal } from 'actions/ui';
import ModalPortal from '../ModalPortal';
import { CSSTransition } from 'react-transition-group';
import CustomerAddressForm from './CustomerAddressForm';
import { KeyCodes } from 'constants/index';

const mapStateToProps = state => ({
	showCustomerAddressModal: state.ui.showCustomerAddressModal,
});

const mapDispatchToProps = (dispatch) => ({
	closeCustomerAddressModal: () => dispatch(closeCustomerAddressModal()),
});

@connect(mapStateToProps, mapDispatchToProps)
class CustomerAddressModal extends React.Component {
	
	componentDidMount () {
		document.addEventListener('keydown', this.handleKeyPress);
	}
	
	componentWillUnmount () {
		document.removeEventListener('keydown', this.handleKeyPress);
	}
	
	onClose = () => this.props.closeCustomerAddressModal();
	
	handleKeyPress = e => {
		if (e.keyCode === KeyCodes.ESCAPE) {
			this.onClose();
		}
	};
	
	render () {
		const { showCustomerAddressModal } = this.props;
		return (
			<CSSTransition
				in={showCustomerAddressModal}
				classNames='fade'
				appear={true}
				enter={true}
				exit={true}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<ModalPortal
					onClose={this.onClose}
					className='create-customer-modal'
				>
					<CustomerAddressForm />
				</ModalPortal>
			</CSSTransition>
		);
	}
}

export default CustomerAddressModal;
