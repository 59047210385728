import React from 'react';

export default function CustomersIcon() {
	return (
		<i className='svg-icon-customers'>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 14"><title>Asset 181</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_75" data-name="Layer 75">
						<path
							d="M8.5,9a2.38,2.38,0,0,0,1.74-.73A2.44,2.44,0,0,0,11,6.5a2.44,2.44,0,0,0-.72-1.77,2.43,2.43,0,0,0-3.47,0A2.44,2.44,0,0,0,6.05,6.5a2.44,2.44,0,0,0,.72,1.77A2.36,2.36,0,0,0,8.5,9Zm4.39,1.93a6.17,6.17,0,0,0-.17-.71,3.52,3.52,0,0,0-.27-.63,2.23,2.23,0,0,0-.4-.53,1.62,1.62,0,0,0-.55-.35,1.8,1.8,0,0,0-.71-.13.94.94,0,0,0-.28.14L10.05,9A2.89,2.89,0,0,1,7,9l-.46-.31a.75.75,0,0,0-.28-.14,1.83,1.83,0,0,0-.71.13A1.62,1.62,0,0,0,5,9.06a2,2,0,0,0-.39.53,3,3,0,0,0-.28.63,6.17,6.17,0,0,0-.17.71c0,.25-.07.49-.09.71s0,.44,0,.67a1.67,1.67,0,0,0,.47,1.24A1.7,1.7,0,0,0,5.71,14h5.58a1.7,1.7,0,0,0,1.24-.45A1.64,1.64,0,0,0,13,12.31c0-.23,0-.45,0-.67S12.93,11.18,12.89,10.93ZM13.6,4.5a2.17,2.17,0,0,0,1.6-.66,2.14,2.14,0,0,0,.67-1.59A2.14,2.14,0,0,0,15.2.66a2.27,2.27,0,0,0-3.2,0,2.14,2.14,0,0,0-.67,1.59A2.14,2.14,0,0,0,12,3.84,2.17,2.17,0,0,0,13.6,4.5ZM4.53,5.62a4.26,4.26,0,0,1,0-.57,3.7,3.7,0,0,1-1.18.2,3.24,3.24,0,0,1-1-.19,5.32,5.32,0,0,1-.87-.38A1.84,1.84,0,0,0,1.1,4.5C.37,4.5,0,5.53,0,7.6a1.19,1.19,0,0,0,.5,1A2,2,0,0,0,1.72,9H2.9A3,3,0,0,1,5.25,7.88,3.87,3.87,0,0,1,4.53,5.62ZM15.9,4.5a1.84,1.84,0,0,0-.38.18,5.32,5.32,0,0,1-.87.38,3.24,3.24,0,0,1-1.05.19,3.7,3.7,0,0,1-1.18-.2,4.26,4.26,0,0,1,.05.57,3.87,3.87,0,0,1-.72,2.26A3,3,0,0,1,14.1,9h1.18a2,2,0,0,0,1.22-.36,1.19,1.19,0,0,0,.5-1C17,5.53,16.63,4.5,15.9,4.5ZM3.4,4.5A2.17,2.17,0,0,0,5,3.84a2.14,2.14,0,0,0,.67-1.59A2.14,2.14,0,0,0,5,.66,2.17,2.17,0,0,0,3.4,0,2.17,2.17,0,0,0,1.8.66a2.14,2.14,0,0,0-.67,1.59A2.14,2.14,0,0,0,1.8,3.84,2.17,2.17,0,0,0,3.4,4.5Z"/>
					</g>
				</g>
			</svg>
		</i>
	);
}