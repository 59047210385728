import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import i18n from 'util/i18n';
import { I18nextProvider } from 'react-i18next';
import store from './store';
import App from './App';

const rootEl = document.getElementById('root');

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <App />
        </Provider>
    </I18nextProvider>,
    rootEl,
);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <NextApp />
                </Provider>
            </I18nextProvider>,
            rootEl,
        );
    });
}
