import React from 'react';
import styles from 'styles/modules/parameters.module.scss';
import { FieldArray } from 'redux-form';

function FormFieldArray (props) {
   const isReadOnly = props.readOnly;
   const classes = isReadOnly ? styles.fieldBlock + ' ' + styles.readOnly : styles.fieldBlock;
  return (
    <div className={classes} >
      <FieldArray {...props} />
    </div>
  );
}

export default FormFieldArray;
