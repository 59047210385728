import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/parameters.module.scss';
import debounce from 'lodash/debounce';

class SearchBox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: props.options
    };
  }

  updateOptions = debounce(newQuery => {
    const filteredOptions = this.props.options.filter(option => option.label.toLocaleLowerCase().indexOf(newQuery) !== -1);
    this.setState({ options: filteredOptions });
  }, 200);

  onQueryChange = event => this.updateOptions(event.target.value);

  renderOption = ({ label, value }) => (
    <li title={label} key={value} onClick={() => this.props.onSelect(value)}>
      {label}
    </li>
  );

  render() {
    return (
      <div className={styles.searchBox}>

        <div className={styles.searchField}>
          <i className={'icon-search2'}/>
          <input type='text' placeholder={'Пошук'} onChange={this.onQueryChange}/>
        </div>

        <ul className={styles.searchResults}>
          {this.state.options.map(this.renderOption)}
        </ul>

      </div>
    );
  }
}

SearchBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SearchBox;
