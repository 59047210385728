import axios from './requestInstance';
import URLS from 'config/urls';
import { objToGETString } from '../helpers';
import { SHOW_NOTIFICATION, START_REQUEST, END_REQUEST } from 'constants/actions';
import store from 'store';
import get from 'lodash/get';
import { NotificationTypes } from 'constants/index';
import { v4 } from 'node-uuid';

// if we import action creator, then circular import will appear
// TODO refactor in future

const startRequest = request => ({
    type: START_REQUEST,
    request
});

const endRequest = requestId => ({
    type: END_REQUEST,
    requestId
});

const showErrorNotification = options => ({
    type: SHOW_NOTIFICATION,
    payload: { type: NotificationTypes.HTTP_ERROR, options }
});

export const notifyError = ({ message, response }) => {
    if (!response && !message) return;
    const error = {
        method: get(response, 'config.method', ''),
        url: get(response, 'config.url', ''),
        statusCode: get(response, 'status', ''),
        message
    };
    store.dispatch(showErrorNotification(error));
};

const REST_METHODS = ['get', 'post', 'put', 'delete'];

const createRequestParams = (path, data, method) => {
    if (!path) return false;

    const newParams = {
        url: URLS.core[path] ? URLS.core[path].url : ''
    };

    if (!data) return newParams;

    // Set path.
    if (data.path) {
        newParams.url += `/${data.path}`;
    }

    // Set headers.
    newParams.headers = data.jsonType
        ? { 'content-type': 'application/json; charset=UTF-8' }
        : { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' };

    // Set request data.
    if (!data.jsonType) {
        if (data.file) {
            newParams.params = data.data.data;
            newParams.data = data.data.file;
        } else {
            if (method === 'get') {
                newParams.params = data.data;
            } else {
                newParams.data = objToGETString(data.data);
            }
        }
    } else {
        if (method === 'get')
            newParams.params = data.data;
        else
            newParams.data = data.data;
    }

    if (data.waitFile) {
        newParams.responseType = 'blob';
    }

    return newParams;
};


export default new Proxy({}, {
    get(target, method) {
        // Check the correspondence of the called method to the methods rest.
        if (!REST_METHODS.includes(method)) {
            return Promise.reject('A non-supported REST method');
        }

        // Return request promise.
        return (path, data, skipErrorNotification) => {
            const requestId = v4();
            store.dispatch(startRequest({ id: requestId, path, data }));

            return axios({ method, ...createRequestParams(path, data, method) })
                .then(response => {
                    if (response.data && response.data.errorMessage && ['not_logged', 'no_session_id']
                        .includes(response.data.errorMessage)) {
                        sessionStorage.clear();
                        window.location.href = URLS.core.not_auth.redirect_url();
                        return true;
                    }

                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject({ error: response.statusText, response });
                    }

                    if (response.data && response.data.errorMessage) {
                        return Promise.reject({ error: response.data.errorMessage, response });
                    }
                    
                    store.dispatch(endRequest(requestId));
                    
                    return response.data
                    
                }).catch(({ error, response }) => {
                    store.dispatch(endRequest(requestId));
                    if (!skipErrorNotification) {
                        notifyError({ message: error, response });
                    }
                    return { message: error, response };
                });
        };
    },
});


// Custom error object to cancel the request.
export function AbortError(message) {
    Error.call(this, message);
    this.name = 'AbortError';
    this.message = `The request was canceled. ${message}`;

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, AbortError);
    } else {
        this.stack = (new Error()).stack;
    }
}

AbortError.prototype = Object.create(Error.prototype);
