import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import cx from 'classnames';
import { translate } from 'react-i18next';

@translate()
class CommentItem extends React.Component {

  constructor(){
    super();
    this.state = {
      multiLine: false,
      open: false
    };

    this.contentRef = React.createRef();
    this.LINE_HEIGHT = 21;
    this.MAX_LINES_COUNT = 3;
  }

  componentDidMount() {
    const textHeight = this.contentRef.current.offsetHeight;
    if (textHeight / this.LINE_HEIGHT > this.MAX_LINES_COUNT) {
      this.setState({ multiLine: true });
    }
  }

  toggleContent = () => this.setState(prevState => ({ open: !prevState.open }));

  render(){
    const { data, highlight, t } = this.props;
    const { multiLine, open } = this.state;
    return (
      <li className={cx(styles.historyItem, { [styles.multiLine]: multiLine })}>
        <i className='icon-chat'/>
        <div className={cx(styles.historyItemContent, { [styles.limited]: multiLine && !open })} ref={this.contentRef}>
          <span className={styles.historyTitleText}>{t('appealHistory.comment')}: </span>
          <span className={styles.historyContentText}>{highlight(data.text)}</span>
        </div>
        { multiLine &&
          <button className={cx(styles.contentToggleButton, styles.absolute)} onClick={this.toggleContent}>
            <i className='icon-kebab-hor' />
          </button>
        }
      </li>
    );
  }
}

CommentItem.propTypes = {
  data: PropTypes.object,
  highlight: PropTypes.func
};

export default CommentItem;
