import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Field, formValueSelector, getFormValues } from 'redux-form';

import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import FormWrapper from '../FormWrapper';
import { reduxFormWrapper, createCheckPropsFunction } from 'helpers';
import { StreetModes, customerAddressFormConfig, customerAddressFormConfigWithType } from 'constants/index';
import baseService from 'services/BaseService';
import StreetField from './StreetField/index';
import validate from './validate';


const mapStateToProps = (state, ownProps) => {
    const formSelector = formValueSelector(ownProps.form);

    return {
        ...formSelector(state, 'settlement', 'street', 'buildingNumber', 'zipCode', 'isSameAsPhysical'),
        mode: formSelector(state, 'mode') || StreetModes.AUTO,
        physicalAddressValues: getFormValues('physical-address-form')(state),
        juridicalAddressValues: getFormValues('juridical-address-form')(state)
    };
};

@translate()
@connect(mapStateToProps)
@reduxFormWrapper({ validate })
class AddressForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            settlementOptions: [],
            streetOptions: [],
            buildingNumberOptions: [],
            zipCodeOptions: []
        };

        this.linkedFields = ['settlement', 'street', 'buildingNumber', 'zipCode'];
        this.addressTypes = this.convertDictionaryObject(props.t('dictionary:addressType', { returnObjects: true }));
        this.clearAllValuesWithoutSettlement = this.clearAllValuesWithoutSettlement.bind(this);
    }

    componentDidUpdate(prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        const { addressType, enableWarning, initialValues, physicalAddressValues, juridicalAddressValues, mode} = this.props;

        if (isPropChanged('isSameAsPhysical') && !isPropChanged('addressType') && addressType === 'juridical') {
            this.synchronizeWithPhysical();
        }
        const key = addressType === 'juridical'? 'juridicalAddress' : 'address';
        if (!this.props.pristine && prevProps.pristine && enableWarning) {
            enableWarning({ [key]: true });
        } else if (this.props.pristine && !prevProps.pristine && enableWarning) {
            enableWarning({ [key]: false });
        }
    }
    
    clearAllValuesWithoutSettlement () {
		if(this.props.mode === StreetModes.AUTO) {
			this.props.change('buildingSecondNumber', null);
			this.props.change('apartmentNumber', null);
			this.resetLinkedFields('street');
			this.resetLinkedFields('buildingNumber');
			this.resetLinkedFields('zipCode');
		}
	
    }
    
    synchronizeWithPhysical = () => {
        const { isSameAsPhysical, physicalAddressValues, juridicalAddressValues } = this.props;
        if (!isSameAsPhysical) {

            this.buildingNumberRef.current.setInputValue('');
            this.zipCodeRef.current.setInputValue('');
            this.props.initialize({ mode: StreetModes.AUTO });

        } else {

            const values = { ...juridicalAddressValues };

            Object.keys(physicalAddressValues).forEach(key => {
                if (!isEmpty(physicalAddressValues[key])) {
                    values[key] = physicalAddressValues[key];
                }
            });

            if (values.mode === StreetModes.MANUAL && !values.streetType && !values.streetName) {
                values.mode = StreetModes.AUTO;
            }

            if (physicalAddressValues.buildingNumberInput) this.buildingNumberRef.current.setInputValue(physicalAddressValues.buildingNumberInput);
            if (physicalAddressValues.zipCodeInput) this.zipCodeRef.current.setInputValue(physicalAddressValues.zipCodeInput);

            this.props.initialize(values);
        }
    };

    // *** Reference setters ***

    onStreetFieldRefReady = streetFieldRef => {
        this.streetFieldRef = streetFieldRef;
    };

    onZipCodeFieldRefReady = zipCodeFieldRef => {
        this.zipCodeRef = zipCodeFieldRef;
    };

    onBuildingNumberFieldRefReady = buildingNumberFieldRef => {
        this.buildingNumberRef = buildingNumberFieldRef;
    };

    resetLinkedFields = field => {
        const fieldsForReset = this.linkedFields.slice(this.linkedFields.indexOf(field) + 1);
        fieldsForReset.forEach(field => this.props.change(field, null));

        const nextState = {};
        fieldsForReset.reduce((acc, field) => {
            acc[`${field}Options`] = [];
            return acc;
        }, nextState);
        this.setState({ ...nextState });
    };

    // *** onChange handlers ***

    onInputChange = field => inputValue => {

        const query = !inputValue && this.props[field] ? this.props[field].label : inputValue;

        switch (field) {
        case 'settlement':
            this.onSettlementChange(query);
            break;
        case 'street':
            this.onStreetChange(query);
            break;
        case 'buildingNumber':
            this.props.change(`${field}Input`, inputValue);
            break;
        case 'zipCode':
            this.props.change(`${field}Input`, inputValue);
            this.onZipCodeChange(query);
            break;
        }

        return inputValue;
    };

    onSettlementChange = debounce(query => {
        if (!query || query.length < 3) return;
        return this.requestSettlements(query)
            .then(response => {
                if (response.success) {
					this.clearAllValuesWithoutSettlement();
					this.setState({ settlementOptions: this.convertSettlementResponse(response.result) });
                } else {
                    this.setState({ settlementOptions: [] });
                }
            });
    }, 500);

    onStreetChange = debounce(query => {
        if (!this.props.settlement || !query || query.length < 2 || get(this.props, 'street.label') === query) return;

        return this.requestStreets(query, this.props.settlement.id)
            .then(response => {
                if (response.success) {
					this.props.change('buildingSecondNumber', null);
					this.props.change('apartmentNumber', null);
					this.resetLinkedFields('buildingNumber');
					this.resetLinkedFields('zipCode');
                    this.setState({ streetOptions: this.convertStreetResponse(response.result) });

                } else {
                    this.setState({ streetOptions: [] });
                }
            });
    }, 500);

    onZipCodeChange = debounce(query => {

        const isQueryShort = !query || query.length < 5;
        const areSettlementAndStreetEmpty = !this.props.settlement && !this.props.street;

        if (isQueryShort || !areSettlementAndStreetEmpty || this.props.mode !== StreetModes.AUTO) {
            return;
        }

        return this.requestZipCodesSearch(query)
            .then(response => {
                if (response.success && response.result && response.result.length > 0) {
                    this.setAddressFoundByIndex(response.result);
                }
            });
    }, 300);

    setAddressFoundByIndex = results => {
        const streetOptions = this.convertStreetResponse(results[0].street);
        const settlement = this.convertSettlementResponse(results.slice(0, 1))[0];

        const focusStreetInput = () => this.streetFieldRef.current.input.input.focus();

        this.props.change('street', streetOptions[0]);
        this.props.change('settlement', settlement);
        this.setState({ settlementOptions: [], streetOptions }, focusStreetInput);
    };

    // *** Converters ***

    convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
        value: prop,
        label: value
    }));

    convertSettlementResponse = result => result.map(settlement => {
        let cutString = settlement.fullName.indexOf('/', 1);
        let tempPartLabel = cutString >= 0 ? settlement.fullName.substr(cutString) : '';
        let modifiedLabel = settlement.name + tempPartLabel;

        return {
            label: modifiedLabel,
            value: settlement.fullName,
            id: settlement.id,
            fullName: settlement.fullName,
            title: modifiedLabel
            };
    });

    convertStreetResponse = result => result.map(street => ({ label: street.name, value: street.id, type: street.type }));

    convertBuildingResponse = result => result.map(building => ({ label: building.name, value: building.id }));

    convertZipCodeResponse = result => result.map(zipCode => ({ label: zipCode.zip_code, value: zipCode.id }));

    // *** Requests ***

    requestSettlements = query => {
        const data = { query, page: 1, start: 0, limit: 10 };
        return baseService.get('search_settlement', { data });
    };

    requestStreets = (query, settlementId) => {
        const data = { query, settlementId, page: 1, start: 0, limit: 10 };
        return baseService.get('search_street', { data });
    };

    requestBuildings = streetId => {
        const data = { streetId };
        return baseService.get('search_building', { data });
    };

    requestZipCodes = (streetId, buildId) => {
        const data = { streetId, buildId };
        return baseService.get('search_zip_code', { data });
    };

    requestZipCodesSearch = query => {
        const data = { query };
        return baseService.get('search_settlement_by_zip_code', { data });
    };

    getSettlementOptionComponent = () => props => {
        return (
            <div className={cx(props.className, 'settlement-option')} onClick={() => props.selectValue(props.option)} title={props.option.label}>
                <div className={'settlementFullName'}>{props.option.label}</div>
            </div>
        );
    };

    handleChange = (event, newValue, previousValue, fieldName) => {
        this.resetLinkedFields(fieldName);

        if (fieldName === 'street' && newValue) {
            this.requestBuildings(newValue.value)
                .then(response => {
                    this.setState({ buildingNumberOptions: response.success ? this.convertBuildingResponse(response.result) : [] });
                });
        }

        if (fieldName === 'buildingNumber' && newValue) {
            this.requestZipCodes(this.props.street.value, newValue.value)
                .then(response => {
                    this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] });
                });
        }
    };

    onStreetModeChange = newStreetMode => {
		this.clearAllValuesWithoutSettlement();
    	this.props.change('mode', newStreetMode);
	};

    getFieldPropsWithOptions = fieldProps => {
        switch (fieldProps.name) {
        case 'settlement':
            return {
                ...fieldProps,
                options: this.state.settlementOptions,
                onInputChange: this.onInputChange('settlement'),
                optionComponent: this.getSettlementOptionComponent(),
                onChange: this.handleChange,
            };
        case 'street':
            return {
                mode: this.props.mode,
                onModeChange: this.onStreetModeChange,
                fieldConfig: fieldProps,
                options: this.state.streetOptions,
                onInputChange: this.onInputChange('street'),
                onChange: this.handleChange,
                changeField: this.props.change,
                id: 'streetInput',
                openOnFocus: true,
                onRefReady: this.onStreetFieldRefReady
            };
        case 'buildingNumber':
            return {
                ...fieldProps,
                onRefReady: this.onBuildingNumberFieldRefReady,
                options: this.state.buildingNumberOptions,
                onChange: this.handleChange,
                onInputChange: this.onInputChange('buildingNumber'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };
        case 'zipCode':
            return {
                ...fieldProps,
                onRefReady: this.onZipCodeFieldRefReady,
                options: this.state.zipCodeOptions,
                onInputChange: this.onInputChange('zipCode'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };

        default:
            return fieldProps;
        }
    };

    renderInputField = fieldProps => {

        switch (fieldProps.name) {
        case 'street':
            return <StreetField key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        default:
            return <Field key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        }
    };

    getTitle = () => {
        const titles = {
            physical: 'Фактична адреса',
            juridical: 'Юридична адреса'
        };

        return titles[this.props.addressType] || '';
    };

    getConfig = () => {
        let config = customerAddressFormConfig;
        if (this.props.addressType === 'juridical') {
            const newFieldConfig = {
                name: 'isSameAsPhysical',
                label: 'Юридична адреса співпадає з фактичною адресою',
                component: CheckBoxToggle
            };
			config = [newFieldConfig, ...customerAddressFormConfig];
        }
        return config;
    };

    render() {
        const { handleSubmit, customerType } = this.props;
        return (
            <FormWrapper
                title={this.getTitle()}
                onSubmit={handleSubmit}
                customerType={customerType}
            >
                {this.getConfig().map(this.renderInputField)}
            </FormWrapper>
        );
    }
}

AddressForm.propTypes = {
    onSubmit: PropTypes.func,
    form: PropTypes.string.isRequired,
    customerType: PropTypes.string,
    addressType: PropTypes.string
};

export default AddressForm;
