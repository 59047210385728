import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { destroy } from 'redux-form';

import Avatar from 'components/Avatar/index';
import CustomerNameField from './CustomerNameField';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import {
    clearApplicantsState, clearContactPersonsList,
    clearContactPersonState, clearCustomerState,
} from 'actions/customer';
import { customerFormNameBuilder, searchCustomersInAppeals } from 'helpers';
import { unlockAppealForm, clearAppealFeedback, removeCustomerFromAppeal } from 'actions/appeal';
import { clearEntireCustomerState } from 'actions/customer';
import { CustomerTypes } from 'constants/index';
import styles from 'styles/modules/appealOperatePage.module.scss';

const mapDispatchToProps = {
    clearApplicantsState,
    clearContactPersonsList,
    clearCustomerState,
    clearContactPersonState,
    clearAppealFeedback,
    unlockAppealForm,
    removeCustomerFromAppeal,
    clearEntireCustomerState,
    destroyForm: (name) => destroy(name),
};

@translate()
@withRouter
@connect(null, mapDispatchToProps)
class CustomerNameBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.closeCustomer = this.closeCustomer.bind(this);
        this.openCustomerWarningPopUp = this.openCustomerWarningPopUp.bind(this);
        this.closeCustomerWarningPopUp = this.closeCustomerWarningPopUp.bind(this);
        this.state = {
            warningPopupOpen: false
        }
    }
    
    openCustomerWarningPopUp () {
        this.setState({ warningPopupOpen: true })
    }
    
    closeCustomerWarningPopUp () {
        this.setState({ warningPopupOpen: false })
    }

    closeCustomer() {
        // event.stopPropagation();
        // event.preventDefault();

        const { customerId, appeals, match: { params: { appealId } } } = this.props;
        const splittedHistory = this.props.history.location.pathname.split('/');
        const url = this.props.match.url.split('/')[2];

        if (splittedHistory[4]) {
            this.props.history.push(`/appeals/${url}/knowledge_base`);
        }
        
        // this.props.clearContactPersonState();
        // this.props.clearCustomerState();
        // this.props.clearContactPersonsList();
        // this.props.clearApplicantsState();
        
        if (searchCustomersInAppeals(appeals, customerId).length === 1) {
            this.props.clearEntireCustomerState(customerId);
            setTimeout(() => {
                customerFormNameBuilder(customerId).forEach((name) => this.props.destroyForm(name));
            }, 1000);
        }
        
        this.props.clearAppealFeedback(appealId);
        this.props.removeCustomerFromAppeal(appealId);
        this.props.unlockAppealForm(appealId);
        
    };

    getCustomerName = () => {
        const { customer } = this.props;

        const names = {
          [CustomerTypes.NATURAL_PERSON]: get(customer, 'party.fullName', ''),
          [CustomerTypes.ORGANIZATION]: get(customer, 'party.officialName', '')
        };

        return names[customer.party.partyType] || '';
    };

    render(){
        const {
            customer, onClick, isContactPerson, disabled, isEditRestricted, isCloseRestrictedButton,
            customerId, unlockAppealForm,
        } = this.props;
        const { warningPopupOpen } = this.state;
        return (
            <div className={styles.avatar} onClick={onClick}>
                <Avatar
                    name={this.getCustomerName()}
                    className={styles.customerAvatar}
                    customerType={customer.party.partyType}
                />
                <CustomerNameField
                    customer={customer}
                    isContactPerson={isContactPerson}
                    disabled={disabled}
                    appealId={this.props.match.params.appealId}
                    unlockAppealForm={unlockAppealForm}
                    isEditRestricted={isEditRestricted}
                    customerId={customerId}
                />
                {
                    isCloseRestrictedButton &&
                        <button className={styles.btnClose} onClick={this.openCustomerWarningPopUp}>
                            <i className='icon-close'/>
                        </button>
                }
    
                {
                    warningPopupOpen &&
                    <ModalPortal
                        onClose={this.closeCustomerWarningPopUp}
                        className='modal-small'
                    >
                        <MessageModal
                            contentModalText={'Відв \'язати Заявника?'}
                            primaryButton={true}
                            primaryButtonText={'Так'}
                            onClickPrimaryButton={this.closeCustomer}
                            secondaryButton={true}
                            secondaryButtonText={'НІ'}
                            onClickSecondaryButton={this.closeCustomerWarningPopUp}
                        />
                    </ModalPortal>
                }
            </div>
        );
    }
}

CustomerNameBlock.propTypes = {
  customer: PropTypes.object,
  isEditRestricted: PropTypes.bool,
  isCloseRestrictedButton: PropTypes.bool,
  onClick: PropTypes.func,
  isContactPerson: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CustomerNameBlock;
