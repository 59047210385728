import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ClickOutsideHolder extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.onClickOutside(e);
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        ref={this.setWrapperRef}
        onWheel={this.props.onWheel}
        style={this.props.top && this.props.left && {
          position: 'absolute',
          top: this.props.top,
          left: this.props.left
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

ClickOutsideHolder.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.node,
  top: PropTypes.number,
  left: PropTypes.number
};
