import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

class StreetOption extends React.Component {

  onFocus = event => {
    if (!this.props.isFocused) {
      this.props.onFocus(this.props.option, event);
    }
  };

  onMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.option.value === -1) {
      this.props.onCreateNewStreet();
    } else {
      this.props.onSelect(this.props.option, event);
    }
  };

  render(){
    const {
      className,
      option,
      optionIndex,
      instancePrefix,
      children
    } = this.props;

    const optionClassName = cx(className, 'pointer', option.className, {
      'primary-bold': option.value === -1
    });

    return (
      <div className={optionClassName}
           style={option.style}
           role="option"
           aria-label={option.label}
           id={`${instancePrefix}-option-${optionIndex}`}
           title={option.title}
           onMouseMove={this.onFocus}
           onMouseEnter={this.onFocus}
           onMouseDown={this.onMouseDown}
      >
        {children}
      </div>
    );
  }
}

StreetOption.propTypes = {
  onCreateNewStreet: PropTypes.func
};

export default StreetOption;
