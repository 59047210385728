import React from 'react';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TabContent from './TabContent';
import Avatar from 'components/Avatar';
import EditCustomerNameForm from './EditCustomerNameForm';
import Popup from 'components/Popup';
import { customerEdit, updateCustomerTabContent } from 'actions/customer';
import { createNewAppeal } from 'actions/appeal';
import { openCreateNewContactPersonModal, openCustomerAddressModal, openCustomerServiceModal, openCreateNewOrderModal } from 'actions/ui';
import NewEntityButton from './NewEntityButton';
import CustomerAddressModal from 'components/CustomerAddressModal';
import styles from 'styles/modules/customerContent.module.scss';
import { RouteWithTabs, isOrganization } from 'helpers';
import CustomerServiceModal from 'components/CustomerServiceModal';
import permissions, { checkPermissions } from 'config/permissions';
import tabsParams from 'constants/tabsParams';
import downloadCSV from 'actions/downloadCSV';
import { setTabName } from 'actions/tabs';
import { extractCustomerFromState } from 'helpers';

function mapStateToProps(state, props) {
    const customer = extractCustomerFromState(state, props.match.params.id || props.id);

    return {
        id: props.match.params.id || props.id,
        showCustomerAddressModal: state.ui.showCustomerAddressModal,
        showCustomerServiceModal: state.ui.showCustomerServiceModal,
        contactPersons: customer.contactPersons,
        customerType: props.customerType || customer.currentCustomer.party.partyType,
        isCustomerManageContactPersons: checkPermissions(permissions.CustomerOperations.action_manageContactPersons),
        isViewAddress: checkPermissions(permissions.CustomerOperations.view_addresses),
        isEditAddress: checkPermissions(permissions.CustomerOperations.action_manageAddresses),
        isEditCustomer: checkPermissions(permissions.CustomerOperations.action_editCustomer),
        isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer),
        currentTab: state.tabs.current,
        // fake check, not founded permission for action_createOrder, change it later
        isOrderCreationAllowed: checkPermissions(
            permissions.CustomerOperations.action_createCustomer,
        )
    };
}

const mapDispatchToProps = {
    customerEdit,
    createNewAppeal,
    openCreateNewContactPersonModal,
    openCustomerAddressModal,
    openCustomerServiceModal,
    updateCustomerTabContent,
    setTabName,
    openCreateNewOrderModal
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gridPopupIsOpen: false,
            showEntityButton: false,
        };
        this.wait = false;
        this.export = this.export.bind(this);
    }

    componentDidMount() {
        const { currentTab, setTabName, customerName } = this.props;
        if (!currentTab.displayedName) {
            setTabName(currentTab.name, customerName);
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const { customerType, match, isCustomerCreationAllowed, isEditAddress, isCustomerManageContactPersons } = nextProps;
        const tabName = match.params.subTab;

        if (customerType && tabName) {
            if ((isOrganization(customerType) && tabName === 'appeals') ||
                (tabName === 'services') ||
                (tabName === 'persons' && !isCustomerCreationAllowed)) {
                return { showEntityButton: false };
            } else if (tabName === 'address') {
                return { showEntityButton: isEditAddress };
            } else {
                return { showEntityButton: isCustomerManageContactPersons };
            }
        }
        return null;
    }

    toggleGridPopup = () => this.setState({ gridPopupIsOpen: !this.state.gridPopupIsOpen });

    // organization doesn't have "new appeal button"
    onNewAppealClick = () => {
        if (this.wait) return;
        this.wait = true;

        const appealRequestData = { customerId: this.props.match.params.id };

        this.props.createNewAppeal({ appealRequestData })
            .then(createdAppeal => {
                if (createdAppeal) this.props.history.push(`/appeals/${createdAppeal.id}/knowledge_base`);
                this.wait = false;
            });
    };

    handleNewEntityClick = () => {
        const { match, openCreateNewContactPersonModal, openCustomerAddressModal, openCustomerServiceModal, openCreateNewOrderModal } = this.props;
        const { subTab, id } = match.params;

        if (subTab === 'persons') {
            return openCreateNewContactPersonModal({
                customerId: id,
                onSuccess: this.props.updateCustomerTabContent,
            });
        }
        else if (subTab === 'appeals') {
            this.onNewAppealClick();
        }
        else if (subTab === 'address') {
            openCustomerAddressModal();
        }
        else if (subTab === 'services') {
            openCustomerServiceModal();
        }
        else if (subTab === 'tasks') {
            openCreateNewOrderModal({ openNewTab: true, customer: id })
        }
    };

    handleSubmit = values => {
        const { customerEdit, customerId, customerType, id } = this.props;
        if (isOrganization(customerType)) {
            customerEdit({
                data: {
                    customerId,
                    field: 'party.officialName',
                    text: values.name
                },
                id
            });
        } else {
            customerEdit({
                data: {
                    personId: null,
                    customerId,
                    edit: [
                        { text: values.lastName, field: 'party.lastName' },
                        { text: values.firstName, field: 'party.firstName' },
                        { text: values.patronymic, field: 'party.patronymic' }
                    ]
                },
                id,
                isMulti: true
            });
        }
    };

    renderTabs = props => {
        const { tabsNames, match, location, isViewAddress, isOrderCreationAllowed } = this.props;

        let tabProps = { ...props, match, location, tabsNames };
        if (!isViewAddress) {
            delete tabProps.tabsNames.address;
            tabProps = { ...tabProps, tabsNames: tabProps.tabsNames };
        }
        if (!isOrderCreationAllowed) {
            delete tabProps.tabsNames.tasks;
            tabProps = { ...tabProps, tabsNames: tabProps.tabsNames };
        }
        switch (props.match.params.subTab) {
            case 'appeals':
            case 'address':
            case 'contracts':
            case 'persons':
            case 'tasks':
                return <TabContent {...tabProps} />;
            default:
                return null;
        }
    };

    export() {
        const { currentTab } = this.props;
        const tabData = _.get(currentTab, 'queries.appeals');
        const appealFields = Object.entries(tabsParams(this.props.t).filter)
            .reduce((acc, [key, value]) => {
                const nameParts = [key];
                if (value.objectField) nameParts.push(value.objectField);

                acc[nameParts.join('.')] = value.name;

                return acc;
            }, {});
        const requestData = {
            fields: appealFields,
            customerId: Number(this.props.match.params.id),
            start: null,
            limit: null,
        };

        if(tabData.sort) {
            requestData['sort'] = {
                field: tabData.sort.field,
                isReversed: tabData.sort.isReversed
            }
        }
        downloadCSV({ requestData, key: 'appeal_customer_csv' }, this.toggleGridPopup );
    }

    render() {
        const {
            location, customerName, customerPatronymic, customerLastName, customerFirstName,
            customerType, showCustomerAddressModal, showCustomerServiceModal, t, contactPersons,
            match, isEditCustomer, isEditAddress, id,
        } = this.props;
        const tabName = match.params.subTab;
        const { gridPopupIsOpen, showEntityButton } = this.state;
        const titleButton = isOrganization(customerType) && contactPersons.length === 0 && tabName === 'appeals' ? 'persons' : tabName;

        return (
            <main className={styles.customerContent}>
                <header className={styles.customerContentHeader}>
                    <div className={styles.headerLeft}>
                        <Avatar name={customerName} customerType={customerType} />
                        <EditCustomerNameForm
                            form={`${id}-edit-customer-name`}
                            onSubmit={this.handleSubmit}
                            customerType={customerType}
                            initialValues={{
                                lastName: String(customerLastName).replace(/null/g, '').trim(),
                                firstName: String(customerFirstName).replace(/null/g, '').trim(),
                                patronymic: String(customerPatronymic).replace(/null/g, '').trim(),
                                name: String(customerName).replace(/null/g, '').trim()
                            }}
                            managedContactPerson={isEditCustomer}
                            isEditAddress={isEditAddress}
                        />
                    </div>
                    <div className='actions'>
                        {
                            showEntityButton &&
                            <NewEntityButton
                                title={titleButton}
                                onClick={this.handleNewEntityClick}
                            />
                        }
                        <div className='btn-add' onClick={this.toggleGridPopup}>
                            <i className='icon-kebab-vert' />
                        </div>
                        {
                            gridPopupIsOpen &&
                            <Popup
                                place='customer-grid-popup'
                                onClickOutside={this.toggleGridPopup}
                            >
                                <button onClick={this.export} className='popup-link'>{t('filters.exportExcel')}</button>
                            </Popup>
                        }
                    </div>
                </header>
                <Switch>
                    <RouteWithTabs
                        path='/customer/:id/:subTab?'
                        render={this.renderTabs}
                        tabs={['appeals', 'address', 'services', 'contracts', 'persons', 'tasks']}
                        tabPath={'subTab'}
                        match={match}
                        location={location}
                    />
                    <Route exact strict path='/customer/:id' render={(props) => <Redirect to={`${props.location.pathname}/appeals`} />} />
                    <RouteWithTabs
                        path='/appeals/:appealId/customer/:id/:subTab?'
                        render={this.renderTabs}
                        tabs={['appeals', 'address', 'services', 'contracts', 'persons', 'tasks']}
                        tabPath={'subTab'}
                        match={match}
                        location={location}
                    />
                    <Route
                        exact
                        strict
                        path='/appeals/:appealId/customer/:id'
                        render={(props) => <Redirect to={`${props.location.pathname}/appeals`} />}
                    />
                    <RouteWithTabs
                        path='/order/:orderId/customer/:id/:subTab?'
                        render={this.renderTabs}
                        tabs={['appeals', 'address', 'services', 'contracts', 'persons', 'tasks']}
                        tabPath={'subTab'}
                        match={match}
                        location={location}
                    />
                    <Route
                        exact
                        strict
                        path='/order/:orderId/customer/:id'
                        render={(props) => <Redirect to={`${props.location.pathname}/tasks`} />}
                    />
                </Switch>
                {isEditAddress && showCustomerAddressModal && <CustomerAddressModal />}
                {showCustomerServiceModal && <CustomerServiceModal />}
            </main>
        );
    }
}

MainContent.propTypes = {
    // ownProps
    customer: PropTypes.object,
    tabsNames: PropTypes.object,
    customerName: PropTypes.string,
    customerType: PropTypes.string,
    customerId: PropTypes.number,
    customerPatronymic: PropTypes.string,
    customerFirstName: PropTypes.string,
    customerLastName: PropTypes.string,
    // from connect mapDispatchToProps
    customerEdit: PropTypes.func,
    openGridPopUp: PropTypes.func
};
