import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/parameters.module.scss';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { openCustomerAddressModal } from 'actions/customer';
import cx from 'classnames';

const mapDispatchToProps = {
    openCustomerAddressModal,
};

@translate()
@connect(null, mapDispatchToProps)
class AddressField extends React.Component {

    constructor(props) {
        super(props);
        this.openAddressModal = this.openAddressModal.bind(this);
    }

    openAddressModal() {
        if (!this.props.disabled) {
            this.props.openCustomerAddressModal();
        }
    }

    render() {
        const { input, label, t, disabled } = this.props;

        const translatedLabel = t(`customerInfoLabel.${label}`);

        if (input.value) {
            return (
                <div className='input-element'>
                    <div className='input-label'>{translatedLabel}</div>
                    <div onClick={this.openAddressModal} className={styles.addressInputContainer}>
                        <div className={cx('input-field', { disabled })} title={input.value}>
                            {input.value}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.itemInput}>
                <div className={styles.inputLabel}>
                    {translatedLabel}
                </div>
                <button className={styles.addButton} onClick={this.openAddressModal}>
                    + Додати
                </button>
            </div>
        );
    }
}

AddressField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    openAddressModal: PropTypes.func
};

export default AddressField;
