import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from 'styles/modules/parameters.module.scss';

class SelectedOption extends React.PureComponent {

  render(){
    const { onRemove, value, disabled } = this.props;

    return (
      <div className={cx(styles.selectedSearchOptionContainer, { disabled })}>
        <span className={styles.selectedOption}>{value}</span>
          {
              disabled ? null : (
                  <button
                      type='button'
                      title='Remove'
                      className={styles.removeButton}
                      onClick={onRemove}
                  >
                      <span className='icon-close' />
                  </button>
              )
          }
      </div>
    );
  }
}

SelectedOption.propTypes = {
  onRemove: PropTypes.func,
  value: PropTypes.string.isRequired
};

export default SelectedOption;
