import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';


export default function HeaderRow({ children }) {
  return <div className={styles.headerRow}>{children}</div>;
}


HeaderRow.propTypes = {
  children: PropTypes.any
};
