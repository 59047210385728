import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import app from './app';
import ui from './ui';
import user from './user';
import tabs from './tabs';
import content from './content';
import customer from './customer';
import headerSearch from './headerSearch';
import liveSearch from './liveSearch';
import filter from './filter';
import client from './client';
import knowledgeBase from './knowledgeBase';
import comments from './comments';
import appeal from './appeal';
import emails from './emails';
import changeHistory from './changeHistory';
import call from './call';
import audioplayer from './audioplayer';
import knowledges from './knowledges';
import session from './session';
import settings from './settings';
import searchFilter from './searchFilter';
import search from './search';
import dashboard from './dashboard';
import orders from './orders';
import order from './order';
import grid from './grid';

const appReducer = combineReducers({
	routing: routerReducer,
    ui,
    app,
    user,
    tabs,
    content,
    form: formReducer,
    customer,
    headerSearch,
    liveSearch,
    filter,
    client,
    knowledgeBase,
    comments,
    appeal,
    changeHistory,
    emails,
    call,
    audioplayer,
    knowledges,
    session,
    settings,
    searchFilter,
    search,
    dashboard,
    orders,
    order,
    grid,
});

const rootReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_STATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    tabCachingEnabled: true,
                },
                ...action.nextState,
            };

        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
