import {
    CLOSE_CREATE_NEW_CUSTOMER_MODAL,
    OPEN_ASIDE_TOP_MENU,
    OPEN_CREATE_NEW_CUSTOMER_MODAL,
    UI_TOGGLE_SIDE_MENU_ADDITIONS,
    UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
    SHOW_TABS_CLOSE_MENU,
    SHOW_TABS_HIDDEN_MENU,
    TOGGLE_LANG_MENU,
    UI_MENU_APPS_LOAD,
    UI_MENU_APPS_SHOW,
    UI_MENU_CREATE_SHOW,
    UI_MENU_CREATE_HIDE,
    OPEN_MESSAGE_MODAL,
    CLOSE_MESSAGE_MODAL,
    CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
    OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
    OPEN_CUSTOMER_ADDRESS_MODAL,
    CLOSE_CUSTOMER_ADDRESS_MODAL,
    OPEN_CUSTOMER_SERVICE_MODAL,
    CLOSE_CUSTOMER_SERVICE_MODAL,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SHOW_NOTIFICATION_PANEL,
    HIDE_NOTIFICATION_PANEL,
    ADDRESS_SAVE,
    OPEN_USER_POPUP,
    CLOSE_USER_POPUP,
    OPEN_CREATE_NEW_ORDER_MODAL,
    CLOSE_CREATE_NEW_ORDER_MODAL,
} from 'constants/actions';
import baseService from '../services/BaseService';

export const showNotification = ({ type, options }) => ({
    type: SHOW_NOTIFICATION,
    payload: { type, options },
});

export const hideNotification = (id) => ({
    type: HIDE_NOTIFICATION,
    payload: { id },
});

export const uiMenuAppsLoad = (apps) => ({
    type: UI_MENU_APPS_LOAD,
    apps,
});

export const openUserPopup = () => ({
    type: OPEN_USER_POPUP,
});

export const closeUserPopup = () => ({
    type: CLOSE_USER_POPUP,
});

export const showNotificationPanel = () => ({
    type: SHOW_NOTIFICATION_PANEL,
});

export const hideNotificationPanel = () => ({
    type: HIDE_NOTIFICATION_PANEL,
});

export const showCreateMenu = () => ({
    type: UI_MENU_CREATE_SHOW,
});

export const hideCreateMenu = () => ({
    type: UI_MENU_CREATE_HIDE,
});

export const loadAppsMenu = (dispatch) => {
    baseService.get('apps_menu').then((data) =>
        dispatch({
            type: UI_MENU_APPS_LOAD,
            apps: data.result,
        }),
    );
};

export const showAppsMenu = (dispatch) => ({
    type: UI_MENU_APPS_SHOW,
});

export const toggleHiddenTabs = () => ({
    type: SHOW_TABS_HIDDEN_MENU,
});

export const showMenu = ({ id }) => ({
    type: SHOW_TABS_CLOSE_MENU,
    payload: { id },
});

export const toggleLangMenu = ({ visible }) => ({
    type: TOGGLE_LANG_MENU,
    payload: { visible },
});

export const openAsideSubMenu = (id) => ({
    type: OPEN_ASIDE_TOP_MENU,
    payload: { id },
});

export const toggleSideMenuAdditions = () => ({
    type: UI_TOGGLE_SIDE_MENU_ADDITIONS,
});

export const toggleSideMenuAdditionsPerson = () => ({
    type: UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
});

export const openCreateNewCustomerModal = (context) => ({
    type: OPEN_CREATE_NEW_CUSTOMER_MODAL,
    context,
});

export const closeCreateNewCustomerModal = () => ({
    type: CLOSE_CREATE_NEW_CUSTOMER_MODAL,
});

export const openCreateNewOrderModal = (context) => ({
    type: OPEN_CREATE_NEW_ORDER_MODAL,
    context
});

export const closeCreateNewOrderModal = () => ({
    type: CLOSE_CREATE_NEW_ORDER_MODAL,
});

export const openCreateNewContactPersonModal = (context) => ({
    type: OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
    context,
});

export const closeCreateNewContactPersonModal = () => ({
    type: CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
});

export const openCustomerAddressModal = (address) => ({
    type: OPEN_CUSTOMER_ADDRESS_MODAL,
    payload: { address },
});

export const closeCustomerAddressModal = () => ({
    type: CLOSE_CUSTOMER_ADDRESS_MODAL,
});

export const openCustomerServiceModal = () => ({
    type: OPEN_CUSTOMER_SERVICE_MODAL,
});

export const closeCustomerServiceModal = () => ({
    type: CLOSE_CUSTOMER_SERVICE_MODAL,
});

export const addressSave = () => ({
    type: ADDRESS_SAVE,
});

export const openMessageModal = () => ({
    type: OPEN_MESSAGE_MODAL,
});

export const closeMessageModal = () => ({
    type: CLOSE_MESSAGE_MODAL,
});
