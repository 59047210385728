import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/appealOperatePage.module.scss';


export default function HeaderSecondaryCell({ children, separated }) {
  return (
    <div className={cx(styles.headerSecondaryCell, { [styles.separatedCell]: separated })}>{children}</div>
  );
}


HeaderSecondaryCell.propTypes = {
  children: PropTypes.any,
  separated: PropTypes.bool
};
