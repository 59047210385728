import React from 'react';
import { connect } from 'react-redux';
import { closeCustomerServiceModal } from 'actions/ui';
import ModalPortal from '../ModalPortal';
import { CSSTransition } from 'react-transition-group';
import { translate } from 'react-i18next';
import CustomerServiceForm from './CustomerServiceForm';

const mapStateToProps = state => ({
  showCustomerServiceModal: state.ui.showCustomerServiceModal,
});

const mapDispatchToProps = (dispatch) => ({
  closeCustomerServiceModal: () => dispatch(closeCustomerServiceModal()),
});

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class CustomerServiceModal extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  onClose = () => this.props.closeCustomerServiceModal();

  handleKeyPress = e => {
    if (e.keyCode === 27) {
      this.onClose();
    }
  };

  render() {
    const { showCustomerServiceModal, t } = this.props;
    return (
      <CSSTransition
        in={showCustomerServiceModal}
        classNames='fade'
        appear={true}
        enter={true}
        exit={true}
        timeout={500}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <ModalPortal
          onClose={this.onClose}
          className='medium-extra'
        >
          <CustomerServiceForm />
        </ModalPortal>
      </CSSTransition>
    );
  }
}

export default CustomerServiceModal;
