import React from 'react';
import { getFirstLetters, isOrganization } from 'helpers';
import styles from 'styles/modules/customerContent.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

function Avatar (props) {

  const formattedName = getFirstLetters(props.name);

  const organization = isOrganization(props.customerType);

  const avatarClassName = cx(
    styles.avatar, {
      [styles.organization]: organization,
      [styles.individual]: !organization
    },
    props.className
  );

  return (
    <div className={avatarClassName}>
      {formattedName}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  customerType: PropTypes.string
};

export default Avatar;
