import React from 'react';
import ComboBox from 'components/Common/ComboBox';
import { isValid } from '../validate';

function ComboBoxDecorator ({input, handleChangeValue, ...rest}) {
    
    function submitComboboxMulty(value, name) {
        if (isValid(value, name)) {
            handleChangeValue({
                value,
                name
            });
        }
    }
    
    function onChange () {
        input.onChange.apply(input, arguments);
        if (rest.multi) {
            return null;
        }
        if (isValid(arguments[0], input.name)) {
            handleChangeValue({
                value: arguments[0],
                name: input.name
            });
        }
    }
    
    const formInputProps = {...input, onChange};
    return (
        <ComboBox input={formInputProps} handleChangeValue={handleChangeValue} submitComboboxMulty={submitComboboxMulty} {...rest} />
    );
}

export default ComboBoxDecorator;
