import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

@translate()
export default class NewEntityButton extends React.Component {
  render() {
    const {title, onClick, t} = this.props;
    return (
      <button className='btn btn-primary' onClick={onClick}>
        {t(`newEntityButton.${title}`)}
      </button>
    );
  }
}

NewEntityButton.propTypes = {
  title: PropTypes.string
};
