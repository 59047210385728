import { validateEmail, individualTaxpayerNumber, PHONE_NUMBER, EMAIL, certificateIdValidation, REQUIRED_FIELD } from 'util/form';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';

/**
 * @param {any} value
 * @param {string} name - field name
 * @return {boolean|void} isValid
 */
export function isValid(value, name) {
    const validator = fieldLevelValidation[name];

    if (!validator) {
        console.warn(`Validator for field "${name}" is not found`);
        return true;
    }

    return validator(value) === undefined;
}

function validateDate(value) {
    if (!value || !value._i) return undefined;

    if ((value && moment.isMoment(value) && !value.isValid()) || value._i.length < 10) {
        return 'Введіть коректний формат дати (ДД.ММ.РРРР)';
    }
}

const validateSite = value => /^((((http|https):(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)(\.\w{2,3})))/.test(value);

function validateArray(value, errorCondition, errorText) {
    if (!value) return undefined;

    const errors = value.reduce((acc, fieldValue, index) => {
        if (errorCondition(fieldValue)) {
            acc[index] = errorText || 'Введіть коректний формат контакту';
        }
        return acc;
    }, {});
    return isEmpty(errors) ? undefined : errors;
}

export function phoneValidator(value, allValues, props) {
    if (!Array.isArray(value)) return undefined;

    const errorCondition = fieldValue => fieldValue && !/^[0-9]{3,12}$/.test(fieldValue);
    return validateArray(value.map(contact => contact.value), errorCondition, PHONE_NUMBER);
}

function faxValidator(value, allValues, props) {
    if (!Array.isArray(value)) return undefined;
    const errorCondition = fieldValue => fieldValue && !/^[0-9]{4,}$/.test(fieldValue);

    return validateArray(value.map(contact => contact.value), errorCondition);
}

function emailValidator(value, allValues, props) {
    if (value) {
        const errorCondition = fieldValue => fieldValue && !validateEmail(fieldValue);
        return validateArray(value.map(contact => contact.value), errorCondition, EMAIL);
    }
}

function siteValidator(value, allValues, props) {
    const errorCondition = fieldValue => fieldValue && !validateSite(fieldValue);
    return validateArray(value.map(contact => contact.value), errorCondition);
}

function selectValueValidator(value, allValues, props) {
    if (value && isObjectLike(value) && value.value) {
        return undefined;
    }

    return 'Поле обов\'язкове';
}

export const fieldLevelValidation = {
    mobilePhone: phoneValidator,
    homePhone: phoneValidator,
    workPhone: phoneValidator,
    organizationPhone: phoneValidator,
    fax: faxValidator,
    email: emailValidator,
    site: siteValidator,
    institutionId: individualTaxpayerNumber,
    taxId: certificateIdValidation,

    passportNumber: (value) => {
        if (value && !/^[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}/.test(value)) {
            return 'Введіть коректний номер паспорту';
        }

        return undefined;
    },

    identificationCode: (value, allValues, props, name) => {
        if (value && !/^([0-9]{5,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})/.test(value)) {
            return 'Введіть коректний формат ідентифікаційного коду';
        }

        return undefined;
    },

    docId: (value, allValues, props, name) => {
        if (value && !/^([0-9]{5,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})/.test(value)) {
            return 'Введіть коректний формат ідентифікаційного коду';
        }

        return undefined;
    },

    bankName: (value, allValues, props, name) => {
        if (!value) {
            return 'Поле обов\'язкове';
        }

        return undefined;
    },

    bankAccount: (value) => {
        if (!value) {
            return 'Поле обов\'язкове';
        } else if (!/^[0-9]{10}$/.test(value)) {
            return 'Введіть коректний номер рахунку';
        }

        return undefined;
    },

    issues: (value = '') => {
        if (value.length === 0) return 'Поле обов\'язкове';
    },

    birthDate: validateDate,
    externalDate: validateDate,
    passportIssuedDate: validateDate
};

function validate(values) {
    const errors = {};

    for (const fieldName in values) {
        if (values.hasOwnProperty(fieldName)) {
            const validator = fieldLevelValidation[fieldName];

            if (!validator) {
                console.debug(`Validator for field "${fieldName}" is not found`);
                continue;
            }

            const error = validator(values[fieldName]);
            if (error) {
                errors[fieldName] = error;
            }
        }
    }

    if (!values.taxId) {
        errors.taxId = REQUIRED_FIELD;
    }

    if (!values.institutionId) {
        errors.institutionId = REQUIRED_FIELD;
    }

    return errors;
}

export default validate;
