import React from 'react';
import PropTypes from 'prop-types';
import { KeyCodes } from 'constants/index';
import ModalPortal from 'components/ModalPortal';
import modalStyles from 'styles/modules/modal.module.scss';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import { translate } from 'react-i18next';
import AppealTypeSearchBlock from './AppealTypeSearchBlock';
import AppealTypesTree from './AppealTypesTree';
import cx from 'classnames';

@translate()
class AppealTypesTreeModal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            submittedQuery: '',
            selectedNode: null
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress(event) {
        if (event.keyCode === KeyCodes.ESCAPE) {
            this.props.onClose();
        }
    };

    onQueryChange(event) {
        this.setState({ query: event.target.value });
    }

    onSearchSubmit() {
        if (this.state.query !== this.state.submittedQuery) {
            this.setState(state => ({ submittedQuery: state.query }));
        }
    }

    onSelect(newSelectedNode) {
        const { valueField } = this.props;

        if (this.state.selectedNode && newSelectedNode && this.state.selectedNode[valueField] === newSelectedNode[valueField]) return;

        this.setState({ selectedNode: newSelectedNode });
    }

    onSubmitClick() {
        this.props.onSubmit(this.state.selectedNode);
    }

    render() {
        const { onClose, t } = this.props;
        const { query, submittedQuery, selectedNode } = this.state;

        const submitButtonClassName = cx('btn btn-primary', {
            ['btn-disabled']: !selectedNode
        });

        return (
            <ModalPortal onClose={onClose}>
                <div className={styles.appealTypeModal}>

                    <div className={modalStyles.modalHeader}>
                        <div className={modalStyles.modalTitle}>
                            {t('appeal.type')}
                        </div>
                    </div>

                    <AppealTypeSearchBlock
                        value={query}
                        onChange={this.onQueryChange}
                        onSubmit={this.onSearchSubmit}
                        placeholder={t('Search')}
                    />

                    <AppealTypesTree
                        {...this.props}
                        searchQuery={submittedQuery}
                        selectedLeaf={selectedNode}
                        onSelectLeaf={this.onSelect}
                    />

                    <footer className={styles.footer}>
                        <button
                            type="button"
                            className={submitButtonClassName}
                            disabled={!selectedNode}
                            onClick={this.onSubmitClick}
                        >
							{t('appeal.select')}
                        </button>
                    </footer>

                </div>
            </ModalPortal>
        );
    }
}

AppealTypesTreeModal.propTypes = {
    onClose: PropTypes.func.isRequired
    // props for tree
};

export default AppealTypesTreeModal;
