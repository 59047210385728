import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { buildThreePath } from 'helpers';

import Header from '../Header/index';
import FiltersForm from '../FiltersForm';
import GridWrapper from 'components/GridWrapper';
import Proxy from 'components/HOC/ContentTabProxy';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import {
    getInfoFilterSuppose,
    openFilterParams,
    closeNewFilterParams,
    closeParamsModal,
    saveFilter,
    deleteFilter,
    openedFilterAppeal
} from 'actions/filter';
import { openMessageModal, closeMessageModal } from 'actions/ui';
import { queriesContentUpdate } from 'actions/content';
import AppealForm from '../AppealForm';
import styles from 'styles/modules/appealsContent.module.scss';
import { translate } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { findType } from '../../../Appeal/helpers';
import { searchTree } from '../../../../helpers';

const mapStateToProps = (state, ownProps) => ({
    showMessageModal: state.ui.showMessageModal,
    tabs: state.tabs,
    saved: state.filter.saved,
    filter: state.filter,
    rootAppealType: get(state, 'filter.appealTypes[0]'),
});

const mapDispatchToProps = (dispatch) => ({
    getInfoFilterSuppose: (params) => dispatch(getInfoFilterSuppose(params)),
    closeNewFilterParams: () => dispatch(closeNewFilterParams()),
    openFilterParams: () => dispatch(openFilterParams()),
    queriesContentUpdate: (params) => dispatch(queriesContentUpdate(params)),
    openedFilterAppeal: (openedFilter) => dispatch(openedFilterAppeal(openedFilter)),
    closeParamsModal: () => dispatch(closeParamsModal()),
    openMessageModal: () => dispatch(openMessageModal()),
    closeMessageModal: () => dispatch(closeMessageModal()),
    deleteFilter: (params) => dispatch(deleteFilter(params)),
    saveFilter: (params) => dispatch(saveFilter(params))
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainContent extends React.Component {

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const { filter, closeNewFilterParams, openFilterParams, openedFilterAppeal } = this.props;
            if (filter.newFilter) {
                closeNewFilterParams();
                openFilterParams();
            }
            openedFilterAppeal(this.props.match.params.id);
        }
    }

    onClickSave = (data) => {
        const { saveFilter, closeMessageModal } = this.props;
        saveFilter({
            key: 'save_filter',
            data: data,
            method: 'POST'
        });
        closeMessageModal();
    };

    onSelectItem = (item) => this.props.history.push(`/appeals/${item.id}/knowledge_base`);

    onClickDelete = () => {
        const data = {
            filterId: this.props.match.params.id,
            code: 'appeal'
        };
        this.props.deleteFilter({ data });
        this.props.closeMessageModal();
    };

    openModal = (type, data) => {
        const { openMessageModal } = this.props;
        this.modalBody = this.renderModalBody(type, data);
        openMessageModal();
    };

    renderModalBody (type, data) {
        const { t } = this.props;
        switch (type) {
        case 'savePredefined': {
            return <MessageModal
                titleModal={t('filters.alreadyExisting')}
                contentModalText={t('filters.predefinedExisting')}
            />;
        }
        case 'saveUser': {
            return <MessageModal
                titleModal={t('filters.alreadyExisting')}
                contentModalText={t('filters.saveModalQuestion')}
                primaryButton={true}
                primaryButtonText={t('filters.save')}
                onClickPrimaryButton={() => this.onClickSave(data)}
                secondaryButton={true}
                secondaryButtonText={t('filters.cancel')}
                onClickSecondaryButton={this.props.closeMessageModal}
            />;
        }
        case 'delete': {
            return <MessageModal
                titleModal={t('filters.titleRemoveModal')}
                contentModalText={t('filters.titleRemoveFilter')}
                dangerButton={true}
                dangerButtonText={t('filters.remove')}
                onClickDangerButton={this.onClickDelete}
                secondaryButton={true}
                secondaryButtonText={t('filters.cancel')}
                onClickSecondaryButton={this.props.closeMessageModal}
            />;
        }
        default: {
            return null;
        }
        }
    }

    getInitialValues = () => {
        const { match, filter, filters = [], rootAppealType } = this.props;
        const { newFilter, filterAttrsResults = [], infoFilterParams, checkedFilterAttrs } = filter;

        const currentFilter = filters.find(el => el.value === match.params.id);

        const nameFilter = () => {
            if (newFilter) {
                const count = filters.reduce((count, item) => !item.predefined ? count + 1 : count, 0);
                return `Фiльтр ${count + 1}`;
            }

            return currentFilter ? currentFilter.name : '';
        };

        const setInitialValues = () => {
            if (newFilter) {
                return {
                    filterName: nameFilter(),
                    isPublic: false,
                    predefined: false
                };
            }

            const paramsIdArr = infoFilterParams && infoFilterParams.map(el => el.paramId);
            const checkParams = Object.keys(checkedFilterAttrs).filter(el => checkedFilterAttrs[el]);

            let paramsNameArray = [];

            if (checkParams.length) {
                paramsNameArray = filterAttrsResults.filter(el => paramsIdArr.includes(el.id) && checkParams.includes(el.code));
            } else {
                paramsNameArray = filterAttrsResults.filter(el => paramsIdArr.includes(el.id));
            }

            const initObj = {};

            paramsNameArray.forEach(field => {
                const fieldName = field.code;

                if (field.type === 'THREE') {
                    const options = buildThreePath(field.dict, false, {children: 'children'});
                    if (infoFilterParams.length && rootAppealType) {
                        const currentAppealType = [];
                            infoFilterParams.map((i) => {

                            const item = searchTree(options, 'children', result => {
                                return i.value === result.key
                            });

                            if (item) {
                                currentAppealType.push({
                                    value: item.key,
                                    label: item.name,
                                    path: item.path,
                                });
                            }
                        });

                        if (currentAppealType) {
                            initObj[fieldName] = currentAppealType;
                        }
                    }
                } else if (field.type === 'DATE') {
                    const foundValues = infoFilterParams.filter(elem => elem.paramId === field.id);

                    if (field.def_condition === 'EQU' && foundValues.length > 0) {
                        initObj[fieldName] = foundValues[0].value;

                    } else if (field.def_condition === 'BTW' && foundValues.length > 0) {

                        foundValues.forEach(date => {
                            const ending = date.conditionCode === 'LEQ' ? '@TO' : '@FROM';
                            initObj[`${fieldName}${ending}`] = date.value;
                        });
                    }

                } else if ((field.type === 'LIST') && (field.multiset_allowed === 'Y')) {
                    const listValue = infoFilterParams.filter(elem => elem.paramId === field.id);
                    initObj[fieldName] = listValue.map(el => el.value);
                    if (field.search && !field.dict_search) {
                        initObj[fieldName] = listValue.map(el => {
                                const option = field.dict.find((item) => {
                                    return el.value === item.key
                                });
                                return {
                                    value: option ? option.key : null,
                                    label: option ? option.value : '',
                                }
                            }
                        );
                    }
                    
                    if (field.dict_search) {
                        initObj[fieldName] = listValue.map(el => {
                            return {
                                value: el.paramId,
                                label: el.label,
                            }
                        });
                    }
                } else {
                    initObj[fieldName] = infoFilterParams.filter(elem => elem.paramId === field.id)[0].value;
                }
            });

            return {
                filterName: nameFilter(),
                isPublic: currentFilter ? !currentFilter.custom : true,
                ...initObj
            };
        };

        return setInitialValues();
    };

    render () {
        const { showMessageModal, match, tabs, filter, data, closeMessageModal, filters = [] } = this.props;
        const { newFilter, showFilterParams } = filter;
        const contentCls = cx(styles.gridContent, { 'open-filter': showFilterParams });

        const currentFilter = filters.find(el => el.value === match.params.id);
        const predefined = currentFilter ? currentFilter.predefined : false;

        const queryKey = get(tabs, 'current.queryKey');
        const checkedItems = get(tabs, `current.queries.${queryKey}.checkedItems`) || [];
        return (
            <div className={cx('main-content', styles.mainContent)}>
                {
                    filters &&
                    <Header
                        data={filters}
                        id={match.params.id}
                        openModal={this.openModal}
                    />
                }
                <div className={styles.contentAppeal}>
                    <div className={contentCls}>
                        <div className={styles.gridWrapper}>
                            <div className='gridWrapper'>
                                {data && (
                                    <GridWrapper
                                        data={data}
                                        tabs={tabs}
                                        match={match}
                                        groups={{
                                            selectItem: (item) => this.onSelectItem(item),
                                            groups: {
                                                form: (
                                                    <AppealForm
                                                        formData={{
                                                            fields: [],
                                                            checkedItems,
                                                            submitForm: (data) => console.log(data)
                                                        }}
                                                    />
                                                ),
                                                checkedItems,
                                                handleCheck: (params) => this.props.queriesContentUpdate({
                                                    ...params,
                                                    update: []
                                                })
                                            }
                                        }}
                                        queriesContentUpdate={this.props.queriesContentUpdate}
                                    />
                                )}

                            </div>
                        </div>
                    </div>
                    <CSSTransition
                        in={showFilterParams}
                        classNames='pageSliderRight'
                        appear={true}
                        enter={true}
                        exit={true}
                        timeout={500}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <div className={styles.filtersForm}>
                            <FiltersForm
                                initialValues={this.getInitialValues()}
                                filterId={newFilter ? null : match.params.id}
                                predefined={predefined}
                                filters={filters}
                                openModal={this.openModal}
                            />
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={showMessageModal}
                        classNames='fade'
                        appear={true}
                        enter={true}
                        exit={true}
                        timeout={500}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <ModalPortal
                            onClose={closeMessageModal}
                            className='modal-small'
                        >
                            {this.modalBody}
                        </ModalPortal>
                    </CSSTransition>

                </div>
            </div>
        );
    }
}

export default Proxy(MainContent, {
    api: data => ({
        key: 'preset_apply',
        data: { filterId: data.id, limit: 20, code: 'INTERACTION_REQUEST' },
        method: 'POST'
    }),
    tabType: data => 'appeal',
    queryKey: data => data && `appeal_${data.tab}_${data.id}`,
    storeName: data => data && data.tab,
    name: data => 'appeal'
});

MainContent.propTypes = {
    data: PropTypes.object,
    gridData: PropTypes.object,
    tabs: PropTypes.object,
    showMessageModal: PropTypes.bool,
    toggleAppealForm: PropTypes.func,
    saved: PropTypes.bool,
    filterAttrsResults: PropTypes.array,
    checkedFilterAttrs: PropTypes.object,
    getInfoFilterSuppose: PropTypes.func,
    closeNewFilterParams: PropTypes.func,
    openFilterParams: PropTypes.func,
    queriesContentUpdate: PropTypes.func,
    openedFilterAppeal: PropTypes.func,
    closeParamsModal: PropTypes.func,
    saveFilter: PropTypes.func,
    deleteFilter: PropTypes.func
};
