import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { translate } from 'react-i18next';

@translate()
class File extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			showMessageModal: false
		};
		this.input = React.createRef();
		this.closeMessageModal = this.closeMessageModal.bind(this);
		this.openMessageModal = this.openMessageModal.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
	}
	getFileLink = () => `../mw/file?fileId=${this.props.id}`;
	
	closeMessageModal() {
		this.setState({ showMessageModal: false });
	}
	
	onDeleteClick () {
		this.closeMessageModal();
		this.props.onDelete(this.props.index);
	}
	
	openMessageModal() {
		this.setState({ showMessageModal: true });
	}
	
	render() {
		const { name, disabled, t } = this.props;
		const { showMessageModal } = this.state;
		
		return (
			<div className='kb-file-wrapper'>
				<CSSTransition
					in={showMessageModal}
					classNames="fade"
					appear
					enter
					exit
					timeout={500}
					mountOnEnter
					unmountOnExit
				>
					<ModalPortal onClose={this.closeMessageModal} className="modal-small modal-center">
						<MessageModal
							titleModal={t("modalWindowsLabels.deleteFileTitle")}
							contentModalText={t("modalWindowsLabels.deleteFileMessage")}
							primaryButton
							primaryButtonText={t("modalWindowsLabels.allow")}
							onClickPrimaryButton={this.onDeleteClick}
							secondaryButton
							secondaryButtonText={t("modalWindowsLabels.disallow")}
							onClickSecondaryButton={this.closeMessageModal}
						/>
					</ModalPortal>
				</CSSTransition>
				<a href={this.getFileLink()} download={name}>
					{name}
				</a>
				{
					!disabled &&
					<i className='icon-times' onClick={this.openMessageModal} />
				}
			</div>
		);
	}
}

File.propTypes = {
  type: PropTypes.oneOf([ 'local', 'remote' ]),
  name: PropTypes.string,
  index: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
  disabled: PropTypes.bool
};

export default File;
