import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import Popup from '../Popup';

import { openCreateNewCustomerModal, showCreateMenu, hideCreateMenu, openCreateNewOrderModal } from 'actions/ui';
import { toggleBlockAddingPopup, closeBlockAddingPopup } from 'actions/tabs';
import { createNewAppeal } from 'actions/appeal';
import { calculateSearchNumber } from 'helpers';
import { translate } from 'react-i18next';

import 'styles/modules/mainTabs.module.scss';
import permissions, { checkPermissions } from 'config/permissions';

function mapStateToProps (state) {
	return {
		create: state.ui.create,
		tabs: state.tabs,
		isAddingActive: state.tabs.isAddingActive,
		blockAddingPopupIsShow: state.tabs.blockAddingPopupIsShow,
		tabsHistory: state.tabs.tabsHistory,
		creatingAppeal: state.appeal.creating,
		callInteractionId: state.call.callInteractionId,
		incomePhoneNumber: state.call.incomePhoneNumber,
		isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer),
        isOrderCreate: checkPermissions(permissions.OrderOperations.action_createOrder),
	}
}

const mapDispatchToProps = {
	showCreateMenu,
	hideCreateMenu,
	openCreateNewCustomerModal,
	createNewAppeal,
	closeBlockAddingPopup,
	toggleBlockAddingPopup,
    openCreateNewOrderModal
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class NewTab extends React.Component {

	state = {
		popupIsOpen: false
	};

	togglePopup = () => {
		const { isAddingActive, toggleBlockAddingPopup } = this.props;
		if (isAddingActive) {
			this.setState(prevState => ({ popupIsOpen: !prevState.popupIsOpen }));
		} else {
			toggleBlockAddingPopup();
		}
	};

	closePopup = (e) => {
		const { closeBlockAddingPopup } = this.props;
		this.setState({ popupIsOpen: false });
		return this.createButton.contains(e.target) ? null : closeBlockAddingPopup();
	};

	openSearch = (e) => {
		e.stopPropagation();
		this.togglePopup();
		const { history, tabs, isAddingActive } = this.props;

		if (isAddingActive) {
			const hash = calculateSearchNumber(tabs);
			history.push(`/search/appeal/${hash}/`);
		}
	};

	renderButton = item => {
		if (!item || (item && !item.allowed)) return null;

		return (
			<button
				className='btn-text text-no-wrap'
				onClick={item.handleClick}
				key={item.title}
			>
				{item.title}
			</button>
		);
	};

	getButtonsConfig = () => {
		const { incomePhoneNumber, callInteractionId, createNewAppeal, history } = this.props;
		const buttons = [];

		async function createAppeal (interactionId) {
			const appeal = await createNewAppeal(undefined, interactionId);
			if (appeal) history.push(`/appeals/${appeal.id}/knowledge_base`);
		}

		if (this.props.isCustomerCreationAllowed) {
			buttons.push({
				title: 'Заявник',
				handleClick: () => this.props.openCreateNewCustomerModal({ openNewTab: true }),
				allowed: this.props.isCustomerCreationAllowed
			});
		}

		if (incomePhoneNumber && callInteractionId) {
			buttons.push({
				title: `Звернення до ${incomePhoneNumber}`,
				handleClick: () => { createAppeal(callInteractionId) },
				allowed: this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)
			});
		}

		if (this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)) {
			buttons.push({
				title: 'Звернення',
				handleClick: () => { createAppeal(undefined) },
				allowed: this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)
			});
        }

        if (this.props.isOrderCreate) {
            buttons.push({
                title: 'Заявка',
                handleClick: () => this.props.openCreateNewOrderModal({ openNewTab: true }),
                allowed: this.props.isOrderCreate,
            });
        }

		return buttons;
	};

	translateName = (name) => {
		const names = ['search', 'appeals', 'customer', 'person'];
		const firstPart = name.split('_')[0];
		if (names.indexOf(firstPart) !== -1) {
			const secondPart = name.split('_').slice(1).join('_');
			const translatedName = this.props.t(`tabNames.${firstPart}`) + (secondPart ? `_${secondPart}` : '');
			return translatedName;
		}
		return name;
	};

	renderTabHistoryItem = (tab, index) => {
		let itemText;
		if (tab.type.startsWith('appeals')) {
			itemText = this.props.t('tabNames.appeals') + '_' + (tab.regnum || tab.displayedName);
		} else {
			itemText = this.translateName(tab.name);
		}

		return (
			<Link className='new-tab-item' to={tab.url} key={index}>
				<i className='icon-clock' />
				<span className='new-tab-item-text'>{itemText}</span>
			</Link>
		);
	};

	render () {
		const { blockAddingPopupIsShow, creatingAppeal, tabsHistory, t, tabsEmpty, callInteractionId } = this.props;
		const { popupIsOpen } = this.state;
		const addButtonStyle = cx('icon-menu-cross add-tab-button', { 'disabled': creatingAppeal }); //creatingAppeal

		return (
			<div className='btn-create' onClick={this.togglePopup} ref={(node) => (this.createButton = node)}>
				<button disabled={creatingAppeal} className={addButtonStyle} />
				{
					!creatingAppeal && popupIsOpen &&
					<Popup
						place={cx('new-tab', { 'first-tab': tabsEmpty, 'min-w-210': callInteractionId })}
						onClickOutside={this.closePopup}
					>
						{
							this.getButtonsConfig().length > 0 ?
								<Fragment>
									{this.getButtonsConfig().map(this.renderButton)}
									<hr className='new-tab-hr' />
								</Fragment> : null
						}
						<button className='new-tab-item' onClick={this.openSearch}>
							<i className='icon-search-maginfer' />
							<span className='new-tab-item-text'>
								{t('newSearch')}
							</span>
						</button>
						{
							tabsHistory.length > 0 ?
								<Fragment>
									<hr className='new-tab-hr' />
									<div className='new-tab-item-list'>
										{tabsHistory.map(this.renderTabHistoryItem)}
									</div>
								</Fragment> : null
						}
					</Popup>
				}

				{
					blockAddingPopupIsShow &&
					<Popup
						place={cx('new-tab', { 'first-tab': tabsEmpty })}
						onClickOutside={this.closePopup}
					>
						<p>{t('blockNewSearch.newSearchBlocked')}</p>
						<Link
							to={'#'}
							className='new-tab-buttom-list-item'
						>
							<div>{t('blockNewSearch.closePopup')}</div>
						</Link>
					</Popup>
				}
			</div>
		);
	}
}

NewTab.propTypes = {
	// ownProps
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// from connect
	showCreateMenu: PropTypes.func,
	hideCreateMenu: PropTypes.func,
	openCreateNewCustomerModal: PropTypes.func,
	isAddingActive: PropTypes.bool,
	isCustomerCreationAllowed: PropTypes.bool
};
