import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';

import styles from 'styles/modules/appealQuality.module.scss'
import baseService from 'services/BaseService'
import cx from "classnames";
import AppealQuality from "./AppealQuality";

@translate()
export default class ViewListQuality extends React.Component {
    static handleSubmit(e) {
        e.preventDefault()
    }

    constructor (props) {
        super(props)
        this.state = {
            callQualityItem: false,
            listQualityItems: [],
            isOneItemView: false,
            onMin: true
        };
        this.renderlistQualityItems = this.renderlistQualityItems.bind(this);
        this.handleSubmit = this.constructor.handleSubmit;
        this.getQualityQuestion = this.getQualityQuestion.bind(this);
        this.toggleCodeQuality = this.toggleCodeQuality.bind(this);
    }

    componentDidMount () {
        const data = { InteractionId: this.props.appeal.interactionId };
        baseService.get('quality_questions_list', { data })
            .then(response => {
                this.setState({listQualityItems: response.result});
            })
            .catch(err => console.log(err));
    }

    getQualityQuestion (e) {
        const { appeal } = this.props;
            const params = {
                data: {
                    ctxObjectType: 'INTERACTION',
                    ctxObjectId: appeal.interactionId,
                    objectId: appeal.interactionId,
                    objectType: e.target.getAttribute('data-mode'),
                    objectTypeId: e.target.getAttribute('data-id'),
                    openMode: 'update',
                    curStepNum: 1,
                    overrideIsReq: ''
                }
            };
            baseService.get('appeal_form_params', params)
                .then(response =>{

                    this.setState({ isOneItemView : true,
                                    question: response.result,
                       });

    })

    }
    toggleCodeQuality() {
        this.setState({ callQualityItem: !this.state.callQualityItem });
    }

    renderlistQualityItems() {
        const { listQualityItems } = this.state;

        return (listQualityItems.map(elem =>
                    <li className={styles.ListItem}
                        data-id={elem.id}
                        data-mode={elem.typeName}
                        onClick={this.getQualityQuestion}>
                    <svg className={styles.star}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.93 13.72">
                        <g id="Layer_2" data-name="Layer 2"><g id="Layer_91" data-name="Layer 91">
                            <path fill="#f7d100"  d="M13.46,4.92l-4.2-.63L7.37.35C7.27.12,7.13,0,7,0s-.3.12-.41.35L4.67,4.29l-4.2.63C.16,5,0,5.1,0,5.32a.67.67,0,0,0,.21.41L3.26,8.79l-.72,4.32a.66.66,0,0,0,0,.17.52.52,0,0,0,.09.31.3.3,0,0,0,.26.13.74.74,0,0,0,.34-.11l3.75-2,3.76,2a.68.68,0,0,0,.34.11.3.3,0,0,0,.25-.13.52.52,0,0,0,.09-.31.81.81,0,0,0,0-.17l-.72-4.32,3-3.06a.62.62,0,0,0,.22-.41c0-.22-.16-.35-.47-.4Z"/>
                            </g>
                        </g>
                    </svg>
                    {elem.name}</li>))
    }


    render () {
        const {  t, onClose, result, appeal } = this.props;
        const { question, isOneItemView , onMin} = this.state;

        return (
            <CSSTransition
                in={true}
                classNames='fade'
                appear={true}
                enter={true}
                exit={true}
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                {
                    isOneItemView ?
                        <AppealQuality onClose={onClose}
                                       appeal={appeal}
                                       onMin={onMin}
                                       querstionParams={question}/>
                        : <ModalPortal
                            onClose={onClose}
                            className='modal-medium'
                            onMin={onMin}
                        >
                            <div className={styles.appealWindowList}>
                                <div className={styles.appealQualityWindowHeader}>
                                    <div className={styles.appealQualityWindowTitle}>{t('quality.ListTitle')}</div>
                                </div>

                                <div className={styles.appealQualityForm}>
                                    <ul className={styles.QualityList}>
                                        {this.renderlistQualityItems()}
                                    </ul>
                                </div>

                            </div>

                        </ModalPortal>
                }
            </CSSTransition>
        )
    }
}

ViewListQuality.propTypes = {
    appealId: PropTypes.string,
    onClose: PropTypes.func
}

