/* global ENVIRONMENT */
import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';
import _ from 'lodash';
import { SUMY, UABET } from 'config/conf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkype } from '@fortawesome/free-brands-svg-icons';
import {
    faGlobeAmericas, faPhone, faEnvelope, faComments, faLandmark,
} from '@fortawesome/free-solid-svg-icons';
import widths from './tabsWidths';
import format from './tabsFormats';

const sourceIcons = {
    2: faPhone,
    4: faEnvelope,
    5: faComments,
    6: faSkype,
    portal: faGlobeAmericas,
    kmu: faLandmark,
};

const appealParams = (t) => {
    const regnum = {
        name: t('filter.regnum'),
        classWidth: widths.mid,
    };

    const attr0 = {
        name: t('filter.source'),
        classWidth: widths.small,
        value: (rowValues) => {
            if (_.get(rowValues, 'attr0.key')) {
                if (Number(rowValues.attr0.key) === 2) {
                    return <ReactSVG svgClassName='grid-icon' src='/data/svg/phone.svg' />;
                }
                
                if (rowValues.attr0.key === 'wspua') {
                    return <ReactSVG svgClassName='grid-icon' src='/data/svg/president_office.svg' />;
                }
                
                return (
                    <FontAwesomeIcon
                        className='grid-icon'
                        title={rowValues.attr0.val}
                        icon={sourceIcons[rowValues.attr0.key]}
                    />
                );
            }
            return null;
        },
    };

    const attr1 = {
        name: t('filter.neededCall'),
        classWidth: widths.min,
        hidden: true,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr1.val')}</div>,
    };

    const attr2 = {
        name: t('filter.restriction'),
        classWidth: widths.max,
        hidden: true,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr2.val')}</div>,
    };

    const attr3 = {
        name: t('filter.territory'),
        classWidth: widths.max,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr3.val')}</div>,
    };

    const attr4 = {
        name: t('filter.resolveAnswerDate'),
        classWidth: widths.mid,
        hidden: true,
        value(rowValues) {
            if (rowValues.attr4) {
                const fieldValue = rowValues.attr4.val;
                if (fieldValue) {
                    const date = moment.unix(fieldValue/1000);
                    if (date.isValid()) return date.format('DD.MM.YYYY HH:mm');
                }

                return rowValues.attr4.val;
            }
            return null;
        },
    };

    const attr5 = {
        name: t('filter.getAnswer'),
        classWidth: widths.mid,
        hidden: true,
        value(rowValues) {
            if (rowValues.attr5) {
                return [
                    rowValues.attr5.val,
                   // rowValues.resolution,
                ].filter(Boolean).join(' ');
            }
        },
    };

    const attr6 = {
        name: t('filter.outputNumber'),
        classWidth: widths.mid,
        hidden: true,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr6.val')}</div>,
    };

    const attr7 = {
        name: t('filter.feedbackResult'),
        classWidth: widths.mid,
        hidden: true,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr7.val')}</div>,
    };

    const attr8 = {
        name: t('filter.feedbackDate'),
        classWidth: widths.mid,
        hidden: true,
        format: format.date,
        value(rowValues) {
            if (rowValues.attr8) {
                const fieldValue = rowValues.attr8.val;
                if (fieldValue) {
                    const date = moment.unix(fieldValue/1000);
                    if (date.isValid()) return date.format('DD.MM.YYYY HH:mm');
                }
                return rowValues.attr8.val;
            }
        },
    };

    const attr9 = {
        name: t('filter.feedbackPerson'),
        classWidth: widths.mid,
        hidden: true,
        value: rowValues => <div className='data-cell'>{_.get(rowValues, 'attr9.val')}</div>,
    };

    const priority = {
        name: t('filter.priority'),
        classWidth: widths.small,
        hasPriority: true
    };

    const destinationName = {
        name: t('filter.destinationName'),
        classWidth: widths.mid,
    };

    const typeFullName = {
        name: t('filter.typeFullName'),
        classWidth: widths.mid,
    };

    const customerName = {
        name: t('filter.customerName'),
        classWidth: widths.mid,
    };

    const stateName = {
        name: t('filter.stateName'),
        classWidth: widths.mid,
        value: rowValues => (rowValues.status ? (
            <div className='data-cell'>
                <span className={`sticker sticker-grid sticker-${rowValues.status.code}`}>{rowValues.status.name}</span>
            </div>
        ) : null),
    };

    const subject = {
        name: t('filter.subject'),
        classWidth: widths.mid,
    };

    const resolution = {
        name: t('filter.resolution'),
        classWidth: widths.min,
    };

    const operatorName = {
        name: t('filter.operatorName'),
        classWidth: widths.min,
        canSorted: true,
    };

    const routeCreateDate = {
        name: t('filter.routeCreateDate'),
        format: format.date,
        classWidth: widths.mid,
    };

    const lastStatusDate = {
        name: t('filter.lastStatusDate'),
        format: format.date,
        classWidth: widths.mid,
    };

    const finishDate = {
        name: t('filter.finishDate'),
        format: format.date,
        classWidth: widths.mid,
        hidden: true,
    };

    const validFrom = {
        name: t('filter.validFrom'),
        format: format.date,
        classWidth: widths.mid,
    };

    const endDate = {
        name: t('filter.endDate'),
        format: format.date,
        classWidth: widths.min,
        hidden: true,
    };

    const startDate = {
        name: t('filter.startDate'),
        format: format.date,
		classWidth: widths.mid,
		value(rowValues) {
			return rowValues.startDate
			}
        };
    
	const lastInProgresDate = {
		name: t('filter.lastInProgresDate'),
		format: format.date,
		hidden: true,
		classWidth: widths.mid,
		value(rowValues) {
			return rowValues.lastInProgresDate;
		}
	};

    const custContactPersonName = {
        name: t('filter.custContactPersonName'),
        classWidth: widths.mid,
        hidden: true,
    };

    if (ENVIRONMENT === SUMY) {
        return {
            regnum,
            priority,
            destinationName,
            typeFullName,
            customerName,
            custContactPersonName,
            stateName,
            resolution,
            operatorName,
            lastStatusDate,
            validFrom,
            endDate,
        };
    }
    else if(ENVIRONMENT === UABET) {
        return {
            attr0,
            priority,
            regnum,
            stateName,
            subject,
            validFrom,
            destinationName,
            typeFullName,
            lastStatusDate,
            customerName,
            custContactPersonName,
            resolution,
            operatorName,
            finishDate,
            endDate,
            startDate,
        };
    }

    customerName.hidden = true;
    resolution.hidden = true;
    operatorName.hidden = true;
    return {
        attr0,
        priority,
        regnum,
        stateName,
        validFrom,
        destinationName,
        typeFullName,
        attr3,
        lastStatusDate,
        routeCreateDate,
        attr2,
        customerName,
        custContactPersonName,
        resolution,
        operatorName,
        finishDate,
        endDate,
        attr4,
        attr5,
        attr6,
        attr1,
        attr7,
        attr8,
        attr9,
		lastInProgresDate
    };
};

export default t => ({
    appeals: appealParams(t),
    services: {
        id: {
            name: t('services.id'),
            format: format.string,
            classWidth: widths.min,
            noSorted: true,
            permanent: true,
        },
        productName: {
            name: t('services.productName'),
            classWidth: widths.mid,
            noSorted: true,
            permanent: true,
        },
        statusName: {
            name: t('services.statusName'),
            classWidth: widths.mid,
            noSorted: true,
        },
        validFrom: {
            name: t('services.validFrom'),
            format: format.date,
            classWidth: widths.mid,
            noSorted: true,
        },
        validTill: {
            name: t('services.validTill'),
            format: format.date,
            classWidth: widths.mid,
            noSorted: true,
        },
    },
    persons: {
        links: {
            name: t('persons.linkState'),
            format: format.toggleCheckbox,
            noSorted: true,
			toggleCustomerLink: true,
			classWidth: widths.min
        },
        lastName: {
            name: t('persons.lastName'),
            format: format.string,
            noSorted: true,
            permanent: true,
        },
        firstName: {
            name: t('persons.firstName'),
            format: format.string,
            noSorted: true,
            permanent: true,
        },
        patronymic: {
            name: t('persons.patronymic'),
            format: format.string,
            noSorted: true,
        },
        contacts: {
            name: t('persons.contacts'),
            format: format.string,
            noSorted: true,
        },
        language: {
            name: t('persons.language'),
            format: format.string,
            noSorted: true,
        },
		issues: {
			name: t('persons.issues'),
			format: format.string,
			classWidth: widths.mid,
			noSorted: true,
		},
        validFrom: {
            name: t('persons.validFrom'),
            format: format.date,
            noSorted: true,
        },
    },
    accounts: {
        fullNumber: {
            name: t('accounts.fullNumber'),
            noSorted: true,
            permanent: true,
        },
        status: {
            name: t('accounts.status'),
            noSorted: true,
            format: format.dictionary,
            dictionaryField: 'accountStatus',
        },
        validFrom: {
            name: t('accounts.validFrom'),
            format: format.date,
            noSorted: true,
        },
        validTill: {
            name: t('accounts.validTill'),
            format: format.date,
            noSorted: true,
        },
    },
    contracts: {
        fullNumber: {
            name: t('contractsTable.fullNumber'),
            format: format.string,
            noSorted: true,
            permanent: true,
        },
        contractStatus: {
            name: t('contractsTable.contractStatus'),
            format: format.string,
            noSorted: true,
        },
        validFrom: {
            name: t('contractsTable.validFrom'),
            format: format.date,
            noSorted: true,
        },
        validTill: {
            name: t('contractsTable.validTill'),
            format: format.date,
            noSorted: true,
        },
        providerName: {
            name: t('contractsTable.providerName'),
            format: format.string,
            noSorted: true,
            permanent: true,
        },
        serviceGroups: {
            name: t('contractsTable.serviceGroups'),
            format: format.string,
            noSorted: true,
        },
        contractNumbers: {
            name: t('contractsTable.contractNumbers'),
            format: format.string,
            noSorted: true,
        },
    },
    address: {
        type: {
            name: t('addressTable.type'),
            noSorted: true,
            permanent: true,
            format: format.dictionary,
            dictionaryField: 'addressType',
        },
        name: {
            name: t('addressTable.name'),
            noSorted: true,
            permanent: true,
        },
    },
    tasks: {
        taskCode: {
            name: t('tasks.taskCode'),
            noSorted: true,
            permanent: true,
        },
        taskName: {
            name: t('tasks.taskName'),
            noSorted: true,
            permanent: true,
        },
        taskStateCode: {
            name: t('tasks.taskStateCode'),
            format: format.dictionary,
            dictionaryField: 'taskStatus',
            noSorted: true,
        },
        clientName: {
            name: t('tasks.clientName'),
            noSorted: true,
            permanent: true,
        },
        servNames: {
            name: t('tasks.servNames'),
            noSorted: true,
        },
        createDate: {
            name: t('tasks.createDate'),
            format: format.date,
            noSorted: true,
        },
        ownerName: {
            name: t('tasks.ownerName'),
            noSorted: true,
        },
        actions: {
            name: t('tasks.actions'),
        },
    },
    customer: {
        id: {
            name: t('customer.id'),
            format: format.string,
            classWidth: widths.min,
            permanent: true,
        },
        name: {
            name: t('customer.name'),
            classWidth: widths.mid,
            permanent: true,
        },
        partyType: {
            name: t('customer.partyType'),
            format: format.dictionary,
            dictionaryField: 'partyType',
            classWidth: widths.min,
        },
        fieldValue: {
            name: t('customer.fieldValue'),
            classWidth: widths.mid,
            html: true,
        },
        phyAddress: {
            name: t('customer.phyAddress'),
            classWidth: widths.mid,
        },
    },
    contact_person: {
        id: {
            name: t('customer.id'),
            format: format.string,
            classWidth: widths.min,
            permanent: true,
        },
        contactName: {
            name: t('customer.pib'),
            classWidth: widths.mid,
        },

        customerName: {
            name: t('customer.name'),
            classWidth: widths.mid,
            permanent: true,
            component: i => <a onClick={(event) => { event.stopPropagation(); }} className='data-cell' href={`#/customer/${i.data.customerId}/appeals`}>{i.data.customerName}</a>,
        },

        contacts: {
            name: t('customer.contactName'),
            classWidth: widths.mid,
        },
        fieldValue: {
            name: t('customer.content'),
            classWidth: widths.mid,
            html: true,
        },
    },
    appeal: appealParams(t),
    callhistory: {
        code: {
            name: 'Код заявки',
            noSorted: true,
        },
        typeName: {
            name: 'Тип заявки',
            noSorted: true,
        },
        // customer: {
        //   name: 'Замовник',
        //   noSorted: true
        // },
        status: {
            name: 'Статус заявки',
            noSorted: true,
        },
        // owner: {
        //   name: 'Відповідальний',
        //   noSorted: true
        // },
        // creator: {
        //   name: 'Автор',
        //   noSorted: true
        // },
        ownerName: {
            name: 'Відповідальний',
            noSorted: true,
        },
        createDate: {
            name: 'Створено',
            format: format.date,
        },
        startDate: {
            name: 'Фактична дата початку',
            format: format.date,
        },
        endDate: {
            name: 'Фактична дата закінчення',
            format: format.date,
        },
        planStartDate: {
            name: 'Планова дата початку',
            format: format.date,
        },
        planEndDate: {
            name: 'Планова дата закінчення',
            format: format.date,
        },
        taskInfo: {
            name: 'Додаткова інформація',
            noSorted: true,
        },
        initVal: {
            name: 'Філія-замовник',
            noSorted: true,
        },
        ownerVal: {
            name: 'Філія-виконавець',
            noSorted: true,
        },
        taskPriority: {
            name: 'Пріоритет',
            noSorted: true,
        },
    },
    file: {
        id: {
            name: t('fileTable.id'),
            format: format.string,
            permanent: true,
        },
        name: {
            name: t('fileTable.name'),
            permanent: true,
        },
    },
    knowledge_base: {},
    all: {
        type: {
            name: t('all.type'),
            permanent: true,
        },
        id: {
            name: t('all.id'),
            format: format.string,
            permanent: true,
        },
        name: {
            name: t('all.name'),
            permanent: true,
        },
        fieldValue: {
            name: t('all.fieldValue'),
            html: true,
        },
        content: {
            name: t('all.content'),
        },
    },
    filter: appealParams(t),
    searchclient: {
        partyType: {
            name: t('searchclient.partyType'),
            permanent: true,
        },
        contactPersonName: {
            name: t('searchclient.contactPersonName'),
            permanent: true,
        },
        // status: {
        //   name: t('searchclient.status')
        // },
        custName: {
            name: t('searchclient.custName'),
            permanent: true,
        },
        phone: {
            name: t('searchclient.phone'),
            classWidth: widths.mid,
        },
        address: {
            name: t('searchclient.address'),
            classWidth: widths.mid,
        },
        email: {
            name: t('searchclient.email'),
            classWidth: widths.mid,
        },
    // externamId: "Номер зовнішньої системи"
    },
    contact: {
        id: {
            name: t('contact.id'),
            format: format.string,
            classWidth: widths.min,
            permanent: true,
        },
        type: {
            name: t('contact.type'),
            format: format.translate,
            classWidth: widths.mid,
        },
        value: {
            name: t('contact.value'),
            format: format.string,
            classWidth: widths.max,
            permanent: true,
        },
    },
    link: {
        actual: {
            name: t('link.actual'),
            format: format.toggleCheckbox,
            classWidth: widths.min,
			togglePersonLink: true,
			noSorted: true,
        },
        id: {
            name: t('link.id'),
            format: format.string,
            classWidth: widths.min,
            permanent: true,
			component: i => <span>{i.data.entityId}</span>,
        },
        entityDescription: {
            name: t('link.entityDescription'),
            format: format.string,
            classWidth: widths.mid,
        },
        issues: {
            name: t('link.issues'),
            format: format.translate,
            classWidth: widths.max,
        },
        validFrom: {
            name: t('link.validFrom'),
            format: format.date,
            classWidth: widths.mid,
        },
    },

    // Settings

    settings_controlUsers_users: {
        login: {
            name: t('settingsSection.controlUsersTable.login'),
            classWidth: widths.mid,
        },
        name: {
            name: t('settingsSection.controlUsersTable.name'),
            classWidth: widths.mid,
        },
        email: {
            name: t('settingsSection.controlUsersTable.email'),
            classWidth: widths.min,
        },
        status: {
            name: t('settingsSection.controlUsersTable.status'),
            classWidth: widths.min,
        },
        fio: {
            name: t('settingsSection.controlUsersTable.fio'),
            classWidth: widths.min,
        },
        firstName: {
            name: t('settingsSection.controlUsersTable.firstName'),
            classWidth: widths.mid,
        },
        lastName: {
            name: t('settingsSection.controlUsersTable.lastName'),
            classWidth: widths.mid,
        },
        middleName: {
            name: t('settingsSection.controlUsersTable.middleName'),
            classWidth: widths.mid,
        },
        title: {
            name: t('settingsSection.controlUsersTable.title'),
            classWidth: widths.min,
        },
        description: {
            name: t('settingsSection.controlUsersTable.description'),
            classWidth: widths.min,
        },
        department: {
            name: t('settingsSection.controlUsersTable.department'),
            format: format.string,
            classWidth: widths.min,
        },
        company: {
            name: t('settingsSection.controlUsersTable.company'),
            classWidth: widths.max,
        },
        phone: {
            name: t('settingsSection.controlUsersTable.phone'),
            classWidth: widths.min,
        },
        mobile: {
            name: t('settingsSection.controlUsersTable.mobile'),
            classWidth: widths.min,
        },
        mail: {
            name: t('settingsSection.controlUsersTable.mail'),
            classWidth: widths.min,
        },
        position: {
            name: t('settingsSection.controlUsersTable.position'),
            classWidth: widths.min,
        },
        domainName: {
            name: t('settingsSection.controlUsersTable.domainName'),
            classWidth: widths.min,
        },
        fax: {
            name: t('settingsSection.controlUsersTable.fax'),
            classWidth: widths.min,
        },
        id: {
            name: t('settingsSection.controlUsersTable.id'),
            classWidth: widths.min,
        },
        lastBreakReasons: {
            name: t('settingsSection.controlUsersTable.lastBreakReasons'),
            classWidth: widths.min,
        },
        objectPermissionsHex: {
            name: t('settingsSection.controlUsersTable.objectPermissionsHex'),
            classWidth: widths.min,
        },
    },

    settings_controlUsers_roles: {
        id: {
            name: t('settingsSection.controlRolesTable.id'),
            classWidth: widths.min,
            noSorted: true,
        },
        name: {
            name: t('settingsSection.controlRolesTable.name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlRolesTable.description'),
            classWidth: widths.mid,
            noSorted: true,
        },
        type: {
            name: t('settingsSection.controlRolesTable.type'),
            classWidth: widths.mid,
            noSorted: true,
        },
        users: {
            name: t('settingsSection.controlRolesTable.users'),
            classWidth: widths.mid,
            noSorted: true,
        },
    },

    settings_controlUsers_domains: {
        id: {
            name: t('settingsSection.controlDomainsTable.id'),
            classWidth: widths.min,
            noSorted: true,
        },
        name: {
            name: t('settingsSection.controlDomainsTable.name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlDomainsTable.description'),
            classWidth: widths.max,
            noSorted: true,
            link: item => `/settings/controlUsers/domains/${item.id}/rules`,
        },
        restrictions: {
            name: t('settingsSection.controlDomainsTable.restrictions'),
            classWidth: widths.mid,
            noSorted: true,
            component: i => <a className='data-cell' href={`#/settings/controlUsers/domains/${i.data.id}/restrs`}>{t('settingsSection.controlDomainsTable.restriction_value')}</a>,
        },
    },
    settings_controlUsers_access_rules: {
        spec_id: {
            name: t('settingsSection.controlAccessRulesTable.spec_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlAccessRulesTable.description'),
            classWidth: widths.min,
            noSorted: true,
        },
        spec_name: {
            name: t('settingsSection.controlAccessRulesTable.spec_name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        validFrom: {
            name: t('settingsSection.controlAccessRulesTable.validFrom'),
            classWidth: widths.min,
            format: format.date,
            noSorted: true,
        },
        sort: {
            name: t('settingsSection.controlAccessRulesTable.sort'),
            classWidth: widths.min,
            noSorted: true,
        },
        spec_area_id: {
            name: t('settingsSection.controlAccessRulesTable.spec_area_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        access_rule: {
            name: t('settingsSection.controlAccessRulesTable.access_rule'),
            classWidth: widths.min,
            noSorted: true,
            component: withRouter(({ data, match }) => <a className='data-cell' href={`#/settings/controlUsers/groups/${match.params.id}/rules/${data.spec_id}`}>{t('settingsSection.controlAccessRulesTable.access_rule_value')}</a>),
        },
    },
    settings_controlUsers_child_access_rules: {
        specId: {
            name: t('settingsSection.controlAccessRulesTable.spec_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        specName: {
            name: t('settingsSection.controlAccessRulesTable.spec_name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        validFrom: {
            name: t('settingsSection.controlAccessRulesTable.validFrom'),
            classWidth: widths.min,
            format: format.date,
            noSorted: true,
        },
        sort: {
            name: t('settingsSection.controlAccessRulesTable.sort'),
            classWidth: widths.min,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlAccessRulesTable.description'),
            classWidth: widths.min,
            noSorted: true,
        },
        access_rule: {
            name: t('settingsSection.controlAccessRulesTable.access_rule'),
            classWidth: widths.min,
            noSorted: true,
            component: withRouter(({ data, match }) => <a className='data-cell' href={`#/settings/controlUsers/groups/${match.params.id}/rules/${data.specId}`}>{t('settingsSection.controlAccessRulesTable.access_rule_value')}</a>),
        },
    },
    settings_controlUsers_groups: {
        id: {
            name: t('settingsSection.controlGroupsTable.id'),
            classWidth: widths.min,
            noSorted: true,
        },
        name: {
            name: t('settingsSection.controlGroupsTable.name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlGroupsTable.description'),
            classWidth: widths.max,
            noSorted: true,
            link: item => `/settings/controlUsers/groups/${item.id}/rules`,
        },
        parent_name: {
            name: t('settingsSection.controlGroupsTable.parentName'),
            classWidth: widths.mid,
            noSorted: true,
        },
        restrictions: {
            name: t('settingsSection.controlGroupsTable.restrictions'),
            classWidth: widths.mid,
            noSorted: true,
            component: i => <a className='data-cell' href={`#/settings/controlUsers/groups/${i.data.id}/restrs`}>{t('settingsSection.controlGroupsTable.restriction_value')}</a>,
        },
    },
    settings_controlUsers_units_users: {
        partyId: {
            name: t('settingsSection.controlUnitUsersTable.partyId'),
            classWidth: widths.mid,
            noSorted: true,
        },
        partyName: {
            name: t('settingsSection.controlUnitUsersTable.partyName'),
            classWidth: widths.max,
            noSorted: true,
        },
        status: {
            name: t('settingsSection.controlUnitUsersTable.status'),
            classWidth: widths.min,
            noSorted: true,
        },
        validFrom: {
            name: t('settingsSection.controlUnitUsersTable.validFrom'),
            classWidth: widths.min,
            format: format.date,
            noSorted: true,
        },
    },
    settings_controlUsers_units_spaces: {
        id: {
            name: t('settingsSection.controlUnitSpacesTable.id'),
            classWidth: widths.min,
            noSorted: true,
        },
        code: {
            name: t('settingsSection.controlUnitSpacesTable.code'),
            classWidth: widths.mid,
            noSorted: true,
        },
        name: {
            name: t('settingsSection.controlUnitSpacesTable.name'),
            classWidth: widths.max,
            noSorted: true,
        },
    },

    settings_controlUsers_restrs: {
        restr_area_id: {
            name: t('settingsSection.controlUnitRestrsTable.restr_area_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        rest_id: {
            name: t('settingsSection.controlUnitRestrsTable.rest_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        rest_name: {
            name: t('settingsSection.controlUnitRestrsTable.rest_name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        description: {
            name: t('settingsSection.controlUnitRestrsTable.description'),
            classWidth: widths.mid,
            noSorted: true,
        },
        cond_id: {
            name: t('settingsSection.controlUnitRestrsTable.cond_id'),
            classWidth: widths.min,
            noSorted: true,
        },
        cond_name: {
            name: t('settingsSection.controlUnitRestrsTable.cond_name'),
            classWidth: widths.mid,
            noSorted: true,
        },
        valid_from: {
            name: t('settingsSection.controlUnitRestrsTable.valid_from'),
            classWidth: widths.mid,
            noSorted: true,
            format: format.date,
        },
        sort: {
            name: t('settingsSection.controlUnitRestrsTable.sort'),
            classWidth: widths.min,
            noSorted: true,
        },
    },
    dashboard_top10: {
        place: {
            name: t('dashboard.top10.place'),
            classWidth: widths.min,
            noSorted: true,
        },
        name: {
            name: t('dashboard.top10.name'),
            classWidth: widths.max,
            noSorted: true,
        },
        received: {
            name: t('dashboard.top10.received'),
            classWidth: widths.min,
            noSorted: true,
        },
        handled: {
            name: t('dashboard.top10.handled'),
            classWidth: widths.min,
            noSorted: true,
        },
    },
    dashboard_current_appeals: {
        name: {
            name: t('dashboard.current_appeals.name'),
            classWidth: widths.max,
            noSorted: true,
        },
        beingCreated: {
            name: t('dashboard.current_appeals.beingCreated'),
            classWidth: widths.min,
            noSorted: true,
        },
        completed: {
            name: t('dashboard.current_appeals.completed'),
            classWidth: widths.min,
            noSorted: true,
        },
        continued: {
            name: t('dashboard.current_appeals.continued'),
            classWidth: widths.min,
            noSorted: true,
        },
        created: {
            name: t('dashboard.current_appeals.created'),
            classWidth: widths.min,
            noSorted: true,
        },
        expired: {
            name: t('dashboard.current_appeals.expired'),
            classWidth: widths.min,
            noSorted: true,
        },
        inProgress: {
            name: t('dashboard.current_appeals.inProgress'),
            classWidth: widths.min,
            noSorted: true,
        },
        solved: {
            name: t('dashboard.current_appeals.solved'),
            classWidth: widths.min,
            noSorted: true,
        },
        wasSent: {
            name: t('dashboard.current_appeals.wasSent'),
            classWidth: widths.min,
            noSorted: true,
        },
    },
});
