import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';
import baseService from 'services/BaseService';
import styles from 'styles/modules/appealPrint.module.scss';
import modalStyles from 'styles/modules/modal.module.scss';
import cx from 'classnames';
import Loader from 'components/Loader';
import URLS from 'config/urls';
import { FileTypes } from 'constants/index';
import { translate } from 'react-i18next';
import injectedStyles from './injectedStyles';

const INTERACTION_REQUEST = 'INTERACTION_REQUEST';

function getGeneratedDoc (objectId, fileType = FileTypes.PDF) {
    const params = { data: { objectType: INTERACTION_REQUEST, objectId, fileType } };
    return baseService.get('doc_template_create', params)
        .then(response => response.result);
}

async function getDefaultTemplateHTML (objectId, contentType = 'html') {
    const response = await baseService.get('get_blank_html', {
        data: {
            objectType: INTERACTION_REQUEST,
            objectId,
            contentType,
        }
    });
    
    return response.result;
}

@translate()
class AppealPrintPopup extends React.Component {
    constructor () {
        super();
        this.state = {
            fileContent: {},
            docTemplId: 0,
            loading: false,
            overlay: false,
        };
    }
    
    componentDidMount () {
        const { appealId } = this.props;
        
        if (appealId) {
            this.setState({ loading: true });
            getDefaultTemplateHTML(appealId)
                .then(fileContent => this.setState({ fileContent, loading: false }))
                .catch(error => console.error(error))
                .finally(() => this.setState({ loading: false }));
        }
    }
    
    exportTo = (fileType = FileTypes.PDF) => {
        const { appealId } = this.props;
        this.setState({ overlay: true });
        getGeneratedDoc(appealId, fileType)
            .then(fileId => {
                const link = document.createElement('a');
                link.href = `${URLS.core.doc_download.url}?fileId=${fileId}`;
                link.click();
            })
            .catch(error => console.error(error.message))
            .finally(() => this.setState({ overlay: false }));
    };
    
    onPrint = () => {
        window.frames.appealFormPrint.focus();
        window.frames.appealFormPrint.print();
    };
    
    render () {
        const { onClose, t } = this.props;
        const { loading, fileContent, overlay } = this.state;
        
        return (
            <CSSTransition
                in={true}
                classNames='fade'
                appear={true}
                enter={true}
                exit={true}
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <ModalPortal
                    onClose={onClose}
                    className='modal-print'
                >
                    <div className={cx(modalStyles.modalHeader, 'no-print')}>
                        <div className={modalStyles.modalTitle}>{t('appeal.printPattern')}</div>
                    </div>
                    {
                        overlay && <div className='print-block-overlay' />
                    }
                    <div className={styles.appealPrintContent}>
                        <div className={cx(styles.buttonSet, 'right', 'no-print')}>
                            {/*<button type='button excel-print-button' onClick={() => this.exportTo(FileTypes.XLS)}>*/}
                            {/*<ReactSVG className="excel-icon" src='/data/svg/excel.svg' />*/}
                            {/*<span>{t('appeal.saveXLS')}</span>*/}
                            {/*</button>*/}
                            <button type='button' onClick={() => this.exportTo(FileTypes.PDF)}>
                                <i className='icon-pdf-doc' />
                                <span>{t('appeal.savePDF')}</span>
                            </button>
                            <button type='button' onClick={() => this.exportTo(FileTypes.DOCX)}>
                                <i className='icon-word-doc' />
                                <span>{t('appeal.saveWord')}</span>
                            </button>
                            <button type='button' onClick={this.onPrint}>
                                <i className='icon-print' />
                                <span>{t('appeal.printAppeal')}</span>
                            </button>
                        </div>
                        {
                            loading ? <Loader />
                                : (
                                    <iframe id='appealFormPrint' name='appealFormPrint' srcDoc={`
                                        <html>
                                            <head>
                                                <style>
                                                ${injectedStyles}
                                                </style>
                                            </head>
                                            ${fileContent.body}
                                        </html>
                                    `}
                                    />
                                )
                        }
                    </div>
                </ModalPortal>
            </CSSTransition>
        );
    }
}

AppealPrintPopup.propTypes = {
    onClose: PropTypes.func,
    typeId: PropTypes.number,
    appealId: PropTypes.number,
    t: PropTypes.func
};

export default AppealPrintPopup;

