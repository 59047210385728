import React from 'react';
import PropTypes from 'prop-types';

import AppealTab from './AppealTab/index';
import ProcessingForm from './ProcessingForm/index';

import { tabsAppeal } from 'constants/appealForm';

import styles from 'styles/modules/appealForm.module.scss';
import { translate } from 'react-i18next';

@translate()
export default class AppealForm extends React.Component {
  state = {
    activeTab: tabsAppeal[0].name
  };

  changeTabs = event => this.setState({ activeTab: event.target.value });

  renderHeader() {
    return (
      <div className={styles.appealFormHeader}>
        {this.props.t('appealForm.selectedAppeals').toUpperCase()}&nbsp;
        ({this.props.formData.checkedItems.length})
      </div>
    );
  }

  render() {
    const { fields, checkedItems, submitForm } = this.props.formData;

    return (
      <div className={styles.appealFormWrapper}>
        {this.renderHeader()}

        <AppealTab
          activeTab={this.state.activeTab}
          changeTabs={this.changeTabs}
        />

        {this.state.activeTab === tabsAppeal[0].name && (
          <ProcessingForm
            fields={fields}
            checkedItems={checkedItems}
            submitForm={submitForm}
          />
        )}
      </div>
    );
  }
}

AppealForm.propTypes = {
  formData: PropTypes.object,
  filterOnlyCheckedItems: PropTypes.func
};
