import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';

@translate()
class DefaultItem extends React.Component {

  getOptionalParts = () => {
    const { data: { item: { originator: { channelName } }, comment } } = this.props;
    return { channelName, comment };
  };

  render(){
    const { channelName, comment } = this.getOptionalParts();
    return (
      <li className={styles.historyItem}>
        <i className='icon-envelope' />
        <div className={styles.historyItemContent}>
          <span className={styles.historyTitleText}>{channelName}</span>
          <span className={styles.historyContentText}>{comment}</span>
        </div>
      </li>
    );
  }
}

DefaultItem.propTypes = {
  data: PropTypes.object
};

export default DefaultItem;
