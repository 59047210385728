import { connect } from 'react-redux';
import AudioPlayer from 'components/Common/AudioPlayer';
import { hideAudioPlayer } from 'actions/audioplayer';


const mapStateToProps = state => ({
  source: state.audioplayer.source,
  title: state.audioplayer.title,
  currentSecond: state.audioplayer.currentSecond,
  autoplay: state.audioplayer.autoplay,
  hidden: state.audioplayer.hidden,
  position: state.audioplayer.position
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hideAudioPlayer())
  // onStop: (currentSecond) => console.info('onStop', { currentSecond }),
  // onPlay: () => console.info('onPlay'),
  // onError: (error) => console.info('onError', { error }),
  // onChangePosition: (position) => console.info('onChangePosition', { position }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
