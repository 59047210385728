import React from 'react';
import PropTypes from 'prop-types';


export default function PlaybackButton({ nowPlaying, onClick }) {
    const iconClassName = nowPlaying ? 'icon-circle-pause' : 'icon-circle-play';

    return (
        <button className='playbackButton audioIcon' onClick={onClick}>
            <i className={iconClassName} />
        </button>
    );
}

PlaybackButton.propTypes = {
    nowPlaying: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};
