export const MINIMUM_RESIZE_CELL_WIDTH = 50;
export const RESIZE_TIMEOUT = 100;
export const DRAG_TIMEOUT = 150;
export const CLASS_WIDTHS_SET = [[50, 80], [180, 240], [300, 360], [300, 1500]];
export const DESC = 'desc';
export const ASC = 'asc';
export const UP = 'up';
export const DOWN = 'down';
export const ADD_FILTER_DATA_TIMEOUT = 400;
export const FILTER_INPUT_ID = 'grid-header-filter';
