import React, { Component } from 'react';
import cx from 'classnames';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import PropTypes from 'prop-types';

class LeafNode extends Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.renderCheckBoxes = this.renderCheckBoxes.bind(this);
    }

    onClick(e) {
        //changedChecked need for use click event on label
        const { node, checked } = this.props;
        const changedChecked = e.target.checked ? e.target.checked : !checked;
        
        this.props.onSelect(node, changedChecked);
    }


    renderCheckBoxes(){
        const { checked } = this.props;
        
        return(
            <div
                className="folder-checkbox"
                data-header-name="_checkbox"
            >
                <label className="container">
                    <input type="checkbox" checked={checked} onChange={this.onClick}/>
                    <span className="check-mark" />
                </label>
            </div>

        )
    }

    render() {
        const { node, searchMode, labelField, valueField, multi, checked } = this.props;
        const titleClassName = cx(styles.title, {
            [styles.searchMode]: searchMode
        });
        const labelClassName = cx({
            [styles.selected]: checked
        });
        
        return (
            <div id={node[valueField]} className={cx(styles.node, styles.leaf, {multi : 'multi'})}>
                { multi && this.renderCheckBoxes() }
                <div className={titleClassName} onClick={this.onClick} >
                    <i className='icon-file'/>
                    <label className={labelClassName}>
                        {searchMode ? node.fullLabelPath : node[labelField]}
                    </label>
                </div>
            </div>
        );
    }
}

LeafNode.propTypes = {
    open: PropTypes.bool,
    node: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    searchMode: PropTypes.bool,
    multi: PropTypes.bool
};

export default LeafNode;
