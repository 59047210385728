export function updateNode(nodeList, path, action, options) {

    function updateNode(node) {
        const updatedNode = { ...node };

        if (path.length === 0) {
            return action(updatedNode);
        }

        const nextLevelValue = path.shift();

        updatedNode[options.childrenField] = updatedNode[options.childrenField].map(child => {
            if (child[options.valueField] === nextLevelValue) {
                return updateNode(child);
            }

            return child;
        });

        return updatedNode;
    }


    const firstLevelValue = path.shift();

    return nodeList.map(node => {
        if (node[options.valueField] === firstLevelValue) {
            return updateNode(node);
        }

        return node;
    });
}

export function closeNodeChildren(node, childrenField) {
    if (!Array.isArray(node[childrenField])) return node;

    node[childrenField] = node[childrenField].map(child => {
        if (child.open) return closeNodeChildren({ ...child, open: false });

        return child;
    });

    return node;
}

export function openNodeAndSelect(nodeList, selectedNode, options) {
    const path = getNodePath(selectedNode, options.valueField);

    function updateNode(node) {
        const updatedNode = { ...node, open: true };

        if (path.length === 0) {
            updatedNode.selected = true;
            return updatedNode;
        }

        if (!Array.isArray(node[options.childrenField])) return updatedNode;

        const nextLevelValue = path.shift();

        updatedNode[options.childrenField] = updatedNode[options.childrenField].map(child => {
            if (child[options.valueField] === nextLevelValue) {
                return updateNode(child);
            }

            return child;
        });

        return updatedNode;
    }

    const firstLevelValue = path.shift();

    return nodeList.map(node => {
        if (node[options.valueField] === firstLevelValue) {
            return updateNode(node);
        }

        return node;
    });
}

/**
 * @return {Array} path - array of node's field values from root to current node.
 * For example: [2019, 2473, 190] or ["root_label", "root_child_label", ...]
 */
export function getNodePath(node, field) {
    const path = [];
    let currentNode = node;

    while (currentNode) {
        path.push(currentNode[field]);
        currentNode = currentNode.parent;
    }

    return path.reverse();
}

function isMatched(string, searchString) {
    return string.toUpperCase().includes(searchString.toUpperCase());
}

export function searchMatchedLeafs(nodeList, searchQuery, options) {
    const searchResults = [];

    function searchInNodeArray(nodeArray) {
        nodeArray.forEach(node => {
            if (node[options.leafField] && isMatched(node[options.labelField], searchQuery)) {
                const foundResult = {
                    ...node,
                    fullLabelPath: getNodePath(node, options.labelField).join(' / ')
                };
                searchResults.push(foundResult);
            }

            if (!node[options.leafField] && Array.isArray(node[options.childrenField])) {
                searchInNodeArray(node[options.childrenField]);
            }
        });
    }

    searchInNodeArray(nodeList);

    return searchResults;
}

export function findNodeByField(nodeList, fieldName, fieldValue, childrenField) {

    function findNode(nodeArray) {
        for (const node of nodeArray) {
            if (node[fieldName] === fieldValue) {
                return node;
            } else if (Array.isArray(node[childrenField])) {
                const foundNode = findNode(node[childrenField]);
                if (foundNode) return foundNode;
            }
        }

        return null;
    }

    return findNode(nodeList);
}


