import React from 'react';
import styles from 'styles/modules/notification.module.scss';
import Notification from '../Notification/Notification';
import { NotificationTypes } from 'constants/index';
import { connect } from 'react-redux';
import { hideNotificationPanel } from 'actions/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

const mapDispatchToProps = dispatch => ({
  hideNotificationPanel: () => dispatch(hideNotificationPanel())
});

@connect(null, mapDispatchToProps)
class NotificationPanel extends React.Component {

  render(){
    return (
      <section className={styles.notificationPanel}>
        <header>
          <div className={styles.bellContainer}>
            <div className={styles.bellIcon}>
              <i className='icon-bell' />
              <div className={styles.notificationCount}>16</div>
            </div>
          </div>

          <span className={styles.panelTitle}>Нотифікації</span>

          <button className={styles.togglePanelButton} onClick={this.props.hideNotificationPanel}>
            <i className='icon-advance'/>
          </button>
        </header>

        <div className={cx('scrollbox', styles.notificationPanelContent)}>
          <div className='scrollbox-content'>
            <div className={styles.notificationGroup}>
              <span className={styles.notificationDate}>Сьогодні</span>
              <ul>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
                <li><Notification type={NotificationTypes.NEW_COMMENT}/></li>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
              </ul>
            </div>

            <div className={styles.notificationGroup}>
              <span className={styles.notificationDate}>Вчора</span>
              <ul>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
                <li><Notification type={NotificationTypes.NEW_COMMENT}/></li>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
                <li><Notification type={NotificationTypes.NEW_APPEAL}/></li>
              </ul>
            </div>

          </div>
        </div>

        <footer>
          <button className='btn btn-primary'>Закрити всі нотифікації</button>
        </footer>

      </section>
    );
  }
}

NotificationPanel.propTypes = {
  hideNotificationPanel: PropTypes.func
};

export default NotificationPanel;
