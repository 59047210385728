import React, { Fragment } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Proxy from 'components/HOC/ContentTabProxy';
import Tab from 'components/Tab';
import KnowledgeBase from './KnowledgeBase';
import Comments from './Comments/Comments';
import Mailing from './Mailing/Mailing';
import AppealHistory from './History';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { RouteWithTabs, createCheckPropsFunction } from 'helpers';
import { connect } from 'react-redux';
import { resetAppealHistory } from 'actions/session';
import { resetComments } from 'actions/comments';
import { resetAppealMailing } from 'actions/emails';
import { resetKnowledgeBase } from 'actions/knowledgeBase';
import permissions, { checkPermissions } from 'config/permissions';
import { createSelector } from 'reselect';

const getAllowableTabs = createSelector(
    state => state.user.operations,
    () => {
        const allowableTabs = [];
        if (checkPermissions(permissions.UserOperations.action_clientPartViewKnowledgeBase)) {
            allowableTabs.push('knowledge_base');
        }

        return allowableTabs.concat(['comments', 'mailing', 'history']);
    }
);

const mapStateToProps = state => ({
    allowableTabs: getAllowableTabs(state)
});

const mapDispatchToProps = dispatch => ({
    resetAppealHistory: () => dispatch(resetAppealHistory()),
    resetComments: () => dispatch(resetComments()),
    resetAppealMailing: () => dispatch(resetAppealMailing()),
    resetKnowledgeBase: () => dispatch(resetKnowledgeBase())
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class TabContent extends React.Component {
    componentDidMount() {
        this.resetPreviousTabState(this.props.match.params.tab);
    }

    componentDidUpdate (prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if (isPropChanged('match.params.tab') && prevProps.match.params.tab) {
            this.resetPreviousTabState(prevProps.match.params.tab);
        }
    }

    resetPreviousTabState = tabName => {
        switch (tabName) {
            case 'knowledge_base':
                return this.props.resetKnowledgeBase();
            case 'comments':
                return this.props.resetComments();
            case 'mailing':
                return this.props.resetAppealMailing();
            case 'history':
                return this.props.resetAppealHistory();
        }
    };

    renderTab = props => {
        switch (props.match.params.tab) {
            case 'knowledge_base':
                return <KnowledgeBase unlockAppealForm={this.props.unlockAppealForm}/>;
            case 'comments':
                return <Comments />;
            case 'mailing':
                return <Mailing />;
            case 'history':
                return <AppealHistory />;
            default:
                return null;
        }
    };

    renderTabButton = tab => {
        return (
            <Tab val={tab} key={tab}/>
        );
    };

    getDefaultTabName = () => {
        return this.props.allowableTabs.includes('knowledge_base')
            ? 'knowledge_base'
            : 'comments';
    };

    render () {
        const {match, location, allowableTabs, iconClass, iconHandler, toggleTabs, data: {result, tabName}, tabsOpened, unlockAppealForm} = this.props;
        const iconCls = tabsOpened ? styles[iconClass] : styles.closed;
        return (
            <div className={cx(styles.tabsWrapper, iconCls, styles[`${tabName}ContentWrapper`])}>
                {tabsOpened ?
                    <Fragment>
                        <div className={styles.tabs}>
                            <div className={styles.leftPart}>
                                {allowableTabs.map(this.renderTabButton)}
                            </div>
                            <div className={styles.tabHeaderButtons}>
                                <i className={`icon-${iconClass}`} onClick={iconHandler}/>
                                <i className='icon-right' onClick={toggleTabs}/>
                            </div>
                        </div>
                        <Switch>
                            <RouteWithTabs
                                path='/appeals/:appealId/:tab?'
                                tabs={allowableTabs}
                                tabPath={'tab'}
                                defaultTabName={this.getDefaultTabName()}
                                match={match}
                                location={location}
                                render={this.renderTab}
                            />
                        </Switch>
                    </Fragment> : <i className='icon-left' onClick={toggleTabs}/>}
            </div>
        );
    }
}

TabContent.propTypes = {
    match: PropTypes.object,
    iconClass: PropTypes.string,
    iconHandler: PropTypes.func,
    toggleTabs: PropTypes.func,
    data: PropTypes.object,
    refresh: PropTypes.func,
    unlockAppealForm: PropTypes.func
};

const getApiParams = (data) => {
    switch (data.tab) {
        case 'knowledge_base': {
            return {
                objectId: null,
                objectType: 'REQUEST_TYPE',
                limit: 25
            };
        }
        case 'comments': {
            return {
                appealId: data.appealId,
                isChild: 0,
                limit: 25
            };
        }
        // data of this request is unused
        case 'mailing': {
            return {
                objectId: data.appealId,
                objectType: 'INTERACTION_REQUEST'
            };
        }
        default:
            return {};
    }
};

export default Proxy(TabContent, {
    noRequest: true,
    tabType: data => data && `appeals_${data.appealId}`,
    name: data => data && `appeals_${data.appealId}`,
    queryKey: data => data && `appeals_${data.appealId}_${data.tab}`,
    storeName: data => data && data.tab
});
