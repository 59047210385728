import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { translate } from 'react-i18next';

import ClickOutsideHolder from 'components/ClickOutsideHolder/index';
import LiveSearch from 'components/LiveSearch/LiveSearch';
import ModalPortal from 'components/ModalPortal/index';
import AdvancedSearchModal from '../AdvancedSearchModal/index';
import { openCreateNewCustomerModal, openCreateNewContactPersonModal } from 'actions/ui';
import {
    getApplicants,
    clearApplicantsState,
    getCustomer,
    getContactPerson,
} from 'actions/customer';
import {
    openAdvancedSearchModal,
    closeAdvancedSearchModal,
    getLiveSearchClientSuppose,
    clearLiveSearchClient,
} from 'actions/client';
import { unlockAppealForm, addCustomerToAppeal, getAppealFeedback } from 'actions/appeal';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import styles from 'styles/modules/appealsContent.module.scss';
import permissions, { checkPermissions } from 'config/permissions';
import { extractAppealFromState, isOrganization } from 'helpers';

function mapStateToProps (state, props) {
    const [appeal] = extractAppealFromState(state, props);
    
    return {
        query: state.client.query,
        showAdvancedSearchModal: state.client.showAdvancedSearchModal,
        applicants: state.customer.applicants,
        appealId: appeal.currentAppeal.id,
        customerButtonRestriction: get(appeal, 'currentAppeal.restriction.customerId'),
        isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer)
    };
}

const mapDispatchToProps = {
    openCreateNewCustomerModal,
    openCreateNewContactPersonModal,
    openAdvancedSearchModal,
    closeAdvancedSearchModal,
    getLiveSearchClientSuppose,
    getApplicants,
    clearApplicantsState,
    clearLiveSearchClient,
    getCustomer,
    unlockAppealForm,
    getContactPerson,
    addCustomerToAppeal,
    getAppealFeedback,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class NotDefinedClient extends React.Component {

    state = {
        value: '',
        showButton: false
    };

    componentDidMount () {
        if (isEmpty(this.props.applicants)) {
            this.props.clearLiveSearchClient();
        }
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.query !== prevState.value) {
            return { value: nextProps.query };
        }
        return null;
    }

    componentDidUpdate ({ match }) {
        if (match.params.appealId !== this.props.match.params.appealId) {
            if (this.search) {
                this.search.value = null;
            }
            this.props.clearLiveSearchClient();
        }
    }

    handleChange = evt => {
        const value = evt.currentTarget.value;
        this.setState({ value });
        this.triggerChange(value);
        this.props.getLiveSearchClientSuppose(value);
    };

    triggerChange = debounce(query => {
        if (query.length > 2) {
            this.props.getApplicants({ name: query, limit: 8 });
        } else if (this.props.applicants.length > 0) {
            this.props.clearApplicantsState();
        }
    }, SEARCH_TIMER_INTERVAL);

    onSearchKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.props.openAdvancedSearchModal();
        } else if (e.keyCode === 27) {
            this.search.value = null;
        }
    };

    onFocus = (e) => {
        if (this.props.customerButtonRestriction && (Number(this.props.customerButtonRestriction) === 3)) {
            this.setState({ showButton: true });
        }
        this.handleChange(e);
    };

    onSelectApplicant = applicant => {
        const { appealId } = this.props;
        const { customerId } = applicant.customer;
        const customerType = applicant.customer.party.partyType;
        
        this.props.addCustomerToAppeal(appealId, customerId, applicant.appl.contactPersonId);
        if (isOrganization(customerType) && !applicant.appl.contactPersonId) {

            const onSuccess = contactPersonId => {
                this.props.getCustomer({ customerId }, customerId);
                this.props.getContactPerson({ id: contactPersonId }, customerId);
            };

            if (this.props.isCustomerCreationAllowed) {
                this.props.openCreateNewContactPersonModal({ customerId, onSuccess });
            }
        } else {
            this.props.getCustomer({ customerId }, customerId);
            if (applicant.appl.contactPersonId) {
                this.props.getContactPerson({ id: applicant.appl.contactPersonId }, customerId)
            }

            this.props.unlockAppealForm(appealId);
        }
        this.props.getAppealFeedback({ appealId, customerId: customerId })
    };

    onClick = (event, selectedApplicant) => {
        event.preventDefault();
        event.stopPropagation();

        const applicant = this.props.applicants[selectedApplicant.index];
        this.onSelectApplicant(applicant);

        this.props.clearApplicantsState();
    };

    handleClickOutside = (e) => {
        const inputClick = (this.search && this.search.contains(e.target));
        const newCustomerButtonClick = this.newCustomerButton && this.newCustomerButton.contains(e.target);

        if (!inputClick && !newCustomerButtonClick) {
            if (this.state.showButton) this.setState({ showButton: false });
            if (this.props.applicants.length !== 0) this.props.clearApplicantsState();
        }
    };

    convertApplicants = applicants => applicants.slice(0, 8).map((elem, index) => ({
        id: elem.customer.customerId,
        type: 'customer',
        name: elem.customer.custName,
        content: elem.customer.phyAddr.name,
        code: 'notDefinedClient',
        contactPersonName: elem.appl.firstName + ' ' + elem.appl.lastName,
        applId: elem.appl.applId,
        contactPersonId: elem.appl.contactPersonId,
        index
    }));

    handleCreateCustomerClick = () => this.props.openCreateNewCustomerModal({ openNewTab: false });

    render () {
        const { applicants, query, showAdvancedSearchModal, t, isCustomerCreationAllowed,
            customerRemoveRestriction,
        } = this.props;
        const searchResults = this.convertApplicants(applicants);
        return (
            <div className={styles.notDefinedClientWrapper}>
                <div className={styles.headerTitle}>{t('searchClient.notDefinedClient')}</div>

                {customerRemoveRestriction === 3 ?
                    <div className={cx(styles.liveSearchClient, { 'inputFocus': this.state.showButton })}>

                        <ClickOutsideHolder onClickOutside={this.handleClickOutside}>
                            <input
                                name='client'
                                autoComplete='off'
                                className={cx(styles.clientInput, { 'inputVal': this.state.value || query })}
                                onChange={this.handleChange}
                                onKeyUp={this.onSearchKeyUp}
                                ref={node => (this.search = node)}
                                value={this.state.value || query || ''}
                                placeholder={t('searchclient.search')}
                                onFocus={this.onFocus}
                            />
                            <span className='icon icon-search-maginfer'/>

                            <button
                                onClick={this.props.openAdvancedSearchModal}
                                className={styles.iconButton}
                                title={t('searchClient.advanceSearch')}
                            >
                                <span className='icon icon-advance'/>
                            </button>


                            {searchResults && (
                                <LiveSearch
                                    searchResults={searchResults}
                                    className={'liveSearch-appeal'}
                                    onClick={this.onClick}
                                />
                            )}

                        </ClickOutsideHolder>
                    </div> : null
                }

                {
                    (applicants.length > 0 || this.state.showButton) && isCustomerCreationAllowed &&
                    <button
                        onClick={this.handleCreateCustomerClick}
                        className={styles.createNewButton}
                        ref={(node) => this.newCustomerButton = node}
                    >
                        + {t('searchClient.createNew')}
                    </button>
                }

                <CSSTransition
                    in={showAdvancedSearchModal}
                    classNames='fade'
                    appear={true}
                    enter={true}
                    exit={true}
                    timeout={500}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <ModalPortal
                        onClose={this.props.closeAdvancedSearchModal}
                        className='modal-large'
                    >
                        <AdvancedSearchModal onSelect={this.onSelectApplicant}/>
                    </ModalPortal>
                </CSSTransition>
            </div>
        );
    }
}

NotDefinedClient.propTypes = {
    getApplicants: PropTypes.func,
    openCreateNewCustomerModal: PropTypes.func,
    applicants: PropTypes.array,
    query: PropTypes.string,
    showAdvancedSearchModal: PropTypes.bool,
    openAdvancedSearchModal: PropTypes.func,
    getLiveSearchClientSuppose: PropTypes.func,
    clearLiveSearchClient: PropTypes.func,
    closeAdvancedSearchModal: PropTypes.func,
    clearApplicantsState: PropTypes.func,
    definedCustomer: PropTypes.func,
    addAppealCustomerId: PropTypes.func,
    appealId: PropTypes.number,
    appealRestriction: PropTypes.number,
    customerButtonRestriction: PropTypes.string,
    isCustomerCreationAllowed: PropTypes.bool,
};
