import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import styles from 'styles/modules/tab.module.scss';

@translate()
@withRouter
class Tab extends React.Component {

    constructor(props) {
        super(props);

        this.onTabClick = this.onTabClick.bind(this);
    }

    onTabClick() {
        if (this.props.onClick) this.props.onClick(this.props.val);
    };

    render() {
        const { val, history, counter, getPath, t } = this.props;
        const aPath = history.location.pathname.split('/');
        aPath.pop();
        aPath.push(val);
        const path = getPath ? getPath() : aPath.join('/');
        return (
            <NavLink exact to={path} className={styles.tabVertical} onClick={this.onTabClick}>
                {t(`tabs.${val}`).toUpperCase()} {counter}
            </NavLink>
        );
    }
}

Tab.propTypes = {
    val: PropTypes.string.isRequired,
    card: PropTypes.object,
    role: PropTypes.object,
    onClick: PropTypes.func,
};

export default Tab;
