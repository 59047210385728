import React from 'react';
import PropTypes from 'prop-types';


export default function PlaybackTimer({ children = 0 }) {
  const minutes = Math.floor(children / 60);
  const seconds = children - minutes * 60;
  const time = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return (
    <div className='playbackTimer' >{time}</div>
  );
}


PlaybackTimer.propTypes = {
  children: PropTypes.number
};
