import React, { Fragment, Component } from 'react';
import cx from 'classnames';
import modalStyles from 'styles/modules/modal.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxFormWrapper, createCheckPropsFunction, extractCustomerFromState } from 'helpers';
import { customerAddressFormConfig, customerAddressFormConfigWithType, StreetModes } from 'constants/index';
import { Field, formValueSelector } from 'redux-form';
import { translate } from 'react-i18next';
import validate from './validate';
import baseService from 'services/BaseService';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { closeCustomerAddressModal, addressSave } from 'actions/ui';
import StreetField from './StreetField';
import { modifyCustomer } from 'actions/customer';

const getValueByLabel = (label, t, dictionaryField) => {
    const dictionary = t(`dictionary:${dictionaryField}`, { returnObjects: true });
    return Object.keys(dictionary).find(key => dictionary[key] === label);
};

const getInitialValues = (currentAddress, t) => ({
    type: getValueByLabel(currentAddress.type, t, 'addressType'),
    settlement: {
        label: currentAddress.settlement.name,
        value: currentAddress.settlement.id,
        fullName: currentAddress.settlement.fullName
    },
    street: {
        label: get(currentAddress.street, 'name', ''),
        value: get(currentAddress.street, 'id', '')
    },
    buildingNumber: {
        value: currentAddress.buildingNumber,
        label: currentAddress.buildingNumber
    },
    buildingSecondNumber: get(currentAddress, 'buildingSecondNumber', ''),
    apartmentNumber: get(currentAddress, 'apartmentNumber', ''),
    zipCode: {
        label: currentAddress.zipCode,
        value: currentAddress.zipCode
    },
    description: get(currentAddress, 'description', '')
});

const formSelector = formValueSelector('add-customer-address');

const mapStateToProps = (state, props) => {
    const customer = extractCustomerFromState(state, props.match.params.id);
    const result = {
        ...formSelector(state, 'type', 'settlement', 'street', 'buildingNumber', 'zipCode'),
        mode: formSelector(state, 'mode') || StreetModes.AUTO,
        customerId: customer.currentCustomer.id
    };
    if (state.ui.address) {
        result.initialAddress = state.ui.address;
        result.initialValues = getInitialValues(state.ui.address, props.t);
    }
    
    return result;
};

const mapDispatchToProps = {
	closeCustomerAddressModal,
	addressSave,
	modifyCustomer,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'add-customer-address', validate })
class CustomerAddressForm extends Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			isEditing: Boolean(props.initialValues),
			settlementOptions: [],
			streetOptions: [],
			buildingNumberOptions: [],
			zipCodeOptions: [],
			addressTypes: null,
		};
		
		this.linkedFields = ['settlement', 'street', 'buildingNumber', 'zipCode'];
		this.wait = false;
		this.addressTypes = this.convertDictionaryObject(props.t('dictionary:addressTypeInfo', {returnObjects: true}));
		this.clearAllValuesWithoutSettlement = this.clearAllValuesWithoutSettlement.bind(this);
		
		this.close = this.close.bind(this);
	}
	
	async componentDidMount () {
		this.props.change('mode', StreetModes.AUTO);
		let addressTypes = await baseService.get('customer_address_type',
			{ data: { objectType: 'CUSTOMER', objectId: this.props.match.params.id } }
		);
		
		addressTypes = addressTypes.result;
		
		if (addressTypes.length) {
			addressTypes = addressTypes.result.map((i) => ({ value: i.code, label: i.name }))
		}
		
		this.setState({ addressTypes: addressTypes });
		
		if (this.props.initialValues) {
            this.requestInitialData();
            this.props.change('type', this.addressTypes.find(type => type.value === this.props.initialValues.type));
        }
    }
	
	componentDidUpdate(prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		const { mode } = this.props;
		
		if(isPropChanged('mode') && mode === StreetModes.MANUAL) {
			this.clearAllValuesWithoutSettlement();
		}
	}
	
	clearAllValuesWithoutSettlement () {
		this.props.change('buildingSecondNumber', null);
		this.props.change('apartmentNumber', null);
		this.resetLinkedFields('street');
		this.resetLinkedFields('buildingNumber');
		this.resetLinkedFields('zipCode');
	}
	
    onStreetFieldRefReady = streetFieldRef => {
        this.streetFieldRef = streetFieldRef;
    };
    
    resetLinkedFields = field => {
        const fieldsForReset = this.linkedFields.slice(this.linkedFields.indexOf(field) + 1);
        fieldsForReset.forEach(field => this.props.change(field, null));
        
        const nextState = {};
        fieldsForReset.reduce((acc, field) => {
            acc[`${field}Options`] = [];
            return acc;
        }, nextState);
        this.setState({ ...nextState });
    };
    
    requestInitialData = () => {
        const { settlement, street, buildingNumber } = this.props.initialValues;
        return Promise.all([
            this.requestSettlements(settlement.label), // 0
            this.requestStreets(street.label, settlement.value), // 1
            this.requestBuildings(street.value) // 2
        ])
            .then(responses => {
                const settlementOptions = responses[0].success ? this.convertSettlementResponse(responses[0].result) : [];
                this.props.change('settlement', settlementOptions.find(settlement => settlement.id === this.props.initialValues.settlement.value));
                const nextState = {
                    settlementOptions,
                    streetOptions: responses[1].success ? this.convertStreetResponse(responses[1].result) : [],
                    buildingNumberOptions: responses[2].success ? this.convertBuildingResponse(responses[2].result) : []
                };
                this.setState({ ...nextState });
                
                const build = responses[2].success ? responses[2].result.find(building => building.name === buildingNumber.value) : null;
                
                if (build) {
                    return this.requestZipCodes(street.value, build.id)
                        .then(response => this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] }));
                }
            })
            .catch(error => console.log('Error in initial request: ', error));
    };
    
    // *** onChange handlers ***
    
    onInputChange = field => inputValue => {
        const query = !inputValue && this.props[field] ? this.props[field].label : inputValue;
        
        switch (field) {
        case 'settlement':
            this.onSettlementChange(query);
            break;
        case 'street':
            this.onStreetChange(query);
            break;
        case 'buildingNumber':
            this.props.change(`${field}Input`, inputValue);
            break;
        
        case 'zipCode':
            let numericMatch = inputValue.match(/\d+/g);
            inputValue = numericMatch ? numericMatch[0] : '';
            this.props.change(`${field}Input`, inputValue);
            this.onZipCodeChange(query);
            break;
        }
        
        return inputValue;
    };
    
    onSettlementChange = debounce(query => {
        const { mode } = this.props;
        if (!query || query.length < 3) return;
        return this.requestSettlements(query)
            .then(response => {
                if (response.success) {
					if(mode === StreetModes.AUTO) {
						this.clearAllValuesWithoutSettlement();
					}
                    this.setState({ settlementOptions: this.convertSettlementResponse(response.result) });
                } else {
                    this.setState({ settlementOptions: [] });
                }
            });
    }, 500);
    
    onStreetChange = debounce(query => {
        if (!this.props.settlement || !query || query.length < 2 || get(this.props, 'street.label') === query) return;
        
        return this.requestStreets(query, this.props.settlement.id)
            .then(response => {
                if (response.success) {
					this.props.change('buildingSecondNumber', null);
					this.props.change('apartmentNumber', null);
					this.resetLinkedFields('buildingNumber');
					this.resetLinkedFields('zipCode');
                    this.setState({ streetOptions: this.convertStreetResponse(response.result) });
                    
                } else {
                    this.setState({ streetOptions: [] });
                }
            });
    }, 500);
    
    onZipCodeChange = debounce(query => {
        
        const isQueryShort = !query || query.length < 5;
        const areSettlementAndStreetEmpty = !this.props.settlement && !this.props.street;
        
        if (isQueryShort || !areSettlementAndStreetEmpty || this.props.mode !== StreetModes.AUTO) {
            return;
        }
        
        return this.requestZipCodesSearch(query)
            .then(response => {
                if (response.success && response.result && response.result.length > 0) {
                    this.setAddressFoundByIndex(response.result);
                }
            });
    }, 300);
    
    setAddressFoundByIndex = results => {
        const streetOptions = this.convertStreetResponse(results[0].street);
        const settlement = this.convertSettlementResponse(results.slice(0, 1))[0];
        
        const focusStreetInput = () => this.streetFieldRef.current.input.input.focus();
        
        this.props.change('street', streetOptions[0]);
        this.props.change('settlement', settlement);
        this.setState({ settlementOptions: [], streetOptions }, focusStreetInput);
    };
    
    // *** Converters ***
    
    convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
        value: value,
        label: prop
    }));

    convertSettlementResponse = result => result.map(settlement => {
        let cutString = settlement.fullName.indexOf('/', 1);
        let tempPartLabel = cutString >= 0 ? settlement.fullName.substr(cutString) : '';
        let modifiedLabel = settlement.name + tempPartLabel;

        return {
            label: modifiedLabel,
            value: settlement.fullName,
            id: settlement.id,
            fullName: settlement.fullName,
            title: modifiedLabel
        };
    });
    
    convertStreetResponse = result => result.map(street => ({ label: street.name, value: street.id }));
    
    convertBuildingResponse = result => result.map(building => ({ label: building.name, value: building.id }));
    
    convertZipCodeResponse = result => result.map(zipCode => ({ label: zipCode.zip_code, value: zipCode.id }));
    
    // *** Requests ***
    
    requestSettlements = query => {
        const data = { query, page: 1, start: 0, limit: 10 };
        return baseService.get('search_settlement', { data });
    };
    
    requestStreets = (query, settlementId) => {
        const data = { query, settlementId, page: 1, start: 0, limit: 10 };
        return baseService.get('search_street', { data });
    };
    
    requestBuildings = streetId => {
        const data = { streetId };
        return baseService.get('search_building', { data });
    };
    
    requestZipCodes = (streetId, buildId) => {
        const data = { streetId, buildId };
        return baseService.get('search_zip_code', { data });
    };
    
    requestZipCodesSearch = query => {
        const data = { query };
        return baseService.get('search_settlement_by_zip_code', { data });
    };
    
    getSettlementOptionComponent = () => props => {
        return (
            <div className={cx(props.className, 'settlement-option')} onClick={() => props.selectValue(props.option)} title={props.option.label}>
                <div className={'settlementFullName'}>{props.option.label}</div>
            </div>
        );
    };
    
    handleChange = (event, newValue, previousValue, fieldName) => {
        this.resetLinkedFields(fieldName);
        
        if (fieldName === 'street' && newValue) {
            this.requestBuildings(newValue.value)
                .then(response => {
                    this.setState({ buildingNumberOptions: response.success ? this.convertBuildingResponse(response.result) : [] });
                });
        }
        
        if (fieldName === 'buildingNumber' && newValue) {
            this.requestZipCodes(this.props.street.value, newValue.value)
                .then(response => {
                    this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] });
                });
        }
    };
    
    onStreetModeChange = newStreetMode => this.props.change('mode', newStreetMode);
    
    addStreetRequest = values => {
        const params = {
            data: {
                parentId: values.settlement.id,
                parentType: 'location',
                name: values.streetName,
                type: 'street',
                streetTypeId: values.streetType.value,
                guid: '',
                sourceId: '',
                description: ''
            },
            jsonType: true
        };
        
        return baseService.post('add_street', params);
    };
    
    getFieldPropsWithOptions = fieldProps => {
        switch (fieldProps.name) {
        case 'type':
            return {
                ...fieldProps,
                options: this.state.addressTypes || []
            };
        case 'settlement':
            return {
                ...fieldProps,
                options: this.state.settlementOptions,
                onInputChange: this.onInputChange('settlement'),
                optionComponent: this.getSettlementOptionComponent(),
                onChange: this.handleChange
            };
        case 'street':
            return {
                mode: this.props.mode,
                onModeChange: this.onStreetModeChange,
                fieldConfig: fieldProps,
                options: this.state.streetOptions,
                onInputChange: this.onInputChange('street'),
                onChange: this.handleChange,
                id: 'streetInput',
                openOnFocus: true,
                onRefReady: this.onStreetFieldRefReady
            };
        case 'buildingNumber':
            return {
                ...fieldProps,
                options: this.state.buildingNumberOptions,
                onChange: this.handleChange,
                onInputChange: this.onInputChange('buildingNumber'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };
        case 'zipCode':
            return {
                ...fieldProps,
                options: this.state.zipCodeOptions,
                onInputChange: this.onInputChange('zipCode'),
                onBlurResetsInput: false,
                onCloseResetsInput: false
            };
        case 'description':
            return {
                ...fieldProps,
                style: {overflow:"auto", height: "85px"},
            };
        default:
            return fieldProps;
        }
    };
    
    saveAddress = data => {
        return baseService.post('customer_address', { data })
            .then(response => {
                if (response.success) {
                    this.props.addressSave();
                    this.props.modifyCustomer(this.props.match.params.id);
                    this.wait = false;
                } else {
                    throw new Error('Address save request error');
                }
            })
            .catch(this.handleSaveError);
    };
    
    handleSaveError = error => {
        console.error(error);
        this.wait = false;
    };
    
    onSubmit = values => {
        if (this.wait) return;
        this.wait = true;
        
        const data = {
            customerId: this.props.match.params.id,
            streetId: get(values, 'street.value') || '',
            type: get(values, 'type.value') || get(this.props, 'initialValues.type') || '',
            settlementId: values.settlement.id,
            street: get(values, 'street.value') || '',
            buildingNumber: values.buildingNumberInput || get(values, 'buildingNumber.label') || '',
            apartmentNumber: get(values, 'apartmentNumber') || '',
            description: get(values, 'description') || '',
            kind: 'urban',
            zipCode: values.zipCodeInput || get(values, 'zipCode.label') || '',
            buildingSecondNumber: get(values, 'buildingSecondNumber') || '',
            intercomNumber: '',
            floor: '',
            latitude: '',
            longitude: ''
        };
        
        if (this.props.initialAddress) {
            data.id = this.props.initialAddress.id;
        }
        
        if (values.mode === StreetModes.MANUAL) {
            this.addStreetRequest(values)
                .then(response => {
                    if (response.success && response.result) {
                        const street = response.result[0];
                        
                        data.street = street.id;
                        data.streetId = street.id;
                        
                        this.props.closeCustomerAddressModal();
                        this.saveAddress(data);
                    } else {
                        throw new Error('Street save request error');
                    }
                })
                .catch(this.handleSaveError);
        } else {
            this.props.closeCustomerAddressModal();
            this.saveAddress(data);
        }
    };
    
    renderInputField = fieldProps => {
        switch (fieldProps.name) {
        case 'street':
            return <StreetField key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        
        default:
            return <Field key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
        }
    };
	
	close () {
		this.props.closeCustomerAddressModal();
	}
    
    render () {
        const { handleSubmit, t } = this.props;
        const { isEditing, addressTypes } = this.state;
	
		if (!addressTypes && !isEditing) return (
			<div className={modalStyles.modalHeader}>
				<div className={modalStyles.modalTitle}>{isEditing ? t('editAddress') : t('newAddress')}</div>
			</div>
		);
	
		return (
			<Fragment>
				<div className={modalStyles.modalHeader}>
					<div className={modalStyles.modalTitle}>{isEditing ? t('editAddress') : t('newAddress')}</div>
				</div>
				{
					!isEditing && addressTypes ?
						(
							<div className={modalStyles.modalContent}>
								<p>Для цього контакта більше немає можливості створити адресу.</p>
								<p>Якщо адреса змінилася, відредагуйте, будьласка існуючу.</p>
								<button className={'btn btn-primary'} onClick={this.close}>
									{t("modalWindowsLabels.good")}
								</button>
							</div>
						) :
						(
							<form className={modalStyles.modalContent} onSubmit={handleSubmit(this.onSubmit)}>
								{isEditing ? customerAddressFormConfig.map(this.renderInputField) : customerAddressFormConfigWithType.map(this.renderInputField)}
								<button className={'btn btn-primary'}>
									<i className='icon icon-check' />
									{isEditing ? t('save') : t('create')}
								</button>
							</form>
						)
				}
			</Fragment>
        );
    }
}

export default CustomerAddressForm;
