import React, { lazy } from 'react';
import { saveFormChanges } from 'actions/changeHistory';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { Redirect, Route } from 'react-router-dom';
import get from 'lodash/get';
import { CustomerTypes } from 'constants/index';
import { unlockAppeal } from './actions/appeal';
import permissions, { checkPermissions } from 'config/permissions';

export const TabTypeGetters = {
    appeal: appealId => `appeals_${appealId}`
};

export const isOrganization = customerType => customerType === CustomerTypes.ORGANIZATION;

export const createCheckPropsFunction = (prevProps, currentProps) => {
    return path => get(prevProps, path) !== get(currentProps, path);
};

export function arrToMap(arr, index) {
  if (!arr || !arr.length) return {};
  index = index || 'id';
  return arr.reduce((acc, cur) => ({
    ...acc, [cur[index]]: cur
  }), {});
}

export function objToGETString(obj) {
  let str = '';
  for (const key in obj) {
    const item = obj[key];
    if (Array.isArray(item)) {
      str += (str) ? '&' : '';
      str += `${key}=${(key === 'query') ? item : encodeURIComponent(JSON.stringify(item))}`;
    } else if (['string', 'number'].includes(typeof item)) {
      str += (str) ? '&' : '';
      str += `${key}=${(key === 'query') ? ((item.includes('+') || item.includes('%')) ? encodeURIComponent(item) : item) : encodeURIComponent(item)}`;
    } else if (typeof item === 'object') {
        str += (str) ? '&' : '';
        str += `${key}=${encodeURIComponent(JSON.stringify(item))}`;
    }
  }
  return str;
}

export function getCurrentTabCacheField(currentTab, field) {
    if (!currentTab || !field) {
        console.error('getCurrentTabCacheField error: currentTab and field are required');
    }
    if (!currentTab[field] || !currentTab.queryKey) return {};
    return currentTab[field][currentTab.queryKey] || {};
}

export const calculateSearchNumber = (tabs) => {
    let searchNumber = 1;
    const searchTabs = tabs && tabs.tabs.reduce((acc, item) => {
        if (item.url.split('/')[1] === 'search') {
            const hash = item.url.split('/')[3];
            acc.push(hash);
        }
        return acc;
    }, []);

    if (searchTabs.length) {
        searchNumber = Math.max(...searchTabs) + 1;
    }
    return searchNumber;
};

const cyrillicSymbol = /[a-zA-Z\u0400-\u04FF]/;

export const getFirstLetters = (name = '') => {
    return name.split(' ', 2)
        .map(word => {
            if (word) {
                const firstChar = word.charAt(0);
                return cyrillicSymbol.test(firstChar) ? firstChar.toUpperCase() : '';
            }
            return '';
        })
        .join('');
};

export const safeQuery = (value, reverse) => reverse ? String(value).replace(/~/g, '%') : String(value).replace(/%/g, '~');

export const cropTabTypeToIconName = (tabType) =>
    String(tabType).match(/[a-zA-Z_]+[a-zA-Z]/);

export const findObjectDifferences = (first, second) =>
    Object.keys(second).reduce((diffs, key) => first[key] !== second[key] ? {
        ...diffs,
        [key]: second[key]
    } : diffs, {});

export const getDefaultValue = (value) => {
    if (Array.isArray(value)) {
        return [];
    } else if (typeof value === 'object') {
        return {};
    }
    return null;
};

export const changeFormListener = (changedValues, dispatch, props, previousValues) => {
    const diff = findObjectDifferences(previousValues, changedValues);
    const diffKeys = Object.keys(diff);
    if (diffKeys.length > 1) return; // More than one change means reinitializing the form

    const field = diffKeys[0];
    if (!field) return;

    const after = changedValues[field] instanceof moment ? moment(changedValues[field]).valueOf() : changedValues[field];
    const before = previousValues[field] instanceof moment ? moment(previousValues[field]).valueOf() : previousValues[field] || getDefaultValue(after);
    const form = props.form;
    dispatch(saveFormChanges({ form, field, before, after }));
};

export const reduxFormWrapper = (config) => {
    return reduxForm({ ...config, onChange: changeFormListener });
};

export const killEvent = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopImmediatePropagation();
};

export const forceFocusOnInput = (field, arrayIndex, formName) => {
    const selector = arrayIndex ? `[name="${field}[${arrayIndex}]"]` : `[name="${field}"]`;
    const element = formName ? document.querySelector(`#${formName} ${selector}`) : document.querySelector(selector);
    element && element.focus();
};


export const getCustomerTabNames = (customerType) => {
    const isViewTasks = checkPermissions(permissions.CustomerOperations.view_Item);
    const tabNames = { appeals: 'appeals' };

    if (isViewTasks) tabNames.tasks = 'tasks';
    tabNames.address = 'address';

    switch (customerType) {
    case 'individual':
        return { ...tabNames };
    case 'organization':
        return { ...tabNames, persons: 'persons' };
    }
    return tabNames;
};

export const RouteWithTabs = ({ tabs, tabPath, match, location, defaultTabName, ...props }) => {
    const tabName = match.params[tabPath];
    const defaultTab = defaultTabName || tabs[0];

    // if not tabs.
    if (!tabName) {
        const newPath = location.pathname.endsWith('/') ? `${location.pathname}${defaultTab}` : `${location.pathname}/${defaultTab}`;
        return <Redirect to={newPath} />;
    }
    // if the tab is included.
    else if (tabs.includes(tabName)) {
        return <Route {...props} />;
    }

    // if the tab is not included.
    const tabPosition = location.pathname.lastIndexOf('/');
    const newPath = `${location.pathname.slice(0, tabPosition)}/${defaultTab}`;
    return <Redirect to={newPath}/>;
};


export const forEachKnowledgeArticle = (item, callback, level = 1, parentId = null) => {
    for (const key in item) {
        if (item[key].leaf) {
            callback(key, item[key], level, parentId);
        } else {
            forEachKnowledgeArticle(item[key].result, callback, level + 1, item[key].id || item[key].item.id);
        }
    }
};

export const forEachKnowledgeDirectory = (knowledgeBase, callback, level = 0, parentId = null) => {
    knowledgeBase.forEach((item, index) => {
        const isFolder = !item.leaf;

        if (isFolder && item.id) {
            callback(index, item, level, parentId);
            forEachKnowledgeDirectory(item.result, callback, level + 1, item.id);
        }
    });
};

export const forEachBuisnessUnits = (item, callback, level = 0) => {
    for (const key in item) {
        const { text, leaf = item[key].leaf } = item[key].object;
        callback(item[key].object.id, { text, leaf }, level, item[key].object.parentId);
        if (!item[key].leaf) {
            forEachBuisnessUnits(item[key].children, callback, level + 1, item[key].object.parentId);
        }
    }
};

export const findPropertyInArray = (array, key, value) => {
    return array.find(element => element[key] === value);
};

export function utoa(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

export function atou(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

export function debounce(callback, delay) {
    return function debounceWrapper(...args) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => callback.apply(this, args), delay);
    };
}

export function keyGen() {
    return Math.random().toString(36).substr(2, 5);
}

export function normalizeDestination(destination) {
    let result = [];
    if (destination) {
        destination.forEach((i) => {
            if (typeof i === 'object') {
                result = [...result, i && i.value];
            } else if (i) {
                result = [...result, i];
            }
        });
    }
    return result;
}

export function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


export function searchTree(searchArray, children, callback) {
    let queue = [...searchArray];
    let result;
    let condition = true;
    while (condition) {
        const item = queue[0];
        if (item && callback(item)) {
            result = item;
            condition = false;
        }
        if (item && item[children]) {
            queue.push(...item[children]);
        }
        queue.shift();
        condition = condition === false ? false : queue.length;
    }
    return result;
}

export function preloadLazy(path) {
    return lazy(() => path);
}

export function buildThreePath (three, path, conf) {
    return three.map((item) => {
        if (item[conf.children]) {
            item[conf.children] = buildThreePath(item[conf.children], item.path ? item.path : item.value, conf);
        }
        item.path = path ? `${path} / ${item.value}` : '';
        if (!item.name) {
            item.name = item.value
        }
        return item;
    });
}

export function getValueOrNull (value) {
    return value || null;
}


/********** State getters, redux-from names builders  **********/

export function extractAppealFromState (state, props) {
    const { appealId } = props.match.params;

    return [(get(state, `appeal.appeals[${appealId}]`) || {}), appealId];
}

export function extractCustomerFromState (state, id) {
    return (get(state, `customer.persons[${id}]`) || {});
}

export function searchCustomersInAppeals (appeals, customerId) {
    return Object.keys(appeals).filter((item) => {
        return parseInt(_.get(appeals, `[${item}].currentAppeal.customerId`)) === parseInt(customerId);
    });
}

export function searchCustomersInTabs (tabs, customerId) {
    if (tabs.length <= 1) {
        return [];
    }

    return tabs.filter((item) => {
        return item.url.search(customerId) > 0;
    });
}

export function destroyFormsAppealsAndCustomers (params) {
	const { forms, clearAllAppeals, destroyForm, clearAllCustomers } = params;

	clearAllAppeals();
	clearAllCustomers();
	setTimeout(() => {
		_.uniq(Object.keys(forms)).forEach((name) => destroyForm(name));
	}, 1000);
}

export function customerFormNameBuilder (customerFormId, type) {
	const customerFilled = `${customerFormId}-customer-filled`;
	const customerEmpty = `${customerFormId}-customer-empty`;
	const customerName = `${customerFormId}-edit-customer-name`;
	const personFilled = `${customerFormId}-person-filled`;
	const personEmpty = `${customerFormId}-person-empty`;
	const personName = `${customerFormId}-edit-person-name`;

	if (type === 'customer') {
		return [customerFilled, customerEmpty, customerName]
	}

	if (type === 'person') {
		return [personFilled, personEmpty, personName]
	}

	return [customerFilled, customerEmpty, personEmpty, personFilled, personName, customerName]
}

export function closeAppeal (props) {
	const {
		clearAppealState, destroyForm, clearEntireCustomerState, appeals, tabs, forms, clearAllAppeals,
		clearAllCustomers, lastTab, id
	} = props;

	const customerId = appeals[id].currentAppeal.customerId;
	let formSet = [`appeal-form-${id}`, `${customerId}-edit-person-name`];

	unlockAppeal({ id });
	if (lastTab) {
		destroyFormsAppealsAndCustomers({ forms, clearAllAppeals, destroyForm, clearAllCustomers });
	} else {
		if ([...searchCustomersInTabs(tabs, customerId), ...searchCustomersInAppeals(appeals, customerId)].length === 1) {
			formSet = [
				...formSet,
				...customerFormNameBuilder(customerId),
			];
			clearEntireCustomerState(customerId);
		}

		clearAppealState(id);
		setTimeout(() => {
			_.uniq(formSet).forEach((name) => destroyForm(name));
		}, 1000);
	}
}
