export function reactDebounce(f, ms) {
  let timer = null;
  return function () {
    const ev = arguments[0];
    const savedArgs = arguments;

    if (ev.persist) ev.persist();

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      f.apply(this, savedArgs);
      timer = null;
    }, ms);
  };
}
