import baseService, { AbortError } from './BaseService';


export default Object.freeze({
  initProxy: (path, params, post) => {
    // do not send an empty search request.
    if (path === 'search' && !params.data.query) {
      return Promise.reject(new AbortError('Do not send a blank search request'));
    }

    // call baseServise
    if (post) {
      return baseService.post(path, params);
    }
    return baseService.get(path, params);
  }
});
