import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { FieldTypes, personFormConfig } from 'constants/index';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import styles from 'styles/modules/parameters.module.scss';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';
import FilledForm from 'components/CustomerPage/Parameters/FilledForm';
import EmptyForm from 'components/CustomerPage/Parameters/EmptyForm';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import isObjectLike from 'lodash/isObjectLike';
import { personDeleteContact, personEdit, personSaveContact, issuesEdit } from 'actions/customer';
import { toggleSideMenuAdditionsPerson } from 'actions/ui';
import i18n from 'util/i18n';
import { extractCustomerFromState, createCheckPropsFunction } from 'helpers';

const convertParametersData = contactPerson => {
    const contacts = groupBy(contactPerson.contacts, 'type');

    return {
        id: contactPerson.id,
        lastName: contactPerson.party.lastName,
        firstName: contactPerson.party.firstName,
        patronymic: contactPerson.party.patronymic,
        lang_code: contactPerson.lang_code,
        validFrom: contactPerson.validFrom,
        validTill: contactPerson.validTill,
        email: contacts.email,
        mobilePhone: contacts.mobilePhone,
        homePhone: contacts.homePhone,
        workPhone: contacts.workPhone,
        utkPhone: contacts.utkPhone,
        fax: contacts.fax,
        organizationPhone: contacts.organizationPhone,
        another: contacts.another,
        issues: contactPerson.links[0].issues,
    };
};

const getInitialValues = (contactPerson, t, dictionary) => {
    const personData = convertParametersData(contactPerson);
    const values = {};

    personData && personFormConfig.forEach(config => {
        let fieldValue = get(personData, config.name);

        // for field type is "select", we need to create object like {value, label}
        if (config.type === FieldTypes.SELECT) {
            const fieldDictionary = get(dictionary, config.options, {});
            if (config.multi) {
                values[config.name] = fieldValue;
            } else {
                values[config.name] = {value: fieldValue, label: fieldDictionary[fieldValue]};
            }
        } else {
            values[config.name] = fieldValue;
        }

    });
    return values;
};

const initialValuesSelector = createSelector(
    (state, props) => extractCustomerFromState(state, props.id).currentContactPerson,
    (state, props) => props.t,
    () => i18n.getResourceBundle('uk', 'dictionary'),
    getInitialValues
);

/**
 * Return object of not-empty values
 */
const filledFieldsSelector = createSelector(
    initialValuesSelector,
    initialValues => {
        return Object.keys(initialValues)
            .reduce((acc, key) => {
                const currentValue = initialValues[key];

                const isNotEmptyObject = isObjectLike(currentValue) && !isEmpty(currentValue);
                const isNotEmptyPrimitive = currentValue && !isObjectLike(currentValue);

                if (isNotEmptyPrimitive || isNotEmptyObject) {
                    acc[key] = currentValue;
                }

                return acc;
            }, {});
    }
);

function mapStateToProps (state, props) {
    const customer = extractCustomerFromState(state, props.id);

    return {
        showSideMenuAdditionsPerson: state.ui.showSideMenuAdditionsPerson,
        contactPerson: customer.currentContactPerson,
        initialValues: initialValuesSelector(state, props),
        filledFields: filledFieldsSelector(state, props),
        contactPersonLoading: customer.contactPersonLoading,
    }
}

const mapDispatchToProps = {
    toggleSideMenuAdditionsPerson,
    personEdit,
    issuesEdit,
    personSaveContact,
    personDeleteContact,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class Parameters extends React.Component {
    constructor (props) {
        super(props);

        this.wait = false;
    }

    componentDidUpdate (prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if ((isPropChanged('contactPerson.id') || isPropChanged('contactPerson.timestamp')) && this.wait) {
            this.wait = false;
        }
    }

    handleCollapse = event => {
        event.stopPropagation();
        this.props.toggleSideMenuAdditionsPerson();
    };

    handleHideSideMenu = (e) => {
        e.stopPropagation();
    };

    /**
     * Normalize redux-form field name
     * Example: mobilePhone[2] => mobilePhone
     * Example: birthDate => birthDate
     */
    normalizeFieldName = fieldName => {
        const arrayFieldNameRegexp = /^[\w]+\[\d+\]$/;
        if (arrayFieldNameRegexp.test(fieldName)) {
            return fieldName.substring(0, fieldName.indexOf('['));
        }
        return fieldName;
    };

    onEditRequestError = () => {
        this.wait = false;
    };

    /**
     * Create request data object for fields of type "array" and send request
     *
     * @param {Object} payload
     * @param {Object|String|Array} payload.value
     * @param {String} payload.name - field name
     * @param {Object} payload.options - action details
     * @param {Object} payload.options.fieldValue
     * @param {String} payload.options.action
     * @param {Number} payload.options.index

     * @param {Object} fieldConfig
     * @param {String} fieldConfig.name
     * @param {String} fieldConfig.field
     *
     * @return {Object} requestData
     */
    sendArrayFieldRequest = (payload, fieldConfig) => {
        const { contactPerson, id} = this.props;

        if (payload.options.action === 'save') {
            const requestData = {
                personId: contactPerson.id,
                id: payload.options.fieldValue.id,
                type: fieldConfig.name,
                value: payload.options.fieldValue.value
            };

            this.props.personSaveContact({ data: requestData, onError: this.onEditRequestError, id });

        } else if (payload.options.action === 'delete') {

            const requestData = {
                contactId: payload.options.fieldValue.id,
            };

            this.props.personDeleteContact({ data: requestData, onError: this.onEditRequestError, id });
        }
    };

    /**
     * Create request data object for fields of all types except "array" and send request
     */
    sendCommonFieldRequest = (payload, fieldConfig) => {
        const { id } = this.props;

        if (payload.name === 'issues') {
            const data = {
                entity_type: 'CUSTOMER',
                personId: this.props.contactPerson.id,
                entityid: _.get(this.props.contactPerson, 'links[0].entityId'),
                issues: payload.value
            };
            return this.props.issuesEdit({ data, onError: this.onEditRequestError, id });
        }

        let value = payload.value;

        if (moment.isMoment(value)) {
            value = value.valueOf();
        }

        if (fieldConfig.type === FieldTypes.SELECT && value) {
            // select value format = {value, label}
            value = value.value;
        }

		const isMulti = true,
			requestData =  {
				"personId": this.props.contactPerson.id,
				"edit":[{
					"text": value,
					"field": fieldConfig.field
				}]
			};



		return this.props.personEdit({ data: requestData, onError: this.onEditRequestError, id, isMulti });
    };

    /**
     * Create request data object and send request
     */
    configureAndSendRequest = (payload, fieldConfig) => {
        if (fieldConfig.type === FieldTypes.ARRAY) {
            return this.sendArrayFieldRequest(payload, fieldConfig);
        } else {
            return this.sendCommonFieldRequest(payload, fieldConfig);
        }
    };

    /**
     * Make request to update contact person.
     *
     * @param {Object} payload
     * @param {Object|String|Array} payload.value
     * @param {String} payload.name - field name
     * @param {Object} [payload.options] - action details
     * @param {Object} [payload.options.fieldValue]
     * @param {String} [payload.options.action]
     * @param {Number} [payload.options.index]
     *
     * @return {Boolean} done - if the request is forbidden (this.wait = true) method must return 'false', otherwise 'true';
     */
    handleChangeValue = payload => {
        if (this.wait) {
            return false;
        }
        this.wait = true;

        const fieldConfig = personFormConfig.find(config => config.name === payload.name);
        this.configureAndSendRequest(payload, fieldConfig);
        return true;
    };

    /**
     * Return array of empty field names.
     */
    getEmptyFields = () => {
        return personFormConfig.reduce((acc, fieldConfig) => {
            const currentValue = this.props.initialValues[fieldConfig.name];

            const isEmptyValue = typeof currentValue === 'number' ? false :isEmpty(currentValue);
            const isEnabled = !fieldConfig.disabled;

            if (isEmptyValue && isEnabled) {
                acc.push(fieldConfig.name);
            }

            return acc;
        }, []);
    };

    renderFooterLink = item => (
        <li key={item.entityId}>
            <Link to={`/customer/${item.entityId}/appeals`} className={styles.footerLink} title={item.entityDescription}>
                {item.entityDescription}
            </Link>
        </li>
    );

    render () {
        const { filledFields, showSideMenuAdditionsPerson, headerText, footerText, t, contactPerson,
            readOnly, contactPersonLoading, id,
        } = this.props;

        const emptyFormWrapperClassName = cx(styles.additionalData, {'show': showSideMenuAdditionsPerson});

        return (
            <ClickOutsideHolder onClickOutside={this.handleHideSideMenu}>
                <div className={cx(styles.asideCustomer, 'aside-customer', {'scroll-y p-r-0': footerText})}>
                    {
                        headerText &&
                        <header className={styles.asideCustomerHeader}>
                            {t('customerInfoLabel.contactPerson')}
                        </header>
                    }

                    <div className={cx(styles.cardData, {
                        'scroll-y p-r-0': headerText && !footerText,
                        'p-r-0': footerText
                    })}>
                        <FilledForm
                            form={`${id}-person-filled`}
                            initialValuesObject={filledFields}
                            updateTimestamp={contactPerson.timestamp}
                            config={personFormConfig}
                            handleChangeValue={this.handleChangeValue}
                            readOnly={readOnly}
                            loading={contactPersonLoading}
                        />

                        {
                            readOnly ? null : (
                                <div className={emptyFormWrapperClassName}>
                                    <EmptyForm
                                        form={`${id}-person-empty`}
                                        fieldList={this.getEmptyFields()}
                                        config={personFormConfig}
                                        handleChangeValue={this.handleChangeValue}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {readOnly ? null : <button className='btn-collapse' onClick={this.handleCollapse}>
                        <i className={`icon icon-${showSideMenuAdditionsPerson ? 'back' : 'list'}`} />
                    </button>}

                </div>

                {
                    footerText &&
                    <footer className={styles.asideCustomerFooter}>
                        <ul className={styles.listLink}>
                            {footerText.map(this.renderFooterLink)}
                        </ul>
                    </footer>
                }
            </ClickOutsideHolder>
        );
    }
}

Parameters.propTypes = {
    footerText: PropTypes.array,
    avatar: PropTypes.bool
};
