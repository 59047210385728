import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {translate} from "react-i18next";
import SelectTree from './SelectTree';
import {connect} from 'react-redux';
import cx from 'classnames';

const mapStateToProps = (state, ownProps) => {
    return {
        fieldsValues: ownProps.fields ? ownProps.fields.getAll() : null
    };
};

@translate()
@connect(mapStateToProps)
class SelectTreeAdd extends Component {
    constructor(props) {
        super(props);
        this.removeField = this.removeField.bind(this);
    }
    
    onAddNewField = fields => () => {
        const values = fields.getAll();
        const isContainsEmptyField = values.some(fieldValue => !Boolean(fieldValue));
        !isContainsEmptyField && fields.push();
    };
    
    filterOptions = (options, values) => {
        const valueIdArray = values.filter(Boolean).map(value => options.valueField(value));
        const initialOptions = options.initialOptions.filter(option => !valueIdArray.includes(options.valueField(option)));

        return {...options, initialOptions};
    };

    shouldRemoveField = (value, index, fields) => {
        const isEmpty = !value;
        const isFirstEmptyField = !value && index === 0 && fields.length === 1;
        const isLastEmptyField = !value && index === fields.length - 1 && fields.length > 1;

        return isEmpty && !isFirstEmptyField && !isLastEmptyField;
    };

    removeField(position) {
        this.props.resetActions({ remove: position });
        this.props.fields.splice(position, 1);
    }

    render() {
        const {fields, label, required, t, options, additionDisabled, disabled} = this.props;
        const values = fields.getAll();
        const fieldForDelete = values && values.findIndex((value, index) => this.shouldRemoveField(value, index, fields));
        
        if (fieldForDelete !== -1) {
            fields.remove(fieldForDelete);
        }
        
        return (
            <div className={cx('input-element select-add', {'disabled': disabled})}>
                <div className='input-label'>
                    {label}
                    {
                        required &&
                        <span className='required-field'>*</span>
                    }
                    {
                        options.initialOptions.length > 0 && !additionDisabled &&
                        <button
                            className={cx('input-add-button', {'disabled': disabled})}
                            type='button'
                            onClick={this.onAddNewField(fields)}
                        >
                            {t('add')}
                        </button>
                    }
                </div>

                {fields.map((name, index) => (
                    <Field
                        key={index}
                        position={index}
                        disabled={this.props.disabled}
                        component={SelectTree}
                        resetActions={this.props.resetActions}
                        removable={true}
                        name={name}
                        unlockAppealForm={this.props.unlockAppealForm}
                        removeField={this.removeField}
                        {...this.props.options}
                        onChange={this.props.onChange}
                        placeholder='Не вибрано'
                    />
                ))}
            </div>
        );
    }
}

SelectTreeAdd.propTypes = {
    options: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    required: PropTypes.bool,
    additionDisabled: PropTypes.bool
};

export default SelectTreeAdd;

