import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LiveSearch from 'components/LiveSearch/LiveSearch';
import { calculateSearchNumber, safeQuery } from 'helpers';
import {
    getHeaderSearchSuppose, clearSearchResult, expandSearch, reduceSearch,
} from 'actions/headerSearch';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { toggleBlockAddingPopup } from 'actions/tabs';
import { MAX_TABS_COUNT, KeyCodes } from 'constants/index';
import debounce from 'lodash/debounce';
import permissions, { checkPermissions } from '../../config/permissions';

const mapStateToProps = state => ({
    tabs: state.tabs,
    searchResults: state.headerSearch.searchResults,
    query: state.headerSearch.query,
    facets: state.headerSearch.facets,
    canSearchCustomer: checkPermissions(permissions.SearchOperations.search_customer),
    canSearchContactPersons: checkPermissions(permissions.SearchOperations.search_contactPersons),
});

const mapDispatchToProps = dispatch => ({
    expandSearch: () => dispatch(expandSearch()),
    reduceSearch: () => dispatch(reduceSearch()),
    getHeaderSearchSuppose: params => dispatch(getHeaderSearchSuppose(params)),
    clearSearchResult: () => dispatch(clearSearchResult()),
    toggleBlockAddingPopup: () => dispatch(toggleBlockAddingPopup()),
});


@connect(mapStateToProps, mapDispatchToProps)
class Search extends Component {
    triggerChange = debounce((value) => {

        const data = {
            query: value,
            page: 1,
            start: 0,
            limit: 8,
        };
        if (value.trim().length > 2) {
            this.props.getHeaderSearchSuppose({ data });
        } else if (this.props.searchResults) {
            this.props.clearSearchResult();
        }
    }, SEARCH_TIMER_INTERVAL);

    constructor(props) {
        super(props);
        this.state = {
            showLiveSearch: false,
            value: '',
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
	
	UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.props.searchResults.length && newProps.searchResults.length && !!this.state.value) {
            this.setState({ showLiveSearch: true });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const { searchResults } = this.props;
        if (searchResults.length && this.searchComponent && !this.searchComponent.contains(e.target)) {
            this.hideSearch();
        }
    };

    hideSearch = () => {
        this.search.blur();
        this.props.reduceSearch();
        this.props.clearSearchResult();
        this.setState({ value: '', showLiveSearch: false });
    };

    onSearchKeyDown = (e) => {
        if (e.keyCode === KeyCodes.ENTER) {
            const { tabs, toggleBlockAddingPopup, history: { push } } = this.props;
            if (tabs.tabs.length < MAX_TABS_COUNT) {
                const query = e.currentTarget.value;
                if (query.trim().length > 2) {
                    const hash = calculateSearchNumber(tabs);
                    push(`/search/appeal/${hash}/${safeQuery(query)}`);
                }
            } else {
                toggleBlockAddingPopup();
            }
            this.search.value = null;
            this.hideSearch();
        } else if (e.keyCode === KeyCodes.ESCAPE) {
            this.hideSearch();
            this.handleSearchClear();
        }
    };

    expandSearch = (e) => {
        this.props.expandSearch(!!e.target.type);
        this.search.focus();
    };

    handleSearchClear() {
        this.props.reduceSearch();
        this.search.value = '';
    }

    handleBlur = () => {
        setTimeout(() => {
            this.handleSearchClear();
            this.hideSearch();
        }, 300);
    };

    handleCloseClick = (e) => {
        e.preventDefault();
        this.hideSearch();
        this.handleSearchClear();
    };

    handleChange = (evt) => {
        const { value } = evt.currentTarget;
        this.setState({ value, showLiveSearch: true });
        this.triggerChange(value);
    };

    render() {
        const {
            expandedSearch, searchResults, query, facets, tabs,
            canSearchCustomer, canSearchContactPersons,
        } = this.props;
        const { value, showLiveSearch } = this.state;
        return (
            <div
                className={`header-block-item search ${expandedSearch ? 'expand' : ''}`}
                onClick={this.expandSearch}
                onBlur={this.handleBlur}
                ref={(node) => { this.searchComponent = node; }}
            >
                <i className='icon-search' />
                <input
                    type='text'
                    className='search-input'
                    ref={(node) => { this.search = node; }}
                    maxLength='255'
                    value={value}
                    placeholder='Пошук'
                    onKeyDown={this.onSearchKeyDown}
                    onChange={this.handleChange}
                />

                <i
                    className={`icon-close ${expandedSearch ? '' : 'hidden'}`}
                    onClick={this.handleCloseClick}
                />

                {showLiveSearch && searchResults.length > 0 && (
                    <LiveSearch
                        searchResults={searchResults}
                        query={query}
                        hideSearch={this.hideSearch}
                        facets={facets}
                        tabs={tabs}
                        className='header-live-search'
                        canSearchContactPersons={canSearchContactPersons}
                        canSearchCustomer={canSearchCustomer}
                    />
                )}

            </div>
        );
    }
}

Search.propTypes = {
    computedMatch: PropTypes.object,
    location: PropTypes.object,
    expandedSearch: PropTypes.bool,
    expandSearch: PropTypes.func,
    reduceSearch: PropTypes.func,
    getHeaderSearchSuppose: PropTypes.func,
    searchResults: PropTypes.array,
    clearSearchResult: PropTypes.func,
    query: PropTypes.string,
    facets: PropTypes.object,
    tabs: PropTypes.array,
    history: PropTypes.object,
    toggleBlockAddingPopup: PropTypes.func,
};

export default Search;
