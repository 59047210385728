import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';


export default function HeaderButton({ onClick, children, ...props }) {
  return (
    <div className={styles.headerButton} onClick={onClick} {...props}>{children}</div>
  );
}

HeaderButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func
};
