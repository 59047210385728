import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';


export default class FormsWrapper extends React.Component {
  render = () => {
    const { isAppealFormOpened, checkedItems, toggleAppealForm, form } = this.props;
    const buttonCls = cx({
      'toggleSlideForm': true,
      'open-form': isAppealFormOpened,
      'hidden-toggle-button': !checkedItems.length
    });

    return (
      <div>
        {/*<div className={buttonCls} onClick={toggleAppealForm}>*/}
          {/*<i className="icon-back"/>*/}
        {/*</div>*/}

        <CSSTransition
          in={isAppealFormOpened && !!checkedItems.length}
          classNames="pageSliderRightForm"
          appear={true}
          enter={true}
          exit={true}
          timeout={{ enter: 100, exit: 600 }}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div className="appealFormWrapper">
            <div className={buttonCls} onClick={toggleAppealForm}>
              <i className="icon-back"/>
            </div>
            {form}
          </div>
        </CSSTransition>
      </div>
    );
  };
}

FormsWrapper.propTypes = {
  form: PropTypes.node,
  isAppealFormOpened: PropTypes.bool,
  checkedItems: PropTypes.array,
  toggleAppealForm: PropTypes.func
};
