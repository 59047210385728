import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import { Link } from 'react-router-dom';

const NewCommentNote = ({ userName, avatar, appealId, customerId }) => (
  <div className={styles.currentAppealChangeNote}>
    <div className={styles.userAvatar}/>
    <div className={styles.noteText}>
      <span className={styles.userName}>{userName}</span>
      <span>додав коментар у звернення <Link className={styles.appealID} to={`/appeals/${appealId}/knowledge_base`}>{appealId}</Link></span>

    </div>
  </div>
);

// NewCommentNote.defaultProps = {
//   userName: 'Підлипський Антон',
//   avatar: '',
//   appealId: 123889,
//   customerId: 'no_customer'
// };

NewCommentNote.propTypes = {
  userName: PropTypes.string,
  avatar: PropTypes.string,
  appealId: PropTypes.number,
  customerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default NewCommentNote;

