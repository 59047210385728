import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, formValueSelector } from 'redux-form';
import 'styles/shared.scss';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';
import KnowledgeBaseItemField from './KnowledgeBaseItemField';
import get from 'lodash/get';
import StatusModal from '../StatusSelect/StatusModal';
import { ModalFields } from '../StatusSelect/constants';
import { searchTree } from 'helpers';
import { extractAppealFromState } from '../../../helpers';

const formSelector = formValueSelector('appeal-form');

function mapStateToProps (state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    
    return {
        ...formSelector(state, 'subject', 'description', 'solution'),
        id,
        statusList: appeal.statusList,
        appealRestriction: appeal.currentAppeal.restriction,
        destinations: appeal.destinations,
        destinationHash: appeal.destinationHash,
        appealTypes: state.appeal.appealTypes,
    };
}

const mapDispatchToProps = dispatch => ({
    change: (field, value, id) => dispatch(change(`appeal-form-${id}`, field, value))
});

@withRouter
@translate()
@connect(mapStateToProps, mapDispatchToProps)
class KnowledgeBaseItem extends React.Component {
    
    constructor () {
        super();
        
        this.state = {
            statusInfoFieldList: [],
            updateExecutor: true
        };
        
        this.updatesCount = 0;
        this.resolveStatusModal = null;
        this.rejectStatusModal = null;
        
        this.getNewFormValue = this.constructor.getNewFormValue;
        this.renderItemField = this.constructor.renderItemField;
        this.canFillField = this.canFillField.bind(this);
        this.openStatusModal = this.openStatusModal.bind(this);
        this.closeStatusModal = this.closeStatusModal.bind(this);
        this.fillForm = this.fillForm.bind(this);
        this.addStatusInfo = this.addStatusInfo.bind(this);
        this.saveStatusInfo = this.saveStatusInfo.bind(this);
        this.fillStatusField = this.fillStatusField.bind(this);
        this.getFieldsConfig = this.getFieldsConfig.bind(this);
        this.shouldRenderFillFormButton = this.shouldRenderFillFormButton.bind(this);
        this.getNextStatusList = this.getNextStatusList.bind(this);
    }
    
    componentDidUpdate (prevProps) {
        const { destinations, destinationHash, id } = this.props;
        const { updateExecutor } = this.state;
        if (destinationHash !== prevProps.destinationHash && updateExecutor) {
            const result = searchTree(destinations, 'result', i => (i.object.isDefault));
            if (result) {
                this.props.change('destination[0]', result.object.id, id);
            }
        }
    }
    
    static getNewFormValue (current, next) {
        if (current && next) {
            return `${current}  ${next}`;
        } else {
            return current || next;
        }
    }
    
    canFillField (path) {
        const restrictionValue = get(this.props.appealRestriction, path);
        return restrictionValue === 3;
    }
    
    openStatusModal (fieldList) {
        this.setState({ statusInfoFieldList: fieldList });
    }
    
    closeStatusModal () {
        const callback = () => this.rejectStatusModal && this.rejectStatusModal('Status modal rejected');
        this.setState({ statusInfoFieldList: [] }, callback);
    }
    
    fillForm () {
        const { item, description, solution, id } = this.props;
        this.updatesCount = 0;
        
        this.fillStatusField()
            .then(() => {
                if (this.canFillField('description')) {
                    this.props.change('description', this.getNewFormValue(description, item.description), id);
                    this.updatesCount++;
                }
                
                if (this.canFillField('solution')) {
                    this.props.change('solution', this.getNewFormValue(solution, item.solution), id);
                    this.updatesCount++;
                }
                
                if (this.canFillField('type')) {
                    this.props.change('type', {
                        code: item.requestTypeCode,
                        fullLabelPath: (_.get(item, 'requestTypeName') || '').split('\\').filter(i => i !== '').join(' / '),
                        id: item.requestTypeId,
                    }, id);
                    this.updatesCount++;
                }
                
                if (this.canFillField('destination') && item.setDefaultDestination) {
                    this.setState({ updateExecutor: true });
                }
                
                if (this.updatesCount > 0) this.props.unlockAppealForm();
            })
            .catch(console.error);
    }
    
    addStatusInfo (fieldList = []) {
        return new Promise((resolve, reject) => {
            this.resolveStatusModal = resolve;
            this.rejectStatusModal = reject;
            
            if (fieldList.length > 0) {
                this.openStatusModal(fieldList);
            } else {
                resolve();
            }
        });
    }
    
    saveStatusInfo (values) {
        this.rejectStatusModal = undefined;
        
        const saveValue = field => {
            const value = values[field] || null;
            
            if (this.props[field] !== value) {
                this.props.change(field, value, this.props.id);
            }
        };
        
        Object.values(ModalFields).forEach(saveValue);
        
        this.resolveStatusModal();
        this.setState({ statusInfoFieldList: [] });
    }
    
    fillStatusField () {
        const { item, statusList, id } = this.props;
        
        if (item.status.code) {
            const foundStatus = statusList.find(status => status.code === item.status.code);
            
            if (foundStatus) {
                const fieldList = [];
                
                if (['pause', 'wait'].includes(foundStatus.code)) {
                    fieldList.push(ModalFields.TIMER_DATE, ModalFields.NEXT_STATUS);
                }
                
                if (foundStatus.isNeedComment) {
                    fieldList.push(ModalFields.COMMENT);
                }
                
                return Promise.resolve(fieldList.length > 0 && this.addStatusInfo(fieldList))
                    .then(() => {
                        this.resolveStatusModal = undefined;
                        const resolution = (item.status.resolution && item.status.resolution.id === 0) ? { id: null } : item.status.resolution;
                        this.props.change('status', item.status, id);
                        this.updatesCount++;
                        this.props.change('resolution', resolution, id);
                    });
            }
        }
        
        return Promise.resolve();
    }
    
    static renderItemField (item) {
        if (!item.value) return null;
        
        return (<KnowledgeBaseItemField field={item} />);
    };
    
    getFieldsConfig () {
        const { item, t } = this.props;
        
        const config = [
            { label: t('knowledgeBase.topic'), value: item.subject || item.name, name: 'subject' },
            { label: t('knowledgeBase.description'), value: item.description, name: 'description' },
            { label: t('knowledgeBase.solution'), value: item.solution, name: 'solution' },
            { label: t('knowledgeBase.text'), value: item.answer, name: 'answer' },
        ];
        
        if (item.files.length) {
            config.push({ label: t('knowledgeBase.file'), value: item.files, name: 'file' });
        }
        
        if (item.status.code) {
            config.push({ label: t('knowledgeBase.status'), value: item.status, name: 'status' });
        }
        
        if (item.requestTypeName) {
            config.push({
                label: t('knowledgeBase.type'),
                value: item.requestTypeName.split('\\').filter(i => i !== '').join(' / '),
                name: 'requestTypeName',
            });
        }
        
        config.push({
            checkBoxLabel: t('knowledgeBase.setDefaultDestination'),
            value: item.setDefaultDestination,
            name: 'checkbox'
        },);
        
        return config;
    }
    
    shouldRenderFillFormButton () {
        return this.canFillField('statusId') || this.canFillField('description') || this.canFillField('solution');
    }
    
    getNextStatusList () {
        const foundStatus = this.props.statusList.find(status => status.code === this.props.item.status.code);
        
        return foundStatus ? foundStatus.nextState : [];
    }
    
    render () {
        const { item } = this.props;
        const { statusInfoFieldList } = this.state;
        
        const config = this.getFieldsConfig();
        
        return (
            <div className={styles.knowledgeBaseItem}>
                <div className={styles.linkWrapper}>
          <span className={styles.link}>
            <i className='icon-file' />
            <span>{item.name}</span>
          </span>
                    {
                        this.shouldRenderFillFormButton() &&
                        <button className='btn-save' onClick={this.fillForm}>
                            <i className='icon-out' />
                            Заповнити за шаблоном
                        </button>
                    }
                </div>
                
                {config.map(this.renderItemField)}
                
                {
                    statusInfoFieldList.length > 0 &&
                    <StatusModal
                        onClose={this.closeStatusModal}
                        nextStatusList={this.getNextStatusList()}
                        saveChanges={this.saveStatusInfo}
                        fieldList={statusInfoFieldList}
                    />
                    
                }
            </div>
        );
    }
}

KnowledgeBaseItem.propTypes = {
    item: PropTypes.object,
    unlockAppealForm: PropTypes.func
};

export default KnowledgeBaseItem;
