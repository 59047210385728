import {
    TABS_REMOVE_TAB, TABS_RESET, CLOSE_ALL_EXCEPT_CURRENT, TABS_REVERSE,
    TOGGLE_BLOCK_ADDING_POPUP, CLOSE_BLOCK_ADDING_POPUP, CONTENT_UPDATE,
    SET_CURRENT_TAB, UPDATE_CURRENT_TAB_URL, CONTENT_SET, SET_CURRENT_TAB_FIELD,
    ADD_TABS, SET_CURRENT_TAB_NAME_BLOCKED, SET_TAB_NAME, SET_BREAD_CRUMBS
} from 'constants/actions';
import { MAX_TABS_COUNT, MAX_TABS_IN_HISTORY } from 'constants/index';
import { customerLinks, settingsLink } from 'config/links';
import { createSelector } from 'reselect';
import settingsService, { SETTINGS_POSTFIX } from 'services/settingsService';

const getDefaultTab = () => ({
    url: '/appeal',
    name: 'appeal',
    isSuperTab: true
});

const initialState = {
    tabs: [],
    current: getDefaultTab(),
    isAddingActive: true,
    blockAddingPopupIsShow: false,
    tabsHistory: [],
    superTabs: [...customerLinks.map(link => link.name), settingsLink.name],
    tabCachingEnabled: false
};

export default (state = initialState, action) => {
    let current;
    let tabs;

    switch (action.type) {

    case CONTENT_UPDATE: {
        const { page, query, sort, filter, checkedItems, fields } = action;
        const cur = { ...state.current };
        if (cur.queryKey) {
            const currentQueries = cur.queries[cur.queryKey] || {};

            if (checkedItems) currentQueries.checkedItems = checkedItems;
            if (fields) currentQueries.fields = fields;
            if (page) currentQueries.page = page;
            if (sort && sort.length) currentQueries.sort = sort;
            if (typeof query === 'string') currentQueries.query = query;
            if (filter) currentQueries.filter = filter;

            cur.queries[cur.queryKey] = currentQueries;
            cur.isSuperTab = state.current.isSuperTab;

            const tabs = state.tabs.map(tab => {
                if (tab.type === cur.tab) {
                    return cur;
                }
                return tab;
            });
            return { ...state, current: cur, tabs };
        }
        return state;
    }

    case TABS_REMOVE_TAB:
        const { tabType, history } = action.payload;

        tabs = [...state.tabs];
        current = { ...state.current };

        const removedIndex = tabs.findIndex(v => v.type === tabType);
        const currentIndex = tabs.findIndex(tab => tab.type === current.type);
        tabs.splice(removedIndex, 1);

        if (!tabs.length) {
            history && history.push('/appeal');
            return {
                ...state,
                tabs,
                current: getDefaultTab(),
            };
        }

        if (removedIndex === currentIndex) {
            current = tabs[tabs.length - 1];
        }

        history && history.replace(current.url);

        settingsService.set(`lastUrl${SETTINGS_POSTFIX}`, current.url);

        return { ...state, tabs, current, tabCachingEnabled: false };

    case TABS_REVERSE:
        tabs = [...state.tabs];
        current = tabs.findIndex(v => v.url === action.payload.url);
        tabs.push(...tabs.splice(current, 1));

        return { ...state, tabs };

    case CLOSE_ALL_EXCEPT_CURRENT:
        tabs = [...state.tabs];
        current = tabs.findIndex(v => v.url === action.payload.url);
        tabs = [{ url: '/', name: 'HOME' }, tabs[current]];
        return { ...state, tabs };

    case TABS_RESET:
        return initialState;

    case TOGGLE_BLOCK_ADDING_POPUP:
        return { ...state, blockAddingPopupIsShow: !state.blockAddingPopupIsShow };

    case CLOSE_BLOCK_ADDING_POPUP:
        return { ...state, blockAddingPopupIsShow: false };

    case CONTENT_SET: {
        const updatedCurrentTab = { ...state.current };

        if (action.queryKey) {
            updatedCurrentTab.queryKey = action.queryKey;
            if (action.query) {
                const { cache, ...query } = action.query;
                updatedCurrentTab.queries = {
                    ...state.current.queries,
                    [action.queryKey]: { ...(state.current.queries[action.queryKey] || {}), ...query }
                };
            }
        }

        let tabsHistory;

        if (updatedCurrentTab.isSuperTab) {
            tabsHistory = state.tabsHistory;
        } else {
            tabsHistory = [updatedCurrentTab, ...state.tabsHistory.slice(1)];
        }

        const updatedTabs = state.tabs.map(tab => {
            if (tab.type === updatedCurrentTab.type) {
                return updatedCurrentTab;
            }
            return tab;
        });

        return { ...state, current: updatedCurrentTab, tabs: updatedTabs, tabsHistory };
    }

    case SET_CURRENT_TAB:
        tabs = [...state.tabs];
        current = { ...state.current };

        if (current.type !== action.tabType) {
            current = tabs.find(v => v.type === action.tabType);

            if (!current) {
                const name = action.name ? action.name(action.params) : '';

                current = {
                    url: action.url,
                    name,
                    type: action.tabType,
                    queries: {},
                    isSuperTab: state.superTabs.includes(name)
                };

                if (action.displayedName) {
                    current = {...current, displayedName: action.displayedName}
                }

                tabs.push(current);
            }

            if (action.queryKey) current.queryKey = action.queryKey(action.params);
        }

        current.queries = action.queries || current.queries || {};
        if (action.queryKey) current.queryKey = action.queryKey(action.params);

        if (current.url !== action.url) {
            current.url = action.url;
        }

        settingsService.set(`lastUrl${SETTINGS_POSTFIX}`, action.url);

        let tabsHistory;

        if (current.isSuperTab) {
            tabsHistory = state.tabsHistory;
        } else {
            if (state.tabsHistory.length === 0) {
                tabsHistory = [{ ...current }];
            } else if (state.tabsHistory[0].type === current.type) {
                tabsHistory = [{ ...current }, ...state.tabsHistory.slice(1)];
            } else {
                tabsHistory = [{ ...current }, ...state.tabsHistory.filter(tab => tab.type !== current.type)];

                if (tabsHistory.length > MAX_TABS_IN_HISTORY) {
                    tabsHistory = tabsHistory.slice(0, MAX_TABS_IN_HISTORY);
                }
            }
        }

        const isAddingActive = tabs.filter(tab => !tab.isSuperTab).length < MAX_TABS_COUNT;

        return { ...state, current, tabs, tabsHistory, tabCachingEnabled: false, isAddingActive };

    case UPDATE_CURRENT_TAB_URL: {

        const updatedCurrentTab = { ...state.current, url: action.currentUrl };
        const updatedTabs = state.tabs.map(tab => {
            if (tab.type === state.current.type) {
                return updatedCurrentTab;
            }
            return tab;
        });

        let tabsHistory = state.tabsHistory;
        if (!updatedCurrentTab.isSuperTab) {
            tabsHistory = [{ ...updatedCurrentTab }, ...state.tabsHistory.slice(1)];
        }

        return {
            ...state,
            current: updatedCurrentTab,
            tabs: updatedTabs,
            tabsHistory
        };
    }

    case SET_CURRENT_TAB_FIELD: {
        const updatedCurrentTab = { ...state.current, [action.field]: action.value };
        const updatedTabs = state.tabs.map(tab => {
            if (tab.type === state.current.type) {
                return updatedCurrentTab;
            }
            return tab;
        });

        let tabsHistory = state.tabsHistory;
        if (!updatedCurrentTab.isSuperTab) {
            tabsHistory = [{ ...updatedCurrentTab }, ...state.tabsHistory.slice(1)];
        }

        return {
            ...state,
            current: updatedCurrentTab,
            tabs: updatedTabs,
            tabsHistory
        };
    }

    case ADD_TABS:
        const additionalTabs = action.payload.map(item => (
            {
                url: `/appeals/${item.id}/knowledge_base`,
                name: item.num,
                regnum: item.num,
                type: `appeals_${item.id}`,
                queries: {},
                isSuperTab: state.superTabs.includes(name)
            }
        ));
        return { ...state, tabs: [...state.tabs, ...additionalTabs] };

    case SET_CURRENT_TAB_NAME_BLOCKED: {
        const newTabs = [...state.tabs];
        const newCurrent = { ...state.current };
        const index = state.tabs.findIndex((i) => i.name === state.current.name);
        newTabs[index].regnum = 'Звернення заблоковане';
        return { ...state, tabs: newTabs, current: newCurrent };
    }

    case SET_TAB_NAME: {
        const newTabs = [...state.tabs];
        const newCurrent = { ...state.current };
        const index = state.tabs.findIndex((i) => (i.name || i.type) === (action.tab || state.current.name));

        newTabs[index].displayedName = action.name;
        if (state.current.name === action.tab) {
            newCurrent.displayedName = action.name;
        }

        return { ...state, tabs: newTabs, current: newCurrent };
    }

    case SET_BREAD_CRUMBS: {
        const newTabs = [...state.tabs];
        // const newCurrent = { ...state.current };
        const index = state.tabs.findIndex((i) => i.name === state.current.name);
        newTabs[index].breadcrumbs = action.breadcrumbs;
        return { ...state, tabs: newTabs, current: newTabs[index] };
    }

    default:
        return state;
    }
};

export const getTabs = createSelector(
    [state => state.tabs.tabs],
    tabs => tabs.filter(tab => !tab.isSuperTab)
);
