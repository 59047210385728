import React from 'react';
import styles from 'styles/modules/appealOperatePage.module.scss';

function FormBlock ({ children }) {
    if (!children) return null;
    
    return (
        <div className={styles.formBlock}>
            {children}
        </div>
    );
}

export default FormBlock;
