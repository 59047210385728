import {
  FILTER_ATTRS_GET_SUPPOSE,
  FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
  FILTER_ATTRS_GET_SUPPOSE_FAIL,
  FILTER_ATTRS_CLEAR_RESULT,
  ADD_FILTER_ATTRS,
  SAVE_FILTER_SUCCESS,
  SAVE_FILTER_FAIL,
  SAVE_FILTER,
  DELETE_FILTER,
  DELETE_FILTER_SUCCESS,
  DELETE_FILTER_FAIL,
  FILTER_PARAMS_GET_SUPPOSE,
  FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
  FILTER_PARAMS_GET_SUPPOSE_FAIL,
  CLEAR_FILTER_ATTRS,
  CLEAR_FILTER_INFO,
  FILTER_LIST_GET_SUPPOSE,
  FILTER_LIST_GET_SUPPOSE_SUCCESS,
  FILTER_LIST_GET_SUPPOSE_FAIL,
  FILTER_LIST_CLEAR_RESULT,
  FILTER_DATA_GET_SUPPOSE,
  FILTER_DATA_GET_SUPPOSE_SUCCESS,
  FILTER_DATA_GET_SUPPOSE_FAIL,
  CLEAR_FILTER_DATA,
  OPENED_FILTER,
  OPEN_FILTER_PARAMS,
  CLOSE_FILTER_PARAMS,
  OPEN_NEW_FILTER_PARAMS,
  CLOSE_NEW_FILTER_PARAMS,
  OPEN_PARAMS_MODAL,
  CLOSE_PARAMS_MODAL,
  CREATE_NEW_FILTER,
  APPLY_EXISTING_FILTER,
  ADD_FILTER_OPTION,
  CLEAR_NEW_FILTER_OPTION,
  SET_OLD_FILTER_DATA,
  GET_APPEAL_TYPES_LIST_FOR_FILTER,
  GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS,
  GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR,
  CLEAR_APPEAL_TYPES_LIST_FOR_FILTER,
  SET_SELECT_OPTIONS_FOR_FILTER
} from 'constants/actions';

const initialState = {
  loading: false,
  loaded: false,
  filterAttrsResults: [],
  checkedFilterAttrs: {},
  saving: false,
  saved: false,
  deleting: false,
  infoLoading: false,
  infoLoaded: false,
  infoFilterParams: [],
  filterList: [],
  loadingFilterList: false,
  loadedFilterList: false,
  lastSaved: '',
  dataLoading: false,
  dataLoaded: false,
  dataFilterResult: null,
  openedFilter: null,
  showFilterParams: false,
  showParamsModal: false,
  newFilter: false,
  showDeleteFilterModal: false,
  newFilterOption: null,
  oldFilterData: null,

  // appeal types
  selectOptions: null,
  optionsHistory: [],
  appealTypes: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_ATTRS_GET_SUPPOSE: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case FILTER_ATTRS_GET_SUPPOSE_SUCCESS: {
      const { result } = action.result;
      return {
        ...state,
        loading: false,
        loaded: true,
        filterAttrsResults: result
      };
    }

    case FILTER_ATTRS_GET_SUPPOSE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.err
      };
    }
    case FILTER_ATTRS_CLEAR_RESULT: {
      return {
        ...state,
        filterAttrsResults: []
      };
    }

    case ADD_FILTER_ATTRS: {
      const { result } = action;
      return {
        ...state,
        checkedFilterAttrs: result
      };
    }

    case CLEAR_FILTER_ATTRS: {
      return {
        ...state,
        checkedFilterAttrs: {}
      };
    }

    case SAVE_FILTER: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }

    case SAVE_FILTER_SUCCESS: {
      const { lastSaved } = action;
      return {
        ...state,
        saving: false,
        saved: true,
        newFilter: false,
        lastSaved
      };
    }
    case SAVE_FILTER_FAIL: {
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.err
      };
    }

    case DELETE_FILTER: {
      return {
        ...state,
        deleting: true,
        deleted: false
      };
    }

    case DELETE_FILTER_SUCCESS: {
      const { id } = action;
      return {
        ...state,
        deleting: false,
        deleted: true,
        id
      };
    }

    case DELETE_FILTER_FAIL: {
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.err
      };
    }

    case FILTER_PARAMS_GET_SUPPOSE: {
      return {
        ...state,
        infoLoading: true,
        infoLoaded: false
      };
    }

    case FILTER_PARAMS_GET_SUPPOSE_SUCCESS: {
      const { result } = action.result;
      return {
        ...state,
        infoLoading: false,
        infoLoaded: true,
        infoFilterParams: result,
        saved: false
      };
    }

    case FILTER_PARAMS_GET_SUPPOSE_FAIL: {
      return {
        ...state,
        infoLoading: false,
        infoLoaded: false,
        error: action.err
      };
    }

    case CLEAR_FILTER_INFO: {
      return {
        ...state,
        infoFilterParams: []
      };
    }

    case FILTER_LIST_GET_SUPPOSE: {
      return {
        ...state,
        loadingFilterList: true,
        loadedFilterList: false
      };
    }

    case FILTER_LIST_GET_SUPPOSE_SUCCESS: {
      const { result } = action.result;
      return {
        ...state,
        loadingFilterList: false,
        loadedFilterList: true,
        filterList: result
      };
    }

    case FILTER_LIST_GET_SUPPOSE_FAIL: {
      return {
        ...state,
        loadingFilterList: false,
        loadedFilterList: false,
        error: action.err
      };
    }
    case FILTER_LIST_CLEAR_RESULT: {
      return {
        ...state,
        filterList: []
      };
    }

    case ADD_FILTER_OPTION: {
      return {
        ...state,
        filterList: [
          ...state.filterList,
          action.payload
        ],
        newFilterOption: action.payload
      };
    }

    case SET_OLD_FILTER_DATA: {
      return {
        ...state,
        oldFilterData: action.payload
      };
    }

    case CLEAR_NEW_FILTER_OPTION: {
      return {
        ...state,
        newFilterOption: null
      };
    }

    case FILTER_DATA_GET_SUPPOSE: {
      return {
        ...state,
        dataLoading: true,
        dataLoaded: false
      };
    }

    case FILTER_DATA_GET_SUPPOSE_SUCCESS: {
      const { result } = action;

      return {
        ...state,
        dataLoading: false,
        dataLoaded: true,
        dataFilterResult: result
      };
    }

    case FILTER_DATA_GET_SUPPOSE_FAIL: {
      return {
        ...state,
        dataLoading: false,
        dataLoaded: false,
        error: action.err
      };
    }

    case CLEAR_FILTER_DATA: {
      return {
        ...state,
        dataFilterResult: null
      };
    }

    case OPENED_FILTER:
      const { openedFilter } = action;
      return {
        ...state,
        openedFilter
      };

    case OPEN_FILTER_PARAMS:
      return {
        ...state,
        showFilterParams: true,
        newFilter: false
      };

    case CLOSE_FILTER_PARAMS:
      return {
        ...state,
        showFilterParams: false
      };

    case OPEN_NEW_FILTER_PARAMS:
      return {
        ...state,
        showFilterParams: true,
        newFilter: true
      };

    case CLOSE_NEW_FILTER_PARAMS:
      return {
        ...state,
        showFilterParams: false,
        newFilter: false
      };

    case CREATE_NEW_FILTER:
      return {
        ...state,
        newFilter: true
      };

    case APPLY_EXISTING_FILTER:
      return {
        ...state,
        newFilter: false
      };

    case OPEN_PARAMS_MODAL:
      return {
        ...state,
        showParamsModal: true
      };

    case CLOSE_PARAMS_MODAL:
      return {
        ...state,
        showParamsModal: false
      };

    case CLEAR_APPEAL_TYPES_LIST_FOR_FILTER: {
      return {
        ...state,
        appealTypes: null
      };
    }

    case GET_APPEAL_TYPES_LIST_FOR_FILTER: {
      return {
        ...state,
        appealTypes: null
      };
    }

    case GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS: {
      const { result } = action;
      return {
        ...state,
        appealTypes: result.children
      };
    }

    case GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR: {
      return {
        ...state,
        appealTypes: null
      };
    }

    case SET_SELECT_OPTIONS_FOR_FILTER: {
      const { options, optionsHistory } = action;
      return {
        ...state,
        selectOptions: options,
        optionsHistory
      };
    }

    default: return state;
  }
};
