import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/appealOperatePage.module.scss';

const getIconType = (link) => {
  if (link.leaf) {
	  return 'icon-file';
  }
	
  return 'icon-folder';
};


const getDependentType = (link) => {
  if (link.dependent) {
    return 'tree-dependent-' + link.dependent ;
  }

  return 'icon-usual';
};

const getLinkClassName = (link) => {
  let className = styles.link;
  if (!link.leaf & (!link.result || !link.result.length)) {
    className = cx(styles.link, styles.disabled);
  }
  return className;
};

const KnowledgeBaseList = ({ links, onClick }) => {
  if (!links) return null;

  return links.map((link, index) => {
    const selectedItem = link.leaf ? link.item.id : undefined;
    return (
      <div
        className={getLinkClassName(link)}
        key={link.id || link.item.id}
        onClick={() => onClick({ index: selectedItem ? null : index, text: link.text }, selectedItem)}
      >

          <span className={getDependentType(link)}><i className={getIconType(link)} /></span>
          <span>{link.text} </span>

      </div>
    );
  });
};

KnowledgeBaseList.propTypes = {
  onClick: PropTypes.func.isRequired,
  links: PropTypes.array
};

export default KnowledgeBaseList;
