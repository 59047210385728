import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import ComboBoxMultipleCheckbox from './ComboBoxMultipleCheckbox';
import { Portal } from 'react-overlays';
import { translate } from 'react-i18next';
import ErrorBlock from 'components/CustomerPage/Parameters/components/ErrorBlock.js';

@translate()
class ComboBox extends Component {
    selectRef = React.createRef();
    
    componentDidMount () {
        if (this.props.onRefReady) {
            this.props.onRefReady(this.selectRef);
        }
    }
    
    toggleErrorPopup = () => {
        this.setPosition();
        setTimeout(() => this.setState({errorPopupIsOpen: !this.state.errorPopupIsOpen}), 100);
    };
    
    openErrorPopup = () => {
        this.setPositionError();
        setTimeout(() => this.setState({errorPopupIsOpen: true}), 100);
    };
    
    closeErrorPopup = () => {
        setTimeout(() => this.setState({errorPopupIsOpen: false}), 100);
    };
    
    setPositionError = () => {
        const rect = this.inputError.getBoundingClientRect();
        const left = window.scrollX + rect.left + rect.width;
        const top = window.scrollY + rect.top;
        
        if (top !== this.state.top || left !== this.state.left) {
            this.setState({left, top});
        }
    };
    
    arrowRender = values => {
        if (values.isOpen) {
            return <i className='icon-up'/>;
        }
        return <i className='icon-down'/>;
    };
    
    onOpen = () => {
        const select = document.querySelector('.Select-menu-outer');
        if (select.scrollIntoViewIfNeeded) {
            select.scrollIntoViewIfNeeded(false);
        } else {
            select.scrollIntoView(false);
        }
    };
    
    onFocus = event => {
        this.props.input && this.props.input.onFocus(event);
    };
    
    onBlur = event => {
        const {input} = this.props;
        input && input.onBlur(input.value);
    };
    
    render () {
        const {
            label,
            options,
            input,
            meta,
            placeholder = 'Не вибрано',
            multi,
            id,
            required,
            searchable,
            filterOption,
            noborder,
            placeErrorPopup,
            submitComboboxMulty,
            noResultsText = this.props.t('noResultsFound'),
            ...restProps
        } = this.props;
        const selectClassName = cx('container-comboBox',
            meta && meta.touched && (meta.error || meta.warning) && 'input-field__error',
            meta && meta.active && 'active', {
                'noborder': noborder
            });
        
        const showErrorBlock = meta && meta.touched && !meta.active && meta.error;
        return (
            <div id={id} className='input-element'>
                <div className='input-label'>
                    {label}
                    {required && <span className='required-field'>*</span>}
                </div>
                {multi ?
                    (
                        <ComboBoxMultipleCheckbox
                            options={options}
                            placeholder={placeholder}
                            input={input}
                            label={label}
                            meta={meta}
                            submitComboboxMulty={submitComboboxMulty}
                            {...restProps}
                        />
                    ) :
                    (
                        <Select
                            {...restProps}
                            {...input}
                            {...meta}
                            options={options}
                            className={selectClassName}
                            searchable={searchable}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            clearable={false}
                            arrowRenderer={this.arrowRender}
                            placeholder={placeholder}
                            optionClassName='select-option'
                            onOpen={this.onOpen}
                            noResultsText={noResultsText}
                            filterOption={filterOption}
                            ref={this.selectRef}
                        />
                    )
                }
                { showErrorBlock && <ErrorBlock error={meta.error} className='m-r-10' /> }
            </div>
        );
    }
}

ComboBox.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    searchable: PropTypes.bool,
    required: PropTypes.bool,
    onBlurResetsInput: PropTypes.bool,
    noResultsText: PropTypes.string,
    filterOption: PropTypes.func,
    noborder: PropTypes.func
};

export default ComboBox;

