import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';
import isObjectLike from 'lodash/isObjectLike';
import last from 'lodash/last';
import moment from 'moment';

/**********************************************************
 *
 *           CONFIGURE FILTER CRITERIA OBJECT
 *
 **********************************************************/

/**
 * @param values {Object} values from redux-form
 * @param attributes {Array} all field configs
 */
export function configureFilterObject (values, attributes) {
    let data = cloneDeep(attributes);
    
    Object.keys(values).forEach(fieldName => {
        
        const filterCode = getCodeFromFieldName(fieldName);
        const filterIndex = data.findIndex(filter => filter.code === filterCode);

        if (filterIndex !== -1) {
            const filterData = data[filterIndex];
            
            if (filterData.type === 'DATE' && filterData.def_condition === 'BTW') {
                setDateValue(filterData, fieldName, values[fieldName]);
            } else {
                filterData.value = values[fieldName];
            }
        }
    });
    
    data = data.filter(filter => Boolean(filter.value));
    
    return flatMap(data, getRequestFilterObject)
        .filter(filter => Boolean(filter.value));
}

function setDateValue (filterData, fieldName, fieldValue) {
    if (fieldName.endsWith('@FROM')) {
        filterData.value = { ...filterData.value, from: fieldValue };
    }
    if (fieldName.endsWith('@TO')) {
        filterData.value = { ...filterData.value, to: fieldValue };
    }
}

function getRequestFilterObject (filterData) {
    
    if (filterData.type === 'LIST' && Array.isArray(filterData.value)) {
        return filterData.value.map(filterValue => ({
            id: filterData.id,
            condition: getComparingCondition(filterData),
            value: getFilterValue(filterValue),
            label: filterValue.label,
        }));
    }
    
    if (filterData.type === 'THREE' && Array.isArray(filterData.value)) {
        return filterData.value.map(filterValue => ({
            id: filterData.id,
            condition: getComparingCondition(filterData),
            value: getFilterValue(filterValue)
        }));
    }
    
    if (filterData.type === 'DATE' && filterData.def_condition === 'BTW') {
        return [{
            id: filterData.id,
            condition: 'GEQ',
            value: getFilterValue(filterData.value.from)
        }, {
            id: filterData.id,
            condition: 'LEQ',
            value: getFilterValue(filterData.value.to)
        }];
    }
    
    return {
        id: filterData.id,
        condition: getComparingCondition(filterData),
        value: getFilterValue(filterData.value)
    };
}

function getComparingCondition (filterData) {
    if (filterData.type === 'LIST') {
        return 'IN';
    }
    return filterData.def_condition;
}

function getFilterValue (filterFieldValue) {
    if (moment.isMoment(filterFieldValue)) {
        return filterFieldValue.isValid() ? filterFieldValue.format('YYYY-MM-DDTHH:mm:ss') : null;
    }
    
    if (isObjectLike(filterFieldValue)) return filterFieldValue.value;
    
    return filterFieldValue;
}

/**
 * @param {String} fieldName
 */
function getCodeFromFieldName (fieldName) {
    if (fieldName.endsWith('@FROM')) {
        return getStringWithoutEnding(fieldName, '@FROM');
    }
    if (fieldName.endsWith('@TO')) {
        return getStringWithoutEnding(fieldName, '@TO');
    }
    
    return fieldName;
}

/**
 * @param {String} string
 * @param {String} ending
 */
function getStringWithoutEnding (string, ending) {
    const endingIndex = string.lastIndexOf(ending);
    
    if (endingIndex === -1) return string;
    
    return string.substring(0, endingIndex);
}

/**********************************************************
 *
 *               CONFIGURE FILTER VALUES
 *
 **********************************************************/

/**
 * @param {Array} filterFields
 * @return {Object} filterValues
 */
export function configureFilterValues (filterFields) {
    return filterFields.reduce(
        (acc, fieldConfig) => {
            const value = getValueFromConfig(fieldConfig);
            putValue(acc, value, fieldConfig);
            return acc;
        },
        {}
    );
}

function putValue (values, fieldValue, fieldConfig) {
    if (!fieldValue) return;
    
    const isArrayField = fieldConfig.type === 'LIST' && fieldConfig.multiset_allowed === 'Y';
    
    if (isArrayField) {
        setArrayValue(values, fieldValue, fieldConfig);
    } else if (fieldConfig.type === 'DATE') {
        setDate(values, fieldValue, fieldConfig);
    } else {
        values[fieldConfig.code] = fieldValue;
    }
}

function setArrayValue (values, fieldValue, fieldConfig) {
    if (!Array.isArray(values[fieldConfig.code])) {
        values[fieldConfig.code] = [];
    }
    
    values[fieldConfig.code].push(fieldValue);
}

function getDateEndingByConfition (condition) {
    const Endings = {
        LEQ: '@TO',
        GEQ: '@FROM',
        // EQU: ''
    };
    
    return Endings[condition] || '';
}

function setDate (values, fieldValue, fieldConfig) {
    const fieldName = fieldConfig.code + getDateEndingByConfition(fieldConfig.def_condition);
    values[fieldName] = fieldValue;
}

/**
 * @param {Object} fieldConfig
 * @return {String|Array|Null} fieldValue
 */
function getValueFromConfig (fieldConfig) {
    if (!fieldConfig.def_value) return null;
    
    if (['NUMBER', 'STRING'].includes(fieldConfig.type)) {
        return fieldConfig.def_value;
    }
    
    if (fieldConfig.type === 'LIST') {
        return getListFieldValue(fieldConfig);
    }
    
    if (fieldConfig.type === 'DATE') {
        const parsedDate = moment(fieldConfig.def_value, 'YYYY-MM-DDTHH:mm:ss');
        
        if (parsedDate.isValid()) {
            return parsedDate;
        }
        
        console.error('Date parsing error: [%s]', fieldConfig.def_value);
    }
    
    return fieldConfig.def_value;
}

function getListFieldValue (fieldConfig) {
    if (!Array.isArray(fieldConfig.dict)) return null;
    
    if (fieldConfig.multiset_allowed === 'Y' && fieldConfig.code !== 'INTERACTION_TYPE_ID') {
        
        // for ComboBoxMultipleCheckbox
        return fieldConfig.def_value;
    } else {
        
        // for ComboBox or MultiSelectWithSearch
        let foundValue = fieldConfig.dict.find(option => fieldConfig.def_value.includes(option.key));
        
        // we need parse appeal type ID.
        const shouldParseValue = fieldConfig.code === 'INTERACTION_TYPE_ID';
        
        return foundValue ? convertDictOption(foundValue, shouldParseValue) : null;
    }
}

function convertDictOption (option, shouldParseValue) {
    return {
        value: shouldParseValue ? Number(option.key) : option.key,
        label: option.value
    };
}



