import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';


export default class Pagination extends Component {
    static defaultProps = {
        page: 1,
        limit: 10,
        total: null,
        itemsRangeDisplay: 5
    };

    paginationItems = React.createRef();
    paginationHighlight = React.createRef();

    componentDidMount = () => {
        this.drawPaginationLine();
    };

    componentDidUpdate = () => {
        this.drawPaginationLine();
    };

    drawPaginationLine(event) {
        if (!this.paginationItems || !this.paginationHighlight) return;

        const container = this.paginationItems.current;
        if (!container) return;

        const activePage = event ? event.target.closest('li') : container.querySelector('li.active');
        if (!activePage || !container) return;

        const activePageLeft = activePage.getBoundingClientRect().left;
        const containerLeft = container.getBoundingClientRect().left;
        const width = activePage.offsetWidth;

        this.paginationHighlight.current.style.marginLeft = `${activePageLeft - containerLeft}px`;
        this.paginationHighlight.current.style.width = `${width}px`;
    }

    getPageTotal = () => {
        const { total, limit } = this.props;
        return Math.ceil(total / limit);
    };

    getItems = () => {
        const { page } = this.props;

        const items = [];
        const itemsRange = this.getItemsRange();
        for (let i = itemsRange.start; i <= itemsRange.end; i++) {
            const tempClassName = (page === i) ? 'active' : '';
            items.push(
                <li key={i} onClick={this.handlePageChange(i)} className={tempClassName}>{i}</li>
            );
        }
        return items;
    };

    getItemsRange = () => {
        const { page, itemsRangeDisplay } = this.props;
        const pageTotal = this.getPageTotal();
        const range = itemsRangeDisplay - 1; // minus active page
        const leftRange = Math.ceil(range / 2);
        const rightRange = range - leftRange;

        let startPage;
        let endPage;
        if (page <= leftRange) {
            startPage = 1;
            endPage = itemsRangeDisplay;
        } else if (page + rightRange > pageTotal) {
            startPage = pageTotal - range;
            endPage = pageTotal;
        } else {
            startPage = page - leftRange;
            endPage = page + rightRange;
        }
        return {
            start: startPage < 1 ? 1 : startPage,
            end: endPage > pageTotal ? pageTotal : endPage
        };
    };

    getPrevPage = () => {
        const { page } = this.props;
        const tempClassName = (page === 1) ? 'disabled' : '';

        return (
            <li
                className={tempClassName}
                onClick={this.handlePageChange(page - 1)}
            >
                <i className='icon-arrow-left'/>
            </li>
        );
    };

    getNextPage = () => {
        const { page } = this.props;
        const tempClassName = (page === this.getPageTotal()) ? 'disabled' : '';

        return (
            <li
                className={tempClassName}
                onClick={this.handlePageChange(page + 1)}
            >
                <i className='icon-arrow-right'/>
            </li>
        );
    };

    getFirstPage = () => {
        const { page } = this.props;
        const tempClassName = (page === 1) ? 'disabled' : '';

        return (
            <li
                className={tempClassName}
                onClick={this.handlePageChange(1)}
            >
                <i className='icon-chevron-double-left'/>
            </li>
        );
    };

    getLastPage = () => {
        const { page } = this.props;
        const pageTotal = this.getPageTotal();
        const tempClassName = (page === pageTotal) ? 'disabled' : '';

        return (
            <li
                className={tempClassName}
                onClick={this.handlePageChange(pageTotal)}
            >
                <i className='icon-chevron-double-right'/>
            </li>
        );
    };

    handlePageChange = newPage => e => {
        const { onChange, page } = this.props;
        if (newPage === page || newPage > this.getPageTotal()) return;
        if (this.props.onChange) return onChange({ page: newPage });

        e.preventDefault();
    };

    getInfo = () => {
        const { start, limit, total, page } = this.props;

        // start_ need when props haven't start value
        let start_ = start ? start : (page == 1) ? 0 : (page - 1) * limit ;
        let end = 0;

        if (total > limit) {
            end = start_ + limit;

           if(end > total) {
               end = total;
           }
        } else {
            end = total;
        }

        return (
            <div className="paginateInfo">
                {start_} - {end} (з {total})
            </div>
        )
    };

    render() {
        const { total, limit, containerClassName } = this.props;
        let tempClassName = '';

        if (!total) {
            return null;
        } else {
            tempClassName = 'pagination-container';
        }
        if (containerClassName && tempClassName !== '') {
            tempClassName += ` ${containerClassName}`;
        }
        return (
            <div className="pagination-wrapper">
                <div className={tempClassName}>
                    <ul className='pagination-controls'>
                        {this.getFirstPage()}
                        {this.getPrevPage()}
                    </ul>
                    <ul className='pagination-items' ref={this.paginationItems}>
                        <div className='pagination-highlight' ref={this.paginationHighlight}/>
                        {this.getItems()}
                    </ul>
                    <ul className='pagination-controls'>
                        {this.getNextPage()}
                        {this.getLastPage()}
                    </ul>

                </div>
                {this.getInfo()}
            </div>
        );
    }
}

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    containerClassName: PropTypes.string,
    itemsRangeDisplay: PropTypes.number
};
