import {
    CUSTOMER_REQUEST,
    CUSTOMER_REQUEST_SUCCESS,
    CUSTOMER_REQUEST_FAILURE,
    CUSTOMER_MODIFY,
    CLEAR_CUSTOMER_STATE,
    
    UPDATE_CUSTOMER_NAME_SUCCESS,
    UPDATE_CUSTOMER_NAME_ERROR,
    
    APPLICANTS_REQUEST,
    APPLICANTS_REQUEST_SUCCESS,
    APPLICANTS_REQUEST_FAILURE,
    CLEAR_APPLICANTS_STATE,
    
    CUSTOMER_EDIT_BEGIN,
    CUSTOMER_EDIT_SUCCESS,
    CUSTOMER_EDIT_ERROR,
    
    CUSTOMER_ADDRESS_REQUEST,
    CUSTOMER_ADDRESS_REQUEST_FAILURE,
    CUSTOMER_ADDRESS_REQUEST_SUCCESS,
    CUSTOMER_ADDRESS_CLEAR,
    CUSTOMER_ADDRESS_MODAL_OPEN,
    CUSTOMER_ADDRESS_MODAL_CLOSE,
    
    SET_CONTACT_PERSONS_LIST,
    CLEAR_CONTACT_PERSONS_LIST,
    LOADING_CONTACT_PERSONS_LIST,
    CONTACT_PERSON_REQUEST,
    CONTACT_PERSON_REQUEST_SUCCESS,
    CONTACT_PERSON_REQUEST_FAILURE,
    PERSON_EDIT_BEGIN,
    PERSON_EDIT_SUCCESS,
    PERSON_EDIT_ERROR,
    PERSON_SAVE_CONTACT_BEGIN,
    PERSON_SAVE_CONTACT_SUCCESS,
    PERSON_SAVE_CONTACT_ERROR,
    PERSON_DELETE_CONTACT_BEGIN,
    PERSON_DELETE_CONTACT_SUCCESS,
    PERSON_DELETE_CONTACT_ERROR,
    CLEAR_CONTACT_PERSON_STATE,
    CUSTOMER_TAB_CONTENT_UPDATE,
    CLEAR_ENTIRE_CUSTOMER_STATE,
    CLEAR_ALL_CUSTOMERS,
} from 'constants/actions';

const customerModel = {
    nameUpdatedSuccess: false,
    nameUpdatedError: false,
    
    editing: false,
    edited: false,
    
    editingPerson: false,
    editedPerson: false,
    
    /********  Contact person list of organization  ********/
    loadingPersons: false,
    loadedPersons: false,
    contactPersons: [],
    
    /********  Customer  ********/
    currentCustomer: null,
    customerLoading: false,
    modificationCount: 0,
    
    /********  Contact person  ********/
    currentContactPerson: null,
    contactPersonFetching: false,
    contactPersonLoading: false,
    contactPersonModificationCount: 0,
    
    tabContentModificationCount: 0,
    
    primaryAddress: null,
    primaryAddressLoading: false,
    isAddressModalOpen: false,
    
    fetching: false,
};

const initialState = {
    persons: {},
    
    applicants: [],
    loadingApplicants: false,
    
    /********  Bank list of organization  ********/
    loadingBankList: false,
    loadedBankList: false,
    bankList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    
    /********  Customer actions  ********/
    
    case CUSTOMER_REQUEST: {
        let data = {
            ...customerModel,
            customerLoading: true,
        };
    
        if (action.silent) {
            data = { customerLoading: true };
        }
    
        return setCustomerData(action.id, state, data);
    }
    
    case CUSTOMER_REQUEST_SUCCESS:
        return setCustomerData(action.id, state, {
            currentCustomer: action.customerData,
            customerLoading: false,
            fetching: true,
        });
    
    case CUSTOMER_REQUEST_FAILURE:
        return setCustomerData(action.id, state, {
            currentCustomer: null,
            customerLoading: false,
        });
    
    case CUSTOMER_MODIFY:
        return setCustomerData(action.id, state, {
            modificationCount: state.persons[action.id].modificationCount + 1,
        });
    
    case CLEAR_CUSTOMER_STATE:
        return setCustomerData(action.id, state, {
            currentCustomer: null,
            customerLoading: false,
            modificationCount: 0,
        });
    
    /********  Contact person request  ********/

    case CONTACT_PERSON_REQUEST:
        return setCustomerData(action.id, state, {
            contactPersonLoading: true,
        });

    case CONTACT_PERSON_REQUEST_SUCCESS:
        
        return setCustomerData(action.id, state, {
            currentContactPerson: action.contactPersonData,
            contactPersonFetching: true,
            contactPersonLoading: false,
        });

    case CONTACT_PERSON_REQUEST_FAILURE:
        return setCustomerData(action.id, state, {
            currentContactPerson: null,
            contactPersonLoading: false,
        });

    case CLEAR_CONTACT_PERSON_STATE:
        return setCustomerData(action.id, state, {
            currentContactPerson: null,
            contactPersonLoading: false,
            contactPersonModificationCount: 0
        });
        
    /********  Customer editing  ********/
    
    case CUSTOMER_EDIT_BEGIN:
        return setCustomerData(action.id, state, {
            editing: true,
            edited: false,
        });
        
    case CUSTOMER_EDIT_SUCCESS:
        return setCustomerData(action.id, state, {
            editing: false,
            edited: true,
            modificationCount: state.persons[action.id].modificationCount + 1,
        });
    
    case CUSTOMER_EDIT_ERROR:
        return setCustomerData(action.id, state, {
            editing: false,
            edited: false,
        });
        
    /********  Customer person editing  ********/
    
    case PERSON_EDIT_BEGIN:
        return setCustomerData(action.id, state, {
            editingPerson: true,
            editedPerson: false,
        });
    
    case PERSON_EDIT_SUCCESS:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: true,
            contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1,
        });
    
    case PERSON_EDIT_ERROR:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: false,
        });
        
    /********  Customer name update  ********/
    
    case UPDATE_CUSTOMER_NAME_SUCCESS:
        return setCustomerData(action.id, state, {
            nameUpdatedSuccess: true,
        });
    
    case UPDATE_CUSTOMER_NAME_ERROR:
        return setCustomerData(action.id, state, {
            nameUpdatedError: true,
        });
        
    /********  Applicants request  ********/
    
    case APPLICANTS_REQUEST:
        return { ...state, loadingApplicants: true };
    
    case APPLICANTS_REQUEST_SUCCESS:
        return { ...state, applicants: action.applicants, loadingApplicants: false };
    
    case APPLICANTS_REQUEST_FAILURE:
        return { ...state, applicants: [], loadingApplicants: false };
    
    case CLEAR_APPLICANTS_STATE:
        return { ...state, applicants: [], loadingApplicants: false };
    
    /********  Customer primary address request ********/
    
    case CUSTOMER_ADDRESS_REQUEST:
        return setCustomerData(action.id, state, {
            primaryAddressLoading: true,
        });
    
    case CUSTOMER_ADDRESS_REQUEST_SUCCESS:
        return setCustomerData(action.id, state, {
            primaryAddress: action.primaryAddress,
            primaryAddressLoading: false
        });
    
    case CUSTOMER_ADDRESS_REQUEST_FAILURE:
    case CUSTOMER_ADDRESS_CLEAR:
        return setCustomerData(action.id, state, {
            primaryAddress: null,
            primaryAddressLoading: false
        });
    
    case CUSTOMER_ADDRESS_MODAL_OPEN:
        return {...state, isAddressModalOpen: true };
    
    case CUSTOMER_ADDRESS_MODAL_CLOSE:
        return {...state, isAddressModalOpen: false };
        
    /********  Contact persons request  ********/
    
    case LOADING_CONTACT_PERSONS_LIST:
        return setCustomerData(action.id, state, {
            loadingPersons: true,
            loadedPersons: false,
        });
    
    case SET_CONTACT_PERSONS_LIST:
        return setCustomerData(action.id, state, {
            loadingPersons: false,
            loadedPersons: true,
            contactPersons: action.payload,
        });
    
    case CLEAR_CONTACT_PERSONS_LIST:
        return setCustomerData(action.id, state, {
            loadingPersons: false,
            loadedPersons: false,
            contactPersons: [],
        });
        
    /********  Saving of contact request  ********/
    
    case PERSON_SAVE_CONTACT_BEGIN:
        return setCustomerData(action.id, state, {
            editingPerson: true,
            editedPerson: false,
            contactPersonLoading: true,
        });
    
    case PERSON_SAVE_CONTACT_SUCCESS:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: true,
            contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1,
        });
    
    case PERSON_SAVE_CONTACT_ERROR:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: false,
        });
        
    /********  Deletion of contact request  ********/
    
    case PERSON_DELETE_CONTACT_BEGIN:
        return setCustomerData(action.id, state, {
            editingPerson: true,
            editedPerson: false,
            contactPersonLoading: true,
        });
    
    case PERSON_DELETE_CONTACT_SUCCESS:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: true,
            contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1
        });
    
    case PERSON_DELETE_CONTACT_ERROR:
        return setCustomerData(action.id, state, {
            editingPerson: false,
            editedPerson: false,
        });
    
    case CUSTOMER_TAB_CONTENT_UPDATE:
        return setCustomerData(action.id, state, {
            tabContentModificationCount: (state.persons[action.id].tabContentModificationCount || 0) + 1,
        });
    
    case CLEAR_ENTIRE_CUSTOMER_STATE: {
        const persons = { ...state.persons };
        delete persons[action.id];
    
        return {
            ...state,
            persons: {
                ...persons,
            },
        };
    }
    
    case CLEAR_ALL_CUSTOMERS:
        return {
            ...state,
            persons: {},
        };
    
    default:
        return state;
    }
};


function setCustomerData (id, state, data) {

    return {
        ...state,
        persons: {
            ...state.persons,
            [id]: { ...state.persons[id], ...data }
        },
    };
}

