import React from 'react';
import PropTypes from 'prop-types';


export default function ControlPanelRow({ children, ...props }) {
  return (
    <div className='controlPanelRow' {...props}>{children}</div>
  );
}

ControlPanelRow.propTypes = {
  children: PropTypes.any
};
