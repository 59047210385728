import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { Link, withRouter } from 'react-router-dom';
import { openEmail } from 'actions/emails';
import { connect } from 'react-redux';
import { EmailTypes } from 'constants/index';
import { translate } from 'react-i18next';

const mapDispatchToProps = dispatch => ({
  openEmail: emailId => dispatch(openEmail(emailId))
});

@translate()
@withRouter
@connect(null, mapDispatchToProps)
class EmailItem extends React.Component {

  getOptionalParts = () => {
    const { data: { emailType, mailFrom, mailTo }, t} = this.props;
    switch (emailType) {
      case EmailTypes.INCOMING: return { title: t('appealHistory.emailFrom'), content: mailFrom};
      case EmailTypes.OUTGOING: return { title: t('appealHistory.emailTo'), content: mailTo};

      default: return 'Email';
    }
  };

  getUrl = () => this.props.match.url.replace('history', 'mailing');

  openEmail = () => this.props.openEmail(this.props.data.id);

  render(){
    const { data, highlight, t } = this.props;
    const { title, content } = this.getOptionalParts();
    return (
      <li className={styles.historyItem}>
        <i className='icon-envelope'/>
        <div className={styles.historyItemContent}>
          <span className={styles.historyTitleText}>{title}: </span>
          <span className={styles.historyContentText}>{content}</span>
          <div className={styles.emailSubject}>
            <Link to={this.getUrl()} onClick={this.openEmail}>
              {t('appealHistory.emailSubject')}: {highlight(data.subject)}
            </Link>
          </div>
        </div>
      </li>
    );
  }
}

EmailItem.propTypes = {
  data: PropTypes.object,
  highlight: PropTypes.func
};

export default EmailItem;
