import {
  SAVE_FORM_CHANGES,
  CANCEL_FORM_CHANGE,
  REPEAT_FORM_CHANGE
} from 'constants/actions';
import { change, focus } from 'redux-form';
import { forceFocusOnInput, findObjectDifferences } from 'helpers';


export const saveFormChanges = (payload) => ({
  type: SAVE_FORM_CHANGES,
  payload
});

const fireRepeatFormChange = () => ({
  type: REPEAT_FORM_CHANGE
});

export const repeatFormChange = () => (dispatch, getState) => {
  const { history, pointer } = getState().changeHistory;
  if (pointer < -1 || pointer + 1 >= history.length) return;
  const { form, field, before, after } = history[pointer + 1];
  if (!form) return;
	
  dispatch(fireRepeatFormChange());
  dispatch(change(form, field, after ));
  dispatch(focus(form, field));
	
  const arrayIndex = Array.isArray(before) ? Object.keys(findObjectDifferences(before, after))[0] || before.length - 1 : undefined;
  forceFocusOnInput(field, arrayIndex);
};

const fireCancelFormChange = () => ({
  type: CANCEL_FORM_CHANGE
});

export const cancelFormChange = (payload) => (dispatch, getState) => {
  const { history, pointer } = getState().changeHistory;
  if (pointer < 0) return;
  const { form, field, before, after } = history[pointer];
  if (!form) return;
	
  dispatch(fireCancelFormChange());
  dispatch(change(form, field, before ));
  dispatch(focus(form, field));
	
  const arrayIndex = Array.isArray(before) ? Object.keys(findObjectDifferences(before, after))[0] || before.length - 1 : undefined;
  forceFocusOnInput(field, arrayIndex);
};
