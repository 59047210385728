/**
 * INPUT PARAMETERS
 {
     onChangeFields: external function that handles changes in columns.
     selectItem: external function  // Replace standard behaviour select row and prevent any events like                                                 (footer.submit, edit). Incoming {ITEM} (selected object from table)
     add: external function,        // will appear 'ADD' button
     edit: external function,       // will appear 'EDIT' button if row selected
     upload: external function,     // will appear icon 'Upload'
     remove: external function,     // will appear icon 'Delete' on end of row
     // groups: true,
     filter: external function,     // will appear 'search' input
     confirm: ['remove'],           // will appear confirm window if you do action in array 'confirm'
     // save: this.save,
     // limitFields: ['type', 'lastEditDate'],
     handleSort: external function,
     fields: {
         attach_id: {
             name: t('ID')
         },
         attach_type_id: {
             name: 'type',               // required! Name of column.
             classWidth: widths.min,     // required! number indicating the column width type (0..2)
             format: 'date',             // indicates if the original value should be changed to a different format ('string', 'number', 'date', 'translate')
             link: '/documents'          // link to be redirected after a click
             html: true,                 // flag indicating that the content should be output as html
             noSorted: true,             // flag for banning column sorting
             hidden: false,              // hiding flag
             permanent: true,            // flag for hiding columns
             value: data => data.id      // function that returns a value for a cell as a result of incoming data
             component: <p>{data}</p>    // component that will be inserted in the cell
         },
         ...
         tr: {
             dblClick: external function
         },
         footer: {                      // object, if footer exist render grid Footer Component
             submit: external function,
             cancel: external function
         },
         groups: {
             form: component with form,
             checkedItems: array of checked items,
             handleCheck: external function for sending checked items,
         }
     }
 }
 **/

import React from 'react';
import PropTypes from 'prop-types';

import GridTable from './GridTable';
import Header from './Header';
import Footer from './Footer';
import FormsWrapper from './FormsWrapper';
import './grid.scss';


export default class Grid extends React.Component {
    state = {
        ...this.props,
        isAppealFormOpened: true
    };
	
	UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({ ...nextProps });
	};

    toggleAppealForm = () => {
        this.setState(prevState => ({ isAppealFormOpened: !prevState.isAppealFormOpened }));
    };

    filter = (val, key) => {
        let add = false;

        for (const i in val) {
            if (!add && val.hasOwnProperty(i)) {
                add = add || (val[i] + '').includes(key);
            }
        }

        return add;
    };

    filterData = key => {
        const { data } = this.props;

        this.setState({ data: data.filter(val => this.filter(val, key)) });
    };

    filterFields = field => {
        const { fields } = this.props.params;
        let newFields = {};

        if (field !== 'all_fields') {
            for (const i in fields) {
                if (i === field) {
                    newFields[i] = fields[i];
                }
            }
        } else {
            newFields = this.props.params.fields;
        }

        this.setState({ params: { ...this.state.params, fields: newFields } });
    };

    selectItem = id => {
        const { data, params } = this.state;
        const selected = data.find(v => {
            const val = v[params.defaultId] || v.id;
            return val && val.toString() === id;
        });

        if (selected) {
            selected.id = selected[params.defaultId] || selected.id;

            if (selected.id || selected.id === 0) {
                if (this.props.params.selectItem) {
                    this.props.params.selectItem(selected);
                }
                this.setState({ selected });
            }
        }
    };

  handleSort = ({ field, isReversed }) => {
    const { data, params: { sort } } = this.props;

    // if it's server side sorting
    if (sort && sort.onChange) return sort.onChange({ field, isReversed });
    // if it's simple sorting
    return this.setState({
      data: data.sort((a, b) => !isReversed  ? (a[field] + "").localeCompare(b[field]) : ((a[field] + "").localeCompare(b[field]) * -1)),
      params: {
        ...this.state.params,
        sort: { ...this.state.params.sort, field, isReversed }
      }
    });
  };

    handleCheck = e => {
        const { value, checked } = e.target;
        const { checkedItems, handleCheck } = this.props.params.groups;

        const items = checked
            ? [...checkedItems, value]
            : checkedItems.filter(item => item !== value);

        handleCheck({ checkedItems: items });

        if (!checkedItems.length && items.length) {
            this.setState({ isAppealFormOpened: true });
        }
    };

    render = () => {
        const { params, data, selected, isAppealFormOpened, strings } = this.state;
        return (
            <div className='grid'>
                {/* <Header*/}
                {/* params={params}*/}
                {/* selected={selected}*/}
                {/* filterData={this.filterData}*/}
                {/* filterFields={this.filterFields}*/}
                {/* strings={strings}*/}
                {/* />*/}
                <GridTable
                    params={params}
                    data={data}
                    selected={selected}
                    sort={{
                        ...params.sort,
                        handleSort: this.handleSort
                    }}
                    selectItem={this.selectItem}
                    {...this.props.params.groups && {
                        groups: {
                            ...this.props.params.groups,
                            handleCheck: this.handleCheck
                        }
                    }}
                    strings={strings}
                />

                {params.footer && (
                    <Footer
                        params={params.footer}
                        selected={selected}
                        strings={strings}
                    />
                )}

                {this.props.params.groups && (
                    <FormsWrapper
                        {...this.props.params.groups}
                        filterOnlyCheckedItems={this.filterOnlyCheckedItems}
                        toggleAppealForm={this.toggleAppealForm}
                        isAppealFormOpened={isAppealFormOpened}
                    />
                )}
            </div>
        );
    };
}

Grid.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    params: PropTypes.object,
    contentUpdate: PropTypes.func,
    strings: PropTypes.object
};
