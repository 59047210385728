import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, isValid, change} from 'redux-form'
import get from 'lodash/get';
import cx from 'classnames';

import NotificationPortal from '../Notification/NotificationPortal';
import TabContent from './TabContent';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import ButtonSelectPopup from 'components/Common/ButtonSelectPopup';
import StatusSelect from './StatusSelect';
import ActionSelect from './ActionSelect';
import NewAppealForm from './Form';
import PersonPageContent from 'components/PersonPage/MainContent';
import CustomerTabContent from 'components/CustomerPage/MainContent';
import AppealPrintPopup from './PrintPopup';
import AppealActionsPopup from './AppealActionsPopup';
import ViewListQuality from './History/CallQuality/ViewListQuality';
import { changeFormListener, extractAppealFromState, extractCustomerFromState } from 'helpers';
import {
    unlockAppealForm, repeatAppeal, removeRefreshAppealModal, getAppealVersion,
	setSaveAndCreateNew,
} from 'actions/appeal';
import downloadCSV from 'actions/downloadCSV';
import { removeTab } from 'actions/tabs';
import styles from 'styles/modules/appealOperatePage.module.scss';
import notificationStyle from 'styles/modules/notification.module.scss';
import { translate } from 'react-i18next';
import { getCustomerTabNames, isOrganization } from 'helpers';
import permissions, { checkPermissions } from 'config/permissions';
import { initialValues } from './helpers';
import {
    lockAppealForm, saveAppeal, setStatusModalVisibility,
    showValidationNotification, createNewAppeal,
} from '../../actions/appeal';


function mapStateToProps (state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const customer = extractCustomerFromState(state, _.get(appeal, 'currentAppeal.customerId'));

    return {
        appeal: appeal.currentAppeal,
		saveAndCreateNew: appeal.saveAndCreateNew,
        appealFeedback: appeal.appealFeedback,
        refreshAppealModal: appeal.refreshAppealModal,
        selectedAppealAction: appeal.selectedAppealAction,
        priorityList: state.appeal.priorityList,
        destinations: appeal.destinations,
        
        formParams: appeal.formParams,
        modalStatusList: appeal.modalStatusList,
        appealTypes: state.appeal.appealTypes,
        unlockAppeal: appeal.unlockAppeal,
        unlockedAppeal: appeal.unlockedAppeal,
        closeAppealFlag: appeal.closeAppealFlag,
    
        customerId: _.get(customer, 'currentCustomer.id'),
        customer: customer.currentCustomer,
        contactPerson: customer.currentContactPerson,
        customerType: get(customer, 'currentCustomer.party.partyType'),
        
        operations: state.user.operations,
        generateFile: !checkPermissions(permissions.FileOperations.generate_file),
        canAddExecutors: checkPermissions(permissions.AppealOperations.action_createAppealDuplet),
        canCreateAppeal: checkPermissions(permissions.AppealOperations.action_createAppeal),
        contentLoading: state.content.contentLoading,
	
		incomePhoneNumber: state.call.incomePhoneNumber,
        
        isValid: isValid(`appeal-form-${id}`)(state),
    }
}

const mapDispatchToProps = {
    unlockAppealForm,
    saveAppeal,
    getAppealVersion,
    removeRefreshAppealModal,
    setStatusModalVisibility,
    showValidationNotification,
    lockAppealForm,
    createNewAppeal,
	setSaveAndCreateNew,
    removeTab,
    
    submitForm: (id) => submit(`appeal-form-${id}`),
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class AppealMainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iconClass: 'qwarter',
            tabsOpened: true,
            popupIsOpen: false,
            save: false,
            createNew: false,
			saveAndClose: localStorage.getItem('saveAndClose') === 'true',
            printPopupIsOpen: false,
            shouldntUnlock: false,
            callQuality: false
        };
        this.toggleCodeQuality = this.toggleCodeQuality.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.submit = this.submit.bind(this);
        this.refresh = this.refresh.bind(this);
        this.closeRefreshModal = this.closeRefreshModal.bind(this);
    }

    openPrintPopup = () => this.setState({ printPopupIsOpen: true, popupIsOpen: false });

    closePrintPopup = () => this.setState({ printPopupIsOpen: false });

    setQwarter = () => this.setState({ iconClass: 'qwarter' });

    setHalf = () => this.setState({ iconClass: 'half' });

    toggleTabs = () => this.setState({ tabsOpened: !this.state.tabsOpened });

    toggleCheckbox () {
        const saveAndClose = !(localStorage.getItem('saveAndClose') === 'true');
        this.setState({ saveAndClose });
        localStorage.setItem('saveAndClose', saveAndClose.toString());
    };

    closePopup = (e) => {
        const actionButtonClick = this.actionButton.contains(e.target);
        return actionButtonClick ? null : this.togglePopup();
    };

    togglePopup = () => this.setState({ popupIsOpen: !this.state.popupIsOpen });
    

    onBreadCrumbsClick = (id) => {
        const { appealId } = this.props.match.params;
        this.props.history.push(`/appeals/${appealId}/customer/${id}/appeals`);
    };

    onBreadCrumbsPersonClick = (id) => {
        const { appealId } = this.props.match.params;
        this.props.history.push(`/appeals/${appealId}/person/${id}/contact`);
    };

    onBreadCrumbsAppealClick = () => {
        const { appealId } = this.props.match.params;
        this.props.history.push(`/appeals/${appealId}/knowledge_base`);
    };

    getBreadCrumbs = () => {
        const { appeal, customer, contactPerson, location, customerType } = this.props;
        
        const currentTab = location.pathname.split('/')[3];
        const currentBreadcrumb = ['customer', 'person'].includes(currentTab) ? currentTab : 'appeal';

        const breadcrumbs = [];

        if (isOrganization(customerType)) {
            breadcrumbs.push({
                className: `breadcrumbsLink ${currentBreadcrumb === 'customer' ? 'currentTitle' : ''}`,
                element: <span>{customer.party.officialName}</span>,
                onClick: () => this.onBreadCrumbsClick(customer.id)
            });

            contactPerson && breadcrumbs.push({
                className: `breadcrumbsLink ${currentBreadcrumb === 'person' ? 'currentTitle' : ''}`,
                element: <span>{contactPerson.party.fullName}</span>,
                onClick: () => this.onBreadCrumbsPersonClick(contactPerson.id)
            });
        }

        if (customerType === 'individual') {
            const fullName = get(customer, 'party.fullName', '').trim();
            breadcrumbs.push({
                className: `breadcrumbsLink ${currentBreadcrumb === 'customer' ? 'currentTitle' : ''}`,
                element: <span>{fullName}</span>,
                onClick: () => this.onBreadCrumbsClick(customer.id)
            });
        }

        let appealStatusText = appeal.status.name;

        if (appeal.resolution.title) {
            appealStatusText = `${appeal.status.name}: ${appeal.resolution.title}`;
        }

        breadcrumbs.push({
            className: `breadcrumbsLink last ${appeal.hasTasks ? 'breadcrumbsTitle' : ''} ${currentBreadcrumb === 'appeal' ? 'currentTitle' : ''}`,
            element: (
                <span>
                    <span className={`sticker sticker-${appeal.status.code}`}>{appealStatusText}</span>
                    <span>Звернення {appeal.regnum}</span>
                </span>
            ),
            onClick: () => this.onBreadCrumbsAppealClick()
        });

        return breadcrumbs;
    };

    onFormChange = (changedValues, dispatch, props, previousValues) => {
        if (this.state.createNew || this.state.save) {
            this.setState({ createNew: false, save: false });
        }
        changeFormListener(changedValues, dispatch, props, previousValues);
    };

    unlockAppealForm = () => {
        const { match, unlockedAppeal, unlockAppeal } = this.props;
        
        if (!unlockedAppeal && !unlockAppeal) {
            this.props.unlockAppealForm(match.params.appealId);
        }
    };

    checkRestrictions = (field) => {
        const { restriction } = this.props.appeal;
        return (restriction && restriction[field] && restriction[field] === 1);
    };

    shouldRenderField = (field) => {
        const fieldRestriction = get(this.props.appeal, `restriction[${field}]`, 0);
        return fieldRestriction !== 2 && fieldRestriction !== 0;
    };

    openRepeatAppeal = () => {
        const requestData = {
            repeatRequestId: this.props.appeal.id,
            appealId: this.props.appeal.id,
            typeCode: 'PRECRM'
        };
        const onSuccess = createdAppeal => this.props.history.push(`/appeals/${createdAppeal.id}/knowledge_base`);

        return repeatAppeal(requestData, onSuccess);
    };
    
    excelExport = () => {
        const requestData = { appealId: this.props.appeal.id, filter: 0, query: null };
        downloadCSV({
            requestData,
            key: 'appeal_interaction_csv',
        }, this.togglePopup);
    };

    toggleCodeQuality() {
        this.setState({ callQuality: !this.state.callQuality });
    }

    renderOperator = (operator) => {
        const shouldRender = operator.code === 'edit' && !operator.owner;

        if (!shouldRender) return null;

        const text = `${operator.codeName} ${operator.name}`;

        return (
            <span title={text} className={styles[`${operator.code}Code`]}>
                {text}
            </span>
        );
    };
    
    async submit (e, createNew) {
		e.preventDefault();
		e.stopPropagation();
	
		if (createNew) {
			await this.props.setSaveAndCreateNew(this.props.appeal.id, true);
		}
		
        await this.props.submitForm(this.props.appeal.id);
    }
    
    refresh () {
        this.props.requestInitialData(true);
    }
    
    closeRefreshModal () {
        this.props.removeRefreshAppealModal(this.props.appeal.id);
    }

    render() {
		const {
			appeal, unlockedAppeal, t, contentLoading, customer, change,
			customerType, contactPerson, generateFile, appealTypes, formParams,
			appealFeedback, customerId, canCreateAppeal,
			} = this.props;

		const { iconClass, tabsOpened, printPopupIsOpen, callQuality, saveAndClose } = this.state;

		if (!appeal) return null;

		const popupConfig = [
			{
				label: t('appeal.save'),
				clickHandler: this.submit,
			},
			{
				label: t('appeal.saveAndCreateNew'),
				clickHandler: (e) => this.submit(e, true),
				disabled: !canCreateAppeal
			}
		];

		const iconHandler = (iconClass === 'qwarter') ? this.setHalf : this.setQwarter;

		const customerName = customerType === 'organization' ? get(customer, 'party.officialName') : get(customer, 'party.fullName');

		const personName = get(contactPerson, 'party.fullName', '');
		const operators = get(appeal, 'openMode.operators', []);



	const intiValues = initialValues(
			this.props.appeal,
			this.props.appealTypes,
			this.props.formParams,
			this.props.appealFeedback,
			this.props.customer,
		);
        return (
            <main className={styles.main}>
                <header className={styles.header}>
                    <Breadcrumbs data={this.getBreadCrumbs()} />
                    <div className={styles.dateRangeWrapper}>
                        <button className={cx('btn-save', { hidden: generateFile })} onClick={this.openPrintPopup}>картка звернення</button>
                        <button
                            className='btn-add'
                            onClick={this.togglePopup}
                            ref={(node) => (this.actionButton = node)}
                        >
                            <i className='icon-kebab-vert'/>
                        </button>

                        <AppealActionsPopup
                            open={this.state.popupIsOpen}
                            closePopup={this.closePopup}
                            openRepeatAppeal={this.openRepeatAppeal}
                            callQuality={this.toggleCodeQuality}
                            excelExport={this.excelExport}
                        />

                        {
                            printPopupIsOpen &&
                            <AppealPrintPopup
                                onClose={this.closePrintPopup}
                                typeId={appeal.typeId}
                                appealId={appeal.id}
                            />
                        }
                    </div>
                </header>

                <Switch>
                    <Route path='/appeals/:appealId/person/:id/:subTab?' render={(props) => {
                        if (!contactPerson) return null;
                        return (
                            <PersonPageContent
                                {...props}
                                contactPerson={contactPerson}
                                personName={personName}
                                customerId={customerId}
                            />
                        );
                    }}/>
                    <Route path='/appeals/:appealId/customer/:id/:subTab?' render={(props) => {
                        if (!customer) return null;
                        return (
                            <CustomerTabContent
                                {...props}
                                customer={customer}
                                tabsNames={getCustomerTabNames(customerType)}
                                customerName={customerName}
                                customerLastName={customer.party.lastName}
                                customerFirstName={customer.party.firstName}
                                customerPatronymic={customer.party.patronymic}
                                customerId={customer.id}
                                customerType={customerType}
                            />
                        );
                    }
                    }/>
                    <Route path='/appeals/:appealId/:tab?' render={(props) => (
                        <Fragment>
                            <div className={styles.contentWrapper}>
                                <NewAppealForm
                                    form={`appeal-form-${appeal.id}`}
                                    appeal={appeal}
                                    customer={customer}
                                    appealFeedback={appealFeedback}
                                    appealTypes={appealTypes}
                                    formParams={formParams}
                                    shouldRenderField={this.shouldRenderField}
                                    checkRestrictions={this.checkRestrictions}
                                    onChange={this.onFormChange}
                                    unlockAppealForm={this.unlockAppealForm}
                                    iconClass={tabsOpened ? iconClass : 'closed'}
                                    resetAppealType={this.props.resetAppealType}
                                    postSave={this.props.postSave}
                                    resetActions={this.props.resetActions}
                                    saveAppeal={this.props.saveAppeal}
                                    modalStatusList={this.props.modalStatusList}
                                    selectedAppealAction={this.props.selectedAppealAction}
                                    priorityList={this.props.priorityList}
                                    destinations={this.props.destinations}
                                    setStatusModalVisibility={this.props.setStatusModalVisibility}
                                    showValidationNotification={this.props.showValidationNotification}
                                    canAddExecutors={this.props.canAddExecutors}
									saveAndCreateNew={this.props.saveAndCreateNew}
									setSaveAndCreateNew={this.props.setSaveAndCreateNew}
									incomePhoneNumber={this.props.incomePhoneNumber}
									contactPerson={contactPerson}
                                    t={t}
									intiValues={intiValues}
									match={props.match}
                                />
    
                                {
                                    this.props.refreshAppealModal && (
                                        <NotificationPortal>
                                            <div className={notificationStyle.notification}>
                                                <i className='icon-close' onClick={this.closeRefreshModal}/>
                                                <div className={notificationStyle.appealChangesFailureNote}>
                                                    <i className='icon-error'/>
                                                    <span>Звернення {appeal.num} було змінено. Натисніть "Оновити" для перегляду.</span>
                                                    <button onClick={this.refresh} className='btn btn-primary m-t-5'>Оновити</button>
                                                </div>
                                            </div>
                                        </NotificationPortal>
                                    )
                                }

                                <TabContent
                                    iconClass={iconClass}
                                    iconHandler={iconHandler}
                                    toggleTabs={this.toggleTabs}
                                    contentLoading={contentLoading}
                                    tabsOpened={tabsOpened}
                                    unlockAppealForm={this.unlockAppealForm}
                                    {...props}
                                />
                            </div>

                            <footer className={styles.footer}>
                                <div className={styles.toggleWrapper}>
                                    <CheckBoxToggle
                                        id='out'
                                        label={t('appeal.footerCheckBoxLabel')}
                                        checked={saveAndClose}
                                        onChange={this.toggleCheckbox}
                                    />
                                </div>
                                <div className={styles.operatorsBlock}>
                                    {operators.map(this.renderOperator)}
                                </div>
                                <div className={styles.footerControlButtons}>
                                    <ActionSelect
                                        change={change}
                                        disabled={this.checkRestrictions('statusId')}
                                        unlockAppealForm={this.unlockAppealForm}
                                        permission={this.props.appeal.restriction.nextAction}
                                    />
                                    <StatusSelect
                                        change={change}
                                        disabled={this.checkRestrictions('statusId')}
                                        unlockAppealForm={this.unlockAppealForm}
                                        permission={this.props.appeal.restriction.statusId}
                                        onSubmitButtonClick={() => this.setState({ save: false })}
                                    />
                                    <ButtonSelectPopup
                                        text={t('appeal.save')}
                                        popupConfig={popupConfig}
                                        onClickBtn={this.submit}
                                        disabled={!unlockedAppeal}
                                        up
                                    />
                                </div>
                            </footer>

                        </Fragment>
                    )}/>
                </Switch>
                {
                    callQuality && <ViewListQuality onClose={this.toggleCodeQuality} appeal={appeal}/>
                }
            </main>
        );
    }
}

AppealMainContent.propTypes = {
    appeal: PropTypes.object,
    customer: PropTypes.object,
};
