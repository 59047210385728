import React from 'react';
import PropTypes from 'prop-types';
import SearchOption from './SearchOption';
import { createCheckPropsFunction } from 'helpers';
import debounce from 'lodash/debounce';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';

class OptionsListContainer extends React.Component {
    
    constructor (props) {
        super(props);
        
        this.state = {
            searchQuery: '',
            filteredOptions: this.filterOptions()
        };
    }
    
    componentDidUpdate (prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        
        if (isPropChanged('options')) {
            this.setState({ filteredOptions: this.filterOptions(this.state.searchQuery) });
        }
        
        if (isPropChanged('selectedOptions') && !this.props.async) {
            this.triggerFilterOptions(this.state.searchQuery);
        }
    }
    
    filterOptions (searchQuery = '') {
        return this.props.options.filter(option => {
            const isMatched = option.label.toLowerCase().includes(searchQuery.toLowerCase());
            
            if (isMatched) {
                const foundIndex = this.props.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value);
                return foundIndex === -1;
            }
            
            return false;
        });
    };
    
    onQueryChange = searchQuery => {
        this.setState({ searchQuery });
        this.debouncedTriggerFilterOptions(searchQuery);
    };
    
    triggerFilterOptions = query => {
        const { async } = this.props;
        
        if (async) {
            async.callback(query, async.code, async.id);
        } else {
            let filteredOptions;
    
            if (query.trim()) {
                filteredOptions = this.filterOptions(query);
            } else {
                filteredOptions = this.props.options;
            }
    
            this.setState({ filteredOptions });
        }
    };
    
    debouncedTriggerFilterOptions = debounce(this.triggerFilterOptions, SEARCH_TIMER_INTERVAL);
    
    onSelect = option => {
        const foundIndex = this.props.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value);
        if (foundIndex !== -1) return;
        
        this.props.onSelect({ value: option.value, label: option.label });
    };
    
    renderOption = option => {
        const onClick = () => this.onSelect(option);
        return (
            <div
                className={'option'}
                key={option.value}
                onClick={onClick}
            >
                {option.label}
            </div>
        );
    };
    
    render () {
        const { isOpen, containerRef } = this.props;
        const { searchQuery, filteredOptions } = this.state;
        if (!isOpen) return null;
        
        return (
            <div className={'options-container'} ref={containerRef}>
                <SearchOption
                    query={searchQuery}
                    onChange={this.onQueryChange}
                />
                <div className={'options-wrapper'}>
                    {filteredOptions.map(this.renderOption)}
                </div>
            </div>
        );
    }
}

OptionsListContainer.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string
    })),
    isOpen: PropTypes.bool,
    containerRef: PropTypes.object,
    selectedOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string
    })),
    onSelect: PropTypes.func,
};

export default OptionsListContainer;
