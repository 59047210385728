import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/parameters.module.scss';
import get from 'lodash/get';
import ErrorBlock from '../ErrorBlock';
import Loader from '../../../../Loader';
import SubmitEditButton from 'components/CustomerPage/SubmitEditButton';
import Cancel from 'components/CustomerPage/Cancel';


class ContactInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            isEdit: false,
            submit: false,
        };
        this.ref = React.createRef();
        this.blur = this.blur.bind(this);
        this.focus = this.focus.bind(this);
        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderControl = this.renderControl.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.loading && prevProps.loading) {
            this.ref.blur();
            this.setState({ isLoad: false, submit: false });
        }
    }
    
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.submit();
        } else if (event.key === 'Escape') {
            event.stopPropagation();
            event.preventDefault();
            this.cancel();
        }
    };
    
    renderControl() {
        const {
            disabled, input, meta, loading, required, fields,
        } = this.props;
        const { isLoad, isEdit } = this.state;
        const value = get(input.value, 'value', '');
        const error = meta.error && value !== '';
        const showRemoveButton = value !== '';
        if (isLoad) {
            return <Loader loaderClass='input-loader' />;
        } else if (error) {
            return <ErrorBlock error={meta.error} />
        } else if (showRemoveButton && !disabled && !loading && !isEdit) {
            if (required && fields.length === 1) {
                return null;
            } else {
                return (
                    <button
                        type='button'
                        title='Remove'
                        className={styles.removeButton}
                        onClick={this.onRemoveClick}
                    >
                        <span className='icon-close' />
                    </button>
                );
            }
            
        } else if (isEdit) {
            return (
                <Fragment>
                    <Cancel onClick={this.cancel} />
                    <SubmitEditButton onClick={this.submit} />
                </Fragment>
            );
        }
        return null;
    }

    onRemoveClick(e, fieldOnly) {
        this.setState({ isLoad: true });
        const { input } = this.props;
        this.props.onRemove(input.value, input.name, fieldOnly);
    };

    handleChange(event) {
        const { input } = this.props;
        input.onChange({ ...input.value, value: event.target.value });
    };
    
    submit() {
        const { meta, input, handleChangeValue } = this.props;
        const initialValue = get(meta.initial, 'value');
        const reduxFormValue = input.value.value;
        if (initialValue !== reduxFormValue && reduxFormValue) {
            this.setState({ isLoad: true, isEdit: false });
            handleChangeValue(input.value, input.name);
        }
    }
    
    cancel() {
        const { meta, input, removeFirst, fields } = this.props;

        if (input.value.id === 0 && fields.length !== 1) {
            this.setState({ isEdit: false });
            removeFirst();
        } else {
            this.setState({ isEdit: false });
            const initialValue = get(meta.initial, 'value');
            input.onChange({ ...input.value, value: initialValue });
        }
    }
    
    blur() {
        this.props.onBlur();
        setTimeout(() => {
            if (!this.state.submit) {
                this.cancel();
            }
        }, 1000);
    }
    
    focus() {
        this.props.onFocus();
        if (!this.state.isEdit) {
            this.setState({ isEdit: true });
        }
    }

    render() {
        const {
            disabled, input, meta, placeholder, maxLength
        } = this.props;
        const value = get(input.value, 'value', '');
        const showErrorBlock = meta && meta.touched && !meta.active && meta.error && value !== '';
        const containerClassName = cx(
            'input-element',
            styles.contactInputContainer,
        );
        const inputClassName = cx(
            'input-field',
            showErrorBlock && 'input-field__error',
        );
        return (
            <div className={containerClassName}>
                <input
                    name={input.name}
					maxLength={maxLength}
                    value={value}
                    disabled={disabled}
                    className={inputClassName}
                    onBlur={this.blur}
                    onFocus={this.focus}
                    onChange={this.handleChange}
                    autoComplete='off'
                    placeholder={placeholder}
                    onKeyDown={this.handleKeyDown}
                    ref={(input) => this.ref = input}
                />
                {this.renderControl()}
            </div>
        );
    }
}

function defaultFunc() {
}

ContactInput.defaultProps = {
    onFocus: defaultFunc,
    onBlur: defaultFunc,
    onRemove: defaultFunc,
    placeholder: '-',
};

ContactInput.propTypes = {
    label: PropTypes.string,
    input: PropTypes.any,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
};

export default ContactInput;
