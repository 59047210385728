import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from 'components/Popup';
import {
    clearFilterInfo,
    getInfoFilterSuppose,
    exportAppealGridToExcel,
    exportAppealsGridToCSV,
    exportAppealsGridToXLS,
    openFilterParams,
    closeFilterParams,
    openNewFilterParams,
    closeNewFilterParams
} from 'actions/filter';
import styles from 'styles/modules/appealsContent.module.scss';
import { translate } from 'react-i18next';
import settingsService, { SORT_POSTFIX, FIELDS_POSTFIX } from 'services/settingsService';
import tabsParams from 'constants/tabsParams';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

function mapStateToProps (state) {
    return {
        queryKey: get(state.tabs, 'current.queryKey'),
    };
}

function mapDispatchToProps (dispatch) {
    return {
        actions: bindActionCreators({
            clearFilterInfo,
            openFilterParams,
            closeFilterParams,
            closeNewFilterParams,
            openNewFilterParams,
            getInfoFilterSuppose
        }, dispatch)
    };
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class Header extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            gridPopupIsOpen: false,
            onRequestExportAllStatus: localStorage['onRequestExportAllStatus'] === 'true'? true : false,
        };

        this.toggleGridPopup = this.toggleGridPopup.bind(this);
        this.openFilterParams = this.openFilterParams.bind(this);
        this.openNewFilter = this.openNewFilter.bind(this);
        this.filterName = this.filterName.bind(this);
        this.exportAllColumnsToExcel = this.exportAllColumnsToExcel.bind(this);
        this.exportToXlsAll = this.exportToXlsAll.bind(this);
        this.exportToCSVAll = this.exportToCSVAll.bind(this);
        this.shouldDisplayExportSelected = this.shouldDisplayExportSelected.bind(this);
        this.exportSelectedToExcel = this.exportSelectedToExcel.bind(this);
        this.renderPopupButton = this.renderPopupButton.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.toggleDisabledStatus = this.toggleDisabledStatus.bind(this);
    }
	
	UNSAFE_componentWillMount(){
        window.onbeforeunload = function() {
            localStorage.removeItem('onRequestExportAllStatus');
        };
    }

    toggleGridPopup () {
        this.setState(prevState => ({gridPopupIsOpen: !prevState.gridPopupIsOpen}));
    };

    openFilterParams (e) {
        const {actions} = this.props;
        e.preventDefault();
        e.stopPropagation();
        actions.closeNewFilterParams();
        this.toggleGridPopup();
        actions.openFilterParams();
    };

    openNewFilter (e) {
        const {actions} = this.props;
        e.preventDefault();
        e.stopPropagation();
        actions.clearFilterInfo();
        actions.closeFilterParams();
        this.toggleGridPopup();
        actions.openNewFilterParams();
    };

    filterName (data, id) {
        let name = '';
        data.forEach(item => (item.value === id ? name = item.name : null));
        return name;
    };

    exportAllColumnsToExcel () {
        const appealFields = Object.entries(tabsParams(this.props.t).filter)
            .reduce((acc, [key, value]) => {
                const nameParts = [key];
                if (value.objectField) nameParts.push(value.objectField);

                acc[nameParts.join('.')] = value.name;

                return acc;
            }, {});

    const requestData = {
      query: '',
      filterId: this.props.id,
      fields: appealFields,
      filterObject: []
    };
    const { queryKey } = this.props;

    let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
    if(sortSettings) {
        requestData['sort'] = {
          field: sortSettings.field,
          isReversed: sortSettings.isReversed
        }
    }

        this.toggleGridPopup();
        return exportAppealGridToExcel(requestData);
    };
    exportToCSVAll () {
        const appealFields = Object.entries(tabsParams(this.props.t).filter)
            .reduce((acc, [key, value]) => {
                const nameParts = [key];
                if (value.objectField) nameParts.push(value.objectField);

                acc[nameParts.join('.')] = value.name;

                return acc;
            }, {});

        const requestData = {
            query: '',
            filterId: this.props.id,
            fields: appealFields,
            filterObject: []
        };
        const { queryKey } = this.props;

        let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
        if(sortSettings) {
            requestData['sort'] = {
                field: sortSettings.field,
                isReversed: sortSettings.isReversed
            }
        }

        this.toggleGridPopup();
        return exportAppealsGridToCSV(requestData);
    };

    toggleDisabledStatus(){
        const newStateDisabled = !this.state.onRequestExportAllStatus;
        this.setState({ onRequestExportAllStatus: newStateDisabled });
        localStorage.setItem('onRequestExportAllStatus', newStateDisabled);
    }

    exportToXlsAll () {
        this.toggleDisabledStatus();
        const appealFields = Object.entries(tabsParams(this.props.t).filter)
            .reduce((acc, [key, value]) => {
                const nameParts = [key];
                if (value.objectField) nameParts.push(value.objectField);

                acc[nameParts.join('.')] = value.name;

                return acc;
            }, {});

        const requestData = {
            query: '',
            filterId: this.props.id,
            fields: appealFields,
        };
		const {queryKey} = this.props;

		let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);

		if(sortSettings) {
			requestData['sort'] = {
				field: sortSettings.field,
				isReversed: sortSettings.isReversed
			}
		}

        this.toggleGridPopup();
		return exportAppealsGridToXLS(requestData, this.toggleDisabledStatus);
    };

    shouldDisplayExportSelected () {
        const {queryKey} = this.props;
        if (!queryKey) return false;

        const fields = settingsService.get(`${queryKey}${FIELDS_POSTFIX}`);

        return Boolean(fields);
    };

    exportSelectedToExcel () {
        const {queryKey} = this.props;
        const sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
        const fields = settingsService.get(`${queryKey}${FIELDS_POSTFIX}`);

        const appealFields = Object.entries(fields)
            .reduce((acc, [key, value]) => {

                if (!value.hidden) {
                    const nameParts = [key];
                    if (value.objectField) nameParts.push(value.objectField);

                    acc[nameParts.join('.')] = value.name;
                }

                return acc;
            }, {});

        const requestData = {
              query: '',
              filterId: this.props.id,
              fields: appealFields,
              filterObject: []
        };


        if(sortSettings) {
            requestData['sort'] = {
                field: sortSettings.field,
                isReversed: sortSettings.isReversed
            }
        }

        this.toggleGridPopup();
        return exportAppealGridToExcel(requestData);
    };

    renderPopupButton (data, id) {
        let predefined = false;
        data.forEach(item => (item.value === id ? predefined = item.predefined : null));
        return predefined;
    };

    onClickDelete () {
        this.toggleGridPopup();
        this.props.openModal('delete');
    };

    render () {
        const {data, id, t} = this.props;
        const {gridPopupIsOpen, onRequestExportAllStatus} = this.state;

        const shouldDisplayExportSelected = this.shouldDisplayExportSelected();

        return (
            <header className='header-content'>
                <div className={styles.headerLeft}>
                    {this.filterName(data, id)}
                </div>
                <div className='actions'>
                    <div className='btn-add' onClick={this.toggleGridPopup}>
                        <i className='icon-kebab-vert'/>
                    </div>
                    {
                        gridPopupIsOpen &&
                        <Popup
                            onClickOutside={this.toggleGridPopup}
                            place='grid-popup'
                        >
                            <button className='popup-link' onClick={this.openNewFilter}>
                                {t('filters.createNewFilter')}
                            </button>
                            <button className='popup-link' onClick={this.openFilterParams}>
                                {t('filters.parametersFilter')}
                            </button>
                            {
                                !this.renderPopupButton(data, id) &&
                                <button className='popup-link' onClick={this.onClickDelete}>
                                    {t('filters.removeFilter')}
                                </button>
                            }
                            <button className='popup-link' onClick={this.exportAllColumnsToExcel}>
                                {t('filters.exportAllColumns')}
                            </button>
                            {/*<button className='popup-link' onClick={this.exportToCSVAll}>*/}
                                {/*{t('filters.exportToCsvAll')}*/}
                            {/*</button>*/}
                            <button className={cx('popup-link',{'disabled' : onRequestExportAllStatus})} onClick={this.exportToXlsAll}>
                                {t('filters.exportToXlsAll')}
                            </button>
                            {
                                shouldDisplayExportSelected &&
                                <button className='popup-link' onClick={this.exportSelectedToExcel}>
                                    {t('filters.exportSelected')}
                                </button>
                            }
                        </Popup>
                    }
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    data: PropTypes.array,
    openFilterParams: PropTypes.func,
    closeFilterParams: PropTypes.func,
    openNewFilterParams: PropTypes.func,
    closeNewFilterParams: PropTypes.func,
    getInfoFilterSuppose: PropTypes.func
};

export default Header;
