import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import { translate } from 'react-i18next';

// props = { children, className, onFocus, onRemove, option, optionIndex, removeValue, selectValue, inputValue }
const RemovableOption = props => {
  return (
    <div className={cx(props.className, 'removable-option-container')}>
      <div className={'removable-option-value'} onClick={() => props.selectValue(props.option)}>{props.option.label}</div>
      <i className={cx('icon-close', 'remove-option-icon')} onClick={event => props.onRemove(event, props.option)}/>
    </div>
  );
};

@translate()
class ComboBoxRemovable extends React.Component {
  constructor() {
    super();
    this.comboBoxRef = React.createRef();
  }

  arrowRender = values => {
    if (values.isOpen) {
      return <i className='icon-up' />;
    }

    return <i className='icon-down' />;
  };

  /**
   * Keep created options in local state or they will be lost
   * on re-render
   */
  onNewOptionClick = (option) => {
    const { props, select } = this.comboBoxRef.current;

    select.selectValue(option);
    this.props.onCreateOption(option);
  };

  onCloseIconClick = (event, option) => {
    const { select } = this.comboBoxRef.current;

    select.removeValue(option);
    this.props.onRemoveOption(option);
  };

  render() {
    const { label, meta, input, options, multi, newOptionCreator, isValidNewOption, value, onChange, name, t, placeholder, noResultsText, onInputChange, error } = this.props;
    // const { name, value, onBlur, onChange, onFocus } = input;

    const transformedValue = transformValue(input && input.value || value, options, multi);

    return (
      <div className='input-element' >
        <div className='input-label'>{label}</div>
        <Select.Creatable
          className={cx('container-comboBox', (error || (meta && meta.touched && meta.error)) && 'input-field__error')}
          arrowRenderer={this.arrowRender}
          isValidNewOption={isValidNewOption}
          multi={multi}
          name={input && input.name || name}
          newOptionCreator={newOptionCreator}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          options={options}
          placeholder={placeholder}
          noResultsText={noResultsText}
          onChange={multi
            ? multiChangeHandler(input && input.onChange || onChange)
            : singleChangeHandler(input && input.onChange || onChange)
          }

          optionComponent={props => <RemovableOption {...props} onRemove={this.onCloseIconClick}/>}
          onInputChange={onInputChange}
          onBlur={() => input && input.onBlur(value)}
          onFocus={input && input.onFocus}
          onNewOptionClick={this.onNewOptionClick}
          promptTextCreator={(name) => `${t('filters.createNewOption')} "${name}"`}
          value={transformedValue}
          valueKey='value'
          ref={this.comboBoxRef}
          {...meta}
        />

      </div>
    );
  }
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(option) {
    func && func(option);
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func && func(values);
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === 'string') return [];

  const filteredOptions = options.filter(option => {
    return multi
      ? value.indexOf(option.value) !== -1
      : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}

ComboBoxRemovable.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  input: PropTypes.object,
  multi: PropTypes.bool,
  meta: PropTypes.object,
  onRemoveOption: PropTypes.func,
  onCreateOption: PropTypes.func,
  isValidNewOption: PropTypes.func,
  onInputChange: PropTypes.func,
  error: PropTypes.string,
};

export default ComboBoxRemovable;

