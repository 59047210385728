import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';

import { reactDebounce } from './helpers';
import { ADD_FILTER_DATA_TIMEOUT } from './gridConfig';
import { FILTER_INPUT_ID } from './gridConfig';


export default class ControlHeader extends Component {

  componentDidMount() {
    const { filter } = this.props.params;
    if (filter) document.getElementById(FILTER_INPUT_ID).focus();
  }

  addFilterData = () => {
    const { strings } = this.props;
    const { onChange, ...rest } = this.props.params.filter;
    const filterProps = {
      name: 'filter',
      placeholder: strings.search,
      id: FILTER_INPUT_ID,
      ...rest
    };
    if (onChange) {
      filterProps.onChange = reactDebounce(e => onChange({ query: e.target.value }), ADD_FILTER_DATA_TIMEOUT);
    }

    return <InputField {...filterProps} />;
  };

  addFilterFields = () => {
    const { strings } = this.props;
    const data = ['all_fields'].concat(this.props.params.limitFields);

    return (
      <ComboBox
        name="dfh"
        disabled={false}
        defaultValue="all_fields"
        data={data}
        onChange={this.props.filterFields}
        placeholder={strings.select}
      />
    );
  };

  addUpload = () => {
    return (
      <div className="file-upload">
        <i className="grid-icon icon-upload"/>
        <input type="file" onChange={this.props.params.upload}/>
      </div>
    );
  };

  edit = () => {
    const { params: { edit }, selected } = this.props;
    typeof edit === 'function' ? edit(selected) : undefined;
  };

  editButton = () => {
    const { strings } = this.props;
    return (
      <div className="add-button" onClick={this.edit}>
        {strings.edit}
      </div>
    );
  };

  addButton = () => {
    const { strings, params: { add } } = this.props;
    return (
      <div className="add-button" onClick={typeof add === 'function' ? add : undefined}>
        <i className="grid-icon icon-plus"/>
        {strings.add}
      </div>
    );
  };

  groupRemove = () => {
    return (
      <div className="group-remove">
        <i className="grid-icon icon-delete"/>
      </div>
    );
  };

  render = () => {
    const { params, selected } = this.props;
    if (!params.filter && !params.limitFields && !params.edit && !params.upload && !params.add) return null;

    return (
      <div className="settings-container">
        <div className="left-settings">
          {params.filter && this.addFilterData()}
          {params.limitFields && this.addFilterFields()}
        </div>
        <div className="right-settings">
          {params.groupsRemove && this.groupRemove()}
          {params.edit && selected && this.editButton()}
          {params.upload && this.addUpload()}
          {params.add && <div className="line-separator"/>}
          {params.add && this.addButton()}
        </div>
      </div>
    );
  };
}

ControlHeader.propTypes = {
  params: PropTypes.object,
  selected: PropTypes.object,
  filterData: PropTypes.func,
  filterFields: PropTypes.func
  // strings: PropTypes.array
};

