import { emailValidation, phoneValidation, fieldLevelValidation, requiredFieldsValidation } from 'util/form';


const rules = {
    email: emailValidation,
    phone: phoneValidation,
};

function validate (values, props) {
    const errors = {};
    requiredFieldsValidation(values, props.config, errors);
    fieldLevelValidation(values, rules, errors);

    return errors;
}

export default validate;
