import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup';
import { translate } from 'react-i18next';

@translate()
class TabCloseMenu extends React.Component {
    handleRemoveTab = (e) => {
        const { removeTab, val } = this.props;
        removeTab(e, val);
    };

    render() {
        const { removeAll, removeAllTabsExceptCurrent, val, place, data, t } = this.props;
        return (
            <Fragment>
                <span className='icon-close hidden-tabs-close' onClick={this.handleRemoveTab}/>
                {
                    data.showCloseTabsMenu === val.id &&
                    <Popup place={`hidden-menu ${place}`}>
                        <div
                            className='hidden-menu-item'
                            onClick={(e) => removeAllTabsExceptCurrent(e, val)}
                        >
                            {t('deleteAllTabsExceptOne')}
                        </div>
                        <div
                            className='hidden-menu-item'
                            onClick={(e) => removeAll(e, val)}
                        >
                            {t('deleteAllTabs')}
                        </div>
                    </Popup>
                }
            </Fragment>
        );
    }
}

TabCloseMenu.HiddenTabs = {
    // from withRouter HOC
    history: PropTypes.object.isRequired,
    // ownProps
    data: PropTypes.object.isRequired,
    tabs: PropTypes.array,
    removeTab: PropTypes.func,
    removeAll: PropTypes.func,
    place: PropTypes.string,
    removeAllTabsExceptCurrent: PropTypes.func,
    hideTabs: PropTypes.bool,
    val: PropTypes.object,
    showMenu: PropTypes.func.isRequired
};

export default TabCloseMenu;
