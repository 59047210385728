import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
import TextArea from 'components/Common/TextArea';
import isNull from 'lodash/isNull';
import moment from 'moment';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { UABET } from '../config/conf';

export const KnowledgeItemTypes = {
    LEAF: 'leaf',
    FOLDER: 'folder',
};

export const StreetModes = {
    MANUAL: 'manual',
    AUTO: 'auto',
};

export const FieldTypes = {
    INPUT: 'input',
    ARRAY: 'array',
    SELECT: 'select',
    SELECT_POPUP_ARRAY: 'selectPopupArray',
    DATE: 'date',
    GROUP: 'group',
    ADDRESS: 'address',
};

export const languages = [
    { label: 'Українська', value: 'UA' },
    { label: 'English', value: 'EN' },
    { label: 'Русский', value: 'RU' },
    { label: 'Не визначено', value: 'NA' },
];


export const createNewContactPersonConfig = [
    {
        name: 'lastName', label: 'Прізвище', component: InputField, maxLength: 50,
    },
    {
        name: 'firstName',
        label: 'Ім\'я',
        component: InputField,
        required: true,
        maxLength: 50,
        errorPlaceholder: true,
    },
    { name: 'patronymic', label: 'По батькові', component: InputField, maxLength: 50 },
    { name: 'workPhone', label: 'Телефон', component: InputField, required: true, maxLength: 19 },
    { name: 'email', label: 'Email', component: InputField, maxLength: 255 },
    { name: 'issues', label: 'Зона відповідальності', component: ComboBox, required: true, multi: true }
];

const addressConfig = [
    {
        name: 'settlement',
        label: 'Населений пункт',
        component: ComboBox,
        required: true,
        searchable: true,
    },
    {
        name: 'street',
        label: 'Вулиця',
        component: ComboBox,
        required: true,
        searchable: true,
		inputProps: { maxLength: 255 },
		maxLength: 255
    },
    {
        name: 'buildingNumber',
        label: 'Будинок',
        component: ComboBox,
        required: true,
        searchable: true,
        inputProps: { maxLength: 16 },
		maxLength: 16
    },
    {
        name: 'buildingSecondNumber',
        label: 'Корпус',
        component: InputField,
        errorPlaceholder: true,
        maxLength: 32,
    },
    {
        name: 'apartmentNumber',
        label: 'Квартира/Офіс',
        component: InputField,
        errorPlaceholder: true,
        maxLength: 32,
    },
    {
        name: 'zipCode',
        label: 'Індекс',
        component: ComboBox,
        required: true,
        searchable: true,
        inputProps: { maxLength: 5 },
		maxLength: 5
    },
    {
        name: 'description',
        label: 'Коментар',
        component: TextArea,
        autosize: true,
        maxLength: 500,
        textAreaClassName: 'description',
    },
];

const typeAddress = { name: 'type', label: 'Тип адреси', component: ComboBox, required: true };


export const appealHistoryFilters = [
    { label: 'Уся історія', value: 0 },
    { label: 'Коментарі', value: 1 },
    { label: 'Мої зміни', value: 2 },
];

export const advancedSearchClientFormConfig = [
    {
        name: 'name', label: 'ПІБ/Назва організації', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
    },
    {
        name: 'address', label: 'Адреса', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
    },
    {
        name: 'phone', label: 'Телефон заявника', type: 'input', placeholder: 'Не вибрано', maxLength: '15',
    },
    {
        name: 'email', label: 'Email', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
    },
    // { name: 'externamId', label: 'Номер зовнішньої системи', type: 'input', placeholder: 'Не вибрано' }
];

const categoryFormatter = value => {
    if (value && Array.isArray(value)) {
        return value.map(category => (isNull(category.id) ? '0' : category.id.toString()));
    }
    return [];
};

const dateFormatter = value => (value ? moment(new Date(value)) : '');

let customerFormConfigCommon = [
    {
        type: 'select', name: 'lang_code', field: 'lang_code', label: 'langName', options: 'languages',
    },
    {
        type: 'date', name: 'birthDate', field: 'party.birthDate', label: 'birthDate', popperPlacement:'bottom-end',
    },
    {
        type: 'select', name: 'gender', field: 'party.gender', label: 'gender', options: 'gender',
    },
    {
        type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
    },
    // { type: 'array', name: 'mobilePhone', field: 'contacts.mobilePhone', label: 'mobilePhone' },
    {
        type: 'array', name: 'homePhone', field: 'contacts.homePhone', label: 'homePhone', required: true,
    },
    // { type: 'array', name: 'workPhone', field: 'contacts.workPhone', label: 'workPhone' },
    {
        type: 'array', name: 'email', field: 'contacts.email', label: 'email',
    },
    {
        type: 'select',
        name: 'socialStatusName',
        field: 'party.socialStatusName',
        valueField: 'party.socialStatusId',
        label: 'socialStatusName',
        options: 'socialStatusName',
    },
    {
        type: 'selectPopupArray',
        name: 'custCategory',
        field: 'party.cust_category',
        label: 'custCategory',
        options: 'custCategory',
        formatter: categoryFormatter,
    },
    {
        type: 'date', name: 'since', field: 'since', label: 'since', disabled: true, formatter: dateFormatter,
    },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];

let customerEntityFormConfigCommon = [
    {
        type: 'select',
        name: 'businessType',
        field: 'party.businessType',
        label: 'businessType',
        options: 'businessType',
    },
    {
        type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
    },
    {
        type: 'input', name: 'institutionId', field: 'party.institutionId', label: 'institutionId',
    },
    {
        type: 'select',
        name: 'ownershipType',
        field: 'party.ownershipType',
        label: 'ownershipType',
        options: 'ownershipType',
    },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];

export const personFormConfig = [
    {
        type: 'select', name: 'lang_code', field: 'lang_code', label: 'langName', options: 'languages',
    },
    {
        type: 'array', name: 'email', field: 'email', label: 'email',
    },
    {
        name: 'issues',
        label: 'issues',
        field: 'issues',
        multi: true,
        type: 'select',
        errorPlaceholder: true,
        submitOnBlur: true,
        options: 'contactIssue'
    },
    // {
    //     type: 'array', name: 'mobilePhone', field: 'mobilePhone', label: 'mobilePhone',
    // },
    // {
    //     type: 'array', name: 'homePhone', field: 'homePhone', label: 'homePhone', required: true,
    // },
    {
        type: 'array', name: 'workPhone', field: 'workPhone', label: 'workPhone', required: true,
    },
    // {
    //     type: 'array', name: 'utkPhone', field: 'utkPhone', label: 'utkPhone',
    // },
    // {
    //     type: 'array', name: 'fax', field: 'fax', label: 'fax',
    // },
    // {
    //     type: 'array', name: 'organizationPhone', field: 'organizationPhone', label: 'organizationPhone',
    // },
    // {
    //     type: 'array', name: 'another', field: 'another', label: 'another',
    // },

    {
        type: 'date',
        name: 'validFrom',
        field: 'validFrom',
        label: 'validFrom',
        disabled: true,
        formatter: dateFormatter,
    },
    // { type: 'date', name: 'validTill', field: 'validTill', label: 'validTill', disabled: true,  formatter: dateFormatter },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];


export const appealQualityConfig = [
    {
        type: 'radio',
        name: 'question1',
        title: 'Запрос не вопрос',
        list: [{ label: 'lably', value: 0, checked: true }, { label: 'lably2', value: 10 }],
    },
    {
        type: 'star', name: 'question2', title: 'Разнообразни пытання', numbers: 10,
    },
    {
        type: 'star', name: 'question3', title: 'Други пытання', numbers: 5,
    },
    {
        type: 'star', name: 'question4', title: 'Трете питання', numbers: 8,
    },
    {
        type: 'radio',
        name: 'question5',
        title: 'Вопрос на запрос',
        list: [{ label: 'не пью', value: 10 }, { label: 'налью', value: 0, checked: true }],
    },
];

/**  Call constants  * */

export const SseEvents = {
    RINGING: 'ringing',
    ANSWER: 'answer',
    HANGUP: 'hangup',
    NOT_READY: 'user_state.not_ready',
    READY: 'user_state.ready',
    LOGOUT: 'user_state.logout',
    RESERVED: 'user_state.reserved',
    TALKING: 'user_state.talking',
    UNAVAILABLE: 'unavailable',
    RESTORED: 'restored',
    WORK: 'user_state.work',
    WORK_READY: 'user_state.work_ready',
    HOLD: 'user_state.hold',
    DISCONNECTION: 'server.DISCONNECTION',
    ALIVE: 'server.alive',
};

export const CallStates = {
    DEFAULT: 'default',
    RINGING: 'ringing',
    TALKING: 'talking',
    WAITING: 'waiting',
    ALIVE: 'alive',
    WAITING_WITH_COUNTER: 'waiting_with_counter',
};

export const HoldStatuses = {
    ON: 'on',
    OFF: 'off',
    DISABLED: 'disabled',
};

export const playPauseIcons = {
    [HoldStatuses.ON]: faPlay,
    [HoldStatuses.OFF]: faPause,
};

/** **************** */

export const HistoryItemTypes = {
    CHANGE: 'change',
    EMAIL: 'email',
    COMMENT: 'comment',
    NOTIFICATION: 'notification',
    CALL: 'call',
    PRECRM: 'precrm',
    SELF_CARE: 'self_care',
};

export const EmailTypes = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
};

export const FileTypes = {
    HTML: 'html',
    PDF: 'pdf',
    DOCX: 'docx',
    XLS: 'xls'
};

export const NotificationTypes = {
    NEW_APPEAL: 'NEW_APPEAL',
    APPEAL_CHANGE_SUCCESS: 'APPEAL_CHANGE_SUCCESS',
    APPEAL_CHANGE_FAILURE: 'APPEAL_CHANGE_FAILURE',
    CURRENT_APPEAL_CHANGE: 'CURRENT_APPEAL_CHANGE',
    NEW_COMMENT: 'NEW_COMMENT',
    HTTP_ERROR: 'HTTP_ERROR',
    SUCCESS: 'SUCCESS',
};

export const knowledgeHeaderMenuOptions = [
    { title: 'Move', value: 'move' },
    { title: 'Copy', value: 'copy' },
    { title: 'Remove', value: 'remove' },
];

export const editorToolbar = {
	defaultLanguage:'uk',
	language:'uk',
	extraPlugins: 'colorbutton,colordialog',
	removeButtons: 'Anchor,Styles',
	toolbarGroups: [{
		"name": "basicstyles",
		"groups": ["basicstyles"]
	},
		{
			"name": "links",
			"groups": ["links"]
		},
		{
			"name": "paragraph",
			"groups": ["list", "blocks"]
		},
		{
			"name": "document",
			"groups": ["mode"]
		},
		{
			"name": "insert",
			"groups": ["insert"]
		},
		{ name: 'colors' },
		{ name: 'tools' },
		{ name: 'others' }
	],
};

export const MAX_TABS_COUNT = 100;
export const MAX_TABS_IN_HISTORY = 5;
export const MAX_SHOW_HIDDEN_TABS = 8;
export const FORM_SAVE_CHANGES_INTERVAL = 500;
export const FORM_SAVE_CHANGES_LIMIT = 5;
export const MAX_FILE_SIZE = 25;
export const DEFAULT_NOTIFICATION_TIMEOUT = 2000;

export const CustomerTypes = {
    NATURAL_PERSON: 'individual',
    ORGANIZATION: 'organization',
};

export const customerTypeOptions = [
    { label: 'Фізична особа', value: CustomerTypes.NATURAL_PERSON },
    { label: 'Юридична особа', value: CustomerTypes.ORGANIZATION },
];

export const KeyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CONTROL: 17,
    ALT: 18,
    ESCAPE: 27,
};

export const settingsHeaderMenuOptions = [
    { title: 'sync', value: 'sync' },
    { title: 'parametersFilter', value: 'parametersFilter' },
];

export const settingsHeaderMenuDomainOptions = [
    { title: 'sync', value: 'sync' },
];

if (ENVIRONMENT === UABET) {
    customerFormConfigCommon = [
        {
            type: 'select', name: 'lang_code', field: 'lang_code', label: 'langName', options: 'languages',
        },
        {
            type: 'date', name: 'birthDate', field: 'party.birthDate', label: 'birthDate', popperPlacement:'bottom-end',
        },
        {
            type: 'select', name: 'gender', field: 'party.gender', label: 'gender', options: 'gender',
        },
        {
            type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
        },
        {
            type: 'array', name: 'homePhone', field: 'contacts.homePhone', label: 'homePhone', required: true,
        },
        {
            type: 'array', name: 'email', field: 'contacts.email', label: 'email',
        },
        {
            type: 'input', name: 'externalId', field: 'externalId', label: 'externalId', maxLength: 255
        },
        {
            type: 'select', name: 'status', field: 'status', label: 'customerStatus', options: 'customerStatus',
        },
        {
            type: 'date', name: 'externalDate', field: 'externalDate', label: 'externalDate',
        },
        {
            type: 'date', name: 'since', field: 'since', label: 'since', disabled: true,
        },
        {
            type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
        },
    ];
    
    
    customerEntityFormConfigCommon = [
        {
            type: 'select',
            name: 'businessType',
            field: 'party.businessType',
            label: 'businessType',
            options: 'businessType',
        },
        {
            type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
        },
        {
            type: 'input', name: 'institutionId', field: 'party.institutionId', label: 'institutionId',
        },
        {
            type: 'select',
            name: 'ownershipType',
            field: 'party.ownershipType',
            label: 'ownershipType',
            options: 'ownershipType',
        },
        {
            type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
        },
        {
            type: 'input', name: 'externalId', field: 'externalId', label: 'externalId', maxLength: 255,
        },
        {
            type: 'select', name: 'status', field: 'status', label: 'customerStatus', options: 'customerStatus',
        },
        {
            type: 'date', name: 'externalDate', field: 'externalDate', label: 'externalDate', formatter: dateFormatter,
        },
    ];
}

export const customerAddressFormConfig = addressConfig;

export const customerAddressFormConfigWithType = [ typeAddress, ...addressConfig ];

export const customerFormConfig = customerFormConfigCommon;

export const customerEntityFormConfig = customerEntityFormConfigCommon;

export const mailEditorToolbar = {
	options: ['fontFamily', 'blockType', 'fontSize', 'inline', 'textAlign', 'list'],
	fontFamily: {
		options: ['Comic Sans MS', 'Garamond', 'Georgia', 'Tahoma', 'Trebuchet MS', 'Verdana'],
		className: 'draftBarDropdown',
	},
	blockType: {
		options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Section'],
		className: 'draftBarDropdown',
	},
	fontSize: {
		className: 'draftBarDropdown'
	},
	inline: {
		options: ['bold', 'italic', 'underline', 'strikethrough'],
		className: 'draftBarIcon',
	},
	textAlign: {
		inDropdown: true,
		options: ['left', 'center', 'right'],
		className: 'draftBarIcon',
	},
	list: {
		inDropdown: false,
		options: ['ordered', 'unordered', 'indent', 'outdent'],
		className: 'draftBarIcon',
	},
};
