import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import CustomerNameBlock from 'components/CustomerPage/Parameters/components/CustomerNameBlock/CustomerNameBlock';
import Loader from 'components/Loader/index';
import CustomerParameters from 'components/CustomerPage/Parameters/index';
import PersonParameters from 'components/PersonPage/Parameters/index';
import styles from 'styles/modules/appealOperatePage.module.scss';
import {
    getContactPerson,
    clearApplicantsState,
    clearContactPersonsList,
    clearCustomerState,
    clearContactPersonState,
    getCustomer,
} from 'actions/customer';
import { isOrganization, extractAppealFromState, extractCustomerFromState } from 'helpers';


function mapStateToProps (state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const customer = extractCustomerFromState(state, appeal.currentAppeal.customerId);
    
    return {
        id,
        appeal: appeal.currentAppeal,
        appeals: state.appeal.appeals,
        
        customer: customer.currentCustomer,
        modificationCount: customer.modificationCount,
        contactPersonModificationCount: customer.contactPersonModificationCount,
        customerType: _.get(customer, 'currentCustomer.party.partyType'),
    
        contactPersons: customer.contactPersons,
        loadingPersons: customer.loadingPersons,
    
        currentContactPerson: customer.currentContactPerson,
        edited: customer.edited,
        editedPerson: customer.editedPerson,
    }
}

const mapDispatchToProps = {
    getContactPerson,
    clearApplicantsState,
    clearContactPersonsList,
    clearCustomerState,
    clearContactPersonState,
    getCustomer,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CustomerInfo extends React.Component {
    
    constructor (props) {
        super(props);
        this.state = {
            activePerson: true,
            activeOrganization: false
        };
        
        this.contactBlock = React.createRef();
        this.organizationBlock = React.createRef();
        this.showPerson = this.showPerson.bind(this);
        this.showOrganization = this.showOrganization.bind(this);
    }
    
    componentDidUpdate (prevProps) {
        const { appeal } = this.props;
        
        if (isOrganization(this.props.customerType) && prevProps.loadingPersons && !this.props.loadingPersons && this.props.contactPersons.length === 0) {
            this.props.clearCustomerState();
            this.props.clearContactPersonState();
        }
        
        if (this.props.modificationCount !== prevProps.modificationCount) {
            this.props.getCustomer({ customerId: appeal.customerId }, appeal.customerId, true);
        }
        
        if (this.props.contactPersonModificationCount !== prevProps.contactPersonModificationCount) {
            this.props.getContactPerson({ id: appeal.contactPersonId }, appeal.customerId);
        }
        
    }
    
    showPerson () {
        if (this.organizationBlock.current) {
            this.organizationBlock.current.scrollTop = 0;
        }
        this.setState({ activePerson: true, activeOrganization: false });
    };
    
    showOrganization () {
        if (this.contactBlock.current) {
            this.contactBlock.current.scrollTop = 0;
        }
        this.setState({ activePerson: false, activeOrganization: true });
    };
    
    render () {
        const { customer, appeal, appeals, customerType, currentContactPerson } = this.props;
        if ([0, 2].includes(appeal.restriction.customerId)) return null;
        
        const paramsClassName = cx(styles.parameters, { 'empty': isEmpty(customer) });
        
        const isOrganizationType = isOrganization(customerType);
        
        const containerClassName = cx(styles.parametersWrapper, { 'loading': isEmpty(customer) });
        const contactPersonBlockClassName = cx(styles.personParameters, {
            'active': this.state.activePerson,
            'organization': true
        });
        const customerBlockClassName = cx(styles.customerParameters, {
            'active': this.state.activeOrganization,
            'organization': isOrganizationType
        });
        
        return (
            <div className={containerClassName}>
                {
                    isOrganizationType &&
                    <div ref={this.contactBlock} className={contactPersonBlockClassName}>
                        {
                            isEmpty(currentContactPerson)
                                ? <Loader />
                                : <Fragment>
                                    <CustomerNameBlock
                                        customer={currentContactPerson}
                                        onClick={this.showPerson}
                                        isEditRestricted={appeal.restriction.customerId === 1}
                                        isCloseRestrictedButton={appeal.restriction.remove_customer === 3}
                                        disabled={!this.state.activePerson}
                                        customerId={appeal.customerId}
                                        appeals={appeals}
                                        isContactPerson
                                    />
                                    <PersonParameters
                                        id={appeal.customerId}
                                        readOnly={appeal.restriction.customerId === 1}
                                    />
                                </Fragment>
                        }
                    </div>
                }
                <div ref={this.organizationBlock} className={customerBlockClassName}>
                    <div>
                        <CustomerNameBlock
                            customer={customer}
                            onClick={this.showOrganization}
                            isEditRestricted={appeal.restriction.customerId === 1}
                            isCloseRestrictedButton={appeal.restriction.remove_customer === 3}
                            disabled={!this.state.activeOrganization && isOrganizationType}
                            customerId={appeal.customerId}
                            appeals={appeals}
                        />
                        <div className={paramsClassName}>
                            <CustomerParameters
                                id={appeal.customerId}
                                readOnly={appeal.restriction.customerId === 1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerInfo;
