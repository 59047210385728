import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../common.scss';
import cx from 'classnames';
import { translate } from 'react-i18next';
import CalendarContainer from './CalendarContainer';
import ErrorBlock from 'components/CustomerPage/Parameters/components/ErrorBlock.js';


@translate()
class DateTimePicker extends React.Component {
    state = {
        showTimeSelect: false,
        left: undefined,
        top: undefined,
    };
    
    componentDidUpdate (prevProps, prevState) {
        if (!prevState.showTimeSelect && this.state.showTimeSelect) {
            this.calendar.setOpen(true);
        } else if (prevState.showTimeSelect && !this.state.showTimeSelect) {
            this.calendar.setOpen(false);
            this.calendar.cancelFocusInput();
        }
    };
    
    handleChange = (date) => {
        const { onlyDate, input } = this.props;
        input.onChange(date);
        
        if (onlyDate) {
        } else {
            this.setState({ showTimeSelect: !this.state.showTimeSelect });
        }
    };
    
    onClickOutside = (e) => {
        this.calendar.setOpen(false);
        this.calendar.cancelFocusInput();
        this.setState({ showTimeSelect: false });
    };
    
    onKeyDown = (e) => {
        if (e.keyCode === 27) {
            this.onClickOutside();
        }
    };
    
    onChangeRaw = (date) => {
        const { value } = date.currentTarget;
        const { input } = this.props;
        date.currentTarget.value = value;
        input.onChange(value ? moment(value) : value);
    };
    
    onBlur = () => {
        const { input, changeOnBlur } = this.props;
        const value = input.value;
        if (changeOnBlur) {
            changeOnBlur(moment(value), input.name);
        }
        input.onBlur();
        this.setState({ showTimeSelect: false });
        this.calendar.setOpen(false);
        this.calendar.cancelFocusInput();
    };
    
    saveRef = (node) => {
        this.calendar = node;
    };
    
    render () {
        const {
            onlyDate, label, disabled, meta, required, input, t, popperPlacement, popperClassName,
            customInput, dayFormat = 'DD.MM.YYYY', dayAndTimeFormat = 'DD.MM.YYYY HH:mm', inputClass, maxDateCurrent,
            ...rest
        } = this.props;
        
        const elementCls = cx('input-element datepicker', {
            'error': meta && meta.touched && (meta.error || meta.warning)
        });
        
        const inputClassName = inputClass || cx('input-field', {
            'input-field__error': meta && meta.touched && (meta.error || meta.warning)
        });
        
        const popperCls = cx('datepicker-popper datepicker-portal', popperClassName);
        
        const selectedItem = (moment(this.props.input.value).isValid() && this.props.input.value) ? moment(this.props.input.value) : null;
        
        const showError = meta && meta.touched && (meta.error || meta.warning);
        return (
            <div id={rest.id} className={elementCls} ref={(node) => this.calendarWrapper = node}>
                {label &&
                <div className='input-label'>
                    {t(`customerInfoLabel.${label}`, { defaultValue: label })}
                    {required && <span className='required-field'>*</span>}
                </div>
                }
                <DatePicker
                    className={inputClassName}
                    showTimeSelect={this.state.showTimeSelect}
                    showTimeSelectOnly={this.state.showTimeSelect}
                    dateFormat={onlyDate ? dayFormat : dayAndTimeFormat}
                    timeFormat='HH:mm'
                    selected={selectedItem}
                    ref={this.saveRef}
                    onClickOutside={this.onClickOutside}
                    popperClassName={popperCls}
                    popperPlacement={popperPlacement || 'bottom-end'}
                    calendarClassName='calendar'
                    onKeyDown={this.onKeyDown}
                    timeCaption='час'
                    timeIntervals={30}
                    disabled={disabled}
                    popperContainer={CalendarContainer}
                    onChangeRaw={this.onChangeRaw}
                    name={input.name}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    autoComplete='off'
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={108}
                    customInput={customInput}
                    maxDate={maxDateCurrent ? moment() : null}
                />
    
                { showError && <ErrorBlock error={meta.error} /> }
            </div>
        );
    }
}

DateTimePicker.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool
};

export default DateTimePicker;
