import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

const NewAppealNote = ({ appealId, customerId, subject, description, date, userName }) => (
  <div className={styles.newAppealNote}>
    <i className='icon-call2'/>

    <header>Надійшло нове звернення</header>

    <div>
      <Link className={styles.appealID} to={`/appeals/${appealId}/knowledge_base`}>ID {appealId}</Link>
      <span> {subject}</span>
      <p>Опис звернення: {description}</p>

      <span className={styles.author}>{moment(date).format('DD/MM/YY HH:mm')}, від {userName}</span>
    </div>
  </div>
);

// NewAppealNote.defaultProps = {
//   appealId: 123889,
//   customerId: 'no_customer',
//   subject: 'Тестова тема звернення',
//   description: 'Ваше звернення дуже важливо для нас але воно не буде опрацьовано найближчим часом.',
//   date: 1530259500000,
//   userName: 'Василь Іванов'
// };

NewAppealNote.propTypes = {
  appealId: PropTypes.number,
  customerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  subject: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.number,
  userName: PropTypes.string
};

export default NewAppealNote;
