import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { CustomerTypes } from 'constants/index';
import { reduxFormWrapper } from 'helpers';
import FormWrapper from '../FormWrapper';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import validate from './validate';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';

export const naturalPersonConfig = [
    { name: 'lastName', label: 'Прізвище', component: InputField, maxLength: 50 },
    { name: 'firstName', label: 'Ім\'я', component: InputField, required: true, maxLength: 50 },
    { name: 'patronymic', label: 'По батькові', component: InputField, maxLength: 50 },
    { name: 'phone', label: 'Телефон', component: InputField, required: true, maxLength: 19, errorPlaceholder: true },
    { name: 'email', label: 'Email', component: InputField, maxLength: 255,  errorPlaceholder: true }
];

export const organization = [
    ...naturalPersonConfig,
    {
        name: 'issues',
        label: 'Зона відповідальності',
        component: ComboBox,
        required: true,
        multi: true,
        errorPlaceholder: true,
        placeholder: 'Не вибрано'
    }
];

const getConfig = customerType => {
    const configs = {
        [CustomerTypes.NATURAL_PERSON]: naturalPersonConfig,
        [CustomerTypes.ORGANIZATION]: organization
    };

    return configs[customerType] || [];
};

const mapStateToProps = (state, ownProps) => ({
    config: getConfig(ownProps.customerType),
    phoneNumber: state.call.incomePhoneNumber
});

@translate()
@connect(mapStateToProps)
@reduxFormWrapper({ form: 'person-form', validate })
class PersonForm extends React.Component {

    constructor(props) {
        super(props);
        this.issues = this.convertDictionaryObject(props.t('dictionary:contactIssue', { returnObjects: true }));
    }

    componentDidMount() {
        this.setPhoneNumberIfCallExists();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pristine && prevProps.pristine) {
            this.props.enableWarning({ personForm: true });
        } else if (this.props.pristine && !prevProps.pristine) {
            this.props.enableWarning({ personForm: false });
        }
    }

    setPhoneNumberIfCallExists = () => {
        if (this.props.phoneNumber) {
            this.props.change('phone', this.props.phoneNumber);
        }
    };

    convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
        value: prop,
        label: value
    }));

    getFieldPropsWithOptions = fieldProps => {
        switch (fieldProps.name) {
        case 'issues':
            return { ...fieldProps, options: this.issues.slice(1) };

        default:
            return fieldProps;
        }
    };

    renderField = fieldConfig => {
        return (
            <Field key={fieldConfig.name} {...this.getFieldPropsWithOptions(fieldConfig)} />
        );
    };

    getTitle = () => {
        const titles = {
            [CustomerTypes.NATURAL_PERSON]: 'Особисті дані фізичної особи',
            [CustomerTypes.ORGANIZATION]: 'Контактна особа'
        };

        return titles[this.props.customerType] || '';
    };

    render() {
        const { handleSubmit, customerType, config } = this.props;
        return (
            <FormWrapper
                title={this.getTitle()}
                onSubmit={handleSubmit}
                customerType={customerType}
            >
                {config.map(this.renderField)}
            </FormWrapper>
        );
    }
}

PersonForm.propTypes = {
    customerType: PropTypes.string,
    onSubmit: PropTypes.func,
    phoneNumber: PropTypes.string
};

export default PersonForm;
