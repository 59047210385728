const validate = (values) => {
  const errors = {};
  const name = (values.name || '').trim();
  const firstName = (values.firstName || '').trim();
  
  if (!name) {
    errors.name = "Обов'язкове поле";
  }

  if (!firstName) {
    errors.firstName = "Обов'язкове поле";
  }

  return errors;
};

export default validate;
