import React from 'react';
import PropTypes from 'prop-types';
import { KeyCodes } from 'constants/index';
import ModalPortal from 'components/ModalPortal';
import modalStyles from 'styles/modules/modal.module.scss';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import { translate } from 'react-i18next';
import OptionTypeSearchBlock from './OptionTypeSearchBlock';
import OptionTypesTree from './OptionTypesTree';
import cx from 'classnames';

@translate()
class OptionTypesTreeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            submittedQuery: '',
            selectedNode: null
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('click', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleKeyPress);
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress(event) {
        if (event.keyCode === KeyCodes.ESCAPE || event.target.classList.contains('modal-window-overlay')) {
            this.props.onClose();
        }
    };

    onQueryChange(event) {
        this.setState({ query: event.target.value });
    }

    onSearchSubmit() {
        if (this.state.query !== this.state.submittedQuery) {
            this.setState(state => ({ submittedQuery: state.query }));
        }
    }

    onChange(newSelectedNode) {
        this.setState({ selectedNodes : newSelectedNode });
    }

    onSubmitClick() {
        const { selectedNodes } = this.state;
        this.props.onSubmit(selectedNodes);
    }

    render() {
        const { onClose, t, multi } = this.props;
        const { query, submittedQuery, selectedNodes } = this.state;
        const submitButtonClassName = cx('btn btn-primary', {
            ['btn-disabled']: !selectedNodes
        });
        return (
            <ModalPortal onClose={onClose}>
                <div className={styles.appealTypeModal}>

                    <div className={modalStyles.modalHeader}>
                        <div className={modalStyles.modalTitle}>
                            {t('appeal.type')}
                        </div>
                    </div>

                    {
                    <OptionTypeSearchBlock
                        value={query}
                        onChange={this.onQueryChange}
                        onSubmit={this.onSearchSubmit}
                        placeholder={t('Search')}
                    />}

                    <OptionTypesTree
                        {...this.props}
                        searchQuery={submittedQuery}
                        onChange={this.onChange}
                    />

                    <footer className={styles.footer}>
                        <button
                            type="button"
                            className={submitButtonClassName}
                            disabled={!selectedNodes}
                            onClick={this.onSubmitClick}
                        >
							{t('filter.select')}
                        </button>
                    </footer>

                </div>
            </ModalPortal>
        );
    }
}

OptionTypesTreeModal.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default OptionTypesTreeModal;
