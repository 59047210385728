import {
    EMAILS_REQUEST,
    EMAILS_REQUEST_SUCCESS,
    EMAILS_REQUEST_FAILURE,
    TOGGLE_ALL_MAILS,
    UPLOAD_EMAIL_FILE_SUCCESS,
    UPLOAD_EMAIL_FILE_FAILURE,
    CREATE_NEW_EMAIL_SUCCESS,
    UPLOAD_EMAIL_FILE_LIST_SUCCESS,
    SET_FILES_LENGTH,
    SET_FORM_FORWARD,
    SET_FORM_REPLY,
    CREATE_NEW_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    RESET_EMAIL_FORM,
    GET_TEMPLATE_EMAIL_SUCCESS,
    GET_TEMPLATE_LIST_SUCCESS,
    UPLOAD_EMAIL_FILE_REQUEST,
    OPEN_EMAIL,
    OPEN_EMAIL_SUCCESS,
    EMAILS_SEARCH_QUERY_UPDATE,
    APPEAL_MAILING_RESET
} from 'constants/actions';
import styles from "../styles/modules/appealOperatePage.module.scss";

const initialState = {
    requestedForAppeal: '',

    emails: [],
    loading: false,
    query: '',

    toggleAllMails: false,
    attachment: [],
    errorUpload: null,
    filesCount: 0,
    filesLength: 0,
    mailBody: null,
    formFields: null,
    sendEmailSuccess: false,
    upload: false,
    uploadingFile: false,
    templateList: [],
    shouldOpenEmail: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        /********  Emails request  ********/

    case EMAILS_REQUEST:
        return { ...state, loading: true, requestedForAppeal: action.appealId };

    case EMAILS_REQUEST_SUCCESS:
        return { ...state, emails: action.emails, loading: false };

    case EMAILS_REQUEST_FAILURE:
        return { ...state, emails: [], loading: false };

        /********  Emails search query update  ********/

    case EMAILS_SEARCH_QUERY_UPDATE:
        return { ...state, query: action.query };

        /********  Emails reset  ********/

    case APPEAL_MAILING_RESET:
        return initialState;

        /********************************/

    case TOGGLE_ALL_MAILS:
        return { ...state, toggleAllMails: !state.toggleAllMails };

    case UPLOAD_EMAIL_FILE_REQUEST:
        return { ...state, uploadingFile: true };

    case UPLOAD_EMAIL_FILE_FAILURE:
        return { ...state, errorUpload: action.error };

    case CREATE_NEW_EMAIL_SUCCESS:
        return { ...state, newEmailId: action.payload };

    case UPLOAD_EMAIL_FILE_LIST_SUCCESS: {
        return {
            ...state,
            attachment: [...action.payload],
            filesCount: 0,
            filesLength: 0,
            upload: false,
            uploadingFile: false
        };
    }

    case UPLOAD_EMAIL_FILE_SUCCESS: {
        return {
            ...state,
            upload: true,
            filesCount: state.filesCount + 1
        };
    }

    case SET_FILES_LENGTH:
        return { ...state, filesLength: action.payload, filesCount: 0 };

    case CREATE_NEW_EMAIL_REQUEST: {
        return {
            ...state,
            formFields: null,
            attachment: [],
            mailBody: null
        };
    }

    case SET_FORM_FORWARD: {
        return {
            ...state,
            formFields: {
                subject: action.email.subject
            },
            attachment: [...action.email.attachments]
        };
    }

    case SET_FORM_REPLY: {
        return {
            ...state,
            formFields: {
                subject: action.email.subject,
                to: action.email.emailType === 'outgoing' ? action.email.mailTo : action.email.mailFrom
            },
            mailBody: null,
            attachment: []
        };
    }

    case SEND_EMAIL_SUCCESS:
        return { ...state, sendEmailSuccess: action.payload };

    case RESET_EMAIL_FORM: {
        return {
            ...state,
            sendEmailSuccess: false,
            mailBody: null,
            attachment: [],
            formFields: null
        };
    }

    case GET_TEMPLATE_EMAIL_SUCCESS: {
        const template = action.payload.filter(item => item.notificationChannelCode === 'email');

        return {
            ...state,
            mailBody: action.body ? action.body + template[0].messageBodyTemplate : template[0].messageBodyTemplate,
        }
    }

    case GET_TEMPLATE_LIST_SUCCESS: {
        const options = action.payload.map(item => ({
            value: item.templateId,
            templateCode: item.templateCode,
            label: item.templateName
        }));

        return { ...state, templateList: options };
    }

    case OPEN_EMAIL:
        return { ...state, shouldOpenEmail: { id: action.payload.id } };

    case OPEN_EMAIL_SUCCESS:
        return { ...state, shouldOpenEmail: null };

    default:
        return state;
    }
};
