import { fieldLevelValidation, requiredFieldsValidation, individualTaxpayerNumber, certificateIdValidation } from 'util/form';
import { organizationConfig } from './OrganizationForm';

const rules = {
    institutionId: individualTaxpayerNumber,
    taxid: certificateIdValidation,
};

function validate (values) {
    const errors = {};
    
    requiredFieldsValidation(values, organizationConfig, errors);
    fieldLevelValidation(values, rules, errors);
    return errors;
}

export default validate;