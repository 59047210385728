import React from 'react';
import { isValid } from '../validate';
import DateTimePicker from 'components/Common/DatePicker';

function DareTimePickerDecorator ({input, meta, handleChangeValue, allowEmpty, changeOnBlur, ...rest}) {
    
    function changeOnBlurFunc(value, name) {
        if (isValid(value, name)) {
            handleChangeValue({
                value,
                name
            });
        }
    }
    
    const formInputProps = {...input};
    
    const error = allowEmpty && !input.value ? null : meta.error;
    const formMetaProps = {...meta, error};
    return (
        <DateTimePicker
            input={formInputProps}
            meta={formMetaProps}
            changeOnBlur={changeOnBlur ?  changeOnBlurFunc : null}
            {...rest}
        />
    );
}

export default DareTimePickerDecorator;
