import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { calculateSearchNumber } from 'helpers';
import { translate } from 'react-i18next';

const iconsConfig = {
    customer: 'customer-icon',
    file: 'speech-bubble',
    appeal: 'ticket',
    contact_person: 'user'
};

const tabConfig = ['contact_person', 'customer', 'appeal', 'callhistory', 'file'];

@translate()
export default class LiveSearch extends React.Component {
    static getLinkTo({id, type, objectId}) {
        switch (type) {
        case 'customer':
            return `/customer/${id}/appeals`;
        case 'appeal':
            return `/appeals/${id}/knowledge_base`;
        case 'file':
            return `/appeals/${objectId}/knowledge_base`;
        case 'contact_person':
            return `/person/${id}/contact`;
        default:
            return '';
        }
    };

    constructor(props) {
        super(props);
        this.getLinkTo = this.constructor.getLinkTo;
        this.renderAssumption = this.renderAssumption.bind(this);
        this.renderTab = this.renderTab.bind(this);
    }
    
    renderAssumption(data) {
        const { id, type, name, content, hideSearch, code, applId, contactPersonName } = data;
        
        const onAssumptionClick = event => {
            if (code) {
                this.props.onClick(event, data);
            } else {
                hideSearch && hideSearch(event);
            }
        };
        return (
            <Link
                key={code ? applId : id}
                to={this.getLinkTo(data)}
                className={cx('assumption-wrapper', 'link')}
                onClick={onAssumptionClick}
            >
                {code ?
                    <Fragment>
                        <span className='customer-name' title={name}>{name}</span>
                        <span className='customer-details'>{content}</span>
                        <span className='customer-details'>{contactPersonName}</span>
                    </Fragment> :
                    <Fragment>
                        <i className={`icon icon-${iconsConfig[type]}`}/>
                        {`${name}, ${content}`}
                    </Fragment>
                }
            </Link>
        );
    };
    
    renderTab(value) {
        const { tabs, query, hideSearch, t, facets, canSearchContactPersons, canSearchCustomer } = this.props;
        const hash = calculateSearchNumber(tabs);
        if (value === 'callhistory' || value === 'file') {
            return false;
        }

        if (value === 'contact_person' && !canSearchContactPersons) {
            return false;
        }
        
        if (value === 'customer' && !canSearchCustomer) {
            return false;
        }
        
        return (
            <NavLink
                key={value}
                className={cx('tab-link', 'link')}
                to={`/search/${value}/${hash}/${query}`}
                onClick={hideSearch}
            >
                {`${t(`tabs.${value}`).toUpperCase()} (${facets[value]})`}
            </NavLink>
        );
    };
    
    render () {
        const {className, searchResults, facets} = this.props;
        return searchResults.length > 0 ? (
            <div className={cx(className, 'search-wrapper')}>
                {searchResults.map(this.renderAssumption)}
                {
                    facets &&
                    <div className='tab-wrapper'>
                        {tabConfig.map(this.renderTab)}
                    </div>
                }
            </div>
        ) : null;
    }
}

LiveSearch.propTypes = {
    className: PropTypes.string,
    searchResults: PropTypes.array,
    query: PropTypes.string,
    hideSearch: PropTypes.func,
    facets: PropTypes.object,
    tabs: PropTypes.object
};
