import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Async } from 'react-select';
import { translate } from 'react-i18next';
import BaseService from 'services/BaseService';
import cx from 'classnames';

@translate()
class AjaxCombo extends Component {
    
    arrowRender = values => {
        if (values.isOpen) {
            return <i className='icon-up'/>;
        }
        return <i className='icon-down'/>;
    };
    
    convertResult = result => {
        return Array.isArray(result)
            ? result.map(object => ({ value: object.key, label: object.value, key: object.key }))
            : [];
    };
    
    getOptions = (input, callback) => {
        const { optionsUrl, attributeKey, blockKey } = this.props;
        
        const data = {
            attributeKey: attributeKey,
            blockKey: blockKey,
            searchStr: input
        };
        
        if (input && input.length > 2) {
            BaseService.post(optionsUrl, { data })
                .then(response => {
                    callback(null, { options: this.convertResult(response.result) });
                })
                .catch(console.error);
        } else {
            callback(null, { options: [] })
        }
    };
    
    handleSelectOption = option => {
        this.props.input && this.props.input.onChange(option);
    };
    
    onFocus = event => {
        this.props.input && this.props.input.onFocus();
    };
    
    onBlur = event => {
        this.props.input.onBlur && this.props.input.onBlur();
    };
    
    render () {
        const {
            children,
            input,
            onChange,
            label,
            required,
            t,
            noborder,
            meta,
            id,
            ...restProps
        } = this.props;
        
        const selectClassName = cx('container-comboBox ajax-combo',
            meta && meta.touched && (meta.error || meta.warning) && 'input-field__error',
            meta && meta.active && 'active', {
                'noborder': noborder
            });
        return (
            <div id={id} className='input-element'>
                <div className='input-label'>
                    {label}
                    {required && <span className='required-field'>*</span>}
                </div>
                <Async
                    {...restProps}
                    {...meta}
                    ignoreAccents
                    children={children}
                    value={input.value}
                    onChange={this.handleSelectOption}
                    className={selectClassName}
                    filterOptions={options => options}
                    onInputChange={onChange}
                    onFocus={this.onFocus}
                    name='form-field-name'
                    placeholder='Не вибрано'
                    searchPromptText='Введіть для пошуку'
                    arrowRenderer={this.arrowRender}
                    allowCreate={true}
                    onBlur={this.onBlur}
                    loadingPlaceholder='Зачекайте'
                    loadOptions={this.getOptions}
                    noResultsText={t('noResultsFound')}
                />
            </div>
        );
    }
}

AjaxCombo.propTypes = {
    optionsUrl: PropTypes.string,
    autoLoad: PropTypes.bool,
    options: PropTypes.object,
    children: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    noborder: PropTypes.bool,
    meta: PropTypes.object,
    attributeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    restProps: PropTypes.any,
    blockKey: PropTypes.any,
    input: PropTypes.object
};

export default AjaxCombo;
