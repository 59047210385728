import React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Portal } from 'react-overlays';
import { getBankList, customerEdit } from 'actions/customer';
import { toggleSideMenuAdditions } from 'actions/ui';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import Popup from 'components/Popup/index';
import styles from 'styles/modules/parameters.module.scss';
import { translate } from 'react-i18next';

const mapStateToProps = state => ({
	bankList: state.customer.bankList,
	loadedBankList: state.customer.loadedBankList,
	showSideMenuAdditions: state.ui.showSideMenuAdditions
});

const mapDispatchToProps = dispatch => ({
	getBankList: (data) => dispatch(getBankList(data)),
	change: (field, value) => dispatch(change('customerInfo', field, value)),
	customerEdit: (data) => dispatch(customerEdit(data)),
	toggleSideMenuAdditions: () => dispatch(toggleSideMenuAdditions())
});

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class ComboBoxGroup extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			gridPopupIsOpen: false,
			inputValueLiveSearch: (props.values && props.values[props.names[0]]) || '',
			inputValue: (props.values && props.values[props.names[1]]) || '',
			valueMFO: (props.values && props.values[props.names[2]]) || '',
			errorPopupIsOpen: false,
			left: undefined,
			top: undefined
		};
	}
	
	componentDidMount () {
		this.timer = null;
	}
	
	UNSAFE_componentWillReceiveProps ({ loadedBankList, bankList }) {
		if ((this.props.bankList.length !== bankList.length) && (bankList.length > 0) && !this.props.loadedBankList && loadedBankList) {
			this.setPosition();
			this.setState({ gridPopupIsOpen: true });
		}
		if ((this.props.bankList.length !== bankList.length) && (bankList.length === 0)) {
			this.setState({ gridPopupIsOpen: false });
		}
	}
	
	toggleErrorPopup = () => {
		this.setPosition();
		setTimeout(() => this.setState({ errorPopupIsOpen: !this.state.errorPopupIsOpen }), 100);
	};
	
	openErrorPopup = () => {
		this.setPositionError();
		setTimeout(() => this.setState({ errorPopupIsOpen: true }), 100);
	};
	
	closeErrorPopup = () => {
		setTimeout(() => this.setState({ errorPopupIsOpen: false }), 100);
	};
	
	setPositionError = () => {
		const rect = this.inputError.getBoundingClientRect();
		const left = window.scrollX + rect.left + rect.width;
		const top = window.scrollY + rect.top - rect.height * 2;
		
		if (top !== this.state.top || left !== this.state.left) {
			this.setState({ left, top });
		}
	};
	
	onSearch = (e) => {
		const { value } = e.currentTarget;
		this.setState({ inputValueLiveSearch: value });
		clearTimeout(this.timer);
		this.timer = setTimeout(() => this.triggerChange(value), SEARCH_TIMER_INTERVAL);
	};
	
	triggerChange = (value) => {
		const { getBankList } = this.props;
		const data = {
			query: value,
			page: 1,
			start: 0,
			limit: 10
		};
		getBankList(data);
	};
	
	toggleGridPopup = () => {
		this.setPosition();
		this.setState({ gridPopupIsOpen: !this.state.gridPopupIsOpen });
	};
	
	setPosition = () => {
		const rect = this.refs.liveSearchRef.getRenderedComponent().getBoundingClientRect();
		const left = window.scrollX + rect.left + rect.width / 2;
		const top = window.scrollY + rect.top;
		
		if (top !== this.state.top || left !== this.state.left) {
			this.setState({ left, top });
		}
	};
	
	renderPopUpChildren = () => {
		const { bankList } = this.props;
		const { inputValueLiveSearch } = this.state;
		return bankList && bankList.map(item => (
			<div
				className={cx('popup-item', { 'active': (inputValueLiveSearch === item.name) })}
				key={item.id}
				onClick={() => this.clickHandler(item.name, item.mfo)}
				value={item.mfo}
			>
				{item.name}
			</div>
		));
	};
	
	clickHandlerLiveSearch = (e) => {
		const { value } = e.currentTarget;
		const { getBankList } = this.props;
		this.setState({ inputValueLiveSearch: value });
		const data = {
			query: value,
			page: 1,
			start: 0,
			limit: 10
		};
		getBankList(data);
	};
	
	clickHandler = (nameBank, mfo) => {
		const { change, names, id, field, customerEdit, showSideMenuAdditions, toggleSideMenuAdditions } = this.props;
		const { inputValue } = this.state;
		this.setState({ gridPopupIsOpen: false, valueMFO: mfo });
		names && change(names[0], nameBank);
		const valueObject = {
			[names[0]]: nameBank,
			[names[1]]: inputValue,
			[names[2]]: mfo
		};
		const gatheredData = {
			personId: null,
			customerId: id,
			field: field,
			text: JSON.stringify(valueObject)
		};
		
		if (inputValue) {
			customerEdit(gatheredData);
		} else {
			this.refs.inputRef.getRenderedComponent().focus();
		}
		!showSideMenuAdditions && toggleSideMenuAdditions();
	};
	
	handleKeyDown = (e) => {
		const { value } = e.currentTarget;
		const { change, customerEdit, id, names, field } = this.props;
		const { inputValue, inputValueLiveSearch, valueMFO } = this.state;
		const valueObject = {
			[names[0]]: inputValueLiveSearch,
			[names[1]]: value,
			[names[2]]: valueMFO
		};
		const gatheredData = {
			personId: null,
			customerId: id,
			field: field,
			text: JSON.stringify(valueObject)
		};
		if (e.keyCode === 13) {
			this.setState({ inputValue: value });
			(value.length > 0) && customerEdit(gatheredData);
		} else if (e.keyCode === 27) {
			names && change(names[1], inputValue);
		} else {
			this.setState({ inputValue: value });
		}
	};
	
	handleBlur = (e) => {
		const { value, name } = e.currentTarget;
		const { customerEdit, id, names, field } = this.props;
		const { inputValueLiveSearch, valueMFO } = this.state;
		const valueObject = {
			[names[0]]: inputValueLiveSearch,
			[names[1]]: value,
			[names[2]]: valueMFO
		};
		const gatheredData = {
			personId: null,
			customerId: id,
			field: field,
			text: JSON.stringify(valueObject)
		};
		(value.length > 0) && (inputValueLiveSearch.length > 0) && (value !== this.props[name].meta.initial) && !this.props[name].meta.error && customerEdit(gatheredData);
	};
	
	removeField = (e, name) => {
		e.preventDefault();
		e.stopPropagation();
		const { change } = this.props;
		change(name, ['']);
		this.refs.inputRef.getRenderedComponent().focus();
	};
	
	render () {
		const { label, value, names, bankList, t } = this.props;
		const { gridPopupIsOpen, inputValueLiveSearch, inputValue, errorPopupIsOpen, left, top } = this.state;
		const el = document.getElementById('popup-portal');
		const stylePopup = {
			left: left,
			top: top
		};
		const containerPopup = document.getElementById('popup-portal');
		
		return (
			<div className='comboBoxGroups'>
				<div className='input-element input-combobox'>
					<div className='input-label'>{names && (t(`customerInfoLabel.${names[0]}`) || names[0])}</div>
					<Field
						component='input'
						type='text'
						className={cx(styles.inputField, this.props[names[0]].meta && this.props[names[0]].meta.touched && this.props[names[0]].meta.error && 'input-fieldError')}
						onClick={this.clickHandlerLiveSearch}
						onKeyUp={this.onSearch}
						value={inputValueLiveSearch}
						name={names[0]}
						withRef
						ref='liveSearchRef'
						autoComplete='off'
					/>
					{this.props[names[0]].meta && this.props[names[0]].meta.touched && this.props[names[0]].meta.error && (this.props[names[0]].value !== '') ?
						<div className='input-error'>
							<div className='input-error__tooltip'>
								{this.props[names[0]].meta.error}
							</div>
						</div> : null}
					{gridPopupIsOpen &&
					<Portal container={el}>
						<Popup
							place='comboBox-popup'
							onClickOutside={this.toggleGridPopup}
							left={this.state.left}
							top={this.state.top}
						>
							<div className='popup-wrapper'>{this.renderPopUpChildren()}</div>
						</Popup>
					</Portal>}
				</div>
				<div className={cx('input-element', styles.itemInput)}
					 title={t(`customerInfoLabel.${names[1]}`) || names[1]}>
					<div className={styles.inputLabel}>{t(`customerInfoLabel.${names[1]}`) || names[1]}</div>
					<Field
						component='input'
						className={cx(styles.inputField, this.props[names[1]].meta && this.props[names[1]].meta.touched && this.props[names[1]].meta.error && 'input-fieldError')}
						onKeyUp={this.handleKeyDown}
						onBlur={this.handleBlur}
						name={names[1]}
						value={inputValue}
						type='text'
						withRef
						ref='inputRef'
						autoComplete='off'
					/>
					<button
						type='button'
						title='Remove'
						className='btn-remove'
						onClick={(e) => this.removeField(e, names[1])}
					>
						<span className='icon-close' />
					</button>
					{this.props[names[1]].meta && this.props[names[1]].meta.touched && this.props[names[1]].meta.error && (this.props[names[1]].value !== '') ?
						<div className='input-error' ref={(node) => this.inputError = node}>
							<span className='icon icon-error' onMouseEnter={this.openErrorPopup}
								  onMouseLeave={this.closeErrorPopup} />
							{errorPopupIsOpen && <Portal container={containerPopup}>
								<div
									className='error-popup'
									style={stylePopup}
								>
									{this.props[names[1]].meta.error}
								</div>
							</Portal>}
						</div> : null}
				</div>
			</div>
		);
	}
}

ComboBoxGroup.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	defaultValue: PropTypes.string,
	meta: PropTypes.object
};


