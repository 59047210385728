import axios from 'axios';
import URLS from '../config/urls';

export default axios.create({
    validateStatus: (status) => {
        if (status === 401) {
            window.location.href = URLS.core.logout.url;
        }
        return true;
    },
});
