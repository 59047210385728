import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import { KeyCodes } from 'constants/index';

function OptionTypeSearchBlock(props) {
    const { value, onChange, placeholder, onSubmit } = props;

    function onKeyUp(event) {
        if (event.keyCode === KeyCodes.ENTER) {
            onSubmit();
        }
    }

    return (
        <div className={styles.searchBlock}>
            <div className={styles.searchInputContainer}>
                <input
                    type='text'
                    className={styles.searchInput}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                />
                <i className='icon-kebab-vert' />
            </div>
        </div>
    );
}

OptionTypeSearchBlock.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
};

export default OptionTypeSearchBlock;
