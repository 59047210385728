import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import cx from 'classnames';
import get from 'lodash/get';
import CheckBoxToggle from '../../Common/CheckBoxToggle';

class KnowledgeBaseItemField extends React.Component {
    
    getFiles = () => {
        const {field} = this.props;
        
        const files = field.value.map(file => {
            const ext = file.fileName.split('.').pop();
            const isImage = (ext === 'jpg' || ext === 'png');
            
            if (isImage) {
                return (<img key={file.id} alt='' src={`../mw/file?fileId=${file.id}`}/>);
            } else {
                return (
                    <a
                        key={file.id}
                        href={`../mw/file?fileId=${file.id}`}
                        className={cx('btn-save', styles.knowledgeBaseDownloadLink)}
                        download
                    >
                        {file.fileName}
                    </a>
                );
            }
        });
        
        return (
            <div className={styles.filesWrapper}>
                {files}
            </div>
        );
    };
    
    getStatusContent = () => {
        const {value: status} = this.props.field;
        
        const wrapperClassName = cx(
            styles.statusSelectWrapper,
            styles.inline,
            styles.disabled
        );
        
        const resolutionName = get(status, 'resolution.title', '');
        
        return (
            <div className={wrapperClassName} onClick={this.openStatusPopup}>
                {
                    status.name &&
                    <span className={`sticker sticker-${status.code}`}>{status.name}</span>
                }
                {
                    resolutionName &&
                    <Fragment>
                        <span className={styles.statusDivider}>|</span>
                        <span className={styles.resolution}>{resolutionName}</span>
                    </Fragment>
                }
            </div>
        );
    };
    
    renderFieldContent = () => {
        const {field} = this.props;
        switch (field.name) {
            
            case 'status':
                return this.getStatusContent();
            
            case 'file':
                return (
                    <div className={styles.description}>
                        {this.getFiles()}
                    </div>
                );
    
            case 'checkbox':
                return (
                    <div className={styles.description}>
                        <CheckBoxToggle input={{ value: field.value }} label={field.checkBoxLabel} />
                    </div>
                );
            
            default:
                return (
                    <div dangerouslySetInnerHTML={{__html: field.value}}/>
                );
        }
    };
    
    render () {
        const {field} = this.props;
        return (
            <div className={styles.block} key={field.label}>
                <div className={styles.title}>{field.label && field.label}</div>
                {this.renderFieldContent()}
            </div>
        );
    }
}

KnowledgeBaseItemField.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
        name: PropTypes.string
    })
};

export default KnowledgeBaseItemField;
