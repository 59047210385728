import React, { Fragment } from 'react';
import styles from 'styles/modules/appealQuality.module.scss';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import StyledRadio from 'components/Common/StyledRadio';

@translate()
export default class Radio extends React.Component {

  onRadioChange = event => {
    const { onChange, name } = this.props;
    onChange(name, event.target.value);
  };

  renderRadioItem = radio => {
    const { name, t,} = this.props;
    return (
      <StyledRadio
        key={name + radio.value}
        name={name}
        value={radio.value}
        onChange={this.onRadioChange}
        defaultChecked={radio.checked}
        title={`${t(radio.label)} (${radio.value})`}
      />
    );
  };

  render() {
    const { t, title, list } = this.props;
    return (
      <Fragment>
        <div className={styles.appealQualityRow}>
          <span className={styles.appealQualityFormQuestion}>{t('quality.Question')}</span>
          <li/>
          <br/>
          <b>{title}</b>
          <br/>
          <fieldset className={styles.appealQualityRadio}>
            {list.map(this.renderRadioItem)}
          </fieldset>
        </div>
      </Fragment>
    );
  }
}

Radio.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  list: PropTypes.array
};
