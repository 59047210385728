import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';

@translate()
class NotificationItem extends React.Component {

  state = {
    open: false
  };

  toggleContent = () => this.setState(prevState => ({ open: !prevState.open }));

  getConfig = () => {
    const { t } = this.props;
    return [
	  { title: t('appealHistory.rule'), path: 'ruleName' },
      { title: t('appealHistory.subject'), path: 'subject', innerHTML: true },
      { title: t('appealHistory.notificationBody'), path: 'body', innerHTML: true },
      { title: t('appealHistory.action'), path: 'actionName', shouldHighlight: true },
      { title: t('appealHistory.status'), path: 'status' },
      { title: 'ID', path: 'id' }
    ];
  };

  createMarkup = content => ({__html: content});

  renderRow = ({ title, path, innerHTML, shouldHighlight }) => {
    const { data, highlight} = this.props;
    return (
      <div className={styles.notificationRow} key={path}>
        <span className={styles.rowTitle}>{title}:</span>
        {
          innerHTML
            ? <div className={styles.rowContent} dangerouslySetInnerHTML={this.createMarkup(data[path])}/>
            : <div className={styles.rowContent}>{shouldHighlight ? highlight(data[path]) : data[path]}</div>
        }
      </div>
    );
  };

  render(){
    const { data, t } = this.props;

    return (
      <li className={styles.historyItem}>
        <i className='icon-external-link-square'/>
        <div className={styles.historyItemContent}>
          <span className={styles.historyTitleText}>Нотифікація: </span>
          <span className={styles.historyContentText}>{`${data.notifierFrom} \<${data.notifierTo}\>`}</span>

			<div className={styles.notificationContent}>
				{
					!this.state.open && this.renderRow({ title: t('appealHistory.ruleName'), path:"ruleName"})
				}
				{
					this.state.open &&
					<React.Fragment>
						{this.getConfig().map(this.renderRow)}
					</React.Fragment>
				}

            <button className={styles.contentToggleButton} onClick={this.toggleContent}>
              <i className='icon-kebab-hor' />
            </button>

          </div>
        </div>
      </li>
    );
  }
}

NotificationItem.propTypes = {
  data: PropTypes.shape({
    actionName: PropTypes.string,
    // actionRuleName: PropTypes.string,
    body: PropTypes.string,
    id: PropTypes.number,
    subject: PropTypes.string,
    status: PropTypes.string,
    notifierFrom: PropTypes.string,
    notifierTo: PropTypes.string
  }),
  highlight: PropTypes.func
};

export default NotificationItem;
