import React from 'react';
import PropTypes from 'prop-types';

function SearchOption (props) {

  function clearInput(event) {
    event.stopPropagation();
    props.onChange('');
  }

  function onChange(event) {
    props.onChange(event.target.value);
  }

  return (
    <div className={'search-container'}>
      <input type={'text'} autoFocus value={props.query} onChange={onChange}/>
      <i className='icon-kebab-vert' />
      {
        props.query.length > 0 &&
        <button className={'btn-clear'} type={'button'} onClick={clearInput}>
          <i className={'icon-times'} />
        </button>
      }
    </div>
  );
}

SearchOption.defaultProps = {
  query: ''
};

SearchOption.propTypes = {
  query: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SearchOption;
