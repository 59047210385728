import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectWithSearch from './MultiSelectWithSearch';

function ReduxFormDecorator ({input, meta, ...rest}) {
  return (
    <MultiSelectWithSearch
      selectedOptions={input.value || undefined}
      onChange={input.onChange}
      error={meta.touched ? (meta.error || meta.warning) : undefined}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      {...rest}
    />
  );
}

ReduxFormDecorator.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
};

export default ReduxFormDecorator;
