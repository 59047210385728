import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { customerLinks, settingsLink } from 'config/links';
import 'styles/modules/navigation.module.scss';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import permissions, { checkPermissions } from 'config/permissions';
import Control from '../Control';

function mapStateToProps () {
    return {
        isSettingsButtonEnabled: checkPermissions(permissions.AdminGrantOperations.view_permissions),
        isKnowledgeBaseButtonEnabled: checkPermissions(permissions.UserOperations.action_clientPartViewKnowledgeBase),
        isDashboardEnabled: checkPermissions(permissions.DashboardOperations.dashboard_workWithDashboard),
        isIdentityEnabled: checkPermissions(permissions.AdminGrantOperations.action_manage_access),
        isOrdersEnabled: checkPermissions(permissions.OrderOperations.view_order),
    };
}

@withRouter
@connect(mapStateToProps)
class Navigation extends Component {
    render () {
        const {
            isKnowledgeBaseButtonEnabled,
            isSettingsButtonEnabled,
            isDashboardEnabled,
            isIdentityEnabled,
            isOrdersEnabled
        } = this.props;

        return (
            <aside>
                <div>
                    <div className='logo-container'>
                        <div className='container-menu'>
                            <img alt='logo' src='/data/images/logo.png'/>
                        </div>
                    </div>
                    <div className='sections-block'>
                        {customerLinks.map((item) => {
                            if (item.name === 'knowledge_base' && !isKnowledgeBaseButtonEnabled) return null;
                            if (item.name === 'dashboard' && !isDashboardEnabled) return null;
                            if (item.name === 'orders' && !isOrdersEnabled) return null;
                            if (item.name === 'identity') {
                                if (isIdentityEnabled) {
                                    return <Control key={item.name} item={{
                                        ...item,
                                        link: `https://sso.ukc.gov.ua/identity/admin`,
                                        blank: true
                                    }}/>;
                                }

                                return null;
                            }

                            return (
                                <Control key={item.name} item={{ ...item, link: `/${item.name}` }}/>
                            );
                        })}
                    </div>
                </div>
                {
                    isSettingsButtonEnabled
                    && (
                        <div>
                            <div className='sections-block'>
                                <Control key='settings' onClick={(e) => { e.preventDefault(); }} item={settingsLink}/>
                            </div>
                        </div>
                    )
                }
            </aside>
        );
    }
}

Navigation.defaultProps = {
    isKnowledgeBaseButtonEnabled: false,
    isSettingsButtonEnabled: false,
};

Navigation.propTypes = {
    isKnowledgeBaseButtonEnabled: PropTypes.bool,
    isSettingsButtonEnabled: PropTypes.bool,
};

export default Navigation;
