import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import Loader from 'components/Loader';
import MessageModal from 'components/MessageModal';
import MailingItem from './MailingItem';
import MailingSendForm from './MailingSendForm';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { openMessageModal, closeMessageModal } from 'actions/ui';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';
import { MAX_FILE_SIZE } from 'constants/index';

import {
    sendEmail,
    toggleAllMails,
    uploadFile,
    deleteFile,
    createNewEmail,
    getFilesList,
    setFilesLength,
    setFormForward,
    setFormReply,
    getTemplateEmail,
    getTemplateList,
    openEmailSuccess,
    getEmails,
    updateSearchQuery,
    resetAppealMailing,
    resetEmailForm
} from 'actions/emails';
import { translate } from 'react-i18next';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import MailingHeader from './MailingHeader';
import { bindActionCreators } from 'redux';
import { extractAppealFromState } from 'helpers';

function mapStateToProps(state, props) {
	const [appeal] = extractAppealFromState(state, props);

    return {
        emails: state.emails.emails,
        requestedForAppeal: state.emails.requestedForAppeal,
        searchQuery: state.emails.query,
        toggleMails: state.emails.toggleAllMails,
        attachmentFiles: state.emails.attachment,
        newEmailInteractionId: state.emails.newEmailId,
        filesCount: state.emails.filesCount,
        filesLength: state.emails.filesLength,
        appealInteractionId: get(appeal, 'currentAppeal.interactionId'),
        initialValues: state.emails.formFields,
        mailBody: state.emails.mailBody,
        contentLoading: state.content.contentLoading,
        shouldOpenEmail: state.emails.shouldOpenEmail,
        showMessageModal: state.ui.showMessageModal,
        templateList: state.emails.templateList
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmails,
        updateSearchQuery,
        sendEmail,
        toggleAllMails,
        uploadFile,
        deleteFile,
        createNewEmail,
        setFilesLength,
        getFilesList,
        setFormForward,
        setFormReply,
        getTemplateEmail,
        getTemplateList,
        openMessageModal,
        closeMessageModal,
        openEmailSuccess,
        resetAppealMailing,
        resetEmailForm,
    }, dispatch);
}

@withRouter
@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class Mailing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            sendFormOpening: false,
            forward: false,
            expand: false,
            template: null,
        };
        this.sendEmailForm = this.sendEmailForm.bind(this);
        this.onSendForm = this.onSendForm.bind(this);
        this.scrollRef = React.createRef();
        this.onClickClose = this.onClickClose.bind(this);
        this.handlerDeleteFile = this.handlerDeleteFile.bind(this);
    }

    componentDidMount () {
        this.props.resetAppealMailing();
        this.requestEmails();
        this.props.getTemplateList();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.shouldOpenEmail && !this.props.shouldOpenEmail) {
            const selector = this.scrollRef.current.querySelector(`div[data-id='${prevProps.shouldOpenEmail.id}']`);
            if (selector) {
                selector.scrollIntoView();
            }
        }

		if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
			this.props.resetAppealMailing();
			this.requestEmails();
			this.props.getTemplateList();
		}
    }
	
	UNSAFE_componentWillReceiveProps (nextProps) {
        const {getFilesList, newEmailInteractionId} = this.props;
        if (this.props.filesLength && this.props.filesLength === nextProps.filesCount) {
            getFilesList(newEmailInteractionId);
        }
    };

    shouldMakeInitialRequest = () => !this.props.requestedForAppeal || this.props.match.params.appealId !== this.props.requestedForAppeal;

    requestEmails = (query = '') => {
        const appealId = this.props.match.params.appealId;

        const requestData = {
            query,
            objectType: 'INTERACTION_REQUEST',
            objectId: appealId,
            sort: [{ property: 'date', direction: 'DESC' }]
        };

        this.props.getEmails(requestData, appealId);
    };

    async onSendForm(value, html) {
        const { sendEmail, initialValues, newEmailInteractionId, appealInteractionId, match } = this.props;
        const { forward } = this.state;
        const getEmail = () => {
            const to = value.to || (initialValues && initialValues.to);
            const copy = value.copy || (initialValues && initialValues.copy);
            let recipients = [];

            if (to) {
                recipients.push(to);
            }

            if (copy) {
                recipients.push(copy);
            }

            return recipients;
        };

        const emails = getEmail();
        if(emails.length) {
            const data = {
                key: forward ? 'forward_email' : 'send_email',
                data: {
                    interactionId: newEmailInteractionId,
                    parentInteractionId: appealInteractionId,
                    objectId: match.params.appealId,
                    objectType: 'new',
                    to: getEmail(),
                    body: html,
                    subject: initialValues && initialValues.subject || value.subject
                },
                jsonType: true
            };

            await sendEmail(data);

            await this.setState({
                sendFormOpening: false,
                forward: false,
                expand: false
            });
        }
    };

    sendEmailForm() {
        const { createNewEmail, attachmentFiles, templateList, appealInteractionId } = this.props;
        this.props.getTemplateEmail(templateList.find(i => i.templateCode === 'EMAILNEW').value, this.props.match.params.appealId);
        this.setState({ sendFormOpening: true, forward: false });
        if (attachmentFiles.length === 0) {
            createNewEmail(appealInteractionId);
        }
    };

    onToggleAllMails = () => this.props.toggleAllMails();

    onUploadFiles = (files, interactionId) => {
        const {uploadFile, setFilesLength, newEmailInteractionId, t, openMessageModal} = this.props;

        setFilesLength(files.length);

        files.forEach(file => {
            const fileSize = file.size / 1024 / 1024;

            if (fileSize > MAX_FILE_SIZE) {
                this.fileModal = (
                    <MessageModal
                        danger
                        titleModal={t('error')}
                        contentModalText={t('errorFile')}
                        contentModalDesc='chm, doc, docx, dot, dotx, m3u, pdf, pub, rtf, txt, xml, xps'
                    />
                );

                openMessageModal();
            } else {
                uploadFile(file, interactionId || newEmailInteractionId);
            }
        });
    };
    handlerDeleteFile(files){
        const {newEmailInteractionId, getFilesList} = this.props;

        getFilesList(newEmailInteractionId);
    };

    onClickReply = (emailId, parentInteractionId) => {
        const { templateList } = this.props;
        this.props.createNewEmail(parentInteractionId);
        this.props.setFormReply(emailId);
        this.props.getTemplateEmail(templateList.find(i => i.templateCode === 'EMAILREPLY').value, this.props.match.params.appealId);
        this.setState({ sendFormOpening: true, forward: false });
    };

    onClickForward = (emailId, parentInteractionId, body) => {
        const { templateList } = this.props;
        this.props.createNewEmail(parentInteractionId);
        this.props.setFormForward(emailId);
        this.props.getTemplateEmail(templateList.find(i => i.templateCode === 'EMAILFORWARD').value, this.props.match.params.appealId, body);
        this.setState({ sendFormOpening: true, forward: true });
    };

    onExpand = () => this.setState(prevState => ({expand: !prevState.expand}));

    onClickClose(){
        const {resetAppealMailing, getTemplateList} = this.props;

        this.setState({
            sendFormOpening: false,
            forward: false,
            expand: false,
            template: null
        });

        resetAppealMailing();
        getTemplateList();
    };

    makeSearchRequest = debounce(query => this.requestEmails(query), SEARCH_TIMER_INTERVAL);

    onSearchMail = event => {
        const searchQuery = event.target.value;
        this.makeSearchRequest(searchQuery);
        this.props.updateSearchQuery(searchQuery);
    };

    render () {
        const { emails, searchQuery, toggleMails, attachmentFiles, initialValues,
            mailBody, t, contentLoading, shouldOpenEmail, showMessageModal, deleteFile
        } = this.props;
        const { expand, sendFormOpening, template } = this.state;

        return (
            <Fragment>
                <MailingHeader
                    searchQuery={searchQuery}
                    onQueryChange={this.onSearchMail}
                    onToggleAll={this.onToggleAllMails}
                    isToggleAllActive={toggleMails}
                />
                {
                    contentLoading
                        ? <Loader withContainer={true} /> :
                        <Fragment>
                            <div className={cx('scrollbox', 'aria-mailing', styles.commentContent)}
                                 ref={this.scrollRef}>
                                <div className={cx('scrollbox-content', styles.commentContentScrollbox)}>
                                    <div className={styles.mailing}>
                                        {emails.map(email => (
                                            <MailingItem
                                                key={email.id}
                                                shouldOpen={shouldOpenEmail && shouldOpenEmail.id === email.id}
                                                openEmailSuccess={openEmailSuccess}
                                                {...email}
                                                attachmentFiles={attachmentFiles}
                                                toggleAllMails={toggleMails}
                                                onUploadFiles={this.onUploadFiles}
                                                onSendForm={this.onSendForm}
                                                onClickReply={this.onClickReply}
                                                onClickForward={this.onClickForward}
                                                id={email.id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div
                                className={cx(styles.commentFooter, expand && styles.expand, !sendFormOpening && styles.withButton)}>
                                <div
                                    className={cx(styles.formMail, {[styles.formMailShow]: sendFormOpening})}>
                                        <MailingSendForm
                                            mailBody={mailBody}
                                            initialValues={initialValues}
                                            attachmentFiles={attachmentFiles}
                                            onUploadFiles={this.onUploadFiles}
                                            onSendForm={this.onSendForm}
                                            onClickTemplate={this.onClickTemplate}
                                            onExpand={this.onExpand}
                                            sendFormOpening={sendFormOpening}
                                            refresh={() => this.requestEmails(searchQuery)}
                                            expand={expand}
                                            template={template}
                                            onClickClose={this.onClickClose}
                                            deleteFile={deleteFile}
                                            onDeleteFile={this.handlerDeleteFile}
                                        />
                                </div>
                                <button
                                    className={cx('btn-save', {'btn-hidden': sendFormOpening})}
                                    onClick={this.sendEmailForm}>
                                    {t('write')}
                                </button>
                            </div>

                            <CSSTransition
                                in={showMessageModal}
                                classNames='fade'
                                appear={true}
                                enter={true}
                                exit={true}
                                timeout={500}
                                mountOnEnter={true}
                                unmountOnExit={true}
                            >
                                <ModalPortal
                                    onClose={this.props.closeMessageModal}
                                    className='modal-medium'
                                >
                                    {this.fileModal}
                                </ModalPortal>
                            </CSSTransition>

                        </Fragment>
                }
            </Fragment>
        );
    }
}
