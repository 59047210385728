import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class GridPopup extends Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			fields: props.fields
		};
		
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
	
	componentDidMount () {
		document.addEventListener('click', this.handleClickOutside, true);
	};
	
	componentWillUnmount () {
		document.removeEventListener('click', this.handleClickOutside, true);
	};
	
	UNSAFE_componentWillReceiveProps (nextProps) {
		if (nextProps.fields) {
			this.setState({ fields: nextProps.fields });
		}
	};
	
	handleClickOutside (event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			event.preventDefault();
			event.stopPropagation();
			const { handleClose, handleSaveChanges } = this.props;
			const { fields } = this.state;
			handleClose();
			handleSaveChanges(fields);
		}
	};
	
	handleChange (event) {
		const { fields } = this.state;
		const { value, checked } = event.currentTarget;
		
		const newFields = { ...fields };
		newFields[value].hidden = !checked;
		this.setState({ fields: newFields });
	};
	
	setWrapperRef (node) {
		this.wrapperRef = node;
	};
	
	render () {
		const { handleToggle, isOpen } = this.props;
		const { fields } = this.state;
		
		return (
			<div className='grid-col-popup-wrapper'>
				<div className='grid-col-popup-button'>
					<i className='icon-kebab-hor' onClick={handleToggle} />
				</div>
				{isOpen && (
					<div className='grid-col-popup' ref={this.setWrapperRef}>
						<div className='grid-col-popup-content'>
							{Object.entries(fields).map(([value, { name, hidden, permanent }], key) => (
								<React.Fragment key={value}>
									<input
										id={`grid-hide-${value}`}
										type='checkbox'
										disabled={permanent}
										value={value}
										checked={!hidden}
										onChange={this.handleChange}
									/>
									<label
										className={cx('grid-col-popup-column', { 'active': !hidden })}
										for={`grid-hide-${value}`}
									>
										{name}
									</label>
								</React.Fragment>
							))}
						</div>
					</div>
				)}
			</div>
		);
	};
}

GridPopup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	fields: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleToggle: PropTypes.func.isRequired,
	handleSaveChanges: PropTypes.func.isRequired
};

export default GridPopup;
