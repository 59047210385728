import {
  FILTER_ATTRS_GET_SUPPOSE,
  FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
  FILTER_ATTRS_GET_SUPPOSE_FAIL,
  FILTER_ATTRS_CLEAR_RESULT,
  ADD_FILTER_ATTRS,
  SAVE_FILTER_SUCCESS,
  SAVE_FILTER_FAIL,
  SAVE_FILTER,
  DELETE_FILTER,
  DELETE_FILTER_SUCCESS,
  DELETE_FILTER_FAIL,
  FILTER_PARAMS_GET_SUPPOSE,
  FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
  FILTER_PARAMS_GET_SUPPOSE_FAIL,
  CLEAR_FILTER_ATTRS,
  CLEAR_FILTER_INFO,
  FILTER_LIST_GET_SUPPOSE,
  FILTER_LIST_GET_SUPPOSE_SUCCESS,
  FILTER_LIST_GET_SUPPOSE_FAIL,
  FILTER_LIST_CLEAR_RESULT,
  FILTER_DATA_GET_SUPPOSE,
  FILTER_DATA_GET_SUPPOSE_SUCCESS,
  FILTER_DATA_GET_SUPPOSE_FAIL,
  CLEAR_FILTER_DATA,
  OPENED_FILTER,
  OPEN_FILTER_PARAMS,
  CLOSE_FILTER_PARAMS,
  OPEN_NEW_FILTER_PARAMS,
  CLOSE_NEW_FILTER_PARAMS,
  OPEN_PARAMS_MODAL,
  CLOSE_PARAMS_MODAL,
  CREATE_NEW_FILTER,
  APPLY_EXISTING_FILTER,
  ADD_FILTER_OPTION,
  CLEAR_NEW_FILTER_OPTION,
  SET_OLD_FILTER_DATA,
  GET_APPEAL_TYPES_LIST_FOR_FILTER,
  GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS,
  GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR,
  CLEAR_APPEAL_TYPES_LIST_FOR_FILTER,
  SET_SELECT_OPTIONS_FOR_FILTER,
  CUSTOMER_SEARCH_REQUEST,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAILURE,
} from 'constants/actions';
import baseService from '../services/BaseService';
import downloadCSV from 'actions/downloadCSV';
import downloadXLSX from 'actions/downloadXLSX';


const getAttrsSuppose = (query) => ({
  type: FILTER_ATTRS_GET_SUPPOSE,
  query
});

const getAttrsSupposeSuccess = (result) => ({
  type: FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
  result
});

const getAttrsSupposeFail = (error) => ({
  type: FILTER_ATTRS_GET_SUPPOSE_FAIL,
  error
});

export const clearFilterAttrsResult = () => ({
  type: FILTER_ATTRS_CLEAR_RESULT
});

export const getFilterAttrsSuppose = (params) => (dispatch, getState) => {
  dispatch(getAttrsSuppose());

  baseService.get('attributes', params)
    .then(data => {
      dispatch(getAttrsSupposeSuccess(data));
      // TODO move appealTypes request
      !getState().filter.appealTypes && dispatch(getAppealTypesForFilter());
    })
    .catch(err => dispatch(getAttrsSupposeFail(err)));
};

export const addFilterAttrs = (result) => ({
  type: ADD_FILTER_ATTRS,
  result
});

export const clearFilterAttrs = () => ({
  type: CLEAR_FILTER_ATTRS
});

const saveFilterSuccess = (lastSaved) => ({
  type: SAVE_FILTER_SUCCESS,
  lastSaved
});

const saveFilterFail = (error) => ({
  type: SAVE_FILTER_FAIL,
  error
});

const fireSaveFilter = () => ({
  type: SAVE_FILTER
});

export const saveFilter = (params, onSuccess) => (dispatch) => {
  dispatch(fireSaveFilter());

  baseService.post('save_filter', params)
    .then(response => {
      if (onSuccess && response.result) onSuccess(response.result);
      dispatch(saveFilterSuccess(params.data.name));
    })
    .catch(err => dispatch(saveFilterFail(err)));
};

const deleteFilterSuccess = (id) => ({
  type: DELETE_FILTER_SUCCESS,
  id
});

const deleteFilterFail = (error) => ({
  type: DELETE_FILTER_FAIL,
  error
});

const fireDeleteFilter = () => ({
  type: DELETE_FILTER
});

export const deleteFilter = (params) => (dispatch) => {
  dispatch(fireDeleteFilter());

  baseService.post('delete_filter', params)
    .then(data => dispatch(deleteFilterSuccess(data.result)))
    .catch(err => dispatch(deleteFilterFail(err)));
};

const getFilterParamsSuppose = (query) => ({
  type: FILTER_PARAMS_GET_SUPPOSE,
  query
});

const getFilterParamsSupposeSuccess = (result) => ({
  type: FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
  result
});

const getFilterParamsSupposeFail = (error) => ({
  type: FILTER_PARAMS_GET_SUPPOSE_FAIL,
  error
});

export const clearFilterInfo = () => ({
  type: CLEAR_FILTER_INFO
});

export const getInfoFilterSuppose = (params) => (dispatch) => {
  dispatch(getFilterParamsSuppose());

  baseService.get('info_filter', params)
    .then(data => dispatch(getFilterParamsSupposeSuccess(data)))
    .catch(err => dispatch(getFilterParamsSupposeFail(err)));
};

const getListSuppose = (query) => ({
  type: FILTER_LIST_GET_SUPPOSE,
  query
});

const getFilterListSupposeSuccess = (result) => ({
  type: FILTER_LIST_GET_SUPPOSE_SUCCESS,
  result
});

const getFilterListSupposeFail = (error) => ({
  type: FILTER_LIST_GET_SUPPOSE_FAIL,
  error
});

export const clearFilterListResult = () => ({
  type: FILTER_LIST_CLEAR_RESULT
});

export const getFilterListSuppose = (params) => (dispatch) => {
  dispatch(getListSuppose());

  baseService.get(params.key || 'filters', params)
    .then(data => dispatch(getFilterListSupposeSuccess(data)))
    .catch(err => dispatch(getFilterListSupposeFail(err)));
};

const getFilterDataSuppose = () => ({
  type: FILTER_DATA_GET_SUPPOSE
});

const getFilterDataSupposeSuccess = (result) => ({
  type: FILTER_DATA_GET_SUPPOSE_SUCCESS,
  result
});

const getFilterDataSupposeFail = (error) => ({
  type: FILTER_DATA_GET_SUPPOSE_FAIL,
  error
});

export const clearFilterData = () => ({
  type: CLEAR_FILTER_DATA
});

export const getDataFilterSuppose = (params) => (dispatch) => {
  dispatch(getFilterDataSuppose());

  baseService.post(params.key, params)
    .then(data => {
      dispatch(getFilterDataSupposeSuccess(data));
    })
    .catch(err => dispatch(getFilterDataSupposeFail(err)));
};

export const exportAppealGridToExcel = requestData => {
    downloadCSV({ requestData, key: 'preset_apply_csv'});
};

export const exportAppealsGridToCSV = requestData => {
    downloadCSV({ requestData, key: 'preset_apply_export_to_csv'});
};

export const exportAppealsGridToXLS = (requestData, callback) => {
    downloadXLSX({ requestData, key: 'preset_apply_export_to_xlsx'}, callback);
};

export const openedFilterAppeal = (openedFilter) => ({
  type: OPENED_FILTER,
  openedFilter
});

export const openFilterParams = () => ({
  type: OPEN_FILTER_PARAMS
});

export const closeFilterParams = () => ({
  type: CLOSE_FILTER_PARAMS
});

export const openNewFilterParams = () => ({
  type: OPEN_NEW_FILTER_PARAMS
});

export const closeNewFilterParams = () => ({
  type: CLOSE_NEW_FILTER_PARAMS
});

export const createNewFilter = () => ({
  type: CREATE_NEW_FILTER
});

export const applyExistingFilter = () => ({
  type: APPLY_EXISTING_FILTER
});

export const openParamsModal = () => ({
  type: OPEN_PARAMS_MODAL
});

export const closeParamsModal = () => ({
  type: CLOSE_PARAMS_MODAL
});

export const addFilterOption = (option) => ({
  type: ADD_FILTER_OPTION,
  payload: option
});

export const setOldFilterData = (data) => ({
  type: SET_OLD_FILTER_DATA,
  payload: data
});

export const clearNewFilterOption = () => ({
  type: CLEAR_NEW_FILTER_OPTION
});

const getAppealTypesListForFilterSuccess = result => {
  return {
    type: GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS,
    result
  };
};

const getAppealTypesListForFilterError = error => {
  return {
    type: GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR,
    error
  };
};

const fireGetAppealTypesListForFilter = () => ({
  type: GET_APPEAL_TYPES_LIST_FOR_FILTER
});

export const getAppealTypesForFilter = () => dispatch => {
  const params = {
    data: {
      classCode: 0,
      node: 'root'
    }
  };

  dispatch(fireGetAppealTypesListForFilter());

  baseService.get('appeal_type', params)
    .then(data => dispatch(getAppealTypesListForFilterSuccess(data)))
    .catch(err => dispatch(getAppealTypesListForFilterError(err)));
};

export const clearAppealTypesListForFilter = () => {
  return {
    type: CLEAR_APPEAL_TYPES_LIST_FOR_FILTER
  };
};

export const setSelectOptionsForFilter = (options, optionsHistory) => {
  return {
    type: SET_SELECT_OPTIONS_FOR_FILTER,
    options,
    optionsHistory
  };
};

const customerSearchRequest = () => ({
  type: CUSTOMER_SEARCH_REQUEST
});

const customerSearchSuccess = (data) => ({
  type: CUSTOMER_SEARCH_SUCCESS,
  payload: data
});

const customerSearchFailure = () => ({
  type: CUSTOMER_SEARCH_FAILURE
});

export const customerSearch = (params) => dispatch => {
  dispatch(customerSearchRequest());

  baseService.post('customer', params)
      .then(data => dispatch(customerSearchSuccess(data)))
      .catch(err => dispatch(customerSearchFailure(err)));
};
