import React from 'react';
import { forceFocusOnInput } from 'helpers';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import ContactArrayComponent from './components/ContactArrayComponent';
import { FieldTypes } from 'constants/index';
import { reduxForm } from 'redux-form';
import SimpleInput from './components/SimpleInput';
import ComboBoxGroup from './ComboBoxGroup_Deprecated';
import DareTimePicker from './components/DareTimePickerDecorator';
import { translate } from 'react-i18next';
import SearchableComboBoxArray from './components/SearchableComboBoxArray';
import AddressField from './components/AddressField';
import FormFieldArray from './components/FormFieldArray';
import FormField from './components/FormField';
import validate from './validate';
import ComboBox from './components/ComboBoxDecorator';

const FieldComponents = {
    [FieldTypes.SELECT]: ComboBox,
    [FieldTypes.SELECT_POPUP_ARRAY]: SearchableComboBoxArray,
    [FieldTypes.DATE]: DareTimePicker,
    [FieldTypes.ARRAY]: ContactArrayComponent,
    [FieldTypes.GROUP]: ComboBoxGroup,
    [FieldTypes.INPUT]: SimpleInput,
    [FieldTypes.ADDRESS]: AddressField
};

const getDefaultValueByType = fieldType => {
    const DefaultFieldValues = {
        [FieldTypes.SELECT]: undefined,
        [FieldTypes.SELECT_POPUP_ARRAY]: [],
        [FieldTypes.DATE]: '',
        [FieldTypes.ARRAY]: [{value: '', id: 0}],
        [FieldTypes.INPUT]: '',
        [FieldTypes.ADDRESS]: ''
    };
    
    return DefaultFieldValues[fieldType] || DefaultFieldValues[FieldTypes.INPUT];
};

const getActualConfig = createSelector(
    ownProps => ownProps.config,
    ownProps => ownProps.fieldList,
    (config, fieldList) => {
        const groupedConfig = config.reduce((acc, fieldConfig) => {
            acc[fieldConfig.name] = fieldConfig;
            return acc;
        }, {});
        return fieldList.map(fieldName => groupedConfig[fieldName]);
    }
);

const getInitialValues = createSelector(
    ownProps => getActualConfig(ownProps),
    actualConfig => actualConfig.reduce((acc, fieldConfig) => {
        acc[fieldConfig.name] = getDefaultValueByType(fieldConfig.type);
        return acc;
    }, {})
);

const mapStateToProps = (state, ownProps) => ({
    actualConfig: getActualConfig(ownProps),
    initialValues: getInitialValues(ownProps)
});

@translate()
@connect(mapStateToProps)
@reduxForm({validate, enableReinitialize: true, destroyOnUnmount: false, keepDirtyOnReinitialize: true})
class EmptyForm extends React.Component {
    
    formatSelectedValue = options => (value, name) => {
        const selectedOption = options.find(option => option.value === value);
        
        if (!selectedOption) {
            // console.error('SelectedOption is not found! Value: [%s] , Name: [%s]', value, name);
        }
        
        return selectedOption ? selectedOption.label : value;
    };
    
    getTranslatedOptions = dictionaryField => {
        const optionsDictionary = this.props.t(`dictionary:${dictionaryField}`, {returnObjects: true});
        return Object.keys(optionsDictionary).map(key => ({value: key, label: optionsDictionary[key]}));
    };
    
    /**
     * Normalize redux-form field name
     * Example: mobilePhone[2] => mobilePhone
     * Example: birthDate => birthDate
     */
    normalizeFieldName = fieldName => {
        const arrayFieldNameRegexp = /^[\w]+\[\d+\]$/;
        if (arrayFieldNameRegexp.test(fieldName)) {
            return fieldName.substring(0, fieldName.indexOf('['));
        }
        return fieldName;
    };
    
    handleChangeArrayValue = (fieldName, options) => {
        const setDefaultValue = () => this.props.change(this.normalizeFieldName(fieldName), getDefaultValueByType(FieldTypes.ARRAY));
        
        if (options && options.action === 'delete') {
            setDefaultValue();
            setTimeout(() => forceFocusOnInput(this.normalizeFieldName(fieldName), '0', this.props.form), 100);
        } else {
            this.props.handleChangeValue(fieldName, options);
            setDefaultValue();
        }
    };
    
    allowEmpty = validate => (value, allValues, props, name) => {
        if (!value) return undefined;
        
        return validate(value, allValues, props, name);
    };
    
    renderFormField = fieldConfig => {
        const {handleChangeValue, t} = this.props;
        
        const Component = FieldComponents[fieldConfig.type];
        
        switch (fieldConfig.type) {
            
            case FieldTypes.ARRAY: {
                
                return (
                    <FormFieldArray
                        key={fieldConfig.name}
                        name={fieldConfig.name}
                        {...fieldConfig}
                        component={Component}
                        handleChangeValue={this.handleChangeArrayValue}
                        single={true}
                    />
                );
            }
            
            case FieldTypes.SELECT_POPUP_ARRAY: {
                const translatedOptions = this.getTranslatedOptions(fieldConfig.options);
                
                return (
                    <FormFieldArray
                        key={fieldConfig.name}
                        name={fieldConfig.name}
                        {...fieldConfig}
                        options={translatedOptions}
                        component={Component}
                        handleChangeValue={handleChangeValue}
                        single={true}
                    />
                );
            }
            
            case FieldTypes.DATE: {
                return (
                    <FormField
                        component={Component}
                        key={fieldConfig.name}
                        changeOnBlur
                        {...fieldConfig}
                        handleChangeValue={handleChangeValue}
                        onlyDate
                        popperPlacement={'bottom-end'}
                        popperClassName={'parametersDate'}
                        maxDateCurrent
                        allowEmpty
                    />
                );
            }
            
            case FieldTypes.SELECT: {
                const translatedOptions = this.getTranslatedOptions(fieldConfig.options);
                return (
                    <FormField
                        component={Component}
                        key={fieldConfig.name}
                        {...fieldConfig}
                        label={t(`customerInfoLabel.${fieldConfig.label}`)}
                        options={translatedOptions}
                        handleChangeValue={handleChangeValue}
                        format={this.formatSelectedValue(translatedOptions)}
                        placeholder={'-'}
                        backspaceRemoves={false}
                        deleteRemoves={false}
                    />
                );
            }
            
            case FieldTypes.INPUT: {
                return (
                    <FormField
                        component={Component}
                        key={fieldConfig.name}
                        {...fieldConfig}
                        placeholder={'-'}
                        handleChangeValue={handleChangeValue}
                    />
                );
            }
            
            case FieldTypes.ADDRESS: {
                return (
                    <FormField
                        component={Component}
                        key={fieldConfig.name}
                        {...fieldConfig}
                        handleChangeValue={handleChangeValue}
                    />
                );
            }
            
            default:
                return null;
        }
    };
    
    onSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    
    render () {
        const {actualConfig, form} = this.props;
        return (
            <form id={form} onSubmit={this.onSubmit}>
                {actualConfig.map(this.renderFormField)}
            </form>
        );
    }
}

EmptyForm.propTypes = {
    form: PropTypes.string.isRequired,
    config: PropTypes.arrayOf(PropTypes.object).isRequired,
    fieldList: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleChangeValue: PropTypes.func
};

export default EmptyForm;
