import {
  USER_INIT,
  ADD_CARD_TO_HISTORY,
  ADD_CARDS_TO_HISTORY,
  UPDATE_PHONES_LIST
} from 'constants/actions';


const initialState = {
  admin: false,
  domain: '',
  login: '',
  user_photo: '',
  user_role: '',
  roles: {},
  openCardHistory: [],
  userPhones: [],
  operations: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INIT:
      return {
        ...state,
        ...action.info
      };

    case ADD_CARD_TO_HISTORY: // used, refactored
      let openCardHistory = [...state.openCardHistory];
      const { url, name } = action.payload;

      openCardHistory = openCardHistory.filter(el => el.url !== url);

      if (openCardHistory.length === 10) {
        openCardHistory = openCardHistory.slice(1);
      }

      openCardHistory.push({ url, name });

      return {
        ...state,
        openCardHistory
      };

    case ADD_CARDS_TO_HISTORY: // used, refactored
      openCardHistory = [...state.openCardHistory];
      const { tabs } = action.payload;
      openCardHistory = openCardHistory.filter(historyEl => !tabs.find(tabEl => historyEl.url === tabEl.url));
      openCardHistory = [...openCardHistory, ...tabs];

      if (openCardHistory.length > 10) {
        openCardHistory = [...openCardHistory.slice(-10)];
      }
      return {
        ...state,
        openCardHistory
      };

    case UPDATE_PHONES_LIST:
      return {
        ...state,
        userPhones: action.payload.userPhones
      };

    default: return state;
  }
};
