import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { openedFilterAppeal } from 'actions/filter';
import Proxy from 'components/HOC/ContentTabProxy';
import Parameters from './Parameters';
import MainContent from './MainContent';
import 'styles/modules/cardContent.module.scss';
import Loader from 'components/Loader';
import { clearAppealState } from 'actions/appeal';
import { clearEntireCustomerState } from 'actions/customer';
import {destroy} from 'redux-form';

const appealPageForms = [
  'appeal-form',
  'customerInfoFilled',
  'customerInfoEmpty',
  'personInfoFilled',
  'personInfoEmpty'
];

const mapStateToProps = (state) => ({
  saved: state.filter.saved,
  deleted: state.filter.deleted,
  openedFilter: state.filter.openedFilter,
  tabs: state.tabs
});

const mapDispatchToProps = (dispatch) => ({
  openedFilterAppeal: (openedFilter) => dispatch(openedFilterAppeal(openedFilter)),
  clearAppealState: () => dispatch(clearAppealState()),
  clearEntireCustomerState: () => dispatch(clearEntireCustomerState()),
  resetRedundantForms: () => dispatch(destroy(...appealPageForms))
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Appeal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // Props cache.
      openedFilter: props.openedFilter
    };
  }


  componentDidMount() {
    this.props.clearAppealState();
    this.props.clearEntireCustomerState();
    this.props.resetRedundantForms();
  }

  static getDerivedStateFromProps({ history, openedFilter, openedFilterAppeal, data, match, location }, prevState) {
    const filters = data.result;
    if (!prevState.openedFilter && !openedFilter && filters && filters[0] && filters[0].value) {

      history.push(`/appeal/filter/${filters[0].value}`);
      openedFilterAppeal(filters[0].value);
      return { openedFilter: filters[0].value };

    } else if (location.pathname === '/appeal' && filters && filters[0] && filters[0].value) {

      const currentFilter = openedFilter || filters[0].value;
      history.push(`/appeal/filter/${currentFilter}`);
      openedFilterAppeal(currentFilter);
      if (currentFilter !== openedFilter) {
        return { openedFilter: currentFilter };
      }

    }

    return null;
  }

  componentDidUpdate({ data, saved, deleted, tabs, match }, prevState) {
    const { history, refresh } = this.props;

    if (!saved && this.props.saved) {
      refresh(match, tabs);
    }

    if (!deleted && this.props.deleted) {
      this.props.refresh(match, tabs);
      const openedFilter = get(this.props, 'data.result[0].value');
      openedFilter && history.push(`/appeal/filter/${openedFilter}`);
    }
  }

  render() {
    const { data } = this.props;

    if (!data || !data.result || !data.result.length) return <Loader withContainer/>;
    return (
      <Fragment>
        <div className='card appeal full'>
          <div className='wrapper-full'>
            { data.result && <Parameters data={data.result} /> }
            <Route path='/appeal/:tab/:id' render={ (props) => <MainContent {...props} filters={data.result} /> } />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Proxy(Appeal, {
  api: data => ({
    key: 'filters',
    data: { query: '', limit: 25, code: 'INTERACTION_REQUEST' }
  }),
  tabType: data => 'appeal',
  name: data => 'appeal',
  queryKey: data => 'appeal_filters',
  storeName: data => 'appeal_filters'
});


Appeal.propTypes = {
  data: PropTypes.object,
  saved: PropTypes.bool,
  deleted: PropTypes.bool,
  filters: PropTypes.array,
  openedFilter: PropTypes.string,
  openedFilterAppeal: PropTypes.func,
  history: PropTypes.object, 
  refresh: PropTypes.func
};
