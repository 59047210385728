import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { createNewAppeal } from 'actions/appeal';
import { personEdit } from 'actions/customer';
import { setTabName } from 'actions/tabs';
import Avatar from 'components/Avatar';
import EditCustomerNameForm from 'components/CustomerPage/EditCustomerNameForm';
import TabContent from './TabContent';
import Popup from 'components/Popup';
import NewEntityButton from 'components/CustomerPage/NewEntityButton';
import styles from 'styles/modules/customerContent.module.scss';
import { translate } from 'react-i18next';
import { RouteWithTabs } from 'helpers';
import PropTypes from 'prop-types';
import { CustomerTypes } from '../../../constants';
import permissions, { checkPermissions } from '../../../config/permissions';

function mapDispatchToProps (dispatch) {
    return bindActionCreators({
        createNewAppeal,
        personEdit,
        setTabName,
    }, dispatch);
}

function mapStateToProps (state) {
    return {
        managedContactPerson: checkPermissions(permissions.CustomerOperations.action_manageContactPersons),
        createAppeal: checkPermissions(permissions.AppealOperations.action_createAppeal),
        viewContacts: checkPermissions(permissions.CustomerOperations.view_contacts),
        tabs: state.tabs
    };
}

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainContent extends React.Component {
    constructor () {
        super();
        
        this.state = {
            gridPopupIsOpen: false
        };
        
        this.wait = false;
    }
    
    componentDidMount () {
        const { tabs, personName, setTabName } = this.props;
        
        if (!tabs.current.displayedName) {
           setTabName(tabs.current.name, personName);
        }
    }
    componentDidUpdate(prevProps){
        const { tabs, personName, setTabName } = this.props;
		if (personName !== prevProps.personName) {
			setTabName(tabs.current.name, personName);
		}
    }
    
    getEditFormInitialValues = () => {
        const { name } = this.props.contactPerson;
        const fields = ['surname', 'name', 'patronymic'];
        const initialValues = {};
        name.split(' ').forEach((item, index) => {
            const value = fields[index];
            initialValues[value] = item;
        });
        return initialValues;
    };
    
    toggleGridPopup = () => this.setState(prevState => ({ gridPopupIsOpen: !prevState.gridPopupIsOpen }));
    
    handleSubmit = values => {
        const { personEdit, contactPerson } = this.props;
        personEdit({
			data: {
                edit: [
					{ text: values.lastName, field: 'party.lastName' },
					{ text: values.firstName, field: 'party.firstName' },
					{ text: values.patronymic, field: 'party.patronymic' }
				],
				personId: contactPerson.id,
			},
            id: contactPerson.id,
			isMulti: true
        });
    };
    
    onNewAppealClick = () => {
        if (this.wait) return;
        this.wait = true;
        
        const { match, contactPerson } = this.props;
        const newCustomerId = match.params.customerId || contactPerson.links[0].entityId;
        
        const appealRequestData = {
            customerId: newCustomerId,
            contactPersonId: contactPerson.id
        };
        
        this.props.createNewAppeal({ appealRequestData })
            .then(appeal => {
                if (appeal) this.props.history.push(`/appeals/${appeal.id}/knowledge_base`);
                this.wait = false;
            });
    };
    
    renderTabs = props => {
        const { match, location } = this.props;
        switch (props.match.params.subTab) {
        case 'contact':
            return <TabContent viewContacts match={match} location={location}/>;
        case 'link':
            return <TabContent viewContacts match={match} location={location}/>;
        default:
            return null;
        }
    };
    
    render () {
        const {
            t, personName, contactPerson, match, location, managedContactPerson,
            createAppeal, viewContacts, customerId
        } = this.props;
        const { gridPopupIsOpen } = this.state;
        let tabs = ['link'];
        
        if (viewContacts) {
            tabs = ['contact', ...tabs];
        }
        
        return (
            <main className={styles.customerContent}>
                <header className={styles.customerContentHeader}>
                    <div className={styles.headerLeft}>
                        <Avatar name={personName} customerType={CustomerTypes.NATURAL_PERSON}/>
                        <EditCustomerNameForm
                            form={`${customerId}-edit-person-name`}
                            onSubmit={this.handleSubmit}
                            initialValues={
                                {
                                    name: personName.trim(),
                                    firstName: contactPerson.party.firstName,
                                    lastName: contactPerson.party.lastName,
                                    patronymic: contactPerson.party.patronymic
                                }}
                            managedContactPerson={managedContactPerson}
                        />
                    
                    </div>
                    <div className='actions'>
                        {
                            createAppeal ? <NewEntityButton title='appeals' onClick={this.onNewAppealClick}/> : null
                        }
                        
                        <div className='btn-add' onClick={this.toggleGridPopup}>
                            <i className='icon-kebab-vert'/>
                        </div>
                        {(gridPopupIsOpen && !contactPerson) &&
                        <Popup
                            place='customer-grid-popup'
                            onClickOutside={this.toggleGridPopup}
                        >
                            <button className='popup-link'>{t('filters.exportExcel')}</button>
                        </Popup>
                        }
                    </div>
                </header>
                
                <Switch>
                    <RouteWithTabs
                        path='/person/:id/:subTab?'
                        render={this.renderTabs}
                        tabs={tabs}
                        tabPath={'subTab'}
                        match={match}
                        location={location}
                    />
                    <Route exact strict path='/person/:id'
                           render={(props) => <Redirect to={`${props.location.pathname}/appeals`}/>}/>
                    <RouteWithTabs
                        path='/appeals/:appealId/person/:id/:subTab?'
                        render={this.renderTabs}
                        tabs={tabs}
                        tabPath={'subTab'}
                        match={match}
                        location={location}
                    />
                    {/* TODO is correct url? '/appeals/:customerId/:appealId/person/:id' */}
                    <Route exact strict path='/appeals/:customerId/:appealId/person/:id'
                           render={(props) => <Redirect to={`${props.location.pathname}/appeals`}/>}/>
                </Switch>
            
            </main>
        );
    }
}

MainContent.propTypes = {
    contactPerson: PropTypes.object.isRequired,
    personName: PropTypes.string
};

export default MainContent;

