import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import _ from 'lodash';
import GridHead from './GridHead';
import GridCell from './GridCell';
import DeleteInstanceButton from './DeleteInstanceButton';
import GridPopup from './GridPopup';
import {
  MINIMUM_RESIZE_CELL_WIDTH,
  RESIZE_TIMEOUT,
  DRAG_TIMEOUT,
  CLASS_WIDTHS_SET,
  DESC,
  ASC,
} from './gridConfig';
import ResizeObserver from './ResizeObserver';



export default class GridTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsHideMenuIsOpen: false,
      hiddenFieldsCache: null,
      draggedColumnName: null,
      resizedColumnName: null,
      resizeStartX: null,
      defaultCellWidth: null,
      forceWidth: null,
      fixedWidths: {},
      selectedRows: [],
      selectAllRows: false,
    };
    this.selectRow = this.selectRow.bind(this);
    this.selectAllHandler = this.selectAllHandler.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount = () => {
    this.restartHeaderResizeObserver();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.params.fields !== this.props.params.fields) {
      this.restartHeaderResizeObserver();
    }
    if (
      _.get(this.props, 'params.footer.pagination.props.page') !==
      _.get(prevProps, 'params.footer.pagination.props.page')
    ) {
      this.setState({ selectedRows: [], selectAllRows: false });
    }
  }
	

  componentWillUnmount = () => {
    this.removeHeaderResizeObserver();
    document.removeEventListener('mousemove', this.handleMoveResize);
    document.removeEventListener('mouseup', this.handleFinishResize);
    document.removeEventListener('mouseleave', this.handleFinishResize);
  };

  selectAllHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    const { data, params } = this.props;
    let selectedRows = [];
    if (!this.state.selectAllRows) {
      selectedRows = data.map((item) => Number(item[params.defaultId] || item.id));
    }
    this.setState({ selectAllRows: !this.state.selectAllRows, selectedRows });
  }

  selectRow(e) {
    e.stopPropagation();
    e.preventDefault();
    const rows = [...this.state.selectedRows];
    const value = Number(e.target.getAttribute('data-id'));
    const index = rows.indexOf(value);
    let state;
    if (index < 0) {
      state = { selectedRows: [...rows, value] };
      this.setState({ selectedRows: [...rows, value] });
    } else {
      rows.splice(index, 1);
      state = { selectedRows: rows };
    }
    this.setState({ ...state, selectAllRows: false });
  }

  addHeaderResizeObserver = () => {
    if (!this.table) return;

    const firstRowCells = this.table.querySelectorAll('tbody tr:nth-child(1) td');
    Array.from(firstRowCells).forEach((cell) => this.resizeObserver.observe(cell));
  };

  removeHeaderResizeObserver = () => {
    this.resizeObserver.disconnect();
  };

  handleHeaderResize = (entries) => {
    const fixedWidths = { ...this.state.fixedWidths };
    entries.forEach((entry) => {
      const columnName = entry.target.dataset.headerName;
      const newWidth = parseInt(getComputedStyle(entry.target).width, 10);
      fixedWidths[columnName] = newWidth;
    });

    this.setState(() => ({ fixedWidths }));
  };

  restartHeaderResizeObserver = () => {
    this.removeHeaderResizeObserver();
    this.addHeaderResizeObserver();
    this.resizeObserver.fireAll && this.resizeObserver.fireAll(); // if polyfill
  };

  resizeObserver = new ResizeObserver(this.handleHeaderResize);

  // Save DOM refs methods:

  saveTableRef = (ref) => (this.table = ref);

  saveTableWrapperRef = (ref) => (this.tableWrapper = ref);

  saveFixedHeaderRef = (ref) => (this.fixedHeader = ref);

  // Handle click methods:

  handleClick = (event) => {
	  this.props.selectItem(event.currentTarget.getAttribute('data'));
  };
  
  
  preventClickToCheckbox = (event) => {
    event.stopPropagation();
    // If you do not stop the ascent of the event, it will call the function SelectItem().
  };

  // Resize columns methods:

  handleStartResize = (event) => {
    const column = event.currentTarget.closest('th');
    const columnName = column.dataset.headerName;
    this.setState({
      resizedColumnName: columnName,
      resizeStartX: event.pageX,
      defaultCellWidth: column.offsetWidth,
    });

    document.addEventListener('mousemove', this.handleMoveResize, { passive: true });
    document.addEventListener('mouseup', this.handleFinishResize, { passive: true });
    document.addEventListener('mouseleave', this.handleFinishResize, { passive: true });
  };

  handleMoveResize = throttle((event) => {
    const { resizeStartX, defaultCellWidth } = this.state;
    if (!defaultCellWidth) return;

    const newWidth = defaultCellWidth + event.pageX - resizeStartX;
    this.setState({
      forceWidth: newWidth <= MINIMUM_RESIZE_CELL_WIDTH ? MINIMUM_RESIZE_CELL_WIDTH : newWidth,
    });
  }, RESIZE_TIMEOUT);

  handleFinishResize = (event) => {
    const {
      params: { onChangeFields, fields },
    } = this.props;
    const { forceWidth, resizedColumnName } = this.state;

    if (forceWidth) {
      // if there was a drag, not a click.
      const newWidthClass = this.calculateSuitableWidthClass(CLASS_WIDTHS_SET, forceWidth);
      const newFields = { ...fields };
      newFields[resizedColumnName].classWidth = String(newWidthClass);
      newFields[resizedColumnName].width = forceWidth;
      onChangeFields(newFields);
    }
    this.setState({
      resizedColumnName: null,
      resizeStartX: null,
      defaultCellWidth: null,
      forceWidth: null,
    });

    document.removeEventListener('mousemove', this.handleMoveResize);
    document.removeEventListener('mouseup', this.handleFinishResize);
    document.removeEventListener('mouseleave', this.handleFinishResize);
  };

  calculateSuitableWidthClass = (widths, selectedWidth) => {
    return widths.reduce(
      (nearestData, widthSet, index) => {
        const diffSet = widthSet.map((width) => Math.abs(selectedWidth - width));
        const diff = Math.min(...diffSet);
        return diff < nearestData.diff ? { diff, index } : nearestData;
      },
      { diff: 10000, index: 0 },
    ).index;
  };

  handleMaximize = (columnName) => {
    const {
      params: { onChangeFields, fields },
    } = this.props;
    const newFields = { ...fields };
    newFields[columnName].classWidth = String(CLASS_WIDTHS_SET.length - 1);
    onChangeFields(newFields);
  };

  // Hide columns methods:

  toggleColumnsHideMenu = (event) => {
    this.setState({ columnsHideMenuIsOpen: !this.state.columnsHideMenuIsOpen });
  };

  closeColumnsHideMenu = (event) => {
    this.setState({ columnsHideMenuIsOpen: false });
  };

  handleSaveChangeHiddenColumn = (fields) => {
    this.props.params.onChangeFields(fields);
  };

  findDropContainer = (event) => {
    const elementFromPoint = document.elementFromPoint(event.clientX, event.clientY);
    return elementFromPoint && elementFromPoint.closest('th');
  };

  handleDragOver = (event) => {
    event.persist();
    event.preventDefault();
    this.handleDragOverOptimized(event);
  };

  handleDragOverOptimized = throttle((event) => {
    const toColumn = this.findDropContainer(event);
    if (!toColumn) {
      this.setState({ draggedColumnName: null });
    } else if (this.state.draggedColumnName !== toColumn.dataset.headerName) {
      this.setState({ draggedColumnName: toColumn.dataset.headerName });
    }
  }, DRAG_TIMEOUT);

  handleDrop = (event) => {
    const fromColumnName = event.currentTarget.dataset.headerName;
    const toColumn = this.findDropContainer(event);
    if (toColumn) {
      const toColumnName = toColumn.dataset.headerName;
      const { fields, onChangeFields } = this.props.params;

      const fieldsKeys = Object.keys(fields);
      const fromIndex = fieldsKeys.findIndex((key) => key === fromColumnName);
      const toIndex = fieldsKeys.findIndex((key) => key === toColumnName);

      if (fromIndex !== toIndex) {
        const movedItem = fieldsKeys.splice(fromIndex, 1)[0];
        fieldsKeys.splice(toIndex, 0, movedItem);

        const newFields = fieldsKeys.reduce((newFields, fieldKey) => {
          newFields[fieldKey] = fields[fieldKey];
          return newFields;
        }, {});
        onChangeFields(newFields);
      }
    }
    setTimeout(() => this.setState({ draggedColumnName: null }), DRAG_TIMEOUT);
    // timeout is needed so that after drop event does not work drag event.
  };

  // Sort columns methods.

    handleSort = (field, item) => {
        //TODO -> now Disabled temp, when MW can answer of sort request remove this "IF"
        // if(this.props.data[0].type == 'customer' || this.props.data[0].type =='contact_person' ||
        //     this.props.data[0].settlement || this.props.data[0].links || this.props.data[0].idLinkedWith)
          if(!this.props.params.fields['attr0']){
            return;
        }
		//END TO-DO
        const {sort: {handleSort, isReversed, field: sortedField}, data} = this.props;
        if (item.noSorted) return;
        if (!data || !data.length) return;
            if (field !== sortedField) return handleSort({field, isReversed: true});
            
        return handleSort({
            field,
            isReversed: !isReversed
        });
    };

  // Render method:

  render() {
    const { params, data, sort, strings } = this.props;
    if (!data || !data.length) return null;
    const {
      columnsHideMenuIsOpen,
      draggedColumnName,
      resizedColumnName,
      forceWidth,
      fixedWidths,
    } = this.state;
    const fieldsValues = Object.keys(params.fields);

    return (
      <div className="table-grid-wrap" ref={this.saveTableWrapperRef}>
        <table
          className="table-grid grid-fixed-header"
          cellSpacing="0"
          ref={this.saveFixedHeaderRef}
        >
          <thead onDragOver={this.handleDragOver} onDragLeave={this.handleDragOver}>
            <tr>
              {data.find((i) => i.status) && <td className="display-inherit" />}
              {this.props.groups && (
                <td
                  onClick={this.selectAllHandler}
                  className="grid-cell-checkbox"
                  data-header-name="_checkbox"
                >
                  <label className="container">
                    <input type="checkbox" checked={this.state.selectAllRows} readOnly />
                    <span className="check-mark" onClick={this.selectAllHandler} />
                  </label>
                </td>
              )}
              {Object.keys(params.fields).map((field, key) => (
                <GridHead
                  hidden={params.fields[field].hidden}
                  headerName={field}
                  key={key}
                  field={field}
                  item={params.fields[field]}
                  sort={sort}
                  onDragEnd={this.handleDrop}
                  dropHighlight={field === draggedColumnName}
                  widthClassName={params.fields[field].classWidth}
                  onStartResize={this.handleStartResize}
                  forceWidth={field === resizedColumnName ? forceWidth : null}
                  fixedWidth={fixedWidths ? fixedWidths[field] : null}
                  nowResize={!!resizedColumnName}
                  nowDrag={!!draggedColumnName}
                  onSort={this.handleSort}
                  onMaximaze={this.handleMaximize}
                />
              ))}
              <td className="grid-cell-hidemenu" data-header-name="columns-hide-menu">
                <GridPopup
                  isOpen={columnsHideMenuIsOpen}
                  fields={params.fields}
                  handleClose={this.closeColumnsHideMenu}
                  handleToggle={this.toggleColumnsHideMenu}
                  handleSaveChanges={this.handleSaveChangeHiddenColumn}
                />
              </td>
              {params.remove && <td data-header-name="_remove" />}
            </tr>
          </thead>
        </table>

        <table className="table-grid grid-static-body" cellSpacing="0" ref={this.saveTableRef}>
          <tbody>
            {data.map((item, key) => {
              const id = Number(item[params.defaultId] || item.id);
              return (
                <tr
                  key={key}
                  data={item[params.defaultId] || item.id}
                  className={`${this.state.selectedRows.includes(id) ? 'selected' : ''}`}
                  onClick={this.handleClick}
                  onDoubleClick={params.tr && params.tr.dblClick && params.tr.dblClick(id)}
                >
                  {item.status && (
                    <td onClick={this.selectRow} data-id={id} className="grid-cell-label">
                      <label className={`label sticker-${item.status.code}`} />
                    </td>
                  )}
                  {this.props.groups && (
                    <td
                      onClick={this.selectRow}
                      data-id={id}
                      className="grid-cell-checkbox"
                      data-header-name="_checkbox"
                    >
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={this.state.selectedRows.includes(id)}
                          readOnly
                        />
                        <span className="check-mark" onClick={this.selectRow} data-id={id} />
                        <span className={`label sticker-${item.status.code}`} />
                      </label>
                    </td>
                  )}
                  {fieldsValues.map((field, k) => (
                    <GridCell
                      hidden={params.fields[field].hidden}
                      headerName={field}
                      key={k}
                      value={item[field] || ''}
                      params={params.fields[field]}
                      id={id}
                      name={field}
                      data={item}
                      widthClassName={params.fields[field].classWidth}
                      forceWidth={field === resizedColumnName && forceWidth ? forceWidth : null}
                      fixedWidth={fixedWidths ? fixedWidths[field] : null}
                      strings={strings}
					  preventClickToCheckbox={this.preventClickToCheckbox}
                    />
                  ))}
                  <td className="grid-cell-hidemenu" data-header-name="_columns-hide-menu" />
                  {params.remove && <DeleteInstanceButton id={id} params={params} />}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

GridTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  params: PropTypes.object.isRequired,
  groups: PropTypes.object,
  selectItem: PropTypes.func,
  dragColumn: PropTypes.func,
  selected: PropTypes.object,
  sort: PropTypes.object,
  hiddenColumns: PropTypes.array,
  strings: PropTypes.object,
};
