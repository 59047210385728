import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClickOutsideHolder from 'components/ClickOutsideHolder';

class ComboBoxMultipleCheckbox extends Component {
    constructor (props) {
        super(props);
    }
    
    state = {
        showOptions: false
    };
    
    componentDidUpdate (prevProps, prevState) {
        if (!prevState.showOptions && this.state.showOptions) {
            const selectOptions = document.querySelector('.optionsWrapper');
            if (selectOptions.scrollIntoViewIfNeeded) {
                selectOptions.scrollIntoViewIfNeeded(false);
            } else {
                selectOptions.scrollIntoView(false);
            }
        }
    };
    
    getLabel = (value) => {
        const {options} = this.props;
        const option = options.find(option => (option.value === value));
        return option && option.label;
    };
    
    handleChange = (event, option) => {
        const {input} = this.props;
        const newValue = [...input.value];
        if (event.target.checked) {
            newValue.push(option);
        } else {
            newValue.splice(newValue.indexOf(option), 1);
        }
        input.onChange(newValue);
    };
    
    toggleOptions = () => {
        const {disabled, input} = this.props;
        if (!disabled) {
            if (this.state.showOptions) {
                input.onBlur();
            } else {
                input.onFocus();
            }
            
            this.setState(prevState => ({showOptions: !prevState.showOptions}));
        }
    };
    
    hideOptions = () => {
        if (this.state.showOptions) {
            this.props.input.onBlur();
            this.setState({showOptions: false});
        }
        const { input: { value, name }, submitComboboxMulty, submitOnBlur, meta } = this.props;
        if (submitOnBlur && meta.touched) {
            submitComboboxMulty(value, name);
        }
    };
    
    renderCheckboxGroup = () => {
        const {options, input, disabled} = this.props;
        return (
            <div className='optionsWrapper'>
                {options.map((option, index) => (
                    <div className='checkbox' key={index}>
                        <label htmlFor={option.value}>
                            <input
                                type='checkbox'
                                name={`${input.label}[${index}]`}
                                id={option.value}
                                checked={input.value.indexOf(option.value) !== -1}
                                onChange={(event) => this.handleChange(event, option.value)}
                                disabled={disabled}
                            />
                            <span className='checkbox-text'>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };
    
    renderOption = (item, index, arr) => {
        if (this.props.options.find(i => item === i.value)) {
            return (
                <span key={index} className='input-value'>
          {this.getLabel(item)}
                    {arr.length > 1 && index !== arr.length - 1 && ', '}{' '}
        </span>
            );
        }
        return null;
    };
    
    render () {
        const { meta, input, placeholder, disabled } = this.props;
        
        const wrapperClassName = cx('input-element comboBox-multi-checkbox', {
            'isOpen': this.state.showOptions,
            'disabled': disabled
        });
        const buttonClassName = cx('input-field', {
            'input-field__error': meta && meta.touched && meta.error
        });
        
        return (
            <ClickOutsideHolder className={wrapperClassName} onClickOutside={this.hideOptions}>
                <button
                    className={buttonClassName}
                    onClick={this.toggleOptions}
                    type='button'
                >
                    {Array.isArray(input.value) ? input.value.map(this.renderOption) : placeholder}
                </button>
                {this.state.showOptions && this.renderCheckboxGroup()}
            </ClickOutsideHolder>
        );
    }
}

ComboBoxMultipleCheckbox.propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    meta: PropTypes.shape({
        error: PropTypes.string,
        label: PropTypes.string,
        touched: PropTypes.bool
    }).isRequired
};

export default ComboBoxMultipleCheckbox;
