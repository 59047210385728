import {
  ADD_CARD_TO_HISTORY,
  ADD_CARDS_TO_HISTORY,
  UPDATE_PHONES_LIST
} from 'constants/actions';


export const addCardToHistory = ({ url, name }) => ({
  type: ADD_CARD_TO_HISTORY,
  payload: { url, name }
});

export const addCardsToHistory = (tabs) => ({
  type: ADD_CARDS_TO_HISTORY,
  payload: { tabs }
});

export const updatePhonesList = userPhones => ({
  type: UPDATE_PHONES_LIST,
  payload: { userPhones }
});
