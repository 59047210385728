import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import Proxy from 'components/HOC/ContentTabProxy';
import Tab from 'components/Tab';
import GridWrapper from 'components/GridWrapper';
import { openCustomerAddressModal, openCustomerServiceModal } from 'actions/ui';
import { removePreviousTabData, queriesContentUpdate } from 'actions/content';
import { createCheckPropsFunction, extractCustomerFromState } from '../../helpers';
import style from 'styles/modules/customerContent.module.scss';

function mapStateToProps (state, props) {
	const customer = extractCustomerFromState(state, props.match.params.id || props.id);

	return {
		edited: state.customer.edited,
		tabs: state.tabs,
		contentLoading: state.content.contentLoading,
		addressModificationCount: state.ui.addressModificationCount,
		tabContentModificationCount: customer.tabContentModificationCount
	};
}

const mapDispatchToProps = (dispatch) => ({
	openCustomerAddressModal: addressItem => dispatch(openCustomerAddressModal(addressItem)),
	openCustomerServiceModal: () => dispatch(openCustomerServiceModal()),
	removePreviousTabData: tabName => dispatch(removePreviousTabData(tabName)),
	queriesContentUpdate: (params) => dispatch(queriesContentUpdate(params)),
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)

class TabContent extends React.Component {
	constructor (props) {
		super(props);
		this.paging = this.paging.bind(this);
	}

	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);

		if (!prevProps.edited && this.props.edited) {
			this.props.refresh(prevProps.match, prevProps.tabs);
		}

		if (isPropChanged('match.params.subTab') && prevProps.match.params.subTab) {
			this.props.removePreviousTabData(prevProps.match.params.subTab);
		}

		if (isPropChanged('tabContentModificationCount') || (isPropChanged('addressModificationCount') && this.props.match.params.subTab === 'address')) {
			this.props.refresh(this.props.match, this.props.tabs);
		}
	}

	onSelectItem = (item) => {
		const { history, match, openCustomerAddressModal, openCustomerServiceModal } = this.props;
		const { subTab, id } = match.params;

		switch (subTab) {
		case 'persons': {
			history.push(`/person/${item.id}/contact`);
			break;
		}
		case 'appeals': {
			history.push(`/appeals/${item.id}`);
			break;
		}
		case 'address':
			openCustomerAddressModal(item);
			break;
		case 'services':
			openCustomerServiceModal();
			break;

		default:
			null;
		}
	};

	paging (page) {
		this.props.queriesContentUpdate(page);
		this.props.refresh(this.props.match, this.props.tabs);
	}

	render () {
		const { data, match, tabs, tabsNames } = this.props;

		return (
			<div className='contentWrapper'>

				<div id='card-bottom-row' className='column'>
					<div className='top-right'>
						{Object.keys(tabsNames).map((val) => {
							return <Tab val={val} key={val} tab={val} />;
						})}
					</div>
				</div>

				<div className={cx('gridWrapper', style.contentGridTab)}>
					{data.result &&
					<GridWrapper
						data={data}
						tabs={tabs}
						match={match}
						onPageChange={this.paging}
						groups={{
							selectItem: this.onSelectItem
						}}
					/>
					}
				</div>
			</div>
		);
	}
}

export default Proxy(TabContent, {
	api: data => ({
		key: `customer_${data.subTab}`,
		data: { customerId: data.id, limit: 20 },
        method: data.subTab === "appeals" ? 'POST':' GET'
	}),
	tabType: data => {
		if (data) {
			if (data.appealId) {
				return `appeals_${data.appealId}`;
			} else if (data.orderId) {
				return `order_${data.orderId}`;
			} else {
				return `customer_${data.id}`;
			}
		}
	},
	name: data => {
		if (data) {
			if (data.appealId) {
				return `appeals_${data.appealId}`;
			} else if (data.orderId) {
				return `order_${data.orderId}`;
			} else {
				return `customer_${data.id}`;
			}
		}
	},
	queryKey: data => data && data.subTab,
	storeName: data => data && data.subTab
});

TabContent.propTypes = {
	data: PropTypes.object,
	match: PropTypes.object,
	user: PropTypes.object,
	tabs: PropTypes.array,
	tabsNames: PropTypes.object,
	history: PropTypes.object,
};

