import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Proxy from 'components/HOC/ContentTabProxy';
import Tab from 'components/Tab';
import GridWrapper from 'components/GridWrapper';
import { createCheckPropsFunction } from 'helpers';

const tabNames = {
  contact: 'contact',
  link: 'link'
};

const mapStateToProps = state => ({
  tabs: state.tabs,
  modificationCount: state.customer.contactPersonModificationCount
});

@withRouter
@connect(mapStateToProps)
class TabContent extends React.Component {

  componentDidUpdate(prevProps) {
    const isPropChanged = createCheckPropsFunction(prevProps, this.props);

    if (isPropChanged('modificationCount') && this.props.match.params.subTab === 'contact') {
      this.props.refresh(this.props.match, this.props.tabs);
    }
  }

  renderTab = tab => (
    <Tab val={tab} key={tab} tab={tab} />
  );

	onSelectItem = item => { if( this.props.match.params.subTab === 'link')  {
			this.props.history.push(`/customer/${item.entityId}`);
		}
	};

	render() {
    const { data, match, tabs, viewContacts } = this.props;
    if (!viewContacts) {
        delete tabNames.contact;
    }
    if (!data) return null;
    return (
      <div className='contentWrapper'>
        <div id='card-bottom-row' className='column'>
          <div className='top-right'>
            {Object.keys(tabNames).map(this.renderTab)}
          </div>
	
        </div>
        <div className='gridWrapper'>
          <GridWrapper data={data} tabs={tabs} match={match}
					   groups={{
                			selectItem: (item) => this.onSelectItem(item)
             }}/>
        </div>
      </div>
    );
  }
}

export default Proxy(TabContent, {
  api: data => ({
    key: `person_${data.subTab}`,
    data: { personId: data.id }
  }),
  tabType: data => data && data.appealId ? `appeals_${data.appealId}` : `person_${data.id}`,
  name: data => data && data.appealId ? `appeals_${data.appealId}` : `person_${data.id}`,
  queryKey: data => data && data.subTab,
  storeName: data => data && data.subTab
});

TabContent.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
};

