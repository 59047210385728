import { emailValidation, phoneValidation, fieldLevelValidation, requiredFieldsValidation } from 'util/form';
import { createNewContactPersonConfig } from 'constants/index';
import { leftTrimProps } from 'util/form';
const rules = {
    email: emailValidation,
    workPhone: phoneValidation,
};

function validate (values) {
    const errors = {};

    leftTrimProps(values);
    requiredFieldsValidation(values, createNewContactPersonConfig, errors);
    fieldLevelValidation(values, rules, errors);

    return errors;
}

export default validate;
