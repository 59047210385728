import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Field, formValueSelector, registerField, change } from 'redux-form';
import { CSSTransition } from 'react-transition-group';
import styles from 'styles/modules/appealOperatePage.module.scss';
import TextArea from 'components/Common/TextArea';
import Progressbar from 'components/Progressbar';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import {
    addComment,
    uploadFile,
    deleteFile,
    uploadFileToComment,
    deleteFileFromComment,
    updateComment
} from 'actions/comments';
import { translate } from 'react-i18next';
import { reduxFormWrapper } from 'helpers';
import CONF from 'config/conf';
import cx from 'classnames';
import { createSelector } from 'reselect';

const getInitialFormValues = createSelector(
    state => state.comments.comments,
    state => state.comments.editableCommentId,
    (comments, editableCommentId) => comments.find(comment => comment.id === editableCommentId)
);

const formSelector = formValueSelector('comment-form');
const mapStateToProps = state => ({
    added: state.comments.added,
    attachment: state.comments.attachment,
    text: formSelector(state, 'text'),
    editableCommentId: state.comments.editableCommentId,
    initialValues: getInitialFormValues(state),
    uploadCounter: state.comments.uploadCounter,
    uploadingError: state.comments.uploadingError,
    update: state.comments.update,
    adding: state.comments.adding,
    uploading: state.comments.uploading
});

const mapDispatchToProps = dispatch => ({
    addComment: (data, callback) => dispatch(addComment(data, callback)),
    updateComment: (data, callback) => dispatch(updateComment(data, callback)),
    registerNewField: (form, name, type) => dispatch(registerField(form, name, type)),
    uploadFile: (file, id) => dispatch(uploadFile(file, id)),
    deleteFile: (fileId) => dispatch(deleteFile(fileId)),
    uploadFileToComment: (file, appealId, commentId) => dispatch(uploadFileToComment(file, appealId, commentId)),
    deleteFileFromComment: (fileId, editableCommentId) => dispatch(deleteFileFromComment(fileId, editableCommentId))
});

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'comment-form', destroyOnUnmount: false, enableReinitialize: true })
export default class AddCommentForm extends React.Component {
    constructor (props) {
        super(props);
        this.textField = React.createRef();
        this.input = React.createRef();
    }

    modalBody = <div/>;
    state = {
        modalIsOpen: false
    };

    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate (prevProps) {
        const { added, refresh, reset, dispatch, handleEditComment, uploadingError, update } = this.props;

        if (!prevProps.added && added && !prevProps.editableCommentId) {
            reset();
            refresh();
        }

        if (update) {
            dispatch(change('comment-form', 'text', ''));
            handleEditComment(null);
            refresh();
        }

        if (!prevProps.uploadingError && uploadingError) {
            this.openModal('uploadingError');
        }
    }

    handleSubmitForm = (values) => {
        const { addComment, attachment, editableCommentId, updateComment, uploading, closeOnSubmit } = this.props;
        const { appealId } = this.props.match.params;

        if (uploading) return;

        if (editableCommentId) {
            updateComment({
                id: editableCommentId,
                text: values.text,
                attachment: attachment.map(file => file.id)
            }, closeOnSubmit);

        }
        else {
            addComment({
                appealId,
                text: values.text,
                attachment: attachment.map(file => file.id)
            }, closeOnSubmit);
        }

    };

    handleLoadFiles = (e) => {
        const { editableCommentId, uploadFile, uploadFileToComment } = this.props;
        const newFiles = [...e.currentTarget.files];
        const { appealId } = this.props.match.params;

        if (editableCommentId) {
            newFiles.forEach(file => uploadFileToComment(file, appealId, editableCommentId));
        } else {
            newFiles.forEach(file => uploadFile(file, appealId));
        }
    };

    handleDeleteFile = (fileId) => {
        const { editableCommentId, deleteFile, deleteFileFromComment } = this.props;
        if (editableCommentId) {
            deleteFileFromComment(fileId, editableCommentId);
        } else {
            deleteFile(fileId);
        }
        this.input.current.value = '';
    };

    handleClickOutside = (e) => {
        const { text, toggleForms } = this.props;
        const inputClick = this.fileInput && this.fileInput.contains(e.target);
        const checkboxClick = this.checkbox && this.checkbox.contains(e.target);
        const sendButtonClick = this.sendButton && this.sendButton.contains(e.target);

        if (!text && !inputClick && !checkboxClick && !sendButtonClick) {
            toggleForms();
        }
    };

    openModal = (type) => {
        this.renderModalBody(type);
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => this.setState({ modalIsOpen: false });

    renderModalBody = (type) => {
        const { t, uploadingError } = this.props;
        switch (type) {
        case 'fileSize':
            return this.modalBody = (
                <ModalPortal onClose={this.closeModal} className='modal-medium'>
                    <MessageModal
                        danger
                        titleModal={t('error')}
                        contentModalText={t('errorFile')}
                        contentModalDesc={CONF.comments.attachFormats}
                    />
                </ModalPortal>
            );

        case 'uploadingError':
            return this.modalBody = (
                <ModalPortal onClose={this.closeModal} className='modal-small'>
                    <MessageModal
                        danger
                        contentModalText={uploadingError || t('errorMessage')}
                        onClickPrimaryButton={this.closeModal}
                    />
                </ModalPortal>
            );

        default:
            return null;
        }
    };

    renderFile = (file, index) => (
        <div className={styles.fileWrapper} key={index} onClick={() => this.handleDeleteFile(file.id)}>
            <span>{file.name}</span>
            <i className='icon-times'/>
        </div>
    );

    render () {
        const { text, handleSubmit, attachment, editableCommentId, t, uploadCounter, adding } = this.props;
        const shortComment = !text || String(text).replace(/[\s\v\n]/gim, '').length < 1;
        const editMode = !!editableCommentId;
        return (
            <Form
                onSubmit={handleSubmit(this.handleSubmitForm)}
                className={cx(styles.commentForm, { [styles.hasFiles]: attachment && attachment.length })}
            >
                <Field
                    name='text'
                    component={TextArea}
                    placeholder={editMode ? t('comments.editComment') : t('comments.addComment')}
                    rows={1}
                    maxLength={1000}
                    autosize
                    props={{ autoFocus: true }}
                />

                {attachment && attachment.length > 0 &&
                    <div className={styles.commentAttachments}>
                        {attachment.map(this.renderFile)}
                    </div>
                }

                {uploadCounter > 0 && <Progressbar/>}

                <div className={styles.newCommentBottomLine}>

                    <div className={styles.toggleWrapper} ref={(node) => (this.checkbox = node)}>
                    </div>

                    <div className={styles.fileUploaderWrapper} ref={(node) => (this.fileInput = node)}>
                        <label className={styles.fileUploader}>
                            <input
                                type='file'
                                multiple
                                onChange={this.handleLoadFiles}
                                accept={CONF.comments.attachMIMETypes}
                                ref={this.input}
                            />
                            <i className='icon-attach'/>
                        </label>
                        <span>|</span>
                        <button
                            type='submit'
                            disabled={shortComment || adding}
                            ref={(node) => (this.sendButton = node)}
                            className='btn-save'
                        >
                            {editMode ? t('comments.editComment') : t('comments.send')}
                        </button>
                    </div>

                </div>

                <CSSTransition
                    in={this.state.modalIsOpen}
                    classNames='fade'
                    appear={true}
                    enter={true}
                    exit={true}
                    timeout={500}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    {this.modalBody}
                </CSSTransition>

            </Form>
        );
    }
}

AddCommentForm.propTypes = {
    handleSubmit: PropTypes.func,
    toggleForms: PropTypes.func,
    attachment: PropTypes.array,
    editableCommentId: PropTypes.number,
    update: PropTypes.bool
};

