import {
  SEARCH_LIVE_OPEN,
  SEARCH_LIVE_HIDE,
  SEARCH_LIVE_GET_SUPPOSE,
  SEARCH_LIVE_GET_SUPPOSE_SUCCESS,
  SEARCH_LIVE_GET_SUPPOSE_FAIL,
  SEARCH_LIVE_CHANGE_QUERY,
  SEARCH_LIVE_CLEAR_RESULT
} from 'constants/actions';

const initialState = {
  loading: false,
  loaded: false,
  searchResults: [],
  query: '',
  openedSearch: false
};

export default (state = initialState, action) => {

  switch (action.type) {

    case SEARCH_LIVE_OPEN:
      return { ...state, openedSearch: true };

    case SEARCH_LIVE_HIDE:
      return { ...state, openedSearch: false };

    case SEARCH_LIVE_CHANGE_QUERY:
      return { ...state, query: action.query };

    case SEARCH_LIVE_GET_SUPPOSE:
      return { ...state, loading: true, loaded: false, query: action.query };

    case SEARCH_LIVE_GET_SUPPOSE_SUCCESS:
      const { result, facets } = action.result;
      return { ...state, loading: false, loaded: false, searchResults: result, facets };

    case SEARCH_LIVE_GET_SUPPOSE_FAIL:
      return { ...state, loading: false, loaded: false, error: action.err, query: initialState.query };

    case SEARCH_LIVE_CLEAR_RESULT:
      return { ...state, searchResults: [] };

    default: return state;
  }
};
