import React from 'react';
import ComboBox from 'components/Common/ComboBox';
import { Field } from 'redux-form';
import { translate } from 'react-i18next';
import InputField from 'components/Common/InputField';
import styles from 'styles/modules/modal.module.scss';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';

const mapDispatchToProps = dispatch => ({
  change: (field, value) => dispatch(change('add-customer-address', field, value))
});

@connect(null, mapDispatchToProps)
@translate()
class ManualStreetField extends React.Component {

  constructor (props) {
    	super(props);

    	this.streetTypeOptions = this.getStreetTypeOptions();
    	this.onClose = this.onClose.bind(this);
  }

  componentWillUnmount () {
	  this.props.change('streetType', null);
	  this.props.change('streetName', '');
  }

  getStreetTypeOptions () {
	  const streetTypes = this.props.t('dictionary:streetType', { returnObjects: true });
	  return Object.keys(streetTypes).map(key => ({ value: key, label: streetTypes[key] }));
  };

  onClose (event) {
	  event.preventDefault();
	  this.props.onClose();
  };

  render () {
	const { inputProps, required }  = this.props;
	
	return (
		<div className={styles.manualStreetContainer}>
		<Field
			name={'streetType'}
			component={ComboBox}
			options={this.streetTypeOptions}
			label={'Тип'}
			required={required}
		/>
		<Field
			name={'streetName'}
			label={'Назва'}
			component={InputField}
			required={required}
			onClose={this.onClose}
			maxLength={inputProps.maxLength}
		/>
		</div>
	);
	}
}

ManualStreetField.propTypes = {
  onClose: PropTypes.func,
  change: PropTypes.func
};

export default ManualStreetField;
