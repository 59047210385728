class ResizeObserverPolyfill {
  constructor(callback) {
    this.observables = [];
    this.callback = callback;
    this.boundCheck();
  }

  observe(target) {
    this.observables.push({ target, size: { height: target.clientHeight, width: target.clientWidth } });
  }

  unobserve(target) {
    this.observables = this.observables.filter((obj) => obj.target !== target);
  }

  disconnect() {
    this.observables = [];
  }

  fireAll() {
    this.callback(this.observables);
  }

  boundCheck = () => {
    const entries = this.observables.reduce((entries, item) => {
      const currentHeight = item.target.clientHeight;
      const currentWidth = item.target.clientWidth;

      if (item.size.height !== currentHeight || item.size.width !== currentWidth) {
        entries.push({ target: item.target });
        item.size.height = currentHeight;
        item.size.width = currentWidth;
      }

      return entries;
    }, []);

    if (entries.length) {
      this.callback(entries);
    }

    window.requestAnimationFrame(this.boundCheck);
  };
}

export default window.ResizeObserver || ResizeObserverPolyfill; 
