import {
    CREATE_NEW_CUSTOMER_ERROR,
    CREATE_NEW_CUSTOMER_SUCCESS,
    OPEN_ASIDE_TOP_MENU,
    OPEN_CREATE_NEW_CUSTOMER_MODAL,
    CLOSE_CREATE_NEW_CUSTOMER_MODAL,
    OPEN_CUSTOMER_ADDRESS_MODAL,
    CLOSE_CUSTOMER_ADDRESS_MODAL,
    ADDRESS_SAVE,
    OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
    CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
    UI_TOGGLE_SIDE_MENU_ADDITIONS,
    UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
    SHOW_TABS_CLOSE_MENU,
    SHOW_TABS_HIDDEN_MENU,
    TOGGLE_LANG_MENU,
    UI_ADD_SUCCESS_MESSAGE,
    UI_DEL_SUCCESS_MESSAGE,
    UI_MENU_APPS_LOAD,
    UI_MENU_APPS_SHOW,
    UI_MENU_CREATE_SHOW,
    UI_MENU_CREATE_HIDE,
    SUBMITTING_PROCESS_FORM,
    SUBMIT_PROCESS_FORM_SUCCESS,
    SUBMIT_PROCESS_FORM_ERROR,
    UI_SHOW_TOOLTIP,
    UI_HIDE_TOOLTIP,
    UI_SET_TOOLTIP_TEXT,
    OPEN_MESSAGE_MODAL,
    CLOSE_MESSAGE_MODAL,
    SHOW_NOTIFICATION_PANEL,
    HIDE_NOTIFICATION_PANEL,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    OPEN_CUSTOMER_SERVICE_MODAL,
    CLOSE_CUSTOMER_SERVICE_MODAL,
    OPEN_USER_POPUP,
    CLOSE_USER_POPUP,
    OPEN_CREATE_NEW_ORDER_MODAL,
    CLOSE_CREATE_NEW_ORDER_MODAL,
} from '../constants/actions';

const initialState = {
    appsMenu: false,
    createMenu: false,
    expandedSearch: false,
    contentView: 'card',
    wide: false,
    loading: false,
    create: [],
    apps: [],
    showLangMenu: false,
    showHiddenTabs: false,
    showCloseTabsMenu: '',
    openAsideSubMenuId: null,
    showSideMenuAdditions: true,
    showSideMenuAdditionsPerson: true,
    successMessage: false,

    showCreateNewContactPersonModal: false,
    newContactPersonModalContext: null,

    showCreateNewCustomerModal: false,
    newCustomerModalContext: null,

    showCreateNewOrderModal: false,
    newOrderModalContext: null,

    showCustomerServiceModal: false,

    showCustomerAddressModal: false,
    address: null,
    addressModificationCount: 0,

    isAppealFormOpened: false,
    isFormSubmitting: false,
    isProcessFormSubmitted: false,
    submitError: null,
    showTooltip: false,
    tooltipText: '',
    showMessageModal: false,
    formsHistory: [],
    notifications: [],
    showNotificationPanel: false,

    isUserPopupOpen: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_USER_POPUP:
            return { ...state, isUserPopupOpen: true };

        case CLOSE_USER_POPUP:
            return { ...state, isUserPopupOpen: false };

        case UI_MENU_CREATE_SHOW:
            return {
                ...state,
                createMenu: !state.createMenu,
            };

        case UI_MENU_CREATE_HIDE:
            return {
                ...state,
                createMenu: false,
            };

        case UI_MENU_APPS_LOAD:
            return {
                ...state,
                appsMenu: !state.appsMenu,
                apps: action.apps,
            };

        case UI_MENU_APPS_SHOW:
            return {
                ...state,
                appsMenu: !state.appsMenu,
            };

        case SHOW_TABS_HIDDEN_MENU:
            return {
                ...state,
                showHiddenTabs: !state.showHiddenTabs,
            };

        case SHOW_TABS_CLOSE_MENU:
            let showCloseTabsMenu = '';

            if (state.showCloseTabsMenu !== action.payload.id) {
                showCloseTabsMenu = action.payload.id;
            }

            return {
                ...state,
                createMenu: false,
                appsMenu: false,
                showCloseTabsMenu,
            };

        case TOGGLE_LANG_MENU:
            return {
                ...state,
                showLangMenu: action.payload.visible,
            };

        case OPEN_ASIDE_TOP_MENU:
            return {
                ...state,
                openAsideSubMenuId: action.payload.id,
            };

        case UI_ADD_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: true,
            };

        case UI_DEL_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: false,
            };

        case UI_TOGGLE_SIDE_MENU_ADDITIONS:
            return {
                ...state,
                showSideMenuAdditions: !state.showSideMenuAdditions,
            };

        case UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON:
            return {
                ...state,
                showSideMenuAdditionsPerson: !state.showSideMenuAdditionsPerson,
            };

        case OPEN_CREATE_NEW_CUSTOMER_MODAL:
            return {
                ...state,
                showCreateNewCustomerModal: true,
                newCustomerModalContext: action.context,
            };

        case CLOSE_CREATE_NEW_CUSTOMER_MODAL:
            return {
                ...state,
                showCreateNewCustomerModal: false,
                newCustomerModalContext: null,
            };

        case OPEN_CREATE_NEW_ORDER_MODAL: 
            return {
                ...state,
                showCreateNewOrderModal: true,
                newOrderModalContext: action.context
            };
        
        case CLOSE_CREATE_NEW_ORDER_MODAL:
            return {
                ...state,
                showCreateNewOrderModal: false,
                newOrderModalContext: null
            };

        case OPEN_CREATE_NEW_CONTACT_PERSON_MODAL:
            return {
                ...state,
                showCreateNewContactPersonModal: true,
                newContactPersonModalContext: action.context,
            };

        case CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL:
            return {
                ...state,
                showCreateNewContactPersonModal: false,
                newContactPersonModalContext: null,
            };

        case OPEN_CUSTOMER_SERVICE_MODAL:
            return {
                ...state,
                showCustomerServiceModal: true,
            };

        case CLOSE_CUSTOMER_SERVICE_MODAL:
            return {
                ...state,
                showCustomerServiceModal: false,
            };

        case OPEN_CUSTOMER_ADDRESS_MODAL:
            return {
                ...state,
                showCustomerAddressModal: true,
                address: action.payload.address,
            };

        case CLOSE_CUSTOMER_ADDRESS_MODAL:
            return {
                ...state,
                showCustomerAddressModal: false,
                address: null,
            };

        case ADDRESS_SAVE:
            return {
                ...state,
                addressModificationCount: state.addressModificationCount + 1,
            };

        case CREATE_NEW_CUSTOMER_SUCCESS:
            return {
                ...state,
                showCreateNewCustomerModal: false,
                successMessage: true,
            };

        case CREATE_NEW_CUSTOMER_ERROR:
            return {
                ...state,
                showCreateNewCustomerModal: false, // TODO add errorMessage
            };

        case SUBMITTING_PROCESS_FORM:
            return {
                ...state,
                isFormSubmitting: true,
                isProcessFormSubmitted: false,
                submitError: null,
            };

        case SUBMIT_PROCESS_FORM_SUCCESS:
            return {
                ...state,
                isFormSubmitting: false,
                isProcessFormSubmitted: true,
            };

        case SUBMIT_PROCESS_FORM_ERROR:
            return {
                ...state,
                isFormSubmitting: false,
                isProcessFormSubmitted: false,
                submitError: action.error,
            };

        case UI_SET_TOOLTIP_TEXT:
            return {
                ...state,
                tooltipText: action.payload,
            };

        case UI_SHOW_TOOLTIP:
            return {
                ...state,
                showTooltip: true,
            };

        case UI_HIDE_TOOLTIP:
            return {
                ...state,
                showTooltip: false,
            };

        case OPEN_MESSAGE_MODAL:
            return {
                ...state,
                showMessageModal: true,
            };

        case CLOSE_MESSAGE_MODAL:
            return {
                ...state,
                showMessageModal: false,
            };

        case SHOW_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, { ...action.payload, id: Date.now() }],
            };

        case HIDE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((note) => note.id !== action.payload.id),
            };

        case SHOW_NOTIFICATION_PANEL:
            return {
                ...state,
                showNotificationPanel: true,
            };

        case HIDE_NOTIFICATION_PANEL:
            return {
                ...state,
                showNotificationPanel: false,
            };

        default:
            return state;
    }
};
