import React from 'react';
import PropTypes from 'prop-types';


export default function ClosePlayerButton({ onClick }) {
  return (
    <button className='closePlayerButton' onClick={onClick} />
  );
}


ClosePlayerButton.propTypes = {
  onClick: PropTypes.func
};
