import i18n from 'i18next';
import ukTranslations from '../locales/uk/translations.json';
import enTranslations from '../locales/en/translations.json';
import ruTranslations from '../locales/ru/translations.json';

export function getUserLanguage() {
  const NAV = navigator;
  let userLanguage = NAV.languages ? NAV.languages[0] : (NAV.language || NAV.userLanguage);

  if (!(userLanguage === 'ru')) {
    userLanguage = 'en';
  }

  return userLanguage;
}

// const lang = getUserLanguage(); @TODO

i18n
  .init({
    lng: 'uk',
    defaultNS: 'translations',
    ns: ['translations', 'dictionary'],
    fallbackLng: 'uk',
    // debug: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    resources: {
      uk: { translations: ukTranslations },
      ru: { translations: ruTranslations },
      en: { translations: enTranslations },
    },
    react: {
      wait: true
    }
  });

i18n.on('languageChanged', lang => console.log('New Language: %s', lang));
i18n.on('added', (lang, namespace) => console.log('Language: %s, Namespace: %s', lang, namespace));

export default i18n;
