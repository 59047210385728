import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { FieldTypes } from 'constants/index';
import ComboBoxGroup from './ComboBoxGroup_Deprecated';
import { connect } from 'react-redux';
import { getFormInitialValues, reduxForm } from 'redux-form';
import ContactArrayComponent from './components/ContactArrayComponent';
import SearchableComboBoxArray from './components/SearchableComboBoxArray';
import { createCheckPropsFunction } from 'helpers';
import AddressField from './components/AddressField';
import ComboBoxDecorator from './components/ComboBoxDecorator';
import SimpleInput from './components/SimpleInput';
import FormFieldArray from './components/FormFieldArray';
import FormField from './components/FormField';
import get from 'lodash/get';
import validate from './validate';
import DareTimePicker from './components/DareTimePickerDecorator';

function mapStateToProps (state, ownProps) {
    return {
        formInitialValues: getFormInitialValues(ownProps.form)(state)
    };
}

@translate()
@connect(mapStateToProps)
@reduxForm({ validate, enableReinitialize: true, destroyOnUnmount: false, keepDirtyOnReinitialize: true })
class FilledForm extends React.Component {
    
    componentDidMount () {
        this.props.initialize(this.props.initialValuesObject);
    }
    
    componentDidUpdate (prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        
        // when "updateTimestamp" changes - initialValuesObject must be updated too.
        // but when dictionary will be loaded only "initialValuesObject" update.
        if (isPropChanged('updateTimestamp') || isPropChanged('initialValuesObject')) {
            this.props.initialize(this.props.initialValuesObject);
        }
    }
    
    getFieldComponent = fieldType => {
        const FieldComponents = {
            [FieldTypes.SELECT]: ComboBoxDecorator,
            [FieldTypes.SELECT_POPUP_ARRAY]: SearchableComboBoxArray,
            [FieldTypes.DATE]: DareTimePicker,
            [FieldTypes.ARRAY]: ContactArrayComponent,
            [FieldTypes.GROUP]: ComboBoxGroup,
            [FieldTypes.INPUT]: SimpleInput,
            [FieldTypes.ADDRESS]: AddressField,
        };
        
        return FieldComponents[fieldType] || FieldComponents[FieldTypes.INPUT];
    };
    
    formatSelectedValue = options => value => {
        const selectedOption = options.find(option => option.value === value);
        
        return get(selectedOption, 'label', value);
    };
    
    getTranslatedOptions = dictionaryField => {
        const optionsDictionary = this.props.t(`dictionary:${dictionaryField}`, { returnObjects: true });
        return Object.keys(optionsDictionary).map(key => ({ value: key, label: optionsDictionary[key] }));
    };
    
    renderFormField = fieldConfig => {
        const { formInitialValues, handleChangeValue, t, readOnly, isEditAddress, loading } = this.props;
        if (!(fieldConfig.name in formInitialValues)) return null;
        
        const Component = this.getFieldComponent(fieldConfig.type);
        
        switch (fieldConfig.type) {
        
        case FieldTypes.ARRAY: {
            return (
                <FormFieldArray
                    key={fieldConfig.name}
                    name={fieldConfig.name}
                    required={fieldConfig.required}
                    readOnly={readOnly}
                    {...fieldConfig}
                    component={Component}
                    handleChangeValue={handleChangeValue}
                    loading={loading}
                />
            );
        }
        
        case FieldTypes.SELECT_POPUP_ARRAY: {
            const translatedOptions = this.getTranslatedOptions(fieldConfig.options);
            
            return (
                <FormFieldArray
                    key={fieldConfig.name}
                    name={fieldConfig.name}
                    disabled={readOnly}
                    {...fieldConfig}
                    options={translatedOptions}
                    component={Component}
                    loading={loading}
                    handleChangeValue={handleChangeValue}
                />
            );
        }
        
        case FieldTypes.DATE: {
            return (
                <FormField
                    component={Component}
                    key={fieldConfig.name}
                    disabled={readOnly}
                    changeOnBlur
                    {...fieldConfig}
                    handleChangeValue={handleChangeValue}
                    onlyDate
                    popperPlacement={'bottom-end'}
                    popperClassName={'parametersDate'}
                    maxDateCurrent
                />
            );
        }
        
        case FieldTypes.SELECT: {
            const translatedOptions = this.getTranslatedOptions(fieldConfig.options);
            return (
                <FormField
                    component={Component}
                    key={fieldConfig.name}
                    disabled={readOnly}
                    {...fieldConfig}
                    label={t(`customerInfoLabel.${fieldConfig.label}`)}
                    options={translatedOptions}
                    handleChangeValue={handleChangeValue}
                    format={this.formatSelectedValue(translatedOptions)}
                    placeholder={'-'}
                    backspaceRemoves={false}
                    deleteRemoves={false}
                />
            );
        }
        
        case FieldTypes.INPUT: {
            return (
                <FormField
                    component={Component}
                    key={fieldConfig.name}
                    disabled={readOnly}
                    {...fieldConfig}
                    handleChangeValue={handleChangeValue}
                />
            );
        }
        
        case FieldTypes.ADDRESS: {
            return (
                <FormField
                    component={Component}
                    key={fieldConfig.name}
                    disabled={!isEditAddress && readOnly}
                    {...fieldConfig}
                    handleChangeValue={handleChangeValue}
                />
            );
        }
        
        default:
            return null;
        }
    };
    
    onSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    
    render () {
        const { formInitialValues, config, form } = this.props;
        
        if (!formInitialValues) {
            return null;
        }
        
        return (
            <form onSubmit={this.onSubmit} id={form}>
                {config.map(this.renderFormField)}
            </form>
        );
    }
}

FilledForm.propTypes = {
    form: PropTypes.string,
    config: PropTypes.array,
    formInitialValues: PropTypes.object,
    initialValuesObject: PropTypes.object,
    handleChangeValue: PropTypes.func,
    updateTimestamp: PropTypes.string
};

export default FilledForm;
