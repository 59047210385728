import React from 'react';
import PropTypes from 'prop-types';
import { ASC, UP, DOWN } from './gridConfig';


class GridHead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: props.fixedWidth,
        };
        this.handleHeadClick = this.handleHeadClick.bind(this);
        this.handleResizerClick = this.handleResizerClick.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
    }
    
    static getDerivedStateFromProps(nextProps) {
        if (nextProps.forceWidth) {
            return { width: nextProps.forceWidth };
        }
        return null;
    }

    handleHeadClick() {
        const { field, item, onSort } = this.props;
		onSort(field, item);
    };

    handleResizerClick() {
        const { headerName, onMaximaze } = this.props;
        onMaximaze(headerName);
    };

    handleDragStart(event) {
        event.dataTransfer.setData('text/plain', this.props.headerName);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.dropEffect = 'move';
    };

    render() {
        const {
            field, item, sort, dropHighlight, headerName, onDragEnd, onDragOver, widthClassName, onStartResize, nowResize,
            hidden, fixedWidth
        } = this.props;
        const { width } = this.state;
        
        if (hidden) return null;

        const isSorted = sort.field === field;
		const sortIconClass = sort.isReversed ? DOWN : UP;	// temparary solution
        const forcedWidthStyle = {
            minWidth: `${width || item.width || fixedWidth}px`,
            maxWidth: `${width || item.width || fixedWidth}px`,
            width: `${width || item.width || fixedWidth}px`
        };
        
        return (
            <th
                title={item.name}
                data-header-name={headerName}
                className={`head-cell grid-cell-${widthClassName} ${dropHighlight ? 'droppable-cell-hover' : ''}`}
                draggable={!nowResize}
                dropzone='move'
                onDragStart={this.handleDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                style={forcedWidthStyle}
            >
                <div className='head-cell-content' onClick={this.handleHeadClick}>
                    <div className='head-cell-text'>{item.name}</div>
                    {
                        isSorted &&
                        <div className='grid-sort-icon-wrapper'>
                            <i className={`icon-${sortIconClass}`}/>
                        </div>
                    }
                </div>
                <div
                    draggable={false}
                    className='head-cell-resizer'
                    onMouseDown={onStartResize}
                    onDoubleClick={this.handleResizerClick}
                />
            </th>
        );
    };
}

GridHead.propTypes = {
    field: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    sort: PropTypes.object,
    dropHighlight: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onDragOver: PropTypes.func,
    headerName: PropTypes.string.isRequired,
    widthClassName: PropTypes.string,
    onStartResize: PropTypes.func.isRequired,
    forceWidth: PropTypes.number,
    fixedWidth: PropTypes.number,
    nowResize: PropTypes.bool,
    nowDrag: PropTypes.bool,
    onSort: PropTypes.func.isRequired,
    onMaximaze: PropTypes.func,
    hidden: PropTypes.bool
};

export default GridHead;
