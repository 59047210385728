import {
    SEARCH_FILTERS_REQUEST,
    SEARCH_FILTERS_REQUEST_SUCCESS,
    SEARCH_FILTERS_REQUEST_FAILURE,

    FILTER_FIELDS_REQUEST,
    FILTER_FIELDS_REQUEST_SUCCESS,
    FILTER_FIELDS_REQUEST_FAILURE,

    ALL_FILTER_FIELDS_REQUEST,
    ALL_FILTER_FIELDS_REQUEST_SUCCESS,
    ALL_FILTER_FIELDS_REQUEST_FAILURE,

    SET_CURRENT_SEARCH_FILTER,
    CLEAR_SEARCH_FILTERS,
    SET_SEARCH_FILTER_CRITERIA,

    APPEAL_TYPES_REQUEST,
    APPEAL_TYPES_REQUEST_SUCCESS,
    APPEAL_TYPES_REQUEST_FAILURE,
    SEARCH_FILTERS_DYNAMIC_STATE_RESET,
} from 'constants/actions';
import pick from 'lodash/pick';

const initialState = {
    filterList: [],
    isFilterListLoading: false,

    filterFields: [],
    areFilterFieldsLoading: false,

    allFilterFields: [],
    areAllFilterFieldsLoading: false,

    currentFilter: null,
    filterCriteria: [],

    appealTypes: [],
    areAppealTypesLoading: false
};

function searchFilterReducer(state = initialState, action) {
    switch (action.type) {

        /********  Search filters request  ********/

        case SEARCH_FILTERS_REQUEST:
            return { ...state, isFilterListLoading: true };

        case SEARCH_FILTERS_REQUEST_SUCCESS:
            return { ...state, isFilterListLoading: false, filterList: action.filterList };

        case SEARCH_FILTERS_REQUEST_FAILURE:
            return { ...state, isFilterListLoading: false, filterList: [] };

        /********  Set current search filter  ********/

        case SET_CURRENT_SEARCH_FILTER:
            return { ...state, currentFilter: action.currentFilter };

        /********  Clear search filters  ********/

        case CLEAR_SEARCH_FILTERS:
            return initialState;

        /********  Set search filter criteria  ********/

        case SET_SEARCH_FILTER_CRITERIA:
            return { ...state, filterCriteria: action.filterCriteria };

        /********  Filter fields request  ********/

        case FILTER_FIELDS_REQUEST:
            return { ...state, areFilterFieldsLoading: true };

        case FILTER_FIELDS_REQUEST_SUCCESS:
            return { ...state, areFilterFieldsLoading: false, filterFields: action.filterFields };

        case FILTER_FIELDS_REQUEST_FAILURE:
            return { ...state, areFilterFieldsLoading: false, filterFields: [] };

        /********  All filter fields request  ********/

        case ALL_FILTER_FIELDS_REQUEST:
            return { ...state, areAllFilterFieldsLoading: true };

        case ALL_FILTER_FIELDS_REQUEST_SUCCESS:
            return { ...state, areAllFilterFieldsLoading: false, allFilterFields: action.allFilterFields };

        case ALL_FILTER_FIELDS_REQUEST_FAILURE:
            return { ...state, areAllFilterFieldsLoading: false, allFilterFields: [] };

        /********  Appeal types request  ********/

        case APPEAL_TYPES_REQUEST:
            return { ...state, areAppealTypesLoading: true };

        case APPEAL_TYPES_REQUEST_SUCCESS:
            return { ...state, areAppealTypesLoading: false, appealTypes: action.appealTypes };

        case APPEAL_TYPES_REQUEST_FAILURE:
            return { ...state, areAppealTypesLoading: false, appealTypes: [] };

        /********  Reset dynamic part of state  ********/

        case SEARCH_FILTERS_DYNAMIC_STATE_RESET:
            const dynamicPart = pick(initialState, [
                'filterList',
                'isFilterListLoading',
                'filterFields',
                'areFilterFieldsLoading',
                'currentFilter',
                'filterCriteria',
            ]);
            return {...state, ...dynamicPart};

        default:
            return state;
    }
}

export default searchFilterReducer;
