import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';

export default class HttpErrorNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: false,
        };
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState({ details: true });
    }

    close() {
        this.setState({ details: false });
    }

    render() {
        const { message } = this.props;
        const re = /ORA-\d+:/;
        let title = message;
        let ora = false;
        if (re.test(message)) {
            const messageArray = message.split(re);
            title = messageArray[0] || messageArray[1];
            ora = true;
        }
        const { details } = this.state;
        return (
            <div className={styles.httpErrorNote}>
                <i className='icon-error' />
                <div className='m-t-8'>{title}</div>
                {
                    (details && ora) && (
                        <div className='m-t-10'>{message}</div>
                    )
                }
                {
                    (details && ora) && (
                        <button onClick={this.close} type='button' className='error-toggle-button'>
                            Менше <span className='top'>&#8963;</span>
                        </button>
                    )
                }

                {
                    (!details && ora) && (
                        <button onClick={this.open} type='button' className='error-toggle-button m-t-10'>
                            Більше <span>&#8964;</span>
                        </button>
                    )
                }
            </div>
        );
    }
}

HttpErrorNote.propTypes = {
    message: PropTypes.string.isRequired,
};
