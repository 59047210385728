import React from 'react';
import PropTypes from 'prop-types';


export default function AudioPlayerBox({ children, position, ...props }) {
  const playerPositionStyle = { bottom: `${position.bottom}px`, right: `${position.right}px` };

  return (
    <div 
      className='audioPlayerBox' 
      style={playerPositionStyle}
      {...props}
    >
      {children}
    </div>
  );
}

AudioPlayerBox.propTypes = {
  children: PropTypes.any,
  position: PropTypes.shape({
    bottom: PropTypes.number,
    right: PropTypes.number
  })
};
