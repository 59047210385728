import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { StreetModes } from 'constants/index';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import StreetOption from './StreetOption';
import ManualStreetField from './ManualStreetField';

const NEW_STREET_OPTION = { value: -1, label: 'Додати нову', type: 'new', disabled: true };

const optionsSelector = createSelector(
    ownProps => ownProps.options,
    options => [...options, NEW_STREET_OPTION]
);

const mapStateToProps = (state, ownProps) => ({
    formattedOptions: optionsSelector(ownProps)
});

@connect(mapStateToProps)
class StreetField extends React.Component {
    
    filterStreetOption = (option, filter) => {
        const filterLowercase = filter.toLowerCase();
        const optionLabel = option.label.toLowerCase();
        
        const isIncludesFilter = optionLabel.indexOf(filterLowercase) !== -1;
        const isNewStreetOption = option.value === NEW_STREET_OPTION.value;
        
        return isIncludesFilter || isNewStreetOption;
    };
    
    renderStreetOption = props => {
        return <StreetOption {...props} onCreateNewStreet={() => this.props.onModeChange(StreetModes.MANUAL)}/>;
    };
    
    render () {
        const {
            fieldConfig,
            formattedOptions,
            onInputChange,
            onChange,
            mode,
            id,
            openOnFocus,
            onRefReady,
            changeField,
            required
        } = this.props;
        
        if (mode === StreetModes.MANUAL) {
            const onClose = () => this.props.onModeChange(StreetModes.AUTO);
            
            return (
                <ManualStreetField onClose={onClose} change={changeField} required={required} />
            );
        }
        
        return (
            <Field
                {...fieldConfig}
                options={formattedOptions}
                onInputChange={onInputChange}
                onChange={onChange}
                filterOption={this.filterStreetOption}
                optionComponent={this.renderStreetOption}
                id={id}
                openOnFocus={openOnFocus}
                onRefReady={onRefReady}
            />
        );
    }
}

StreetField.propTypes = {
    fieldConfig: PropTypes.object,
    options: PropTypes.array,
    onInputChange: PropTypes.func,
    onChange: PropTypes.func,
    // mode: PropTypes.oneOf([ StreetModes.MANUAL, StreetModes.AUTO ]),
    mode: PropTypes.oneOf(['manual', 'auto']),
    onModeChange: PropTypes.func,
    onRefReady: PropTypes.func,
    openOnFocus: PropTypes.bool,
    changeField: PropTypes.func
};

export default StreetField;
