import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

export const REQUIRED_FIELD = 'Обов\'язкове поле';
export const PHONE_NUMBER = 'Введіть коректний номер телефону (від 3 до 12 цифр)';
export const EMAIL = 'Введіть корректний email';

// https://en.wikipedia.org/wiki/Ukrainian_alphabet
export const ukrainian_alphabet = '\u0404\u0406\u0407\u0410-\u0429\u042C\u042E-\u0449\u044C\u044E\u044F\u0454\u0456\u0457\u0490\u0491';

export const emailRegexp = /^([-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-011\013\014\016-\177])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
export const nameRegexp = new RegExp(`^[${ukrainian_alphabet}\'-]+$`);

export const validDate = /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/;

// 191233434555   =>   +19(123)34-34-555
// 191233434   =>   +19(123)34-34
export const formatPhone = value => {
    if (value) {
        let formattedValue = '';
        // phone format: +xx(xxx)xx-xx-xxx
        formattedValue = addPhonePart(formattedValue, value, `+${value.substring(0, 2)}`, 1);
        formattedValue = addPhonePart(formattedValue, value, `(${value.substring(2, 5)}`, 3);
        formattedValue = addPhonePart(formattedValue, value, `)${value.substring(5, 7)}`, 6);
        formattedValue = addPhonePart(formattedValue, value, `-${value.substring(7, 9)}`, 8);
        formattedValue = addPhonePart(formattedValue, value, `-${value.substring(9, 12)}`, 10);
        return formattedValue;
    }
    return '';
};

export const addPhonePart = (formattedValue, value, part, length) => {
    if (value.length >= length) {
        return formattedValue.concat(part);
    }
    return formattedValue;
    
};

// +1(123)34-34-555   =>   11233434555
export const parsePhone = value => {
    if (/\d/.test(value)) {
        return value.replace(/\D+/g, '');
    }
    return '';
    
};

export const parseWord = value => {
    // https://en.wikipedia.org/wiki/Ukrainian_alphabet
    const ukrainian_word = new RegExp(`[^${ukrainian_alphabet}\'-]+`, 'g');
    if (value) {
        return value.replace(ukrainian_word, '');
    }
    return '';
};

// { someProp: 'uKraiNiaN' }   =>   { someProp: 'Ukrainian' }
export const capitalizeProps = (data, propsNames) => {
    if (propsNames && Array.isArray(propsNames)) {
        propsNames.forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = capitalize(data[prop]);
            }
        });
    } else {
        Object.keys(data).forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = capitalize(data[prop]);
            }
        });
    }
};

// { someProp: '   some prop    ' }   =>   { someProp: 'some prop' }
// { someProp: '       ' }   =>   { someProp: '' }
export const trimProps = (data, propsNames) => {
    if (propsNames && Array.isArray(propsNames)) {
        propsNames.forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = data[prop].trim();
            }
        });
    } else {
        Object.keys(data).forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = data[prop].trim();
            }
        });
    }
};
export const leftTrimProps = (data, propsNames) => {
    if (propsNames && Array.isArray(propsNames)) {
        propsNames.forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = data[prop].trimLeft();
            }
        });
    } else {
        Object.keys(data).forEach(prop => {
            if (data[prop] && typeof(data[prop]) === 'string') {
                data[prop] = data[prop].trimLeft();
            }
        });
    }
};

export const validateEmail = email => emailRegexp.test(email);

export const normalizeDate = (value) => {
    if (!value) {
        return value;
    }
    
    const onlyNums = String(value).replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2);
    }
    return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 4) + '.' + onlyNums.slice(4, 8);
};

export const normalizeDateTime = (value) => {
    if (!value) {
        return value;
    }
    
    const onlyNums = String(value).replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2);
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 4) + '.' + onlyNums.slice(4, 8);
    }
    if (onlyNums.length <= 10) {
        return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 4) + '.' + onlyNums.slice(4, 8) + ' ' + onlyNums.slice(8, 10);
    }
    return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 4) + '.' + onlyNums.slice(4, 8) + ' ' + onlyNums.slice(8, 10) + ':' + onlyNums.slice(10, 12);
};

export const Normalizers = {
    digitsOnly: (value, previousValue) => /^\d*$/.test(value) ? value : previousValue
};

export function emailValidation(email) {
    if (!emailRegexp.test(email)) {
        return EMAIL;
    }
}

export function individualTaxpayerNumber(value) {
    if (value && !/^\d{8}$|^\d{10}$/.test(value)) {
        return 'Введіть коректний код ЄДРПОУ/ІНН (8 або 10 цифр)';
    }
    
    return undefined;
}

export function phoneValidation(phone) {
    if (phone && !/^[0-9]{3,12}$/.test(phone)) {
        return PHONE_NUMBER;
    }
    
    return undefined;
}


export function certificateIdValidation(id) {
    if (id && !/^[0-9]{1,20}$/.test(id)) {
        return 'Введіть коректний номер свідоцтва (до 20 цифр)';
    }
    
    return undefined;
}

export function fieldLevelValidation(values, rules, errors) {
    for (const fieldName in values) {
        if (values.hasOwnProperty(fieldName)) {
            const validator = rules[fieldName];
            
            if (!validator) continue;
            
            const error = validator(values[fieldName]);
            if (error) {
                errors[fieldName] = error;
            }
        }
    }
}

export function requiredFieldsValidation(values, config, errors) {
    config.forEach(field => {
        if (field.required && (isEmpty(values[field.name]) || values[field.name][0] ==='undefined')) {
            errors[field.name] = REQUIRED_FIELD;
        }
    });
}

export function zipCodeValidation(value) {
    if (value && !/^\d{5}$/.test(value)) {
        return 'Введіть коректний поштовий індекс (5 цифр)';
    }
    
    return undefined;
}
