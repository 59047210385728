import { 
  SET_AUDIO_TRACK,
  SHOW_AUDIO_PLAYER, 
  HIDE_AUDIO_PLAYER,
  SAVE_AUDIO_TRACK_TIME,
  SAVE_AUDIO_PLAYER_POSITION
} from 'constants/actions';


const initialState = {
  source: '',
  title: 'Elvis Presley - Blue Suede Shoes',
  currentSecond: 0,
  autoplay: true,
  hidden: true,
  position: { bottom: 50, right: 100 }
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIO_TRACK: {
      const cachedCurrentSecond = state.source === action.source ? state.currentSecond : undefined;

      return {
        ...state,
        source: action.source,
        title: action.title,
        currentSecond: action.currentSecond || cachedCurrentSecond || initialState.currentSecond,
        autoplay: action.autoplay || initialState.autoplay,
        hidden: action.hidden || initialState.hidden
      };
    }

    case SAVE_AUDIO_TRACK_TIME: {
      return {
        ...state,
        currentSecond: action.currentSecond
      };
    }

    case SHOW_AUDIO_PLAYER: {
      return {
        ...state,
        hidden: false
      };
    }

    case HIDE_AUDIO_PLAYER: {
      return {
        ...state,
        hidden: true
      };
    }

    case SAVE_AUDIO_PLAYER_POSITION: {
      return {
        ...state,
        position: action.position
      };
    }

    default:
      return state;
  }
};
