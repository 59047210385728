import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import ModalPortal from 'components/ModalPortal'
import { CSSTransition } from 'react-transition-group'

import styles from 'styles/modules/appealQuality.module.scss'
import { appealQualityConfig } from 'constants/index'
import AppealQualityRadio from './Radio'
import AppealQualityRank from './Rank'

@translate()
export default class AppealQuality extends PureComponent {
    static handleSubmit(e) {
        e.preventDefault()
    }

    constructor (props) {
        super(props)
        this.state = {
            quality: 0,
            questions: {},
        };
        this.renderRanks = this.renderRanks.bind(this);
        this.renderItemByType = this.renderItemByType.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.constructor.handleSubmit;
    }

    componentDidMount () {
        const { appeal, querstionParams } = this.props;
                this.setState({questions: querstionParams});
    }



    renderRanks() {
        // return this.props.querstionParams.map(block => this.renderItemByType({...block}));
    }

    renderItemByType(props) {
        const { bloks } = props;
        const { t } = this.props;

        switch (bloks.type) {
            case 'star':
                return (
                    <AppealQualityRank
                        key={name}
                        name={name}
                        title={t(title)}
                        numbers={props.numbers}
                        rank={this.state[name]}
                        onChange={this.onChange}
                    />
                );

            case 'radio':
                return (
                    <AppealQualityRadio
                        key={name}
                        name={name}
                        title={t(title)}
                        list={props.list}
                        onChange={this.onChange}
                    />
                );

            default:
                return null;
        }
    }

    onChange(field, value) {
        const {questions} = this.state;
        questions[field] = value;
        let sum = 0
        for (let key in  questions) {
            sum += parseInt(questions[key]);
        }
        this.setState({quality: sum});
    }

    render () {
        const { appeal, t, onClose, onMin } = this.props;
        const { questions } = this.state;
        return (
            <CSSTransition
                in={true}
                classNames='fade'
                appear={true}
                enter={true}
                exit={true}
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <ModalPortal
                    onClose={onClose}
                    className='modal-medium'
                    onMin={onMin}
                >
                    <div className={styles.appealWindow}>
                        <div className={styles.appealQualityWindowHeader}>
                            <div className={styles.appealQualityWindowTitle}>{t('quality.Title')} ID {appeal.id}</div>
                        </div>
                        <div className={styles.appealQualityWindowForm}>
                            <form onSubmit={this.handleSubmit}>
                                <div className={styles.appealQualityForm}>
                                    <ul>
                                        {this.renderRanks()}
                                    </ul>
                                </div>
                            </form>
                            <div className={styles.appealFinal}>
                                <div className={styles.appealFinalContainer}>
                                    <span className={styles.appealFinalQualityLabel}>ЗАГАЛЬНА ОЦІНКА</span> <span
                                    className={styles.appealFinalQuality}>{this.state.quality}</span>
                                </div>
                                <div className={styles.appealFinalContainer}>
                                    <button className='btn btn-primary btn-right'>
                                        {t('appeal.save')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalPortal>
            </CSSTransition>
        )
    }
}

AppealQuality.propTypes = {
    appealId: PropTypes.string,
    onClose: PropTypes.func
}

