import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from 'components/Common/ComboBox';
import InputField from 'components/Common/InputField';
import { Field } from 'redux-form';
import { reduxFormWrapper } from 'helpers';
import FormWrapper from '../FormWrapper';
import { translate } from 'react-i18next';
import validate from './validate';

export const organizationConfig = [
    { name: 'businessType', label: "Тип суб'єкту", component: ComboBox, },
    {
        name: 'shortName', label: 'Назва', component: InputField, required: true, maxLength: 255, errorPlaceholder: true
    },
    {
        name: 'ownershipType', label: 'Форма власності', component: ComboBox, maxLength: 255,
    },
    {
        name: 'institutionId', label: 'ЄДРПОУ', component: InputField, required: true, maxLength: 255, errorPlaceholder: true
    },
    {
        name: 'taxid', label: '№ свідоцтва', component: InputField, required: true, maxLength: 255, errorPlaceholder: true
    }
];

@translate()
@reduxFormWrapper({ form: 'organization-form', validate })
class OrganizationForm extends React.Component {

    constructor(props) {
        super(props);
        this.businessTypes = this.convertDictionaryObject(props.t('dictionary:businessType', { returnObjects: true }));
        this.ownershipTypes = this.convertDictionaryObject(props.t('dictionary:ownershipType', { returnObjects: true }));
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pristine && prevProps.pristine) {
            this.props.enableWarning({ organization: true });
        } else if (this.props.pristine && !prevProps.pristine) {
            this.props.enableWarning({ organization: false });
        }
    }

    convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
        value: prop,
        label: value
    }));

    getFieldPropsWithOptions = fieldProps => {
        switch (fieldProps.name) {
        case 'businessType':
            return { ...fieldProps, options: this.businessTypes };
        case 'ownershipType':
            return { ...fieldProps, options: this.ownershipTypes };

        default:
            return fieldProps;
        }
    };

    renderField = fieldConfig => {
        return (
            <Field key={fieldConfig.name} {...this.getFieldPropsWithOptions(fieldConfig)} />
        );
    };

    render() {
        const { handleSubmit, customerType } = this.props;
        return (
            <FormWrapper
                title={'Дані про юридичну особу'}
                onSubmit={handleSubmit}
                customerType={customerType}
            >
                {organizationConfig.map(this.renderField)}
            </FormWrapper>
        );
    }
}

OrganizationForm.propTypes = {
    customerType: PropTypes.string,
    onSubmit: PropTypes.func,
};

export default OrganizationForm;
