import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import InputField from 'components/Common/InputField';
import validate from './validate';
import { ukrainian_alphabet } from 'util/form';
import { translate } from 'react-i18next';
import { reduxFormWrapper, isOrganization } from 'helpers';

import { KeyCodes } from '../../../constants';

const ukrainian_word = `[${ukrainian_alphabet}\'-]*`;
const space = '[ ]?';
const regex = new RegExp('^' + ukrainian_word + space + ukrainian_word + space + ukrainian_word + '$');

@translate()
@reduxFormWrapper({ validate, enableReinitialize: true, destroyOnUnmount: false, keepDirtyOnReinitialize: true })
export default class EditCustomerNameForm extends React.Component {
    
    state = {
        isInEdit: false
    };
    
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
	
	UNSAFE_componentWillReceiveProps ( nextProps ) {

        if ( nextProps.form !== this.props.form ) {
            this.setState({isInEdit: false});
            this.props.initialize(this.props.initialValues);
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };
    
    startEditHandler = () => {
        if (this.props.managedContactPerson) {
            this.setState({isInEdit: true});
        }
    };
    
    finishEditHandler = () => this.setState({isInEdit: false});
    
    submitForm = (event) => {
        event.preventDefault();
        if (this.props.valid) {
            this.finishEditHandler();
            this.props.submit(this.props.handleSubmit);
        }
    };
    
    handleKeyPress = (e) => {
        if (e.keyCode === KeyCodes.ESCAPE) {
            this.props.reset();
            this.finishEditHandler();
            document.activeElement.blur();
        } else if (e.keyCode === KeyCodes.ENTER) {
            document.activeElement.blur();
        }
    };
    
    normalizeFullName = (value, previousValue) => {
        if (value) {
            let trimmedValue = value;
            if (value.startsWith(' ')) {
                trimmedValue = trimmedValue.substring(1);
            }
            trimmedValue = value.replace(/\s{2}/g, ' ');
            return regex.test(trimmedValue) ? trimmedValue : previousValue;
        }
        return value;
    };
    
    onPatronymicKeyDown = (e) => {
        if (e.keyCode === KeyCodes.TAB) {
            e.preventDefault();
            this.lastName.focus();
        } else if (e.keyCode === KeyCodes.BACKSPACE && !e.target.value) {
            this.firstName.focus();
        }
    };
    
    onFirstNameKeyDown = (e) => {
        if (e.keyCode === KeyCodes.BACKSPACE && !e.target.value) {
            e.preventDefault();
            this.lastName.focus();
        }
    };
    
    render () {
        const { t, customerType, managedContactPerson } = this.props;
        const { isInEdit } = this.state;
        return (
            <form
                onSubmit={this.submitForm}
                className='form-inline margin-right'
                ref={this.setWrapperRef}
                onFocus={this.startEditHandler}
            >
                {!isInEdit && (
                    <Field
                        component={InputField}
                        name={'name'}
                        // normalize={this.normalizeFullName}
                        additionalInputClassName='fullName'
                        fullWidth
                        autoComplete='off'
                        autoFocus={false}
                        errorPlaceholder
                        maxLength='255'
                        disabled={!managedContactPerson}
                    />
                )}
                {isInEdit && isOrganization(customerType) && (
                    <Fragment>
                        <Field
                            component={InputField}
                            name={'name'}
                            additionalInputClassName='headerOfficialName'
                            label={t('customerInfoLabel.officialName')}
                            fullWidth
                            autoComplete='off'
                            autoFocus={true}
                            errorPlaceholder
                            maxLength='255'
                        />
                    </Fragment>
                )}
                {isInEdit && !isOrganization(customerType) && (
                    <Fragment>
                        <Field
                            component={InputField}
                            name={'lastName'}
                            // normalize={this.normalizeFullName}
                            label={t('customerInfoLabel.lastName')}
                            additionalInputClassName='headerLastName'
                            // fullWidth
                            autoComplete='off'
                            autoFocus={true}
                            errorPlaceholder
                            maxLength='50'
                            setFieldToBeFocused={lastName => {this.lastName = lastName;}}
                        />
                        <Field
                            component={InputField}
                            name={'firstName'}
                            // normalize={this.normalizeFullName}
                            additionalInputClassName={'headerFirstName'}
                            label={t('customerInfoLabel.firstName')}
                            // fullWidth
                            autoComplete='off'
                            // autoFocus={false}
                            errorPlaceholder
                            maxLength='50'
                            onKeyDown={this.onFirstNameKeyDown}
                            setFieldToBeFocused={firstName => {this.firstName = firstName;}}
                        />
                        <Field
                            component={InputField}
                            name={'patronymic'}
                            // normalize={this.normalizeFullName}
                            additionalInputClassName={'headerPatronymic'}
                            label={t('customerInfoLabel.patronymic')}
                            // fullWidth
                            autoComplete='off'
                            // autoFocus={false}
                            errorPlaceholder
                            maxLength='50'
                            onKeyDown={this.onPatronymicKeyDown}
                            setFieldToBeFocused={patronymic => {this.patronymic = patronymic;}}
                        />
                    </Fragment>
                )}
                {isInEdit && (
                    <button className='btn-save'>
                        <i className='icon-check'/>
                        {t('save')}
                    </button>
                )}
            </form>
        );
    }
}

EditCustomerNameForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    submit: PropTypes.func,
    handleSubmit: PropTypes.func,
    customerType: PropTypes.string
};
