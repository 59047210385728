export const customerLinks = [
    {
        id: '28241843',
        name: 'dashboard',
        title: 'Панель моніторингу',
        description: 'Статистичні дані',
    },
    {
        id: '28735821',
        name: 'appeal',
        title: 'Звернення',
        description: 'Звернення заявників',
    },
    {
        id: '28241842',
        name: 'knowledge_base',
        title: 'База знань',
        description: 'База знань',
    },
    {
        id: '28242334',
        name: 'identity',
        title: 'Ідентіті',
        description: 'Ідентіті',
    },
    {
        id: '111111',
        name: 'orders',
        title: 'Заявки',
        description: 'Заявки',
    },
];

export const settingsLink = {
    id: '123151',
    name: 'settings',
    title: 'settings',
    description: '',
    link: '/settings/controlUsers/domains',
};

export const settingsNavigation = [
    {
        name: 'controlUsers',
        route: '/settings/controlUsers',
        id: 1,
        exact: true,
        children: [
            {
                name: 'domains',
                route: '/settings/controlUsers/domains',
                id: 101,
            },
            {
                name: 'groups',
                route: '/settings/controlUsers/groups',
                id: 102,
            },
            {
                name: 'users',
                route: '/settings/controlUsers/users',
                id: 103,
            },
            {
                name: 'roles',
                route: '/settings/controlUsers/roles',
                id: 104,
            },
            {
                name: 'bu',
                route: '/settings/controlUsers/bu',
                id: 105,
            },
        ],
    },
];
