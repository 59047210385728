import React from 'react';

export default function TicketIcon() {
    return (
        <div className='svg-icon-ticker'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 15'>
                <defs>
                </defs>
                <title>Asset 145</title>
                <g id='Layer_2' data-name='Layer 2'>
                    <g id='Layer_74' data-name='Layer 74'>
                        <path className='cls-1'
                              d='M10,15H2a2,2,0,0,1-2-2V3A2,2,0,0,1,2,1V3h8V1a2,2,0,0,1,2,2V13A2,2,0,0,1,10,15Zm0-8H2V8h8Zm0,2H2v1h8Zm0,2H2v1h8ZM3,0H9V2H3Z'/>
                    </g>
                </g>
            </svg>
        </div>
        
    );
}