import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import { translate } from 'react-i18next';

@translate()
class MailingHeader extends React.Component {

  render() {
    const { t, isToggleAllActive, onToggleAll, onQueryChange, searchQuery } = this.props;
    const toggleButtonClassName = cx('btn-add', { active: isToggleAllActive });

    return (
      <div className={styles.tabHeader}>
        <input
          value={searchQuery}
          onChange={onQueryChange}
          type='text'
          className={styles.searchInput}
          placeholder={t('searchByMail')}
        />
        <span className={styles.iconsWrapper}>
          <div className={toggleButtonClassName} onClick={onToggleAll}>
            <i className='icon-sort' />
          </div>
        </span>
      </div>
    );
  }
}

MailingHeader.propTypes = {
  isToggleAllActive: PropTypes.bool,
  onToggleAll: PropTypes.func,
  searchQuery: PropTypes.string,
  onQueryChange: PropTypes.func
};

export default MailingHeader;
