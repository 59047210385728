import React, { PureComponent } from 'react';
import { Field, getFormValues, reset, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromHTML, ContentState, DefaultDraftBlockRenderMap } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import InputField from 'components/Common/InputField';
import { validateEmail } from 'util/form';
import { resetEmailForm } from 'actions/emails';
import { reduxFormWrapper } from 'helpers';
import styles from 'styles/modules/appealOperatePage.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { translate } from 'react-i18next';
import Progressbar from 'components/Progressbar';
import { mailEditorToolbar } from 'constants/index';
import PropTypes from 'prop-types';
import htmlToDraft from 'html-to-draftjs';

const blockRenderMap = Map({
    'font': {
        element: 'font'
    }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const validate = (values) => {
    const errors = {};
    const { to, copy } = values;

    if (!to) {
        errors.to = 'Обов\'язкове поле';
    } else if (!validateEmail(to)) {
        errors.to = 'Введіть коректний e-mail';
    }

    if (!copy) {
    } else if (!validateEmail(copy)) {
        errors.copy = 'Введіть коректний e-mail';
    }

    return errors;
};


const mapStateToProps = (state) => ({
    formValues: getFormValues('send-new-mail')(state),
    sendEmailSuccess: state.emails.sendEmailSuccess,
    uploadingFile: state.emails.uploadingFile,
    templateList: state.emails.templateList
});

const mapDispatchToProps = (dispatch) => ({
    reset: (name) => dispatch(reset(name)),
    resetEmailForm: () => dispatch(resetEmailForm())
});

@withRouter
@translate()
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'send-new-mail', validate, enableReinitialize: true })

export default class MailingSendForm extends PureComponent {
    state = {
        editorState: EditorState.createEmpty(),
        toolbar: false,
        selectedOption: null,
        isFocused: false,
		styleMap : {
		}
    };
    constructor(props){
        super(props);

        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mailBody !== prevProps.mailBody) {
            if (this.props.mailBody) {
                this.changeEmailBody(this.props.mailBody);
            }

            if (!this.props.mailBody) {
                this.setState({
                    editorState: EditorState.createEmpty()
                });
            }
        }

        if (this.props.sendEmailSuccess) {
            const { refresh } = this.props;

            this.setState({
                editorState: EditorState.createEmpty(),
                selectedOption: null
            });
            this.resetForm();
            refresh();
        }

        if (!this.props.sendFormOpening) {
            this.setState({ selectedOption: null });
            this.props.reset('send-new-mail');
        }
    }

    focus (editorState) {
        this.setState({isFocused: true});
    };

    blur (editorState){
        this.setState({isFocused: false});
    };

    changeEmailBody = (mailBody) => {

        mailBody = mailBody.replace(/<font\s/g,'<font style="" ');
        let i = 6;
        while(i-- > 1) {
            mailBody = mailBody.replace(new RegExp(`" size="${i}"`, "g") , ';font-size:' + (9 + i) + 'px;"');
        }

        mailBody = mailBody.replace(/"\s*color="/g,';color:#');

        const contentBlock = htmlToDraft(mailBody, undefined, extendedBlockRenderMap);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({ editorState: editorState, extendedBlockRenderMap });
    };

    renderInputField = ({ name, label, placeholder }) => (
        <Field
            component={InputField}
            key={name}
            name={name}
            label={label}
            placeholder={placeholder}
            errorPlaceholder={true}
        />
    );

    handleLoadFiles = (e) => {
        const { onUploadFiles, interactionId } = this.props;
        const newFiles = [...e.currentTarget.files];

        onUploadFiles(newFiles, interactionId);
		e.target.value = null;
    };

    handleDeleteFile (fileId){
        const { deleteFile, onDeleteFile, attachmentFiles } = this.props;

		deleteFile(fileId, function(){
			onDeleteFile(attachmentFiles);
		});

	};

    onEditorStateChange = (editorState) => this.setState({ editorState });

    onShowEditorToolbar = () => {
        this.setState(prevState => ({ toolbar: !prevState.toolbar }));
    };

    onChangeSelect = (data) => {
        const { onClickTemplate } = this.props;
        this.setState({ selectedOption: data });
        if (!data.value) {
            this.resetForm();
        } else {
            onClickTemplate(data.value);
        }
    };

    resetForm = () => {
        this.props.reset('send-new-mail');
        this.props.resetEmailForm();
        this.setState({ editorState: EditorState.createEmpty() });
    };

    renderAttachment = (file, index) => (
        <div className={styles.fileWrapper} key={index} onClick={() => this.handleDeleteFile(file.id, index)}>
            <span>{file.name}</span>
            <i className='icon-times'/>
        </div>
    );


    render() {
        const { attachmentFiles, handleSubmit, onSendForm, t, onExpand, uploadingFile, templateList, expand } = this.props;
        const { editorState, toolbar, selectedOption, isFocused } = this.state;
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));


        const {onClickClose} = this.props;
        if (this.props.mailBody) {
            return (
                <form
                    className={styles.formBody}
                    onSubmit={handleSubmit(value => {if(!_.isEmpty(validate(value))) { throw new SubmissionError(validate(value)) } else { onSendForm(value, html);}})}
                >
                    <div className={styles.inlineFields}>
                        <div className={styles.inputWrapper}>
                            {this.renderInputField({
                                name: 'to',
                                placeholder: t('toWhom'),
                                required: true
                            })}
                        </div>
                        <div className={styles.inputWrapper}>
                            {this.renderInputField({
                                name: 'copy',
                                placeholder: t('copy')
                            })}
                        </div>
                    </div>

                    <div className={styles.inlineFields}>
                        <div className={styles.inputWrapper}>
                            {this.renderInputField({
                                name: 'subject',
                                placeholder: t('theme')
                            })}
                        </div>
                    </div>


                    <div className={cx(styles.inputWrapper, styles.textArea)}>
                        <Editor
                            editorState={editorState}
                            wrapperClassName={cx('draft-wrapper', { 'draft-wrapper--toolbar': toolbar }, 'draftWrapperToolbar')}
                            editorClassName={cx('draft-editor', 'draftEditor', {'focused': isFocused})}
                            toolbarClassName={'draftToolbar'}
                            onEditorStateChange={this.onEditorStateChange}
                            blockRenderMap={extendedBlockRenderMap}
                            toolbar={mailEditorToolbar}
                            onFocus={this.focus}
                            onBlur={this.blur}
                        />
                    </div>

                    {attachmentFiles && attachmentFiles.length > 0 && (
                        <div className={styles.commentAttachments}>
                            {attachmentFiles.map(this.renderAttachment)}
                        </div>
                    )}

                    {uploadingFile && <Progressbar className={styles.progressBar}/>}

                    <div className={styles.mailEditorButtons}>
                        <div>
                            <i className={cx('icon-text', { active: toolbar })} onClick={this.onShowEditorToolbar}/>
                            <span className={styles.iconDevider}>|</span>
                            <label className={styles.fileUploader}>
                                <input
                                    type='file'
                                    multiple
                                    onChange={this.handleLoadFiles}
                                    accept='*'
                                />
                                <i className='icon-attach'/>
                            </label>
                        </div>
                        <div>
                            <button className={cx('btn-save', styles.btnSave)}>{t('send')}</button>
                            <i className={expand ? 'icon-shrink2' : 'icon-enlarge2'} onClick={onExpand}/>
                            <i className='icon-close' onClick={onClickClose}  title={t('close')}/>
                        </div>
                    </div>
                </form>
            );
        }
        return null;

    }
}

MailingSendForm.propTypes = {
    refresh: PropTypes.func,
    onUploadFiles: PropTypes.func,
    onSendForm: PropTypes.func,
    onClickTemplate: PropTypes.func,
    onExpand: PropTypes.func,
    initialValues: PropTypes.object,
    mailBody: PropTypes.string,
    attachmentFiles: PropTypes.array,
    sendFormOpening: PropTypes.bool,
    expand: PropTypes.bool,
    onClickClose: PropTypes.func
};
