import { 
  SET_AUDIO_TRACK,
  SAVE_AUDIO_TRACK_TIME,
  SHOW_AUDIO_PLAYER, 
  HIDE_AUDIO_PLAYER,
  SAVE_AUDIO_PLAYER_POSITION
} from 'constants/actions';


export const setAudioTrack = ({ source, title, currentSecond, autoplay, hidden }) => ({
  type: SET_AUDIO_TRACK,
  source, 
  title, 
  currentSecond, 
  autoplay,
  hidden
});

export const showAudioPlayer = () => ({
  type: SHOW_AUDIO_PLAYER
});

export const hideAudioPlayer = () => ({
  type: HIDE_AUDIO_PLAYER
});


export const saveAudioTrackTime = (currentSecond) => ({
  type: SAVE_AUDIO_TRACK_TIME,
  currentSecond
});


export const saveAudioPlayerPosition = (position) => ({
  type: SAVE_AUDIO_PLAYER_POSITION,
  position
})
;
