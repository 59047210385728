import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealOperatePage.module.scss';
import cx from 'classnames';
import { translate } from 'react-i18next';

@translate()
class ChangeItem extends React.Component {
  
  static isLink(value = '') {
      if (value && value.match(/href="(.*?)"/)) {
         return <span dangerouslySetInnerHTML={{ __html: value }} />;
      } else {
        return value;
      }
  }

  constructor(){
    super();
    this.state = {
      multiLine: false,
      open: false
    };
    this.isLink = this.constructor.isLink;
    this.contentRef = React.createRef();
    this.LINE_HEIGHT = 21;
    this.MAX_LINES_COUNT = 3;
  }

  componentDidMount() {
    const textHeight = this.contentRef.current.offsetHeight;
    if (textHeight / this.LINE_HEIGHT > this.MAX_LINES_COUNT) {
      this.setState({ multiLine: true });
    }
  }

  toggleContent = () => this.setState(prevState => ({ open: !prevState.open }));

  getPrefix = () => {
    const { data, t } = this.props;
    return data.oldValue ? `${t('appealHistory.updated')} - ` : '';
  };

  render(){
    const { data, highlight } = this.props;
    const { multiLine, open } = this.state;
      // {highlight(data.newValue)}
    return (
      <li className={cx(styles.historyItem, { [styles.multiLine]: multiLine })}>
        <i className='icon-check'/>
        <div className={cx(styles.historyItemContent, { [styles.limited]: multiLine && !open })} ref={this.contentRef}>
          <span className={styles.historyTitleText}>{this.getPrefix()}{highlight(data.attributeName)}: </span>
          <span className={styles.historyContentText}>{highlight(this.isLink(data.newValue))} </span>
          <span className={styles.changesBefore}>{highlight(this.isLink(data.oldValue))}</span>
        </div>
        { multiLine &&
          <button className={cx(styles.contentToggleButton, styles.absolute)} onClick={this.toggleContent}>
            <i className='icon-kebab-hor' />
          </button>
        }
      </li>
    );
  }
}

ChangeItem.propTypes = {
  data: PropTypes.object,
  highlight: PropTypes.func
};

export default ChangeItem;
